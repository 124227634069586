import { get, post, url } from '../../service/ApiDecorator'

class Login {
  // 获取token
  @url('/auth/eos/login/getToken')
  @post
  getToken() {}

  // 刷新token
  @url('/auth/eos/login/getTokenRefresh')
  @post
  getTokenRefresh() {}

  // 根据学校域名 获取学校信息
  @url('/auth/eos/getschool/byDomain')
  @post
  getSchool() {}

  // 当前登录学校信息
  @url('/auth/eos/my/getschool')
  @post
  getMySchool() {}

  // 获取区域化服务号appid
  @url('/auth/eos/area/getWxAppid')
  @post
  getAreaWxAppid() {}

   // 获取微信appid
  @url('/auth/eos/login/getWxAppid')
  @post
  getWxAppid() {}

  // 获取钉钉appid
  @url('/auth/eos/login/dingtalk/getCorpid')
  @post
  getDdAppid() {}

  // 微信授权code换取用户列表
  @url('/auth/eos/login/getuserinfos')
  @post
  getUserInfo() {}

    // 授权code换取用户列表（区域化）
  @url('/auth/eos/area/getuserinfos')
  @post
  getAreaUserInfo() {}

  // 钉钉授权code换取用户列表
  @url('/auth/eos/login/dingtalk/getUserinfos')
  @post
  getDdUserInfo() {}
  // 微信授权code换取用户列表
  @url('/auth/lvyaform/testjwt')
  @post
  testjwt() {}

  // 登录
  @url('/auth/eos/login/pwd')
  @post
  loginPwd() {}

  // 获取验证码图片
  @url('/common/eos/login/getAuthCode')
  @get
  getAuthCode() {}

  // 微信扫码-获取扫码验证码
  @url('/auth/eos/login/getQrCode')
  @post
  getQrCode() {}

  // 微信扫码-微信端验证验证码是否正确
  @url('/auth/eos/login/verifyQrCode')
  @post
  verifyQrCode() {}

  // 微信扫码-判断验证码是否已经被扫码或者过期
  @url('/auth/eos/login/checkQrCode')
  @post
  checkQrCode() {}

  // 发送手机验证码
  @url('/common/eos/phone/sendCode')
  @get
  sendCode() {}

  // 手机验证码登录
  @url('/auth/eos/login/phone')
  @post
  loginPhone() {}

  // 重置密码
  @url('/auth/eos/login/resetPhonePwd')
  @post
  resetPhonePwd() {}

  // 修改密码
  @url('/auth/eos/login/resetPwd')
  @post
  resetPwd() {}

  // 获取其他学校token
  @url('/auth/eos/other/getToken')
  @post
  getOtherToken() {}

  // 检查当前密码是否安全
  @url('/auth/eos/login/checkWeakPassword')
  @post
  checkWeakPassword() {}

  // 判断是否可以切换用户
  @url('/auth/eos/area/checkSwitch')
  @get
  checkSwitch() {}
  // 获取学校类型接口，区域化
  @url('/region/commom/getSchoolType')
  @get
  getSchoolType() {}
  // 应用工厂-获取游客token
  @url('/app/facapp/datatableval/getVisitorToken')
  @post
  getVisitorToken() {}
  // 应用工厂-获取游客用户
  @url('/app/facapp/datatableval/getAnyUserByType')
  @post
  getAnyUserByType() {}
  // 获取本机ip地址
  @url('/ip')
  @get
  getLocalIp() {}
  // 单校小程序获取scheme 码
  @url('/auth/eos/schoolminapp/generatescheme')
  @post
  generatescheme() {}
}

export default new Login()
