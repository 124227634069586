import { post, url } from '../../service/ApiDecorator'
class DataVisualization {
  // 华山-全区域学生视力情况分布
  @url('/v3/visual/huashang/getHuaShanEyesightAllAreaCount')
  @post
  getHuashanEyesightAllAreaCount() {}

  // 华山-各年级视力统计
  @url('/v3/visual/huashang/getGradeEyesightCount')
  @post
  getGradeEyesightCount() {}

  // 华山-各年级视力情况预警
  @url('/v3/visual/huashang/getGradeEyesightForSchoolRankingCount')
  @post
  getGradeEyesightForSchoolRankingCount() {}

  // 华山-各学期近视情况
  @url('/v3/visual/huashang/getSemesterEyesightCount')
  @post
  getSemesterEyesightCount() {}

  // 华山-历年体育成绩情况
  @url('/v3/visual/huashang/listAvgSportScoreTrend')
  @post
  listAvgSportScoreTrend() {}

  // 华山-学生身体素质
  @url('/v3/visual/huashang/getStuPhysicalHealthRatio')
  @post
  getStuPhysicalHealthRatio() {}

  // 华山中学-历年学生平均身高变化趋势
  @url('/v3/visual/huashang/listAvgStuHeightTrend')
  @post
  listAvgStuHeightTrend() {}

  // 华山中学-历年学生平均体重变化趋势
  @url('/v3/visual/huashang/listAvgStuWeightTrend')
  @post
  listAvgStuWeightTrend() {}

  // 华山中学-年级学年集合
  @url('/v3/visual/huashang/getGradeClassAndYear')
  @post
  getHuaShanGradeClassAndYear() {}

  // 个人体育情况
  @url('/v3/visual/huashang/getSportsAchievementInfo')
  @post
  getSportsAchievementInfo() {}

  // 身体素质变化趋势
  @url('/v3/visual/huashang/getSgTzInfo')
  @post
  getSgTzInfo() {}

  // 个人bmi变化趋势
  @url('/v3/visual/huashang/getMbiInfo')
  @post
  getMbiInfo() {}
}
export default new DataVisualization()
