import { get, post, postJson, url } from '../../service/ApiDecorator'

class Admin {
  // 判断是否有（前后端分离）后台管理权限
  @url('/center/api/isAdmin')
  @get
  getIsAdmin() {}

  // 获取首页数据 --学校信息，用户信息，栏目信息，栏目是否显示通讯录
  @url('/center/api/index')
  @get
  getIndexInfo() {}

  // 获取首页数据 --系统应用和自定义应用分类及列表
  @url('/center/api/appList')
  @get
  getAppList() {}

  // 获取外部链接信任码
  @url('/center/api/app/getTrustCode')
  @get
  getTrustCode() {}

  // 导航目录
  @url('/center/api/app/sysMenu')
  @get
  getSysMenu() {}

  // 导航目录
  @url('/center/api/app/sysMenuAreaAdmin')
  @get
  getSysMenuAreaAdmin() {}

  // 修改密码
  @url('/center/api/updatePwd')
  @get
  updatePwd() {}

  // 验证密码
  @url('/center/api/validateOld')
  @get
  validateOld() {}

  // 获取前台首页模板
  @url('/center/api/getTemplateBySet')
  @get
  getTemplateBySet() {}

  // 根据id获取应用详情
  @url('/center/api/app/getid')
  @get
  getid() {}

  // 根据windon.open() 新打开页面 在部门浏览器会被拦截
  @url('/center/redirectUrl')
  @get
  redirectUrl() {}

  // 系统应用获取用户的按钮code权限
  @url('/center/permissions/getUserInfo')
  @post
  getSysButonCode() {}

  // 获取基础设置信息
  @url('/sys/api/setting/getSetting')
  @get
  getSetting() {}

  // 为admin角色初始化权限
  @url('/center/permissions/setInitAdmin')
  @post
  setInitAdmin() {}

  // 查询课表列表
  @url('/timetable/manage/api/getTimetableSetList')
  @get
  getTimetableSetList() {}

  // 获取课表设置信息
  @url('/timetable/manage/api/getTimetableSet')
  @get
  getTimetableSet() {}

  // 获取教师课表信息
  @url('/timetable/manage/api/getTimetableByTeaId')
  @get
  getTimetableByTeaId() {}

  // 当天校历信息
  @url('/schoolCalendar/:sid/queryInfoByTime')
  @get
  queryInfoByTime() {}

  // 获取知识中心
  @url('/encyclopedia/:id/queryTypes')
  @get
  queryTypes() {}

  // 获取知识中心
  @url('/encyclopedia/:id/queryArtsByTypeid')
  @get
  queryArtsByTypeid() {}

  // 获取班级课表信息
  @url('/timetable/manage/api/getTimetableByOrgId')
  @get
  getTimetableByOrgId() {}

  // 同步等待成功后返回zip文件批量导出pdf
  @url('/v3/pdfConvert/syncBatchConvertPdf')
  @postJson
  syncBatchConvertPdf() {}

  // 生成pdf导出记录
  @url('/v3/stu/searchFileDownLoadList')
  @get
  searchFileDownLoadList() {}

  // 按照时间获取日历列表--新版日历
  @url('/commoncalendar/queryTime')
  @post
  getCalendarByTime() {}

  // APP统计 不走redirectUrl的调用本接口
  @url('/center/api/stat/app')
  @get
  getAppRank() {}

  // 获取用户最近点击应用
  @url('/center/api/appLastTimeList')
  @get
  appLastTimeList() {}

  // 获取前10位 应用使用排行榜 老师使用排行榜 学生使用排行榜
  @url('/center/api/stat/getList')
  @get
  getAppRankList() {}

  // 首页教师发展统计
  @url('/v3/itemData/getTeacherDevelopScenceForEos')
  @get
  getTeacherDevelopScenceForEos() {}

  // 云平台素养教师发展相关初始化 ---params： {eosSchoolTempleId: 28}  固定传28
  @url('/v3/initTeacherDevelopData')
  @get
  initTeacherDevelopData() {}

  // 获取用户常用应用
  @url('/center/api/appHistoryList')
  @get
  getAppHistoryList() {}

  // 获取待办数据  返回type类型 1.审批请示、2.请假管理、3.预约管理、4.申请加入、5.宿舍管理
  @url('/center/api/getToDoList')
  @get
  getToDoList() {}

  // 获取基础字段
  @url('/temperature/api/user/getBaseInfo')
  @get
  getUserBaseInfo() {}
}

export default new Admin()
