import FormApi from '../../api/eos/Form'

/**
 * 时间处理
 */
class DoTime {
  /**
   * 时间秒数格式化
   * @param s 时间戳（单位：秒）
   * @returns {*} 格式化后的时分秒
   */
  secToTime(s) {
    var t
    if (s > -1) {
      var hour = Math.floor(s / 3600)
      var min = Math.floor(s / 60) % 60
      var sec = s % 60
      if (hour < 10) {
        t = '0' + hour + ':'
      } else {
        t = hour + ':'
      }

      if (min < 10) { t += '0' }
      t += min + ':'
      if (sec < 10) { t += '0' }
      t += sec.toFixed(0)
    }
    return t
  }

  /**
   * 时间转为秒
   * @param time 时间(00:00:00)
   * @returns {string} 时间戳（单位：秒）
   */
  timeToSec(time) {
    var s = ''

    var hour = time.split(':')[0]
    var min = time.split(':')[1]
    var sec = time.split(':')[2]

    s = Number(hour * 3600) + Number(min * 60) + Number(sec)

    return s
  }

  // 时间格式化显示 yyyy-MM-DD HH-mm-ss
  formatTime(time, format = 'yyyy-MM-dd HH:mm:ss') {
    const datetime = new Date(time)
    if (datetime == 'Invalid Date') return
    //	let datetime = new Date(toDateTime(time))
    //	console.log(datetime+"aa")
    const year = datetime.getFullYear()
    const month = datetime.getMonth()
    const date = datetime.getDate()
    const hour = datetime.getHours()
    const minute = datetime.getMinutes()
    const second = datetime.getSeconds()

    if (/yyyy/i.test(format)) {
      format = format.replace(/yyyy/i, year)
    }
    if (/MM/.test(format)) {
      if ((month + 1) < 10) {
        format = format.replace(/MM/i, '0' + (month + 1))
      } else {
        format = format.replace(/MM/i, (month + 1))
      }
    }
    if (/dd/i.test(format)) {
      if (date < 10) {
        format = format.replace(/dd/i, '0' + date)
      } else {
        format = format.replace(/dd/i, date)
      }
    }
    if (/HH/i.test(format)) {
      if (hour < 10) {
        format = format.replace(/HH/i, '0' + hour)
      } else {
        format = format.replace(/HH/i, hour)
      }
    }
    if (/mm/.test(format)) {
      if (minute < 10) {
        format = format.replace(/mm/i, '0' + minute)
      } else {
        format = format.replace(/mm/i, minute)
      }
    }
    if (/ss/i.test(format)) {
      if (second < 10) {
        format = format.replace(/ss/i, '0' + second)
      } else {
        format = format.replace(/ss/i, second)
      }
    }
    return format
  }

  /**
   * author:Beginner
   * create:20180901
   * revamp:20200419
   * email:BeginnerMind@163.com
   * @param {*} format yyyy-MM-dd hh:mm:ss:SS 星期w 第q季度
   * @param {*} date date格式、时间格式字符串(yyyy-MM-dd hh:mm:ss||yyyy/MM/dd hh:mm:ss)(其他字符串格式请外部处理成date格式传进来)、时间戳、不传则返回当前时间、传递数据不可转为date则返回空('')
   * @param {*} errorReturn 如果传递的 date 无法正常转为date格式，指定返回标识
   */
  dateFormat(format, reqDate, errorReturn) {
    let date
    if (typeof (errorReturn) === 'undefined') { errorReturn = '' }
    if (typeof (reqDate) === 'undefined') {
      date = new Date()
    } else {
        if (typeof (reqDate) === 'string') {
          let aDate = reqDate.split(/[- :\/]/)
          aDate[3] = aDate[3] ? aDate[3] : '00'
          aDate[4] = aDate[4] ? aDate[4] : '00'
          aDate[5] = aDate[5] ? aDate[5] : '00'
          date = new Date(aDate[0], aDate[1] - 1, aDate[2], aDate[3], aDate[4], aDate[5])
        } else if (Array.isArray(reqDate)) {
          date = new Date(reqDate[0], reqDate[1] - 1, reqDate[2], reqDate[3] || 0, reqDate[4] || 0, reqDate[5] || 0)
        } else {
          date = new Date(reqDate || '')
        }
        if (!date || !date.getYear()) {
          return errorReturn
        }
    }
    // 格式化主逻辑
    var Week = ['日', '一', '二', '三', '四', '五', '六']
    var o = {
        'y+': date.getFullYear(), // year
        'M+': date.getMonth() + 1, // month
        'd+': date.getDate(), // day
        'h+': date.getHours(), // hour
        'm+': date.getMinutes(), // minute
        's+': date.getSeconds(), // second
        'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
        'S': date.getMilliseconds(), // millisecond
        'w': Week[date.getDay()]
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
    }
    return format
  }
  /**
   * 获取时间对象
   * @param {*} _stime 时间字符串
   * @returns 时间对象
   */
  newDate(_stime) {
		let _date
		if (typeof (_stime) === 'string') {
			let aDate = _stime.split(/[- :\/]/)
			aDate[3] = aDate[3] ? aDate[3] : '00'
			aDate[4] = aDate[4] ? aDate[4] : '00'
			aDate[5] = aDate[5] ? aDate[5] : '00'
			_date = new Date(aDate[0], aDate[1] - 1, aDate[2], aDate[3], aDate[4], aDate[5])
		} else {
			_date = new Date(_stime)
		}
		return _date
	}
  /**
   * 时间对象加减年
   * @param {*} _stime 时间字符串
   * @param {*} _addYearNum number可以是负数
   * @returns 计算后的时间对象
   */
	addDate_Year(_oDate, _addYearNum) {
		_oDate.setFullYear(_oDate.getFullYear() + _addYearNum)
    return _oDate
	}
  addDate_Date(_oDate, _addDateNum) {
    return new Date(_oDate.getTime() + (_addDateNum * 24 * 60 * 60 * 1000))
  }
	/**
	* author:Beginner
	* create:20210720
	* revamp:
	* email:BeginnerMind@163.com
	* description:日期计算
	* @param {*} _stime 可以是时间字符串也可以是时间戳
	* @param {*} _addDate 增加的天数，可以是负数
	*/
	addDate(_stime, _addDate) {
		let _date = this.getDate(_stime)
		let _rsTime = _date.getTime() + (_addDate * 24 * 60 * 60 * 1000)
		return this.dateFormat('yyyy-MM-dd', _rsTime)
	}
	addYear(_stime, _addYear) {
		let _date = this.getDate(_stime)
		let _nDate = _date.setFullYear(_date.getFullYear() + _addYear)
		return this.dateFormat('yyyy-MM-dd', _nDate)
	}
	getMonthLastDate(_stime) {
		let _MonthsDates = { '01': '31', '03': '31', '05': '31', '07': '31', '08': '31', '10': '31', '12': '31', '04': '30', '06': '30', '09': '30', '11': '30', '02': '' }
		let _lastDate = _stime.substr(0, 8)
		if (_stime.substr(5, 2) != '02') {
			_lastDate += _MonthsDates[_stime.substr(5, 2)]
		} else if (Number(_stime.substr(0, 4)) % 4 == 0) {
			_lastDate += '29'
		} else {
			_lastDate += '28'
		}
		return _lastDate
	}
  getPrevNextMonth(_stime, _type) {
		let oDate = this.getDate(_stime)
		let _month = oDate.getMonth()
		let _year = oDate.getFullYear()
		if (_type == 'prev') {
			if (_month != 0) {
				_month--
			} else {
				_month = 11
				_year--
			}
		} else if (_type == 'next') {
			if (_month != 11) {
				_month++
			} else {
				_month = 0
				_year++
			}
		}
    oDate.setDate(1)
		oDate.setMonth(_month)
		oDate.setFullYear(_year)
		return this.dateFormat('yyyy-MM', oDate.getTime())
	}

	getDate(_stime) {
		let _date
		if (typeof (_stime) === 'string') {
			let aDate = _stime.split(/[- :\/]/)
			aDate[3] = aDate[3] ? aDate[3] : '00'
			aDate[4] = aDate[4] ? aDate[4] : '00'
			aDate[5] = aDate[5] ? aDate[5] : '00'
			_date = new Date(aDate[0], aDate[1] - 1, aDate[2], aDate[3], aDate[4], aDate[5])
		} else if (Array.isArray(_stime)) {
      _date = new Date(_stime[0], _stime[1] - 1, _stime[2], _stime[3] || 0, _stime[4] || 0, _stime[5] || 0)
    } else {
			_date = new Date(_stime)
		}
		return _date
	}
  getDayName(_stime) {
    let _date = this.getDate(_stime)
    let _sDay = ['日', '一', '二', '三', '四', '五', '六']
    return _sDay[_date.getDay()]
  }
  /**
   * 获取当前日期
   * @param {*} type 0：yyyy-MM-dd 1:昨天  9：MMdd
   * @returns
   */
  getDateTime(type = 0) {
    let datetime = type == 1 ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000) : new Date()
    let year = datetime.getFullYear()
    let month = datetime.getMonth() + 1
    const date = datetime.getDate()
    const day = date < 10 ? '0' + date : date
    if (month < 10) month = '0' + month
    return type == 0 || type == 1 ? `${year}-${month}-${day}` : type == 9 ? `${month}${day}` : type == 10 ? `${year}-${month}` : `${month}月${day}日`
  }

  /**
   * 获取一段时间的日期列表
   * @param {*} dateNow :Date类
   * @param {*} intervalDays :间隔天数
   * @param {*} bolPastTime Boolean,判断在参数date之前，还是之后
   * return arr 开始时间到结束时间，日期时间列表
   */
  getDateRange(dateNow, intervalDays, bolPastTime) {
    let oneDayTime = 24 * 60 * 60 * 1000
    let list = []
    let lastDay
    let nextDay = dateNow.getTime()

    if (bolPastTime == true) {
      lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime)
      list.push(this.formateDate(lastDay))
      list.push(this.formateDate(dateNow))
    } else {
      list.push(this.formateDate(dateNow))
      for (let i = 0; i < intervalDays; i++) {
        if (i > intervalDays) return
        nextDay = new Date(nextDay - oneDayTime)
        list.push(this.formateDate(nextDay))
      }
      // lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime)
      // list.push(this.formateDate(lastDay))
    }
    return list
  }

  formateDate(time) {
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let day = time.getDate()

    if (month < 10) {
      month = '0' + month
    }

    if (day < 10) {
      day = '0' + day
    }

    return year + '-' + month + '-' + day + ''
  }
  /**
   * 转换时间，时间戳为格式化 yyyy-MM-dd hh:mm:ss
   * @param {*} date 时间、时间戳等
   */
  getFullDate(str, type = 'datatime') {
    let date = new Date(str)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    let minute = date.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    let second = date.getSeconds()
    second = second < 10 ? ('0' + second) : second
    if (type == 'shortTime') return h + ':' + minute
    if (type == 'time') return h + ':' + minute + ':' + second
    if (type == 'day') return y + '-' + m + '-' + d
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
  }

  getCurDate() {
    // 获取当前日期
    const date = new Date()
    const week = date.getDay()// 周(0~6,0表示星期日)
    const days = ['日 ', '一 ', '二 ', '三 ', '四 ', '五 ', '六 ']
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日  星期${days[week]}`
  }

  getCurrentDate(type) {
    // 获取当前日，用于素养护照文件名
    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    let minute = date.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    let second = date.getSeconds()
    second = second < 10 ? ('0' + second) : second
    if (type == 'pdf') {
      return `_${y}-${m}-${d}`
    } else {
      return `_${y}-${m}-${d}-${h}-${minute}-${second}`
    }
  }

  getCurrentDateScore() {
    // 获取当前日，用于素养得分文件名后缀
    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    let minute = date.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute
    let second = date.getSeconds()
    second = second < 10 ? ('0' + second) : second
    return `${y}${m}${d}${h}${minute}${second}`
  }

  /**
   * 时间转换
   * type 1：当前时间，2：当天 3：当周 4：近7天 5：当月 6：近30天 7：当年
   */
  async timeToCustom(type) {
    let curTime = await FormApi.getSysTimestamp()
    console.log('curTime:', curTime)
    // 取当前日期
    let start = new Date(new Date(parseInt(curTime)).toLocaleDateString())
    let end = new Date(new Date(parseInt(curTime)).toLocaleDateString())
    switch (type) {
      case '2': // 2：当天
        start = this.formatTime(start.getTime())
        end = this.formatTime(end.getTime() + 3600 * 1000 * 24 - 1)
        curTime = start + ',' + end
        break
      case '3': // 3：当周
        const nowDay = new Date().getDay()
        start = this.formatTime(start.getTime() - 3600 * 1000 * 24 * (nowDay - 1))
        end = this.formatTime(end.getTime() + 3600 * 1000 * 24 * (7 - nowDay + 1) - 1)
        curTime = start + ',' + end
        break
      case '4': // 4：近7天
        start = this.formatTime(start.getTime() - 3600 * 1000 * 24 * 7)
        end = this.formatTime(end.getTime())
        curTime = start + ',' + end
        break
      case '5': // 5：当月
        const nowDate = new Date().getDate()
        const noeMonth = new Date().getMonth() + 1
        let day = 0
        if ([1, 3, 5, 7, 8, 10, 12].includes(noeMonth)) {
          day = 31
        } else if ([4, 6, 9, 11].includes(noeMonth)) {
          day = 30
        } else {
          let year = new Date().getFullYear()
          if (year % 4) {
            day = 28
          } else {
            day = 29
          }
        }
        start = this.formatTime(start.getTime() - 3600 * 1000 * 24 * (nowDate - 1))
        end = this.formatTime(end.getTime() + 3600 * 1000 * 24 * (day - nowDate + 1) - 1)
        curTime = start + ',' + end
        break
      case '6': // 6：近30天
        start = this.formatTime(start.getTime() - 3600 * 1000 * 24 * 30)
        end = this.formatTime(end.getTime())
        curTime = start + ',' + end
        break
      case '7': // 7：当年
        let year = new Date(parseInt(curTime)).getFullYear()
        start = this.formatTime(new Date(`${year}/1/1 00:00:00`))
        end = this.formatTime(new Date(`${year}/12/31 23:59:59`))
        curTime = start + ',' + end
        break
      default:
        curTime = this.formatTime(parseInt(curTime))
        break
    }
    return curTime
  }
  /**
   *
   * @param {*} data //Fri Oct 31 18:00:00 UTC+0800 2008
   * @returns 2008-10-31
   */
  toData(data) {
    data = data + ''
    let newData = ''
    let month = {
      'Jan': '01',
      'Feb': '02',
      'Mar': '03',
      'Apr': '04',
      'May': '05',
      'Jun': '06',
      'Jul': '07',
      'Aug': '08',
      'Sep': '09',
      'Oct': '10',
      'Nov': '11',
      'Dec': '12'
    }
    let str = data.split(' ')
    newData = str[5] + '-'
    newData = newData + month[str[1]] + '-' + str[2]
    return newData
  }
  /**
   * @param {*} type  1: 2021-1-1, 2: 2-1, 3: 2月1日
   * @returns [{week: 周一, day: 2021-1-1}]
   */
  getCurWeek() {
    let currentDate = new Date()
    let timesStamp = currentDate.getTime()
    let currenDay = currentDate.getDay()
    let dates = []
    let week = ['一 ', '二 ', '三 ', '四 ', '五 ', '六 ', '日']
    for (var i = 0; i < 7; i++) {
      let day = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/\//g, '-')
      let splitArr = day.split('-')
        dates.push({
          week: `周${week[i]}`,
          fullday: day,
          day: `${splitArr[1]}月${splitArr[2]}日`
        })
    }
    return dates
  }

  /**
   * 计算剩余时间
   * @type {*}
   */
  computedResidualTime(createTime) {
    let date = new Date()
    let currentTime = parseInt(date.getTime() / 1000)
    // 获取距离1970年1月1日00:00:00的毫秒数，并转换成秒数，通过parseInt转成整秒数
    // 在我这个项目中是两天的过期时间
    let allSeconds = 2 * 24 * 60 * 60
    // createTime获取的时间，单位是秒
    let residualTime = allSeconds - (currentTime - createTime)
    // 这是剩余的所有秒数（规定时间过期时间－（本机距离1970年1月1日00:00:00的秒数－后台的创建时间））
    let day = parseInt(residualTime / (24 * 3600)) // 剩余天数
    let hour = parseInt((residualTime) % (24 * 3600) / 3600) // 剩余小时
    let minute = parseInt((residualTime) % 3600 / 60) // 剩余分钟
    let second = parseInt((residualTime) % 60) // 剩余秒数
    return `剩余时间：${day}天${hour}小时${minute}分${second}秒`
  }
  /**
   *  获取当前天 00:00:00 时间戳
   */
  getTodayTime() {
    let curDay = this.getDateTime()
    return new Date(`${curDay} 00:00:00`).getTime()
  }

  /**
   * 获取年数据
   */
  getYearList(start, end) {
    let startYear = 0
    let endYear = 0
    if (start) {
      startYear = start
      endYear = end
    } else {
      let currentYear = new Date().getFullYear()
      let lastNum = currentYear.toString().substring(currentYear.toString().length - 1) * 1
      startYear = currentYear - lastNum
      endYear = currentYear + (9 - lastNum)
    }
    let yearList = []
    for (let index = startYear; index <= endYear; index++) {
      yearList.push(index)
    }
    return yearList
  }

  /**
   * 日期转时间戳
   * str :2015-03-05 17:59:00.0 转时间戳（秒）
   */
  getStamps(str) {
    str = str.substring(0, 19)
    str = str.replace(/-/g, '/')
    const timestamp = new Date(str).getTime()
    return timestamp / 1000
  }
  /**
   * 时间按数组格式 转换为日期字符串
   * @param {*} arr [2022, 8, 18, 11, 45, 41]
   * @param {*} separator '/', '-'
   */
  getTimeFromArr(arr, separator = '/') {
    return `${arr.slice(0, 3).join(separator)} ${arr.slice(3, 6).join(':')}`
  }
  /**
   * 把日期字符串转换为日期，ios系统特殊处理
   * @param {*} time 日期字符串
   * @returns 日期对象
   */
  getDateFromStr(time) {
    return new Date(this.isiOS ? time.replace(/-/g, '/') : time)
  }
  getDayCompDate(startDate, endDate) {
    const sd = new Date(startDate.replace(/-/g, '/'))
    const ed = new Date(endDate.replace(/-/g, '/'))
    return Math.round((ed - sd) / (1000 * 60 * 60 * 24)) + 1
  }
}

// 导出单例，方便直接使用
export default new DoTime()
