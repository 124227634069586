import { postJson, post, get, url } from '../../service/ApiDecorator'

class Certificate {
  // 已下发证书列表
  @url('/v3/ruleCertificate/getUserRuleCertificateList')
  @postJson
  getUserRuleCertificateList() { }
  // 已下发证书详情
  @url('/v3/ruleCertificate/getUserRuleCertificateDetailList')
  @postJson
  getUserRuleCertificateDetailList() { }

  // 已下发证书详情(无权限的接口)
  @url('/v3/ruleCertificate/getUserRuleCertificateDetailListNoAuth')
  @postJson
  getUserRuleCertificateDetailListNoAuth() { }

  // 获取证书列表
  @url('/v3/ruleCertificate/getCertificateList')
  @postJson
  getCertificateList() { }

  // 获取证书模板列表
  @url('/v3/ruleCertificate/getCertificateLibraryList')
  @postJson
  getCertificateLibraryList() { }

  // 删除证书
  @url('/v3/ruleCertificate/deleteRuleCertificateInfo')
  @postJson
  deleteRuleCertificateInfo() { }

  // 保存证书
  @url('/v3/ruleCertificate/insertRuleCertificateInfo')
  @postJson
  insertRuleCertificateInfo() { }

  // 修改证书
  @url('/v3/ruleCertificate/updateRuleCertificateInfoV2')
  @postJson
  updateRuleCertificateInfoV2() { }

  // 证书详情
  @url('/v3/ruleCertificate/getRuleCertificateInfo')
  @postJson
  getRuleCertificateInfo() { }

  // 查询个人获得证书
  @url('/app/certificate/getMyAcquireCertificateList')
  @postJson
  getMyAcquireCertificateList() { }

  // 删除证书
  @url('/v3/ruleCertificate/delRuleCertificateInfo')
  @postJson
  delRuleCertificateInfo() { }

  // 初始化证书
  @url('/v3/ruleCertificate/initCertificateLibrary')
  @postJson
  initCertificateLibrary() { }

  // 新增证书配置【手动下发证书】
  @url('/v3/ruleCertificate/insertRuleCertificateConfigure')
  @postJson
  insertRuleCertificateConfigure() { }

  // 获取上汇证书列表
  @url('/v3/ruleCertificate/getShCertificateList')
  @postJson
  getShCertificateList() { }

  // 证书人员模板下载
  @url('/v3/ruleCertificate/getTemplate')
  @get
  getCertificateTemplate() { }

  // 批量赋分
  @url('/v3/ruleCertificate/batchAddScore')
  @post
  batchAddScore() { }

  // 获取证书时间
  @url('/app/certificate/getCertificateDate')
  @post
  getCertificateDate() { }

  // 获取证书时间
  @url('/v3/ruleStuLog/listCertAcquiredScore')
  @get
  listCertAcquiredScore() { }

  // 发放证书(区域)
  @url('/v3/ruleCertificate/getRuleCertificateAcquires')
  @get
  getRuleCertificateAcquires() { }

  // 确定并生成签章
  @url('/v3/ruleCertificate/createCertStamp')
  @get
  createCertStamp() { }

  // 确定并生成签章扫码详情
  @url('/v3/ruleCertificate/getCertAcquireInfo')
  @get
  getCertAcquireInfo() { }

  // 批量下发
  @url('/v3/ruleCertificate/batchUpdateState')
  @post
  batchUpdateState() { }

  // 发放证书导入数据成功获取数据
  @url('/v3/ruleCertificate/getUserRuleCertificateReleaseDetailList')
  @postJson
  getUserRuleCertificateReleaseDetailList() { }

  // 证书详情
  @url('/v3/ruleCertificate/getCertInfoByAcquireId')
  @get
  getCertInfoByAcquireId() { }

  // 确认并下发
  @url('/v3/ruleCertificate/saveAndDistribute')
  @get
  saveAndDistribute() { }

  // 下载
  @url('/v3/ruleCertificate/batchExportPdf')
  @post
  batchExportPdf() { }

  // 导出记录
  @url('/v3/ruleCertificate/getDownLoadPdfList')
  @get
  getDownLoadPdfList() { }

  // 发放证书
  @url('/v3/ruleCertificate/getUserRuleCertificates')
  @post
  getUserRuleCertificates() { }

  // 导入进度接口
  @url('/v3/ruleCertificate/importCountInfo')
  @get
  importCountInfo() { }

  // 对接印章印章集合
  @url('/v3/ruleCertificate/getCertStampList')
  @get
  getCertStampList() { }

  // 导入错误信息
  @url('/v3/ruleCertificate/getImportErrorUrl')
  @get
  getImportErrorUrl() { }

  // 手动下发
  @url('/v3/ruleCertificate/changeStampFileStatus')
  @get
  changeStampFileStatus() { }

  // 删除数据
  @url('/v3/ruleCertificate/delByAcquireId')
  @get
  delByAcquireId() { }

  // 预览删除
  @url('/v3/ruleCertificate/delAcquireReleaseById')
  @get
  delAcquireReleaseById() { }
}
export default new Certificate()
