import DoApi from '../DoApi'
class Storage {
  constructor() {
  }
  // 设置缓存
  setItem(params) {
    let obj = {
      name: '',
      value: '',
      expires: 60000, // 默认60秒
      startTime: new Date().getTime() // 记录何时将值存入缓存，毫秒级
    }
    let options = {}
    try {
      // 将obj和传进来的params合并
      Object.assign(options, obj, params)
      if (options.expires) {
        // 如果options.expires设置了的话
        // 以options.name为key，options为值放进去
        sessionStorage.setItem(options.name, JSON.stringify(options))
      } else {
        // 如果options.expires没有设置，就判断一下value的类型
        // let type = Object.prototype.toString.call(options.value)
        // 如果value是对象或者数组对象的类型，就先用JSON.stringify转一下，再存进去
        if (Object.prototype.toString.call(options.value) == '[object Object]') {
          options.value = JSON.stringify(options.value)
        }
        if (Object.prototype.toString.call(options.value) == '[object Array]') {
          options.value = JSON.stringify(options.value)
        }
        sessionStorage.setItem(options.name, options.value)
      }
    } catch (err) {
      console.log('ajax cache error')
      // 清除数据应用和应用工厂缓存
      sessionStorage.clear()
      sessionStorage.clear('fac_')
    }
  }
  hasKey(name) {
    const sessionKeys = Object.keys(sessionStorage)
    let isExist = false
    for (let i = 0; i < sessionKeys.length; i++) {
      // 可以对key 进行分析从而决定是否要删除sessionStorage 里的缓存
      if (sessionKeys[i] == name) isExist = true
    }
    return isExist
  }
  // 拿到缓存
  getItem(name) {
    let item = sessionStorage.getItem(name)
    if (!item) return
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item)
    } catch (error) {
      // 如果不行就不是json的字符串，就直接返回
      console.lg(error)
    }
    // 如果有startTime的值，说明设置了失效时间
    if (item.startTime) {
      let date = new Date().getTime()
      // 何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
      if (date - item.startTime > item.expires) {
        // 缓存过期，清除缓存，返回false
        sessionStorage.removeItem(name)
        return false
      } else {
        // 缓存未过期，返回值
        return item.value
      }
    } else {
      // 如果没有设置失效时间，直接返回值
      return item.value
    }
  }
  // 移出缓存
  removeItem(name) {
    sessionStorage.removeItem(name)
  }
  // 移出全部缓存
  clear(prefix = 'dataApp_') {
    const sessionKeys = Object.keys(sessionStorage)
    for (let i = 0; i < sessionKeys.length; i++) {
      // 可以对key 进行分析从而决定是否要删除sessionStorage 里的缓存
      if (sessionKeys[i]?.indexOf(prefix) > -1) sessionStorage.removeItem(sessionKeys[i])
    }
  }
  // 取所有参数值作为变量名,暂只对数据应用dataApp_
  getStorageName(paramJson, prefix = 'dataApp_') {
    if (!paramJson) return ''
    let names = prefix + DoApi.jsonUrlFormat(paramJson)
    return names
  }
}
//  导出单例，方便直接使用
export default new Storage()
