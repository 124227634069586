import Vue from 'vue'
import Vuex from 'vuex'

import animation from './modules/animation'
import yzsyStore from './modules/yzsy'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    animation,
		yzsyStore, // 亦庄实验中学门户网站store
  }
})

export default store
