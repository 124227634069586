/**
 * Created by lgzhang08@gmail.com 20190514
 * 设备相关信息
 */
class DeviceUtil {
  // 设备类型
  getDeviceType() {
    var u = navigator.userAgent
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    var isQYWX = u.indexOf('wxwork') > -1 // 企业微信
    var isWeixin = /MicroMessenger/i.test(u) && u.indexOf('wxwork') == -1 // 是微信，但不是企业微信
    var isDingDing = window.dd && window.dd.env && window.dd.env.platform != 'notInDingTalk'
    // 小程序 debugDevice=miniPro为本地调试
    var isMiniProgram = u.indexOf('miniProgram') > -1 || u.indexOf('miniprogram') > -1 || window?.__wxjs_environment == 'miniprogram' || /debugDevice=miniPro/.test(location.href) // 小程序
    var isPcWechat = u.indexOf('WindowsWechat') > -1
    // 手机小程序，排除web小程序
    var isMiniProgramApp = isMiniProgram && !isPcWechat || false
    // TODO 小程序环境下，微信强制改成h5
    // if (isMiniProgram) {
    //   isWeixin = false
    //   isQYWX = false
    // }
    // TODO 预览页面先不起用
    if (/phonePreview/.test(location.href)) {
      isWeixin = false
      isQYWX = false
    }
    const deviceObj = { isAndroid, isiOS, isQYWX, isWeixin, isDingDing, isMiniProgram, isPcWechat, isMiniProgramApp }
    return deviceObj
  }
  // 是否是web端
  isWeb() {
    const types = this.getDeviceType()
    return !types.isAndroid && !types.isiOS && !types.isQYWX && !types.isWeixin && !types.isDingDing
  }
  // 是否是第三方回调
  isAuthCallBack() {
    const types = this.getDeviceType()
    return types.isQYWX || types.isWeixin || types.isDingDing || types.isPcWechat || types.isMiniProgram
  }
  // 微信相关
  isWechat() {
    const types = this.getDeviceType()
    return types.isQYWX || types.isWeixin || types.isPcWechat
  }
}

// 导出单例，方便直接使用
export default new DeviceUtil()
