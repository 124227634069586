import { get, post, url } from '../../service/ApiWebDecorator'

// 开通素养学校
class OpenSchool {
  // 获取学校列表
  @url('/v3/school/getSchoolPage')
  @post
  getSchoolPage() {}

  // 修改学校状态
  @url('/v3/school/changeSchoolFlg')
  @post
  changeSchoolFlg() {}

  // 根据学校名称获取EOSID
  @url('/v3/eos/getEosSchoolByName')
  @post
  getEosSchoolByName() {}

  // 组织分页
  @url('/v3/eos/getEosDepartmentPage')
  @post
  getEosDepartmentPage() {}

  // 添加/修改学校  第一步
  @url('/v3/school/addSchoolInfo')
  @post
  addSchoolInfo() {}

  // 同步指定学校数据  1.5步
  @url('/v3/syncAllEosData')
  @post
  syncAllEosData() {}

  // 添加/修改学校管理员信息   第二步
  @url('/v3/school/addSchoolUserInfo')
  @post
  addSchoolUserInfo() {}

  // 查询学校详情
  @url('/v3/school/getSchoolInfoDetail')
  @post
  getSchoolInfoDetail() {}

  // 添加EOS微信引用
  @url('/v3/eos/addEosWxApp')
  @post
  addEosWxApp() {}

  // 微信可信域名验证
  @url('/v3/upFile')
  @post
  Filedata() {}

  // 编辑时获得角色列表
  @url('/v3/sysRole/getListBySchoolId')
  @post
  getListBySchoolId() {}

  // 获取组织
  @url('/v3/org/getOrgTreeBeanListBySchoolId')
  @post
  getOrgTreeBeanListBySchoolId() {}

  // 获取组织(选人插件)
  @url('/v3/getOrgListRecursiveBySchoolId')
  @post
  getOrgListRecursiveBySchoolId() {}

  // 获取老师(选人插件)
  @url('/v3/getTeaPageBySchoolId')
  @post
  getTeaPageBySchoolId() {}

  // 根据eosSchoolId查询学校
  @url('/v3/eos/getEosSchoolByEosId')
  @post
  getEosSchoolByEosId() {}
  // 开通学校初始化
  @url('/v3/school/initSchoolIfNecessary')
  @get
  initSchoolIfNecessary() {}
}
export default new OpenSchool()
