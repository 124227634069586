/*
*  数据检验
*/
class DoRequire {
  /*
   * 表单提交数据校验
   * 循环data,返回第一个不合格的数据
   * return {'name': '', error: ''}
    */
  submitFormReg(list) {
    let data = this.getFields(list)
    let errorTips = ''
    if (!data) {
      errorTips = '不能为空'
      return { 'name': '', error: errorTips }
    }
    let isEmpty = false
    let isValida = false
    for (let i = 0; i < data.length; i++) {
      // TODO, 枚举字段val值处理，强制增加 formId, itemId
      // if ([4, 5, 16, 17].includes(data[i].type)) {
      //   if (data[i].val) {
      //     try {
      //       let val = JSON.parse(data[i].val)
      //       val.forEach(valItem => {
      //       })
      //     }
      //   }
      // }
      if (data[i].eosType) {
        if (data[i].eosType == 10001 || data[i].eosType == 10002 || data[i].eosType == 10003) {
          if (data[i].val == '') {
            errorTips = data[i].name + '不能为空'
            return { 'name': '', error: errorTips }
          }
          if (data[i].eosType == 10002) {
            if (!/^[0-9A-Za-z]{3,12}$/.test(data[i].val)) {
              return { 'name': '', error: data[i].name + '3~12位字母或者数字组成' }
            }
          }
        }
        if (data[i].eosType == 10004 || data[i].eosType == 10012) {
          if (data[i].val == '') return false
          if (!/^1[3456789]\d{9}$/.test(data[i].val)) {
            return { 'name': '', error: data[i].name + '输入格式错误' }
          }
        }
      }
      // 级联字段 '[]'值处理
      if (data[i].type == '34' || data[i].type == '35') {
        if (data[i].val == '[]') {
          data[i].val = ''
        }
      }
      if (data[i].type == '32') { // 分组表单 需要循环校验
        let groupList = []
        data[i].countList && data[i].countList.forEach(element => {
          groupList = groupList.concat(element.showState != 4 && element.childItem)
        })
        if (data[i].isNull === 'N') {
          let isTrue = groupList.filter(list =>
            !['8', '9', '30'].includes(list.type)
          ).some(item => item.val)
          if (!isTrue) {
            errorTips = data[i].name + '不能为空'
            return { 'name': '', error: errorTips }
          }
        }
        let errorMsgs
        for (let index = 0; index < data[i].countList?.length; index++) {
          let element = data[i].countList[index]
          let errorMsg = this.submitFormReg(element.childItem)
          if (errorMsg) {
            errorTips = data[i].name + errorMsg.error
            return errorMsgs = { 'name': '', error: errorTips }
          }
        }
        if (errorMsgs) {
            return errorMsgs
        }
      } else if (data[i].isNull === 'N' && !data[i].val && data[i].val !== 0) { // TODO 引用数据有值 不参与判断
        if (data[i].showState == 4) continue // 隐藏大于必填校验
        isEmpty = true
        for (let key in data[i].typeJson) {
          if (key === 'isNullErrMsg' && data[i].typeJson[key]) {
            isValida = true
            errorTips = data[i].typeJson[key]
          }
        }
        if (!isValida) errorTips = data[i].name + '不能为空'
        break
      }
    }
    // 字符长度校验
    var lenReg = this.lengthReg(data)
    if (lenReg) {
      return { 'name': '', error: lenReg }
    }
    // 格式校验
    var regFormatResult = this.regFomat(data)
    // 枚举字段校验
    let regEnumResult = this.submitEnum(data)
    // 级联校验
    let regCascadeResult = this.submitCascade(data)
    if (isEmpty) { // 为空校验
      return { 'name': '', error: errorTips }
    } else if (regFormatResult) {
      errorTips = regFormatResult
      return { 'name': '', error: errorTips }
    } else if (regEnumResult) {
      errorTips = regEnumResult
      return { 'name': '', error: errorTips }
    } else if (regCascadeResult) {
      errorTips = regCascadeResult
      return { 'name': '', error: errorTips }
    } else {
      return false
    }
  }
  // 格式校验
  regFomat(formList) {
    var errorTips = '填写不正确'
    for (let i = 0; i < formList.length; i++) {
      /* if(formList[I].type =="")*/
      // 邮箱
      if (formList[i].type == '14' && formList[i].val != '') {
        if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(formList[i].val)) {
          return formList[i].name + errorTips
        }
      }
      // 电话
      if (formList[i].type == '15' && formList[i].val != '') {
        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(formList[i].val)) {
          return formList[i].name + errorTips
        }
      }
      // 邮编
      if (formList[i].type == '26' && formList[i].val != '') {
        if (!/^[0-9]\d{5}(?!\d)$/.test(formList[i].val)) {
          return formList[i].name + errorTips
        }
      }
      // 身份证
      if (formList[i].type == '27' && formList[i].val != '') {
        // if (!/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/.test(formList[i].val)) {
        //   return formList[i].name + errorTips
        // }
        if (!this.checkIDCard(formList[i].val)) {
          return formList[i].name + errorTips
        }
      }
      // 整数
      if (formList[i].type == '19' && formList[i].val != '') {
        if (!Number.isInteger(Number(formList[i].val))) {
          return formList[i].name + errorTips
        }
      }
      // 小数
      if (formList[i].type == '20' && formList[i].val != '') {
        if (!Number.isInteger(Number(formList[i].val)) && !/^(\-|\+?)+([0-9]{1,}[.][0-9]*)$/.test(formList[i].val)) {
          return formList[i].name + errorTips
        }
      }
      // 百分数
      if (formList[i].type == '21' && formList[i].val != '') {
        if (!/^[+-]?\d*\.?\d{1,3}$/.test(formList[i].val)) {
          return formList[i].name + errorTips
        }
      }
    }
  }
  // 枚举字段校验
  submitEnum(list) {
    let aFormList = this.getFields(list)
    let formList = aFormList.filter(v => v.showState != 4)
    for (let i = 0; i < formList.length; i++) {
      let { type, val, typeJson, name } = formList[i]
      if (['17', '5'].includes(type)) {
        const minOptionNum = typeJson?.minOptionNum || ''
        const maxOptionNum = typeJson?.maxOptionNum || ''
        const len = val ? JSON.parse(val)?.length : 0
        if (minOptionNum) {
          if (len < minOptionNum) return name + `最少选择${minOptionNum}项`
        }
        if (maxOptionNum) {
          if (len > maxOptionNum) return name + `最多选择${maxOptionNum}项`
        }
      }
      if (['4', '5', '16', '17'].includes(type)) {
        if (!val) continue
        let valArr = JSON.parse(val)
        if (valArr.filter(v => v.custom == 1 && v.customRequired == 'true' && !v.customVal).length) return `${name}字段中有选项自定义值不能为空`
      }
    }
    return ''
  }
  // 级联校验
  submitCascade(list) {
    let formList = this.getFields(list)
    for (let i = 0; i < formList.length; i++) {
      let { type, val, typeJson, name } = formList[i]
      if (['34', '35'].includes(type)) {
        if ([4, 5, 16, 17].includes(typeJson?.styleType)) {
          if (val && JSON.parse(val).find(v => v.children)) {
            return name + '：数据结构为多级字典，不能提设置为枚举格式'
          }
        }
      }
    }
    return ''
  }
  // 整数、小数、百分数设置校验
  submitNum(list) {
    let formList = this.getFields(list)
    for (let i = 0; i < formList.length; i++) {
      const typeJson = formList[i]['typeJson'] || {}
      if (formList[i].type == '19' || formList[i].type == '20' || formList[i].type == '21') { // 整数
        const inputLimit = formList[i].inputLimit || typeJson.inputLimit
        if (inputLimit == 1) {
          let errorTips = '只能填写正数'
          if (!/^(\+?)\d+(\.\d+)?$/.test(formList[i].val) && formList[i].val != '') {
            return formList[i].name + errorTips
          }
        }
        if (inputLimit == 2) {
          let errorTips = '只能填写负数'
          if (!/^\-\d+\.?\d*$/.test(formList[i].val) && formList[i].val != '') {
            return formList[i].name + errorTips
          }
        }
        if (inputLimit == 3) {
          let errorTips = '只能填写数字'
          if (!/^[+-]?\d*\.?\d{1,3}$/.test(formList[i].val) && formList[i].val != '') {
            return formList[i].name + errorTips
          }
        }
        if (formList[i].val != '') {
          const rangeMin = formList[i].rangeMin || typeJson.rangeMin
          const rangeMax = formList[i].rangeMax || typeJson.rangeMax
          let numTips = inputLimit == 1 ? '正数' : inputLimit == 2 ? '负数' : '数'
          if (rangeMin != null && parseFloat(formList[i].val) < parseFloat(rangeMin) || (rangeMax != null && parseFloat(formList[i].val) > parseFloat(rangeMax))) {
            // return formList[i].name+"必须大于最小值";
            if (rangeMin != null && rangeMax != null) {
              return `请输入${rangeMin}到${rangeMax}的${numTips}`
            } else if (rangeMin != null) {
              return `请输入大于${rangeMin}的${numTips}`
            } else if (rangeMax != null) {
              return `请输入小于${rangeMax}的${numTips}`
            }
          }
          // if (formList[i].rangeMax != null && formList[i].val > formList[i].rangeMax) {
          //   return formList[i].name + "必须小于最大值"
          // }
        }
        const val = formList[i].val?.toString()
        if (formList[i].type == '20' && formList[i].val != '' && formList[i].val != null && val.indexOf('.') > -1) {
          if (val.split('.')[1].length > 2) return formList[i].name + '最多只能填写两位小数'
        }
      }
      if (formList[i].type == '1') {
        // 单行默认长度不做限制
        let charNum = formList[i].charNumber != null ? formList[i].charNumber : 0
        if (charNum && formList[i].val && formList[i].val.length > charNum) {
          return formList[i].name + '长度不能大于允许的最大长度'
        }
      }
      if (formList[i].type == 32) { // 分组表单 需要循环校验
        let errorMsgs
        for (let index = 0; index < formList[i].countList?.length; index++) {
          let element = formList[i].countList[index]
          let errorMsg = this.submitNum(element.childItem)
          if (errorMsg) {
            errorMsgs = formList[i].name + errorMsg
            return errorMsgs
          }
        }
        if (errorMsgs) {
          return errorMsgs
        }
      }
    }
    return ''
  }
  // 数字范围校验
  rangeReg(startNum, endNum) {
    // if(!startNum || !endNum) return ' 最大值和最小值必须同时输入'
    if (startNum && endNum && parseFloat(startNum) > parseFloat(endNum)) return ' 最小值须小于最大值'
    return ''
  }
  // 字符长度限制校验
  lengthReg(arr) {
    let errorTxt = ''
    for (let i = 0; i < arr.length; i++) {
      let typeJson = arr[i].typeJson
      if (arr[i].type == 2 && typeJson && typeJson.charNumber) {
        if ((arr[i].val.length > Number(typeJson.charNumber))) {
          errorTxt = `${arr[i].name}长度不能超过${typeJson.charNumber}`
          return errorTxt
        }
      }
    }
  }
  // 时间类型限制时间校验
  submitTimeReg(formList) {
    for (let i = 0; i < formList.length; i++) {
      if (['22', '3'].includes(formList[i].type) && formList[i].typeJson.type != null) {
        if (formList[i].typeJson.startDate && formList[i].typeJson.endDate) {
          if (new Date(formList[i].val).getTime() < new Date(formList[i].typeJson.startDate).getTime()) {
            return '当前' + formList[i].name + '要在限制时间范围内'
          }
          if (new Date(formList[i].val).getTime() > new Date(formList[i].typeJson.endDate).getTime()) {
            return '当前' + formList[i].name + '要在限制时间范围内'
          }
        }
      }
    }
    return false
  }
  getFields(formList) {
    let list = []
    let getArr = arr => {
      arr && arr.forEach(element => {
        if (['45'].includes(element.type.toString())) {
          if (element.childItem) {
            getArr(element.childItem)
          }
        } else {
          list.push(element)
        }
      })
    }
    getArr(formList)
    return list
  }
  // 身份证效验(且验证规则)
  checkIDCard(idcode) {
    // 转成大写校验
    idcode = idcode?.toUpperCase()
    let weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    let check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
    let code = idcode + ''
    let last = idcode[17]
    let seventeen = code.substring(0, 17)
    let arr = seventeen.split('')
    let len = arr.length
    let num = 0
    for (let i = 0; i < len; i++) {
      num = num + arr[i] * weight_factor[i]
    }
    let resisue = num % 11
    let last_no = check_code[resisue]
    let idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0-9]{2})([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/
    let format = idcard_patter.test(idcode)
    // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
    if (last === last_no && format) {
      return true
    } else {
      return false
    }
    // return last === last_no && format ? true : false;
  }
  isJSON(str) {
    if (typeof str == 'string') {
      try {
          JSON.parse(str)
          return true
      } catch (e) {
          return false
      }
    }
  }
  // 判断是否有数据处理字段
  isHandleData(list) {
    let handleData = false
    list && list.forEach(item => {
      if (item.type == '30') handleData = true
    })
    return handleData
  }
}
export default new DoRequire()
