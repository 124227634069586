import { post, url, postJson } from '../../service/ApiWebDecorator'

// 过程数据--统计
class Statistics {
  // 按部门获取场景统计数据
  @url('/v3/statistics/getStatisticsSceneOrgData')
  @post
  getStatisticsSceneOrgData() {}

  // 获取所有场景统计数据
  @url('/v3/statistics/getAllStatisticsSceneData')
  @post
  getAllStatisticsSceneData() {}

  // 获取场景数据   组织排名getSceneStuFormValueData
  @url('/v3/statistics/getSceneAndOrgFormValueData')
  @post
  getSceneAndOrgFormValueData() {}

  // 获取学生排名
  @url('/v3/statistics/getSceneStuFormValueData')
  @post
  getSceneStuFormValueData() {}

  // 获取级联组织结构
  @url('/v3/choiceUser/getOrgListRecursive')
  @postJson
  getOrgTreeBeanList() {}

  // 场景层面统计-其他接口(场景活动？sceneId)
  @url('/v3/statistics/getSingleSceneTaskAndOrgFormValueData')
  @post
  getSingleSceneTaskAndOrgFormValueData() {}
  // 场景层面统计-其他接口(任务？taskId)
  @url('/v3/statistics/getSingleTaskAndOrgFormValueData')
  @post
  getSingleTaskAndOrgFormValueData() {}
  // 场景层面统计-其他接口(过程？sceneItemId)
  @url('/v3/statistics/getSingleProcessFormValueData')
  @post
  getSingleProcessFormValueData() {}

  // 场景层面统计-学生排名(场景？sceneId)
  @url('/v3/statistics/getSingleSceneStuFormValueData')
  @post
  getSingleSceneStuFormValueData() {}
  // 场景层面统计-学生排名(任务？taskId)
  @url('/v3/statistics/getSingleTaskStuFormValueData')
  @post
  getSingleTaskStuFormValueData() {}
  // 场景层面统计-学生排名(过程？sceneItemId)
  @url('/v3/statistics/getSingleProcessStuFormValueData')
  @post
  getSingleProcessStuFormValueData() {}
  // 过程数据PDF返回ID集合
  @url('/v3/getTaskQrCodeById')
  @post
  getTaskQrCodeById() {}
  // 过程数据批量获取任务字段（图片、附件）
  @url('/v3/export/listImgAndFileItem')
  @post
  listImgAndFileItem() {}
}

export default new Statistics()
