const portalFrame = () => import('../views/sysPortal/components/portalFrame.vue')
export default [
  {
    path: '/',
    title: '门户框架',
    component: portalFrame,
    children: [
      // 公用路由
      {
        path: '/portal',
        component: () => import('@/views/sysPortal/components/pc/index')
      },
      {
        path: '/portalList',
        component: () => import('@/views/sysPortal/components/pc/list.vue')
      },
      {
        path: '/portalDetails',
        component: () => import('@/views/sysPortal/components/pc/details.vue')
      },
      {
        path: '/portalYZSYNews', // 亦庄实验新闻
        component: () => import('@/views/sysPortal/yzsy/news.vue')
      },
      {
        path: '/portalYZSYNotify', // 亦庄实验通知公告
        component: () => import('@/views/sysPortal/yzsy/notify.vue')
      },
      {
        path: '/portalYZSYParty', // 亦庄实验党建动态
        component: () => import('@/views/sysPortal/yzsy/party.vue')
      },
      {
        path: '/portalYZSYPartyMember', // 亦庄实验党员风采
        component: () => import('@/views/sysPortal/yzsy/partyMember.vue')
      },
      // {
      //   path: '/portalYZSYDetail', // 亦庄实验文章详情页
      //   component: () => import('@/views/sysPortal/yzsy/detail.vue')
      // },
      {
        path: '/portalYZSYProfile', // 亦庄实验学校简介
        component: () => import('@/views/sysPortal/yzsy/profile.vue')
      },
      {
        path: '/portalYZSYHistory', // 亦庄实验校史
        component: () => import('@/views/sysPortal/yzsy/history.vue')
      },
      {
        path: '/portalYZSYScenery', // 亦庄实验风光
        component: () => import('@/views/sysPortal/yzsy/scenery.vue')
      },
      {
        path: '/portalYZSYVideos', // 亦庄实验映像
        component: () => import('@/views/sysPortal/yzsy/videos.vue')
      },
      {
        path: '/portalYZSYStudent', // 亦庄实验学生
        component: () => import('@/views/sysPortal/yzsy/student.vue')
      },
      {
        path: '/portalYZSYTeacher', // 亦庄实验教职工
        component: () => import('@/views/sysPortal/yzsy/teacher.vue')
      },
      {
        path: '/portalYZSYFriend', // 亦庄实验校友
        component: () => import('@/views/sysPortal/yzsy/friend.vue')
      },
      {
        path: '/portalYZSYCourseNormal', // 亦庄实验国家课程
        component: () => import('@/views/sysPortal/yzsy/courseNormal.vue')
      },
      {
        path: '/portalYZSYCourseNormalDetail', // 亦庄实验国家课程课程设置详情页
        component: () => import('@/views/sysPortal/yzsy/courseNormalDetail.vue')
      },
      {
        path: '/portalYZSYCourseInternational', // 亦庄实验国际课程
        component: () => import('@/views/sysPortal/yzsy/courseInternational.vue')
      },
      {
        path: '/portalYZSYCourseFacultyStrength', // 亦庄实验师资力量
        component: () => import('@/views/sysPortal/yzsy/facultyStrength.vue')
      },
      {
        path: '/portalYZSYCampusSpecial', // 亦庄实验特色校园
        component: () => import('@/views/sysPortal/yzsy/campusSpecial.vue')
      },
      {
        path: '/portalYZSYCampusClub', // 亦庄实验社团
        component: () => import('@/views/sysPortal/yzsy/campusClub.vue')
      },
      {
        path: '/portalYZSYCampusStudents', // 亦庄实验学生风采
        component: () => import('@/views/sysPortal/yzsy/campusStudents.vue')
      },
      {
        path: '/portalYZSYCurriculumSystem', // 亦庄实验学生风采
        component: () => import('@/views/sysPortal/yzsy/curriculumSystem.vue')
      },
    ]
  },
]
