import { get, post, url } from '../../service/ApiDecorator'

class File {
    // 返回前端直接上传文件到upyun的配置参数
    @url('/oss2/eos/oss/get/config')
    @get
    getConfig() {}

    // 微信图片转存
    @url('/oss2/eos/wx/getImg')
    @post
    getWxImg() {}
    // 小程序 图片转存
    @url('/oss2/eos/wx/getImgByAppId')
    @post
    getImgByAppId() {}

    // 微信签名
    @url('/oss2/eos/wx/jsConfig')
    @post
    getJsConfig() {}
    // 获取前端调用微信接口配置(小程序)
    @url('/oss2/eos/minapp/jsConfig')
    @post
    getMiniproJsConfig() {}
    // 钉钉签名
    @url('/oss2/eos/dingtalk/jsConfig')
    @post
    getDdJsConfig() {}

    // 视频截图
    @url('/oss2/eos/oss/get/snapshot')
    @post
    videoSnapShot() {}

    // 判断文件是否存在
    @url('/oss2/eos/oss/isExist')
    @post
    isExist() {}

    // 获取微信上传语音文件url
    @url('/oss2/eos/wx/getVoice')
    @post
    getVoice() {}

    // 上传base64图片到云存储并返回完整地址
    @url('/oss2/eos/oss/uploadBase64')
    @post
    uploadBase64() {}

    // 上传base64图片到云存储并返回完整地址
    @url('/:prdfix/eos/form/wx/sendFile')
    @get
    sendFile() {}

    // 文档解压
    @url('/oss2/eos/oss/get/depress')
    @post
    depress() {}

    // 获取文档解压状态，成功返回目录
    @url('/oss2/eos/oss/get/depressState')
    @post
    depressState() {}

    // 获取目录下文件列表
    @url('/oss2/eos/oss/get/getDirectory')
    @post
    getDirectory() {}
}

export default new File()
