import { get, post, postJson, url } from '../../service/ApiWebDecorator'

class TrainingModel {
  @url('/v3/culitivate/insertOrUpdate')
  @post
  updateModelInfo() {}
  @url('/v3/culitivate/insert')
  @post
  insert() {}
  @url('/v3/culitivate/update')
  @post
  update() {}
  @url('/v3/culitivate/clone')
  @post
  clone() {}
  @url('/v3/culitivate/getCulitivateInfo')
  @post
  getModelInfo() {}
  // 培养模型列表
  @url('/v3/culitivate/getList')
  @post
  getModelList() {}
  // 培养模型首页
  @url('/v3/culitivate/getCulitivateInfoFromIndex')
  @post
  getCulitivateInfoFromIndex() {}
  // 培养模型复制
  @url('/v3/culitivate/getCulitivateInfoFromCopy')
  @post
  getCulitivateInfoFromCopy() {}
  // ===================
  @url('/v3/culitivate/ajaxCultivate')
  @post
  getEchartsInfo() {}
  // 培养模型首页
  @url('/v3/culitivate/ajaxCultivateFromIndex')
  @post
  ajaxCultivateFromIndex() {}
  // 培养模型复制
  @url('/v3/culitivate/ajaxCultivateFromCopy')
  @post
  ajaxCultivateFromCopy() {}
  // 获取培养模型单条信息
  @url('/region/cultivate/getCulitivateInfo')
  @post
  getCulitivateInfo() {}
  // 获取培养模型图表
  @url('/region/cultivate/ajaxCultivate')
  @post
  ajaxCultivate() {}
  // 区域保存培养模型
  @url('/region/cultivate/insert')
  @post
  insertcul() {}
  // 区域获取培养模型列表
  @url('/region/cultivate/listTargetCultivateModel')
  @postJson
  listTargetCultivateModel() {}
  // 区域获取培养模型列表
  @url('/region/cultivate/update')
  @post
  updateModel() {}
  // 区域删除培养模型列表
  @url('/region/cultivate/del')
  @post
  delModel() {}
  // 区域推送培养模型
  @url('/region/cultivate/push')
  @get
  pushModel() {}
}

export default new TrainingModel()
