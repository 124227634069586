import { get, post, url, postJson } from '../../service/ApiWebDecorator'

class Target {
  // 获取素养指标
  @url('/v3/target/ajaxListTarget')
  @post
  ajaxListTarget() {}
  // 修改素养指标
  @url('/v3/target/update')
  @post
  updateTarget() {}
  // 添加子节点
  @url('/v3/target/insert')
  @post
  insertTarget() {}
  // 修改老师角色
  @url('/v3/target/del')
  @post
  delTarget() {}
  // 添加指标模板
  @url('/region/targetModel/addTargetModel')
  @postJson
  addTargetModel() {}
  // 删除指标模板
  @url('/region/targetModel/delTargetModel')
  @post
  delTargetModel() {}
  // 删除指标模板新接口
  @url('/region/targetModel/delTargetInfoModel')
  @post
  delTargetInfoMode() {}
  // 指标模板详情
  @url('/region/targetModel/getTargetModelDetail')
  @post
  getTargetModelDetail() {}
  // 指标模板列表
  @url('/region/targetModel/listTargetModel')
  @postJson
  listTargetModel() {}
  // 修改指标模板
  @url('/region/targetModel/updateTargetModel')
  @postJson
  updateTargetModel() {}
  // 推送指标模板
  @url('/region/targetModel/pushTargetModelDetail')
  @post
  pushTargetModelDetail() {}
  // 指标模板列表
  @url('/region/targetModel/listTargetModelAll')
  @post
  listTargetModelAll() {}
  // 获取学校类型接口
  @url('/region/commom/getSchoolType')
  @get
  getSchoolType() {}
  // 区域的选择的学段
  @url('/region/commom/listAreaStuPeroidByAreaId')
  @post
  RegionalistAreaStuPeroidByAreaId() {}
  // 获取所有学期
  @url('/region/commom/listTermBeanByAreaId')
  @post
  listTermBeanByAreaId() {}
  // 获取当前模板下最末级指标
  @url('/v3/target/listLastTarget')
  @post
  listLastTarget() {}
  // 查询学段接口
  @url('/v3/schoolTermAndYear/getPeriod')
  @get
  getPeriod() {}
}

export default new Target()
