import { post, url, get } from '../../service/ApiDecorator'
// 学生个人全面发展计划书 广西职业学院-学生自我诊断报告书 下载
class GxStudevelop {
  // 学生个人全面发展计划书
  @url('/app/dynamicapi/school/developmentPlan')
  @get
  developmentPlan() {}

  // 广西职业学院-学生自我诊断报告书
  @url('/app/dynamicapi/school/getMyDiagnoseReport')
  @post
  getMyDiagnoseReport() {}
}
export default new GxStudevelop()
