import { post, postJson, url } from '../../service/ApiWebDecorator'

// 统计规则
class StatsRule {
  // 增加统计规则,返回规则id
  @url('/v3/fac/aggs/addAggsRule')
  @postJson
  addAggsRule() {}
  // 根据规则id删除统计规则
  @url('/v3/fac/aggs/delete')
  @post
  deleteAggsRule() {}
  // 根据ruleId查询统计规则包含sql规则
  @url('/v3/fac/aggs/getAggsRuleByRuleId')
  @post
  getAggsRuleByRuleId() {}
  // 根据应用id查询统计规则列表(不包含sql规则)
  @url('/v3/fac/aggs/listAggsRule')
  @postJson
  listAggsRule() {}
  // 根据应用id分页查询统计规则(不包含sql规则)
  @url('/v3/fac/aggs/pageAggsRule')
  @postJson
  pageAggsRule() {}
  // 修改统计规则,返回规则id
  @url('/v3/fac/aggs/updateAggsRule')
  @postJson
  updateAggsRule() {}
  // 根据应用id分页查询统计规则运算
  @url('/v3/fac/calc/pageCalc')
  @postJson
  pageCalc() {}
  // 根据应用id查询统计规则运算(无分页)
  @url('/v3/fac/calc/listCalc')
  @postJson
  listCalc() {}
  // 增加运算,返回运算id
  @url('/v3/fac/calc/addCalc')
  @postJson
  addCalc() {}
  // 根据id删除运算
  @url('/v3/fac/calc/delete')
  @post
  delAggsCalc() {}
  // 修改运算,返回运算id
  @url('/v3/fac/calc/update')
  @postJson
  updateAggsCalc() {}
}

export default new StatsRule()
