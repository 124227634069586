import Vue from 'vue'
// 检测HTML元素在viewport内的那部分是否被其他元素遮住（z相关）
// function isOverlapped(element) {
//   let document = element.ownerDocument
//   let { x, y, width, height } = element.getBoundingClientRect()
//   x |= 0
//   y |= 0
//   width |= 0
//   height |= 0
//   let elements = [
//     document.elementFromPoint(x, y),
//     document.elementFromPoint(x + width, y),
//     document.elementFromPoint(x, y + height),
//     document.elementFromPoint(x + width, y + height)
//   ]
//   return elements.filter(el => el !== null).some(el => el !== element)
// }
// 根据当前元素位置，判断是否被遮挡，改变定位位置
Vue.directive('hover-to-position', {
  inserted(el, { value }, vnode) {
    // const parentNode = el.parentNode
    const nodeObj = el.getBoundingClientRect()
    const parentNodeEle = $('.main-content>.el-scrollbar>.el-scrollbar__wrap')[0]
    const parentNodeObj = parentNodeEle.getBoundingClientRect()
    const containerObj = $(el).parents('.drag-item-container') // 元素的容器元素
    // console.log('el:', nodeObj, parentNodeObj)
    if (parentNodeObj.left > nodeObj.left) {
      el.style.left = '-2px'
      el.style.right = 'auto'
    }
    // 处理溢出
    const scrollFlag = parentNodeEle.scrollWidth > (parentNodeEle.innerWidth || parentNodeEle.clientWidth)
    if (scrollFlag) {
      if (parentNodeObj.right - nodeObj.right === 1) {
        // 判断容器是否含有滚动条
        const dragNodeObj = $(el).parents('.drag-item-wrap')[0].getBoundingClientRect()
        if (dragNodeObj.right === parentNodeObj.right) el.style.right = `${parentNodeEle.scrollWidth - (parentNodeEle.innerWidth || parentNodeEle.clientWidth)}px`
      }
    } else {
      const marginRight = parseInt($(parentNodeEle).css('marginRight'))
      if (nodeObj.right > (parentNodeObj.right + marginRight)) {
        el.style.right = (nodeObj.right - (parentNodeObj.right + marginRight)) + 'px'
      }
    }
    // 位于页面顶部的元素，放置在容器内部
    if (nodeObj.top < parentNodeObj.top) {
      el.style.top = '-2px'
    } else if (containerObj[0] && nodeObj.top < containerObj[0].getBoundingClientRect().top) { // 或者位于容器顶部的元素，放置在容器内部
      el.style.top = '-2px'
    }
    // $('.coms-operate-wrap').css('left', nodeObj.left).css('top', 10 + nodeObj.top)
  },
})
