import { post, postJson, url } from '../../service/ApiDecorator'

class ColumnSetting {
  // 获取学期
  @url('/region/commom/listTermBeanByAreaId')
  @post
  listTermBeanByAreaId() {}
  // 新增/编辑栏目
  @url('/v3/scoreColumnSettings/saveOrUpdate')
  @post
  saveOrUpdate() {}
  // 查询栏目
  @url('/v3/scoreColumnSettings/listByColumnName')
  @post
  listByColumnName() {}
  // 删除栏目
  @url('/v3/scoreColumnSettings/delById')
  @post
  delById() {}
  // ================= 以下是场景栏目
  // 新增-编辑栏目
  @url('/v3/sceneColumn/addColumnScene')
  @postJson
  addColumnScene() {}
  // 栏目列表
  @url('/v3/sceneColumn/listColumnScene')
  @postJson
  listColumnScene() {}
  // 删除栏目
  @url('/v3/sceneColumn/deleteColumnScene')
  @postJson
  deleteColumnScene() {}
  // 获取场景列表
  @url('/v3/sceneColumn/listColumnSceneData')
  @postJson
  listColumnSceneData() {}
}

export default new ColumnSetting()
