import AppList from './AppList'
import AppInfo from './AppInfo'
import appCategory from './appCategory'
import AppPage from './AppPage'
import AppActive from './AppActive'
import VariableCenter from './VariableCenter'
import ConditionEditor from './ConditionEditor'
import StatsRule from './StatsRule'
import Permission from './Permission'
import AppSqlRule from './AppSqlRule'
const ApiNocode = {
  AppList,
  AppInfo,
  appCategory,
  AppPage,
  AppActive,
  VariableCenter,
  ConditionEditor,
  StatsRule,
  Permission,
  AppSqlRule,
}

// 导出
export default ApiNocode
