import { get, post, postJson, url } from '../../service/ApiWebDecorator'

// 条件编辑器
class ConditionEditor {
  // 添加条件信息
  @url('/v3/fac/appactivecondition/addActiveCondition')
  @postJson
  addActiveCondition() {}
  // 批量添加条件变量信息
  @url('/v3/fac/appactivecondition/addConditionVariableList')
  @postJson
  addConditionVariableList() {}
  // 批量添加条件变量信息
  @url('/v3/fac/appactivecondition/addConditionVariable')
  @postJson
  addConditionVariable() {}
  // 修改条件信息
  @url('/v3/fac/appactivecondition/updateActiveCondition')
  @postJson
  updateActiveCondition() {}
  // 删除条件信息
  @url('/v3/fac/appactivecondition/deleteActiveCondition')
  @post
  deleteActiveCondition() {}
  // 根据事件ID查询页面配置的条件信息
  @url('/v3/fac/appactivecondition/getConditionById')
  @post
  getConditionById() {}
  // 根据条件ID分页查询变量信息
  @url('/v3/fac/appactivecondition/pageVariableByConditionId')
  @post
  pageVariableByConditionId() {}
  // 删除页面选中的变量
  @url('/v3/fac/appactivecondition/deleteConditionVariable')
  @post
  deleteConditionVariable() {}
  // 根据条件ID查询条件配置的规则信息
  @url('/v3/fac/appactivecondition/listConditionRuleByConditionId')
  @get
  listConditionRuleByConditionId() {}
  // 添加条件规则信息
  @url('/v3/fac/appactivecondition/addConditionRule')
  @postJson
  addConditionRule() {}
  // 修改选中的规则
  @url('/v3/fac/appactivecondition/updateConditionRule')
  @postJson
  updateConditionRule() {}
  // 删除选中的规则
  @url('/v3/fac/appactivecondition/deleteConditionRule')
  @post
  deleteConditionRule() {}
  // 获取表达式结果
  @url('/app/facapp/expr/executeExprStr')
  @postJson
  executeExprStr() {}
  // 添加条件设置信息
  @url('/v3/fac/appactivecondition/addUpdateConditionSetupRef')
  @post
  addConditionSetup() {}
  // 批量添加条件设置信息
  @url('/v3/fac/appactivecondition/addUpdateConditionSetup')
  @postJson
  addUpdateDeleteConditionSetup() {}
  // 根据条件ID查询条件条件设置信息
  @url('/v3/fac/appactivecondition/getConditionSetupByConditionId')
  @get
  listConditionSetupByConditionId() {}
  // 删除选中的条件设置
  @url('/v3/fac/appactivecondition/deleteConditionSetupRef')
  @post
  deleteConditionSetup() {}
  // 添加动作条件顺序
  @url('/v3/fac/apppsort/updateActionConditionSort')
  @postJson
  updateActionConditionSort() {}
  // 获取条件顺序
  @url('/v3/fac/apppsort/listActionConditionSortByActiveId')
  @get
  listActionConditionSortByActiveId() {}
  // 修改条件中的动作顺序
  @url('/v3/fac/appactivecondition/updateSetupRefSort')
  @get
  updateSetupSort() {}
}

export default new ConditionEditor()
