// 审批相关
const state = {
  nav: localStorage.getItem('nav') ? JSON.parse(localStorage.getItem('nav')) : [], // 顶部导航
  roles: localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : [], // 用户类型
  courseTypes: null,
}

const mutations = {
  setYzsyNav(state, payload) {
    localStorage.setItem('nav', JSON.stringify(payload))
    state.nav = payload
  },
  setYzsySideBar(state, payload) {
    localStorage.setItem('roles', JSON.stringify(payload))
    state.roles = payload
  },
  setCourseTypes(state, payload) {
    state.courseTypes = payload
  }
}

export default {
  state,
  mutations
}
