import { postJson, post, url } from '../../service/ApiWebDecorator'

// 任务自定自己流程
class CustomTask {
  // 保存接口
  @url('/v3/autoshow/addLeaderAutoshow')
  @postJson
  addLeaderAutoshow() {}

  // 修改自定义
  @url('/v3/autoshow/updateLeaderAutoshow')
  @postJson
  updateLeaderAutoshow() {}

  // 获取任务字段集合
  @url('/v3/admin/common/getFormItemInfoListByTaskId')
  @post
  getFormItemInfoListByTaskId() {}

  // 获取自定义集合
  @url('/v3/autoshow/getLeaderAutoshowListByTaskId')
  @post
  getLeaderAutoshowListByTaskId() {}
}

export default new CustomTask()
