/*
 * @Author: lgzhang08@gmail.com
 * @Date: 2020-09-10 20:05:41
 * @LastEditTime: 2020-09-10 20:23:16
 * @LastEditors: Please set LastEditors
 * @Description: 文字高亮处理
 */
import Vue from 'vue'
import RegRule from '../../utils/Reg/RegRule'

// 高亮文本
Vue.directive('charformat', function(el) {
    let html = $('<div>' + el.innerHTML + '</div>').text().replace(/\s/g, '')

    // 按加减乘除 括号切分
    html = html.replace(RegRule.regFormulaOperate, function(str) {
        return ' <span class="highlight-text">' + str + '</span> '
    })
    el.innerHTML = html
})

// 高亮搜索结果
Vue.directive('light-text', function(el, binding) {
  const query = binding.value
  let reg = new RegExp(query.text, 'gi')
  const html = query.fullText.replace(reg, function(str) {
    return '<span class="highlight-text">' + str + '</span>'
  })
  el.innerHTML = html
})

// 高亮组织搜索结果
Vue.directive('light-text-org', function(el, binding) {
    const query = binding.value
    if (!query.fullText) return ''
    const textArray = query.fullText.split(',')

    let html = ''
    textArray.forEach((text) => {
        if (text.indexOf(query.text) > -1) {
            html = text.replace(query.text, function(str) {
                return '<span class="highlight-text">' + str + '</span>'
            })
        }
    })
    // 多个组织显示...
    html = html || textArray[0]
    if (textArray.length > 1) {
        html = html + ' ...'
    }
    el.innerHTML = html
})

/**
 * 纵向滚动加载
 * { callback: loadMore, class: '.el-table__body-wrapper', size: 1200 }
 * @param callback 滚动回调方法
 * @param class 监听滚动的类名
 * @param size 触发滚动监听的阈值
 */
Vue.directive('scrol-load', function(el, binding) {
  const selectWrap = el.querySelector(binding.value.class)
  selectWrap.addEventListener('scroll', function() {
    const getToBottom = this.scrollHeight - this.scrollTop
    if (getToBottom <= binding.value.size && binding.value) {
      binding.value.callback()
    }
  })
})
