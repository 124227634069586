'use strict'

import SceneAdmin from './SceneAdmin'
import SceneEdit from './SceneEdit'
import OrgAdmin from './OrgAdmin'
import DataApp from './DataApp'
import RuleEngine from './RuleEngine'
import passport from './passport'
import Statistics from './processData'
import ImportBatch from './ImportBatch'
import viewGroupCharts from './viewGroupCharts'
import openSchool from './openSchool'
import archives from './archives'
import AddressBook from './AddressBook'
import customTask from './customTask'
import Club from './Club'
import TeaRole from './TeaRole'
import newVisualization from './newVisualization'
import TrainingModel from './TrainingModel'
import PageComs from './PageComs'
import ApiManage from './ApiManage'
import LiteracyParadigm from './LiteracyParadigm'
import Modification from './Modification'
import teacherGrowth from './teahcerGrowth'
import scoreList from './scoreList'
import regional from '../admin/regional'
import ExamineCustom from '../admin/ExamineCustom'
import aggregation from '../admin/aggregation'
import Target from './Target'
import MeizhouCustomApi from './MeizhouCustomApi'
import GeneralRules from './GeneralRules'
import ExportPdf from './ExportPdf'
import TaskDataMonitor from './TaskDataMonitor'
import SystemUseStatistics from './SystemUseStatistics'
import ScoreDataCenter from './ScoreDataCenter'
// 栏目设置
import columnSetting from './columnSetting'
// 应用工厂
import NoCode from '../noCode/index'
// 区域化应用id置换
import AreaApp from './areaApp'
// 多元化课程
import TMSCourse from './TMSCourse'
// 听评课
import evaluateLesson from './evaluateLesson'
// 听评课手机端
import evaluateLessonM from './evaluateLessonMobile'
// 广西项目-学生自我诊断报告书
import GxStudevelop from './GxStudevelop'
// 家校中心
import homeSchCenterM from './homeSchCenterMobile'
import homeSchCenter from './homeSchCenter'
// 亦庄-行为规范
import Behavior from './behavior'
// 素养证书
import Certificate from './certificate'
// 可视化
import DataVisualization from './dataVisualization'
// 二师可视化
import DataVisualizationErshi from './dataVisualizationErshi'
// 天一可视化
import DataVisualizationTianyi from './dataVisualizationTianyi'
// 开州可视化
import DataVisualizationKaizhou from './dataVisualizationKaizhou'
// 教师晋级档案
import Report from './Report'
// 页面组件定制图表
import StuComsChart from './StuComsChart'
// 考勤统计
import AttendanceM from './AttendanceM'
// 历史数据管理
import HistoryMag from './HistoryMag'
// 学生生成码
import EvaCodeManage from './EvaCodeManage'
// 教学班相关
import TeachClassMag from './TeachClassMag'
const ApiAdmin = {
  SceneAdmin,
  SceneEdit,
  OrgAdmin,
  DataApp,
  RuleEngine,
  passport,
  Statistics,
  viewGroupCharts,
  openSchool,
  archives,
  AddressBook,
  customTask,
  Club,
  TeaRole,
  newVisualization,
  TrainingModel,
  PageComs,
  ApiManage,
  LiteracyParadigm,
  ImportBatch,
  Modification,
  teacherGrowth,
  scoreList,
  regional,
  ExamineCustom,
  aggregation,
  Target,
  NoCode,
  MeizhouCustomApi,
  AreaApp,
  GeneralRules,
  ExportPdf,
  TaskDataMonitor,
  SystemUseStatistics,
  ScoreDataCenter,
  TMSCourse,
  Behavior,
  columnSetting,
  evaluateLesson,
  evaluateLessonM,
  Certificate,
  GxStudevelop,
  homeSchCenterM,
  homeSchCenter,
  DataVisualization,
  DataVisualizationErshi,
  DataVisualizationTianyi,
  DataVisualizationKaizhou,
  Report,
  StuComsChart,
  AttendanceM,
  HistoryMag,
  EvaCodeManage,
  TeachClassMag
}

// 导出
export default ApiAdmin
