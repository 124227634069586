import { post, url } from '../../service/ApiWebDecorator'

class Club {
  // 获取社团列表
  @url('/v3/association/listAssociation')
  @post
  getClubList() {}
  // 删除列表项
  @url('/v3/association/del')
  @post
  delItem() {}
  // 获取社团信息
  @url('/v3/association/getAssInfo')
  @post
  getClubInfo() {}
  // 新建或修改社团
  @url('/v3/association/addOrUpdateAssociation')
  @post
  getUpdateList() {}
  // 移除当前学生所在的社团
  @url('/v3/association/removeStuFromAssociation')
  @post
  removeStuFromClub() {}
  // 新增学生到多个社团
  @url('/v3/association/addAssociationMemberBatch')
  @post
  addStuFromClub() {}
}

export default new Club()
