import { post, url } from '../../service/ApiDecorator'
// 学生发展内置到素养护照，页面组件定制图表
class StuComsChart {
  // 学生历年bmi变化趋势分析
  @url('/v3/component/chart/getStuBmiAnalysis')
  @post
  getStuBmiAnalysis() {}

  // 个人体育素质情况
  @url('/v3/component/chart/getStuClazzSportScoreAnalysis')
  @post
  getStuClazzSportScoreAnalysis() {}

  // 学生历年体育成绩变化趋势分析
  @url('/v3/component/chart/getStuSportScoreAnalysis')
  @post
  getStuSportScoreAnalysis() {}

  // 分页-学生bmi变化
  @url('/v3/component/chart/pageStuBmi')
  @post
  pageStuBmi() {}

  // 分页-个人体育分数
  @url('/v3/component/chart/pageStuSportScore')
  @post
  pageStuSportScore() {}

  // 指定学期各测验Z分数统计
  @url('/develop/exam/chart/listExamZscore')
  @post
  listExamZscore() {}

  // 指定学期各测验卷面分数统计
  @url('/develop/exam/chart/listExamScore')
  @post
  listExamScore() {}

  // 查询各项目加减分次数
  @url('/v3/custom/yizhuang/conduct/chart/getBonusAndMinusEntry')
  @post
  getBonusAndMinusEntry() {}

  // 初始分及加减分信息
  @url('/v3/custom/yizhuang/conduct/chart/getStuScoreInfo')
  @post
  getStuScoreInfo() {}

  // 不同行为分类的的加分次数分布
  @url('/v3/custom/yizhuang/conduct/chart/listStuCategoryBonusPercent')
  @post
  listStuCategoryBonusPercent() {}

  // 不同行为分类的的扣分次数分布
  @url('/v3/custom/yizhuang/conduct/chart/listStuCategoryMinusPercent')
  @post
  listStuCategoryMinusPercent() {}

  // 每日得分情况
  @url('/v3/custom/yizhuang/conduct/chart/listStuDayScoreInfo')
  @post
  listStuDayScoreInfo() {}

  // 查询得分分页
  @url('/v3/custom/yizhuang/conduct/chart/pageStuDayScore')
  @post
  pageStuDayScore() {}

  // 查询纸笔测验分数对应的学科
  @url('/develop/exam/score/listUserScoreSubjectCode')
  @post
  listUserScoreSubjectCode() {}

  // 查体质学年
  @url('/v3/component/chart/listStuSchoolYear')
  @post
  listStuSchoolYear() {}

  // 查询素养分
  @url('/v3/target/querySyScoreInfo')
  @post
  querySyScoreInfo() {}

  // 素养护照-能力达成情况
  @url('/v3/passport/getPassportTargetGearReach')
  @post
  getPassportTargetGearReach() {}

  // 素养护照-能力解读
  @url('/v3/passport/getPassportAbilityAnswer')
  @post
  getPassportAbilityAnswer() {}

  // 素养护照-能力解读立即更新
  @url('/v3/passport/syncAbilityAnswer')
  @post
  syncAbilityAnswer() {}

  // 素养护照-作品信息
  @url('/v3/passport/getPassportTargetWorks')
  @post
  getPassportTargetWorks() {}

  // 素养护照-作品信息(导出)
  @url('/v3/passport/exportTargetWorks')
  @post
  exportTargetWorks() {}

  // 行为规范组建(升级接口)
  @url('/v3/custom/yizhuang/conduct/chart/getStuCategoryPercent')
  @post
  getStuCategoryPercent() {}
}
export default new StuComsChart()
