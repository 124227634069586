import { get, post, postJson, url } from '../../service/ApiWebDecorator'

// 交互
class AppActive {
  // 查询页面交互配置信息
  @url('/v3/fac/appactive/listActiveByBtnPageId')
  @get
  listActiveByBtnPageId() {}
  // 查询页面按钮所有动作信息
  @url('/v3/fac/action/listActionComposeVoByActiveId')
  @get
  listActionComposeVoByActiveId() {}
  // 增加页面交互配置信息
  @url('/v3/fac/appactive/addSetUp')
  @postJson
  addSetUp() {}
  // 增加页面交互配置信息,返回交互id（新）
  @url('/v3/fac/appactive/addSetUpNew')
  @postJson
  addSetUpNew() {}
  // 修改页面交互配置信息
  @url('/v3/fac/appactive/updateSetUpNew')
  @postJson
  updateSetUp() {}
  @url('/v3/fac/appactive/updateSetUpNew')
  @postJson
  updateSetUpNew() {}
  // 删除页面交互配置信息
  @url('/v3/fac/appactive/deleteSetUp')
  @get
  deleteSetUp() {}
  // 设置起始页面
  @url('/v3/fac/apppage/updateSetStartPage')
  @post
  updateSetStartPage() {}
  // 全局流水线查询-根据终端和应用id获取所有交互信息
  @url('/v3/fac/appactive/listActiveByAppId')
  @get
  listActiveByAppId() {}
  // 根据页面id查询页面下所有交互配置信息
  @url('/v3/fac/appactive/listAllActiveByPageId')
  @get
  listAllActiveByPageId() {}
  // 修改交互排序
  @url('/v3/fac/appactive/updateSort')
  @get
  updateSort() {}
  // 数据交换执行
  @url('/app/facapp/datatableval/executeRule')
  @postJson
  executeRule() {}
  // 执行api动作
  @url('/app/facapp/datatableval/executeApiAction')
  @postJson
  executeApiAction() {}
  // 查询表格数据(暂时不用)
  @url('/v3/fac/appdatatable/listDataFieldByTableId')
  @get
  listDataFieldByTableId() {}
  // 执行发通知
  @url('/app/facapp/datatableval/executeSendMsg')
  @postJson
  executeSendMsg() {}

  // 根据按钮或者页面id查询页面交互配置信息(新接口)
  @url('/v3/fac/appactive/listActiveComposeByBtnPageId')
  @get
  listActiveComposeByBtnPageId() {}

  // 通过交换规则ID，获取所有交互规则配置信息
  @url('/v3/fac/appsqltable/listSqlTableComposeVoByRuleId')
  @get
  listSqlTableComposeVoByRuleId() {}

  // 根据页面id查询页面下所有配置了动作的组件id(包含当前页面)
  @url('/v3/fac/appactive/listItemIdWithActionByPageId')
  @get
  listItemIdWithActionByPageId() {}

  // 根据应用id查询页面下所有配置了二位码的动作的组件id(包含当前页面)
  @url('/v3/fac/appactive/listQrItemIdWithActionByAppId')
  @get
  listQrItemIdWithActionByAppId() {}

  // 根据id删除动作
  @url('/v3/fac/action/delete')
  @post
  deleteAction() {}

  // 修改单条状态变更
  @url('/v3/fac/action/update')
  @postJson
  updateAction() {}

  // 通过动作ID，获取所有交互规则
  @url('/v3/fac/appsqlrule/listSqlRuleComposeVoByActionId')
  @get
  listSqlRuleComposeVoByActionId() {}

  // 子查询更新变量
  @url('/app/facapp/datatableval/executeChildSqlVal')
  @postJson
  executeChildSqlVal() {}

  // 根据分页组件id查询可绑定表格列表
  @url('/v3/listGridInfoByPageItemId')
  @post
  listGridInfoByPageItemId() {}

  // 根据动作ID查询配置的API数据
  @url('/v3/fac/appactiveapi/listByActionId')
  @get
  listByActionId() {}

  // 批量更新API数据-修改api排序
  @url('/v3/fac/appactiveapi/update')
  @post
  apiUpdate() {}

  // 校验数据交换规则
  @url('/v3/fac/action/checkRule')
  @postJson
  checkRule() {}
  // 校验自定义sql的接口
  @url('/v3/fac/action/checkCustomSqlContent')
  @postJson
  checkCustomSqlContent() {}

  // 判断是否是 TableValId
  @url('/app/facapp/datatableval/existTableValId')
  @get
  existTableValId() {}

  // 添加动态编码
  @url('/v3/dynamicscript/add')
  @postJson
  addDynamicCode() {}

  // 修改动态编码
  @url('/v3/dynamicscript/updateById')
  @postJson
  updateById() {}

  // 动态编码列表
  @url('/v3/dynamicscript/page')
  @get
  dynamicCodeList() {}

  // 删除动态编码
  @url('/v3/dynamicscript/deleteByIds')
  @get
  deleteByIds() {}

  // 根据id获取动态编码
  @url('/v3/dynamicscript/getById')
  @get
  getScriptById() {}

  // 通过id执行动态代码
  @url('/v3/dynamicscript/excutorById')
  @postJson
  excutorById() {}
}

export default new AppActive()
