import Vue from 'vue'
import DoSelect from '../../utils/DoSelect'

// 组织id，去除子级，返回父级名称
Vue.directive('do-org-name', async function(el, value) {
  if (!value.value) return ''
  let orgIds = value.value.split(',')
  let orgList = await DoSelect.doTreeDataById(orgIds)
  let orgNames = []
  orgList.forEach((org) => {
    orgNames.push(org.label)
  })
  console.log('orgList:', orgList)
  el.innerHTML = orgNames.toString()
})

// 批量添加组织名称，增加 父级名称
Vue.directive('tree-org-name', async function(el, value) {
  if (!value.value) return ''
  let orgNode = await DoSelect.doTreeNameData(value.value)
  if (orgNode.treeName) el.innerHTML = orgNode.treeName
  else el.innerHTML = value.value
})
