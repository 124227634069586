import { post, url } from '../../service/ApiWebDecorator'

// 区域可视化API

class Regional {
  // 首页
  @url('/regionalDemo/index')
  @post
  getIndexData() {}

  // 首页各地区职教工数
  @url('/regionalDemo/teachingStaff')
  @post
  teachingStaff() {}

  // 首页各地区招生/毕业生情况
  @url('/regionalDemo/getStuSituationData')
  @post
  getStuSituationData() {}

  // 首页列表
  @url('/regionalDemo/indexList')
  @post
  getIndexList() {}

  // 首页顶部信息
  @url('/regionalDemo/indexInfo')
  @post
  getIndexInfo() {}

  // 区县首页 学生参加素养
  @url('/regionalDemo/stuSyNum')
  @post
  getStuSyNum() {}

  // 区县首页 老师参加素养
  @url('/regionalDemo/teaSyNum')
  @post
  getTeaSyNum() {}

  // 区县首页顶部信息
  @url('/regionalDemo/hdAreaInfo')
  @post
  getHdAreaInfo() {}

  // 区县首页 学校对比
  @url('/regionalDemo/schoolContrast')
  @post
  getSchoolContrast() {}

  // 学生基本数据区县学校分布
  @url('/regionalDemo/ShoolBalic')
  @post
  getShoolBalicData() {}

  // 班级班额数量
  @url('/regionalDemo/classbangeshuliangtongji')
  @post
  getclassbangeData() {}

  // 学生成长数据
  @url('/regionalDemo/Studentgrowth')
  @post
  getStudentGrowthData() {}

  @url('/regionalDemo/biyesengshuju')
  @post
  getbiyesengshujuData() {}
  @url('/regionalDemo/hedongquruxuebiye')
  @post
  gethedongquruxuebiyeData() {}
  @url('/regionalDemo/xuexiaoshishenbi')
  @post
  getxuexiaoshishenbiData() {}
  @url('/regionalDemo/gegequshuju')
  @post
  getgegequshujuData() {}
  @url('/regionalDemo/mouquxiandata')
  @post
  getmouquxiandataData() {}
  @url('/regionalDemo/teacherfanzhan')
  @post
  getteacherfanzhanData() {}
  @url('/regionalDemo/selectquxian')
  @post
  getselectquxianData() {}

  // 根据各个区查询教师发展数据
  @url('/regionalDemo/selectaerdata')
  @post
  getselectaerdataData() {}
}
export default new Regional()
