import { get, postJson, url } from '../../service/ApiDecorator'

class Permission {
  // 应用工厂-保存权限
  @url('/v3/fac/permission/addPermission')
  @postJson
  addPermission() {}
  // 应用工厂-保存单页面权限
  @url('/v3/fac/permission/addPermissionByPage')
  @postJson
  addPermissionByPage() {}
  // 应用工厂-获取应用的所有权限
  @url('/v3/fac/permission/listPermissionByAppId')
  @get
  listPermissionByAppId() {}
  // 应用工厂-获取页面的所有权限
  @url('/v3/fac/permission/listPermissionByPageId')
  @get
  listPermissionByPageId() {}
  // 应用工厂-根据条件查询有权限设置的页面id
  @url('/v3/fac/permission/listPermissionPageId')
  @get
  listPermissionPageId() {}
  // 应用工厂-通过页面ID获取页面及其组件权限
  @url('/v3/fac/permission/getPermissionByPageId')
  @get
  getPermissionByPageId() {}
  // 应用工厂-通过页面ID获取用户的页面及其组件权限
  @url('/app/facapp/datatableval/getPermissionByPageIdUserBean')
  @get
  getPermissionByPageIdUserBean() {}
  // 应用工厂-获取用户的所有页面数据权限
  @url('/app/facapp/datatableval/listPermissionPage')
  @get
  listPermissionPage() {}
}

export default new Permission()
