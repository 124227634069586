import { post, url } from '../../service/ApiWebDecorator'

class LiteracyParadigm {
  @url('/v3/summary/listWhole')
  @post
  listWhole() {}

  @url('/v3/summary/getLevelThat')
  @post
  getLevelThat() {}

  @url('/v3/summary/getAvgTarget')
  @post
  getAvgTarget() {}

  @url('/v3/summary/getRadarMap')
  @post
  getRadarMap() {}

  @url('/v3/summary/getScattergram')
  @post
  getScattergram() {}

  @url('/v3/summary/getCerThat')
  @post
  getCerThat() {}

  @url('/v3/summary/ajaxCerList')
  @post
  ajaxCerList() {}
}

export default new LiteracyParadigm()
