/**
 * 数据处理
 */
import DoRequire from './Reg/DoRequire'
class DoArray {
  constructor() {
    this.nodes = []
  }
  /**
   * 查看对象数组中的属性，返回index
   * @param list [] 源对象数据
   * @param value string 要判断相等的值
   * @param type string 要判断相等的属性值
   * return 查到返回index,否则返回-1
   */
  searchArrayIndex(list, value, type) {
    let searchIndex = -1
    if (!list || !list.length) return searchIndex
    list.forEach((item, index) => {
      if (item[type] === value) searchIndex = index
    })
    return searchIndex
  }

  /**
   * 查看对象数组中的属性，返回index，两个属性的比对
   * @param list [] 源对象数据
   * @param value string 要判断相等的值
   * @param type string 要判断相等的属性值
   * return 查到返回index,否则返回-1
   */
  searchArrayIndexTwo(list, value, type, value2, type2) {
    let searchIndex = -1
    if (!list || !list.length) return searchIndex
    list.forEach((item, index) => {
      if (item[type] === value && item[type2] === value2) searchIndex = index
    })
    return searchIndex
  }

  /**
   * 查看对象数组中的属性，返回 当前对象
   * @param list [] 源对象数据
   * @param value string 要判断相等的值
   * @param type string 要判断相等的属性值
   * @param deepClone 是否神拷贝，默认false
   * return 查到返回index,否则返回-1
   */
  searchArrayObject(list, value, type, deepClone) {
    let searchIndex = ''
    if (!list || !list.length) return searchIndex
    list.forEach((item) => {
      if (item?.[type] === value && deepClone) searchIndex = JSON.parse(JSON.stringify(item))
      else if (item?.[type] === value) searchIndex = item
    })
    return searchIndex
  }

  /**
   * 查看对象数组中的属性，返回 value数组
   * @param list [] 源对象数据
   * @param type string 要判断相等的属性值
   * @param valType string 返回值的类型默认不处理(特殊情况下需要值类型转换时用)
   * return 查到返回value数组
   */
  doArrayValue(list, type, valType = '') {
    const tmp = []
    list && list.forEach((item) => {
      tmp.push(valType == 'string' ? item[type].toString() : valType == 'number' ? parseFloat(item[type]) : item[type])
    })
    return tmp
  }

  /**
   * 查看对象数组中的属性，从另一个已知数组中查找，返回 value数组
   * @param list [] 源对象数据
   * @param valList [] 已知数组
   * @param type string 要判断相等的属性值
   * return 查到返回value数组
   */
  doArrayValueList(list, valList, type) {
    const tmp = []
    valList && valList.forEach((val) => {
      list.forEach((item) => {
        if (val == item[type]) tmp.push(item)
      })
    })
    return tmp
  }

  /**
   * map索引返回数组
   * @param o {} 源对象数据
   * @param keys [] 属性
   * return [] 查到返回key数组
   */
  doKeysArray(o, keys, type, from) {
    const tmp = []
    if (keys === 'all') {
      for (const key in o) {
        const obj = { ...o[key] }
        if (obj && type) {
          if (from == 'systemList') obj[type] = o[key].type
          else obj[type] = key
        }
        tmp.push(obj)
      }
      return tmp
    }
    keys.forEach((key) => {
      if (o[key]) {
        const obj = { ...o[key] }
        if (obj && type) obj[type] = key
        tmp.push(obj)
      }
    })
    return tmp
  }

  /**
   *
   * @param {Array} arr 对象数组
   * 函数功能是为了获取对象数组下  对象中某一个Number类型属性的最大值
   */
  getMaxNum(arr, props) {
    if (!arr || arr.length === 0) return 0
    const sortArr = []
    arr.forEach((item) => {
      if (item[props]) sortArr.push(item[props])
    })
    return sortArr.length > 0 ? Math.max.apply(null, sortArr) : 0
  }
  /**
   *  根据key递归查找属性值相等的数据
   * @param {*} list 数据源
   * @param {*} key 数据型名
   * @param {*} val 属性值
   * @param {*} childProps 递归属性名
   */
  getRecursiveNodeList(list = [], key, val, childProps = 'children', node = []) {
    list.forEach(item => {
      if (item[key] == val) {
        node.push(item)
      }
      if (item?.[childProps]?.length) {
        this.getRecursiveNodeList(item[childProps], key, val, childProps, node)
      }
    })
    return node
  }
  /**
   * 根据id递归查询目录树中的节点， origin ，引用原节点
   */
  getRecursiveNode(list = [], props, value, childProps = 'children', node = '', origin) {
    if (!list) return node
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (item[props] == value) {
        if (origin) node = item
        else node = JSON.parse(JSON.stringify(item))
        break
      } else if (item && item[childProps] && item[childProps].length > 0) {
        node = this.getRecursiveNode(item[childProps], props, value, childProps, node, origin)
        if (node.id) return node
      }
    }
    return node
  }
  /**
   * 根据id递归查询目录树中的子节点
   */
  getRecursiveChildren(list, props, value, childProps = 'children', node = []) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (item[props] == value) {
        node = JSON.parse(JSON.stringify(item[childProps]))
        // 去掉children
        node && node.forEach(() => {
          delete node[childProps]
        })
        break
      } else if (item && item[childProps] && item[childProps].length > 0) {
        node = this.getRecursiveChildren(item[childProps], props, value)
        if (node[props]) return node
      }
    }
    return node
  }

  /**
   * 扁平化 树结构
   * @param data
   * @param excludeChildType 需要排除子集的字段（遍历-46）
   * @param deepClone 深拷贝
   * @returns {[]}
   */
  flattenTreeData(data = [], childProps = 'children', excludeChildType = '', deepClone = true) {
    if (!data) return data
    let treeData = deepClone ? JSON.parse(JSON.stringify(data)) : data
    const flattenData = []
    function flattenTree(data) {
      data.forEach(ele => {
        flattenData.push(ele)
        // if (ele[childProps]) flattenTree(ele[childProps])
        if (ele[childProps] && ele.type !== excludeChildType) flattenTree(ele[childProps])
      })
    }
    flattenTree(treeData)
    return flattenData
  }

  /**
   * 查找父节点
   * @param list
   * @param id
   * @returns {[]}
   */
  getParentList(list, props = 'id', value, childProps = 'children', pid = 'pid') {
    let dataList = this.flattenTreeData(list, childProps, pid)
    let parentArr = [] // 存储所有的父级元素
    function findParent(dataList, itemId) {
      dataList.forEach(ele => {
        // TODO 限制10层
        if (ele[props] === itemId && parentArr.length < 10) {
          parentArr.unshift(ele)
          findParent(dataList, ele[pid])
        }
      })
    }
    findParent(dataList, value)
    return parentArr
  }
  /**
   * 合并对象，用第一个对像keyObj的key，第二个对象valueObj的值
   */
  mergeObjectByKey(keyObj, valueObj) {
    let data = {}
    for (let key in keyObj) {
      data[key] = keyObj[key]
      if (valueObj[key] || valueObj[key] == 0) data[key] = valueObj[key]
    }
    return data
  }
  /**
   * 查询node目录树中的节点keys
   */
  getRecursiveKeys(list, props, keys) {
    if (!list) return false
    for (let i = 0; i < list.length; i++) {
      keys.push(list[i][props])
      if (list[i] && list[i].children && list[i].children.length > 0) {
        this.getRecursiveKeys(list[i].children, props, keys)
      }
    }
    return keys
  }
  /**
   * 查询目录树中所有叶子节点
   */
  getOnlyChildren(list, childPops = 'children') {
    let tmpList = []
    if (!list) return false
    function getChildren(list, childPops) {
      for (let i = 0; i < list.length; i++) {
        if (!list[i][childPops] || list[i][childPops].length == 0) {
          tmpList.push(list[i])
        }
        if (list[i] && list[i][childPops] && list[i][childPops].length > 0) {
          getChildren(list[i][childPops], childPops)
        }
      }
    }
    getChildren(list, childPops)
    return tmpList
  }
  /**
   * 数组转成 树结构
   */
  convertDataToTree(data, pid = 'pid', childPops = 'children') {
    let parents = data.filter(value => !value[pid] || value[pid] == 0)
    let childrens = data.filter(value => value[pid])
    let translator = (parents, childrens) => {
      parents.forEach((parent) => {
        childrens.forEach((current, index) => {
          if (current[pid] === parent.id) {
            let temp = JSON.parse(JSON.stringify(childrens))
            temp.splice(index, 1)
            translator([current], temp)
            typeof parent[childPops] != 'undefined' ? parent[childPops].push(current) : parent[childPops] = [current]
          }
        }
        )
      })
    }
    translator(parents, childrens)
    return parents
  }
  /**
   * 添加在子级统一添加父级id
   */
  addChildPops(list, pid, childPops = 'children') {
    for (let i = 0; i < list.length; i++) {
      // TODO pid 后端大小写不统一
      list[i].pid = pid
      list[i].pId = pid
      if (list[i][childPops]) this.addChildPops(list[i][childPops], list[i].id, childPops)
    }
  }
  /**
* 添加在子级统一添加父级属性值
*/
  addChildPopsV2(list, props, pid, childPops = 'children') {
    for (let i = 0; i < list.length; i++) {
      // TODO pid 后端大小写不统一
      list[i].pid = pid
      list[i].pId = pid
      if (list[i][childPops]) this.addChildPopsV2(list[i][childPops], props, list[i][props], childPops)
    }
  }
  /**
   * 添加在子级统一添加 属性和值
   */
  addChildPopsByProps(list, props, val, childPops = 'children') {
    for (let i = 0; i < list.length; i++) {
      list[i][props] = val
      if (list[i][childPops]) this.addChildPopsByProps(list[i][childPops], props, val, childPops)
    }
  }
  /**
   * 设置子集属性
   * @param list 源数据
   * @param id 索引id
   * @param key 属性名
   * @param val 属性值
   * @param childPops 子集键
   */
  setChildPops(list, id, val, key, childPops = 'children') {
    if (!list || !list.length) return
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        list[i][key] = val
      }
      if (list[i][childPops]) this.setChildPops(list[i][childPops], id, val, key, childPops)
    }
  }
  /**
   * 设置子集属性 2.0
   * @param list 源数据
   * @param props 判断的属性名
   * @param id 判断的属性值
   * @param key 设置的属性名
   * @param val 设置的属性值
   * @param childPops 子集键
   */
  setChildPopsV2(list, props, id, val, key, childPops = 'children') {
    if (!list || !list.length) return
    for (let i = 0; i < list.length; i++) {
      if (list[i][props] == id) {
        list[i][key] = val
      }
      if (list[i][childPops]) this.setChildPopsV2(list[i][childPops], props, id, val, key, childPops)
    }
  }
  /**
   * 递归设置子集属性
   * @param list 源数据
   * @param id 索引id
   * @param key 属性名
   * @param val 属性值
   * @param childPops 子集键
   */
  setRecursiveChildVal(list, props, val, childPops = 'children') {
    if (!list || !list.length) return
    for (let i = 0; i < list.length; i++) {
      list[i][props] = val
      if (list[i][childPops]) this.setRecursiveChildVal(list[i][childPops], props, val, childPops)
    }
  }
  /**
   * 去除树的空数据
   */
  removeTreeChild(list, childPops = 'children') {
    for (let i = 0; i < list.length; i++) {
      if (!list[i]) {
        list.splice(i, 1)
        i--
      } else if (list[i] && list[i][childPops]) {
        this.removeTreeChild(list[i][childPops], childPops)
      }
    }
  }
  //
  /**
   * 处理级通数据 最有一级空数组 children置成null
   * @param list
   * @param type
   * @return {*}
   */
  removeTreeChildData(list, children = 'children') {
    list.forEach(item => {
      if (!item[children] || item[children].length == 0) {
        item[children] = null
      } else {
        this.removeTreeChildData(item[children], children)
      }
    })
    return list
  }
  /**
   * 找出树形结构里面id最大的  ----可视化
   */
  getMaxId(list, objNum) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id > objNum.num) {
        objNum.num = list[i].id
      }
      if (list[i].children && list[i].children.length > 0) {
        this.getMaxId(list[i].children, objNum)
      }
    }
    return objNum
  }
  /**
   * 数组 转 树
   */
  doArrayToTree(list, props, refProps, type = 'children') {
    /* for (let i = 0 i < list.length i++) {
      if (!list[i].children) list[i].children = []
      for (let j = i + 1 j < list.length j++) {
        if (list[i][props] == list[j][refProps]) {
          list[i].children.push(list[j])
        }
      }
    }
    return list*/
    for (let i = 0; i < list.length; i++) {
      if (!list[i][type]) list[i][type] = []
      for (let j = 0; j < list.length; j++) {
        // TODO 初始 isRepeat 为 false
        list[j].isRepeat = false
        if (list[i][props] == list[j][refProps]) {
          list[i][type].push(list[j])
          list[j].isRepeat = true
        }
      }
    }
    return list.filter(row => { return !row.isRepeat })
  }
  /**
   * 找出数组对象中的最大sort值 list对应数组 type 对应字段
   */
  findMaxSort(list, type) {
    let arr = []
    list.forEach(item => {
      arr.push(item[type])
    })
    return Math.max(...arr)
  }
  /**
   * 删除树形结构对应数据  ---可视化
   */
  delTreeData(list, id) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        list.splice(i, 1)
        return true
      }
      if (list[i].children && list[i].children.length > 0) {
        this.delTreeData(list[i].children, id)
      }
    }
  }
  /**
   * 删除元素
   */
  deleteByPropsId(list, props, value) {
    if (!list) return false
    list.forEach((item, index) => {
      if (item[props] == value) list.splice(index, 1)
    })
  }
  /**
   * 删除数组元素
   */
  deleteByValue(list, value) {
    if (!list) return false
    list.forEach((item, index) => {
      if (item == value) list.splice(index, 1)
    })
  }

  /**
   *
   * @param {Array} array 数组去重
   */
  doArrayRepeat(array) {
    return Array.from(new Set(array))
  }
  /**
   * 对象数组去重
   * @param {*} array 需要去重数组
   * @param {*} attrName 去重依据属性
   */
  doObjArrayRepeat(array, attrName) {
    if (array.length == 0) return
    let result = []
    let attrArr = []
    array.forEach(element => {
      if (!attrArr.includes(element[attrName])) {
        result.push(element)
        attrArr.push(element[attrName])
      }
    })
    return result
  }
  /**
   * 获取对你数组重复的第一个索引
   * @param {*} array 需要去重数组
   * @param {*} attrName 去重依据属性
   */
  getRepeatArrayIndex(array, attrName) {
    if (array.length == 0) return
    let result = []
    let attrArr = []
    let repeatIndex = -1
    array.forEach((element, index) => {
      if (!attrArr.includes(element[attrName])) {
        result.push(element)
        attrArr.push(element[attrName])
      } else if (repeatIndex == -1) {
        repeatIndex = index
      }
    })
    return repeatIndex
  }
  /**
   * 对象数组去重合并
   * @param {*} arr1
   * @param {*} arr2
   * @param {*} attrName 去重依据属性
   */
  doObjArrConnect(arr1, arr2, attrName) {
    if (arr2.length == 0) return arr1
    let result = JSON.parse(JSON.stringify(arr1))
    let arr = this.doArrayValue(arr1, attrName)
    arr2.forEach(item => {
      if (!arr.includes(item[attrName])) {
        result.push(item)
      }
    })
    return result
  }

  /**
   * 字符串转对象数组
   * @param {string} str 需要处理的字符串
   * @param {string} attr 需要添加的属性
   * arr  返回结果： ('1,2,3', 'id') =>  [{ id: 1},{ id: 2},{ id: 3}]
   */
  strToArr(str, attr) {
    if (str == '' || str == null) return []
    let arr = str.split(',')
    let res = []
    arr.forEach(item => {
      let obj = { [attr]: item }
      res.push(obj)
    })
    return res
  }
  /**
   *计算数组中，某一个属性的和（可计算类型）
  * @param {Array} arr
  * @param {String} attr
  */
  getArrSum(list, attr) {
    // 为空时候可能是 空字符串
    let arr = JSON.parse(JSON.stringify(list))
    let sum = 0
    if (arr.length == 0) return
    arr.forEach(ele => {
      ele[attr] = ele[attr] == '' ? 0 : Number(ele[attr])
      sum += ele[attr]
    })
    return sum
  }
  /**
   * 树形结构 禁用非末级节点，子节点
   * @param {Array} data 树形结构
   * @param {String} attr 子节点属性名称
   */
  checkedOnlyFinal(data, attr = 'children') {
    data.forEach(item => {
      if (item[attr].length != 0) {
        item.disabled = true
        item[attr].forEach(() => {
          this.checkedOnlyFinal(item[attr], attr)
        })
      }
      // else {
      //   item.disabled = item.type != 4 && item.type != 5
      // }
    })
    return data
  }
  /**
   * 禁用一级节点
   * @param {Array} data 树形结构
   * @param {String} attr 子节点属性名称
   */
  disableFirstNode(data) {
    data.forEach(item => {
      item.disabled = true
    })
    return data
  }
  /**
   * 统计树形结构总节点个数
   * @param {Object} data 树形结构顶级节点
   * @param {String} child 子节点属性值
   */
  getTreeNodeNum(data = {}, child = 'children') {
    let total = data[child].length || 0
    data[child] && data[child].forEach(element => {
      if (element[child] && element[child].length > 0) {
        total += element[child].length
        this.getTreeNodeNum(element[child], child)
      }
    })
    return total
  }
  /**
   * 树结构转数组
   * @param {Object} data 树形结构顶级节点
   * @param {String} child 子节点属性值
   */
  getTreeNodeToArr(data = {}, child = 'children') {
    let arr = [data]
    data[child] && data[child].forEach(element => {
      arr.push(element)
      if (element[child] && element[child].length > 0) {
        this.getTreeNodeToArr(element[child], child)
      }
    })
    return arr
  }
  /**
   * 获取相同pid的节点数组
   * @param {*} tree 树结构
   * @param {*} value pid值
   * @param {*} curValue 排除参照值（同级排除）
   * @param {*} curProps 排除参属性名
   * @param {*} child 子结构属性名
   * @param {*} props 父id属性名
   * @returns {[]}
   */
  getParentArr(tree, value, curValue, curProps = 'id', child = 'children', props = 'pid') {
    let temp = []
    tree && tree.forEach(item => {
      if (item[props] == value && item[curProps] != curValue) temp.push(item)
      if (item[child] && item[child].length > 0) {
        let childTemp = this.getParentArr(item[child], value, curValue, curProps, child, props)
        temp = [...temp, ...childTemp]
      }
    })
    return temp
  }
  /**
   * 根据id找出数组中找出对应的对象
   * @param {list} 数组对象
   * @param {id} 对应id
   */
  findArrToObj(list, id) {
    let obj = null
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        obj = list[i]
        break
      }
    }
    return obj
  }
  /**
   * 根据id找tree所有父级id
   * @param {tree} 树形结构
   * @param {id} 对应子集id
   */
  getAllParentNodes(id, tree) {
    this.getParentNodes([], id, tree)
    return this.nodes
  }
  getParentNodes(his, targetId, tree) {
    tree.some((list) => {
      const children = list.children || []
      if (list.id === targetId) {
        this.nodes = his
        return true
      } else if (children.length > 0) {
        const history = [...his]
        history.push(list)
        return this.getParentNodes(history, targetId, children)
      }
    })
  }
  /**
   * 根据值列表查出对应节点数组
   * @param {*} tree
   * @param {*} idsArr
   */
  getParentArrByIds(tree, valArr, childProps = 'children') {
    if (!valArr.length) return
    let result = []
    let dataList = this.flattenTreeData(tree, childProps)
    // debugger
    valArr.forEach(item => {
      result.push(dataList.filter(ele => ele.value == item)[0])
    })
    return result
  }
  findNodeById(list, props, value, childProps = 'children') {
    let dataList = this.flattenTreeData(list, childProps)
    return dataList.filter(item => item[props] == value)[0]
  }
  /**
   * 根据将id转换为options中对应的name值
   */
  getValForOption(item) {
    if (item.refType == '34') {
      return item.val ? (JSON.parse(item.val)[0].name || JSON.parse(item.val)[0].label) : ''
    }
    let ids = []
    if (item.refType == '10') ids = item.val.split(',')
    else {
      if (DoRequire.isJSON(item.val)) {
        let vals = JSON.parse(item.val)
        vals.forEach(val => {
          ids.push(val.id)
        })
      }
    }
    let newVals = []
    for (const id of ids) {
      let current = item.optionInfos.find(item => item.id == id)
      if (current) newVals.push(current.name)
    }
    return newVals.join('，')
  }
  /**
   * 根据将id找出父级模板id,并返回整个对象
   * @param {*} list 整体list
   * @param {*} id 子集id
   * @param {*} id 子集list
   */
  getValFindParent(list, id, childrenName) {
    for (let i = 0; i < list.length; i++) {
      if (list[i][childrenName].length) {
        let listData = list[i][childrenName].filter(item => item.id == id)
        if (listData.length) return list[i]
      }
    }
    return {}
  }
  /**
   * 生成26个字母
   */
  generateLetter() {
    let letterList = []
    for (let i = 65; i < 91; i++) {
      letterList.push(String.fromCharCode(i))
    }
    return letterList
  }
  /**
   * 颜色转换
   *  16进制转换RGB
   *  str 16进制颜色   colorType 颜色透明度
   */
  sixteenToRgb(str, colorType) {
    let r, g, b, rgb
    if (str.length == 7) {
      r = parseInt(str.substr(1, 2), 16)
      g = parseInt(str.substr(3, 2), 16)
      b = parseInt(str.substr(5, 2), 16)
    } else if (str.length == 4) {
      r = parseInt('' + str.substr(1, 1) + str.substr(1, 1), 16)
      g = parseInt('' + str.substr(2, 1) + str.substr(2, 1), 16)
      b = parseInt('' + str.substr(3, 1) + str.substr(3, 1), 16)
    } else {
      return 'false'
    }
    rgb = 'rgba(' + r + ',' + g + ',' + b + ', ' + (colorType || 1) + ')'
    return rgb
  }
  /**
   * 树形结构添加禁用属性
   * @param {*} tree
   * @param {*} attr 是否禁用判定属性
   * @param {*} attVals 禁用数值值（字符串）
   * @param {*} childProps
   */
  doTreeSetDisabled(tree, attr, attValsArr, childProps = 'childrenNew') {
    if (!tree || !tree.length) return
    tree.forEach(item => {
      if (attValsArr.includes(item[attr])) item.disabled = true
      if (item[childProps].length) this.doTreeSetDisabled(item[childProps], attr, attValsArr, childProps = 'childrenNew')
    })
  }
  /**
   * 取出指标模板最后一级
   * @param {*} data
   * @param {*} type 属性值
   */
  doLastLevel(data, type) {
    let targetList = []
    function getLevalTarget(data, type) {
      data.forEach(row => {
        if (row[type]?.length) {
          getLevalTarget(row[type], type)
        } else {
          targetList.push({ id: row.id, title: row.title })
        }
      })
    }

    getLevalTarget(data, type)
    return targetList
  }
  /**
   * 数组对象去重
   * list 数据列表
   * key 对应对象过滤的key值
   */
  arrayDuplication(list, key) {
    if (!list?.length) return []
    let obj = {}
    let arr = []
    arr = list.reduce(function(item, next) {
      obj[next[key]] ? '' : obj[next[key]] = true && item.push(next)
      return item
    }, [])
    return arr
  }
  /**
   * 数组对象替换原有key
   * objAry 数据列表
   * key 原本key
   * newKey 新key
   */
  changeKey(objAry, key, newKey, del = true) {
    if (objAry != null) {
      objAry.forEach((item, index) => {
        Object.assign(item, {
          [newKey]: item[key],
        })
        if (del) delete item[key]
        this.changeKey(item.children, key, newKey, del)
      })
    }
  }
  /**
   * 数组对象排序
   * key 按照那个key值排序
   * desc true 升序 false降序
   */
  compare(key, desc) {
    return function(a, b) {
      let value1 = a[key]
      let value2 = b[key]
      if (desc == true) {
        // 升序排列
        return value1 - value2
      } else {
        // 降序排列
        return value2 - value1
      }
    }
  }
  /**
   * 获取数组中 对应value 的 name
   * @param {*} _array
   * @param {*} _value
   * @param {*} _valueKey
   * @param {*} _getKey
   * @returns
   */
  getArrayValueName(_array, _value, _valueKey = 'value', _getKey = 'label') {
    let _name
    for (const iterator of _array) {
      if (iterator[_valueKey] == _value) {
        _name = iterator[_getKey]
      }
    }
    return _name
  }
  /**
   * 递归查找对应值
   * @param {*} _treeDataList 树形结构数据列表
   * @param {*} _value 要查找的值
   * @param {*} _valueKey 值对应的key
   * @param {*} _childKey child数据对应的key
   * @returns true false
   */
  findValueFromTreeData(_treeDataList, _value, _valueKey = 'value', _childKey = 'child') {
    let _hasValue = false
    function recurve(_treeDataList, _value) {
      for (const iterator of _treeDataList) {
        if (iterator[_valueKey] == _value) {
          _hasValue = true
          break
        }
        if (iterator[_childKey] && iterator[_childKey].length) {
          recurve(iterator[_childKey], _value)
        }
        if (_hasValue) {
          break
        }
      }
    }
    recurve(_treeDataList, _value)
    return _hasValue
  }
  /**
   * 动态修改返回组件(crumbsList)名称
   * newName 要修改的名称
   */
  modifyReturnComponents(newName) {
    if (JSON.parse(sessionStorage.getItem('crumbsList'))) {
      let oldCrumbsList = JSON.parse(sessionStorage.getItem('crumbsList'))
      oldCrumbsList[oldCrumbsList.length - 1].title = newName
      sessionStorage.setItem('crumbsList', JSON.stringify(oldCrumbsList))
    }
  }
  /**
   * 获取树结构中id 的父级ID
   * @param {*} list 数据源
   * @param {*} val 判断目标值
   * @param {*} id 目标值依据
   * @param {*} pid 判断依据
   */
  getParentIds(list, val, id = 'id', pid = 'pid') {
    let resArr = this.flattenTreeData(list, 'childs')
    let result = [val]
    function getPid(targetId) {
      let f = resArr.filter(v => v[id] == targetId)
      if (f?.length && f[0][pid] != 0) {
        result.unshift(f[0][pid])
        getPid(f[0][pid])
      }
    }
    getPid(val)
    return result
  }
  sortDate(_data, _sortkey, _isup = true) {
    function compare() {
      return function(a, b) {
        var v1 = Number(a[_sortkey])
        var v2 = Number(b[_sortkey])
        if (_isup) {
          return v1 - v2
        } else {
          return v2 - v1
        }
      }
    }
    return _data.sort(compare())
  }
}

// 导出单例，方便直接使用
export default new DoArray()
window.DoArray = new DoArray()
