import { url, get } from '../../service/ApiDecorator'
class EvaCodeManage {
  // 评价码学生列表
  @url('/v3/evaluationCode/listUser')
  @get
  getEvaCodeStuList() {}

  // 下载评价码
  @url('/v3/evaluationCode/exportEvaluationCode')
  @get
  downloadEvaCode() {}

  // 评价码导出记录列表
  @url('/v3/evaluationCode/listEvaluationExportRecord')
  @get
  getEvaCodeExportRecordList() {}

  // 删除评价码下载记录
  @url('/v3/evaluationCode/delEvaluationExportRecordById')
  @get
  delEvaCodeExportRecord() {}

  // 获取场景列表
  @url('/develop/scene/ref/getEvaluationCodeSceneVo')
  @get
  getSceneList() {}

  // 获取课程列表
  @url('/develop/scene/ref/getDevCourseVo')
  @get
  getCourseList() {}

  // 保存评价码和任务关系
  @url('/v3/evaluationCode/addEvaluationTask')
  @get
  addEvaluationTask() {}

  // 获取评价码和任务关系
  @url('/v3/evaluationCode/queryBindingTasks')
  @get
  getEvaluationTask() {}

  // 获取小程序设置
  @url('/sys/api/school/minapp/get')
  @get
  getSigleMpSetting() {}

  // 学生卡片
  @url('/v3/evaluationCode/getStuInfo')
  @get
  getStuInfo() {}

  // 评价目录
  @url('/v3/evaluationCode/listEvaluationDirectory')
  @get
  listEvaluationDirectory() {}

  // 取消任务未读消息
  @url('/v3/evaluationCode/clearNoReadCount')
  @get
  clearNoReadCount() {}
}
export default new EvaCodeManage()
