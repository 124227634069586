import { get, url, post, postJson } from '../../service/ApiDecorator'

class Form {
    // 后台表单设计器修改添加
    @url('/:prdfix/eos/form/admin/add')
    @postJson
    addForm() {}

    // 获取表单设计器完整表单信息
    @url('/:prdfix/eos/form/admin/byid')
    @get
    getForm() {}

    // 表单删除
    @url('/:prdfix/eos/form/admin/del')
    @get
    delForm() {}

    // 表单项排序
    @url('/:prdfix/eos/form/admin/item/saveSort')
    @postJson
    saveSort() {}

    // 表单项删除
    @url('/:prdfix/eos/form/admin/item/del')
    @get
    deleteItem() {}

    // 表单项保存(子表单项请保存完整的父表单项)
    @url('/:prdfix/eos/form/admin/item/save')
    @postJson
    saveItem() {}

    // 表单项复制
    @url('/:prdfix/eos/form/admin/item/copy')
    @get
    copyItem() {}

    // 表单发布
    @url('/:prdfix/eos/form/admin/release')
    @get
    releaseForm() {}

    // 获取微信端完整表单信息
    @url('/:prdfix/eos/form/wx/byid')
    @get
    getWxForm() {}

    // 提交微信端完整表单信息
    @url('/:prdfix/eos/form/wx/add')
    @postJson
    submitForm() {}
    // 批量提交微信端完整表单信息
    @url('/:prdfix/eos/form/wx/addBatch')
    @postJson
    submitBatchForm() {}
    // 批量提交微信端完整表单信息（访客提交）
    @url('/app/visitor/addBatchVisitor')
    @postJson
    addBatchVisitor() {}
    // 任务填写暂存 表单
    @url('/app/task/addDrafts')
    @postJson
    saveDraftsForm() {}
    // 后台表单设计器基础信息修改添加
    @url('/:prdfix/eos/form/admin/saveSetting')
    @postJson
    saveSetting() {}

    // 后台表单设计器基础信息修改添加
    @url('/common/eos/phone/sendCode')
    @get
    sendCode() {}

    // 获取表单项的匹配配置
    @url('/:prdfix/eos/form/getMatchInfo')
    @get
    getMatchInfo() {}

    // 保存表单项的匹配配置
    @url('/:prdfix/eos/form/saveMatch')
    @post
    saveMatch() {}

    // 表单数字项计算
    @url('/:prdfix/eos/form/wx/calculate')
    @postJson
    fieldCalculate() {}

    // 计算范围 本次提交值或周期值
    @url('/:prdfix/eos/form/getCalculateType')
    @get
    getCalculateType() {}
      // 查询级联选择需要的字典类型
    @url('/:prdfix/eos/form/admin/queryDictType')
    @get
    queryDictType() {}
    // 批量改变css
    @url('/:prdfix/eos/form/admin/item/updateStyleBatch')
    @postJson
    updateStyleBatch() {}
    // 查询级联选择需要的字典json
    @url('/saascommon/eos/form/wx/:dictTypeId/queryDict')
    @get
    mqueryDict() { }
  // 查询级联选择需要的字典json
    @url('/:prdfix/eos/form/wx/queryApiDict')
    @get
    queryApiDict() {}
    // 日期字段获取服务器时间
    @url('/:prdfix/base/getSysTimestamp')
    @get
    getSysTimestamp() {}

    // 保存选项接口
    @url('/v3/saveOrInsertBaseFormOptionInfo')
    @post
    saveOptionInfo() {}

    // eos系统拓展字段角色列表
    @url('/v3/api/query/listRoles')
    @post
    queryListRoles() {}

    // eos扩展字段-查询该学校的所有eos扩展表单
    @url('/v3/api/query/listEosExTypeByRoleId')
    @post
    queryFormMenuList() {}

    // eos所有的表单及其下级字段
    @url('/v3/api/query/listFieldTypeContainItem')
    @post
    queryFormAndFields() {}

    // 获取eos表单已使用的列表
    @url('/v3/formItem/getFieldTypeFormItemList')
    @post
    queryFieldTypeFormItemList() {}

    // 获取eos表单下字段已使用的列表
    @url('/v3/formItem/getExFormItemList')
    @post
    queryExFormItemList() {}
    // 获取应用工厂共享字段
    @url('/app/fac/form/wx/listSharedEnum')
    @get
    listSharedEnum() {}
    // 获取学期
    @url('/v3/schoolTermAndYear/getSchoolTerms')
    @post
    getSchoolTerms() {}
    // 获取学年
    @url('/v3/schoolTermAndYear/getSchoolYears')
    @post
    getSchoolYears() {}
    // 学年、学期级联
    @url('/v3/schoolTermAndYear/getSchoolYearTermCascade')
    @post
    getSchoolYearTermCascade() {}
    // 学期、学年级联
    @url('/v3/schoolTermAndYear/getTermSchoolYearCascade')
    @post
    getTermSchoolYearCascade() {}
    // 获取表单formId 初始化表单设计器完整表单信息
    @url('/develop/customtemplate/task/initForm')
    @get
    initForm() {}
    // 删除表单
    @url('/develop/customtemplate/task/delFormById')
    @get
    delFormById() {}

    // 获取应用工厂所有组件包括页面
    @url('/app/fac/form/wx/listAllFormInfo')
    @get
    listAllFormInfo() {}

    // 获取二维码扫码后返回参数
    @url('/v3/fac/appactive/getQrVariableVal')
    @get
    getQrVariableVal() {}

    // 保存二维码扫码后返回参数
    @url('/v3/fac/appactive/addQrVariable')
    @post
    addQrVariable() {}
}

export default new Form()
