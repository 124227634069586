import { get, post, url } from '../../service/ApiWebDecorator'

// 组织机构
class OrgAdmin {
  // 获取指标
  @url('/v3/target/getTargetTree')
  @get
  getTargetTree() {}

  // 同步组织结构
  @url('/v3/syncEosOrgData')
  @post
  syncEosOrgData() {}

  // 获取组织
  @url('/v3/choiceUser/getOrgListRecursive')
  @post
  getOrgListRecursive() {}

  // 获取社团
  @url('/v3/association/getAssociationInfoListBySchoolId')
  @post
  getAssociationInfoListBySchoolId() {}

  // 获取角色列表
  @url('/v3/sysRole/getSysRoleListBySchoolId')
  @post
  getSysRoleListBySchoolId() {}

  // 获取学生信息
  @url('/v3/stu/getStuInfo')
  @post
  getStuInfo() {}

  // 获取学校人数
  @url('/auth/eos/getschool/getUserCount')
  @post
  getUserCount() {}
}

export default new OrgAdmin()
