/**
 * api 拦截器
 * author: lgzhang@gmail.com
 * date: 2019-4-20
 *
 * 功能说明：
 *  1 统一增加header， token
 *  2 统一加载转圈处理，移动端  window.vant.Toast({type:loading,message:''}),web端 element loading
 *  3 统一异常处理，移动端mistoken重新认证，web端跳转登录页
 */

import axios from 'axios'
import DoUrl from '../utils/DoUrl'
import LoginToken from './auth/LoginToken'
import CacheAxios from '../utils/storage/CacheAxios'
/**
 * 解析window.location.serch
 */
function getSearchByName(name) {
  var reg = new RegExp('[?|&]' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.href.match(reg)
  if (r != null) return (r[1].split('#')[0])
  return null
}

import DeviceUtil from '../utils/DeviceUtil'
var axiosInstance = axios.create({
  baseURL: window.GateWayServer
})

axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
// 声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
// 调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0

function startLoading() {
  // 延迟100秒，如果100毫秒数据回来就不用loading了
  VueInstance && VueInstance.$store.commit('ajaxLoadingFinished', false)
  // 应用工厂自定义loading开启了就不用默认loading
  if (sessionStorage.globalLoadImage) return
  setTimeout(() => {
    if (needLoadingRequestCount > 0) {
      if (window.CommonService) location.href.includes('SDP') ? VueInstance.$sdpLoading?.show() : window.CommonService?.Loading()
    }
  }, 100)
}

function endLoading() {
  VueInstance && VueInstance.$store && VueInstance.$store.commit('ajaxLoadingFinished', true)
  if (window.CommonService) location.href.includes('SDP') ? VueInstance.$sdpLoading?.hide() : window.CommonService.Loading?.close()
  //  window.vant.Toast.clear()
}

function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    try {
      startLoading()
    } catch (error) {
      console.log('loading error:', error)
      // alert('请切换浏览器模式，手机端用模拟手机(F12打开模拟)，web端用web模式，或用真机或电脑直接查看')
    }
  }
  needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
  endLoading()
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount <= 0) {
    endLoading()
  }
}

axiosInstance.interceptors.request.use((config) => {
  // console.log('config:', config)
  // 临时配置，后端服务
  //  TODO center等导航不处理
  if (/http/.test(config.url) == false && getSearchByName('TomcatServer') && config.url.includes('/center/') == false) {
    config.url = 'http://' + getSearchByName('TomcatServer') + config.url
  }
  // mock数据处理，mock=true
  if (/mock=true/.test(window.location.href) || /mock=true/.test(config.url) || /mock=true/.test(config.data)) {
    config.method = 'get'
    config.url = window.MockServer + '/mock-data' + config.url.split('?')[0] + '.json?mock=true&noCache=' + (new Date()).getTime()
  }
  // 本地nodejs代理
  if (/http/.test(config.url) == false && DoUrl.getSearchByName('NodeServer') && /node-proxy/.test(config.url)) {
    config.url = 'http://' + DoUrl.getSearchByName('NodeServer') + config.url
  }
  // 统一增加后端服务地址
  if (/(http|https):\/\//.test(config.url) == false) {
    config.url = window.GateWayServer + config.url
  }
  // 增加 header token
  let token = Cookies.get('EOSToken')
  if (Cookies.get('SDPOperateToken')) token = Cookies.get('SDPOperateToken') || ''
  if (token) config.headers.Authorization = token ? 'Bearer ' + token : ''
  if ((config.url && config.url.indexOf('isAreaAuthorization=true') != -1) || (config.data && typeof (config.data) == 'string' && config.data.indexOf('isAreaAuthorization=true') != -1) || (config.data && typeof (config.data) == 'object' && config.data.isAreaAuthorization)) {
    const areaToken = Cookies.get('EOSAreaToken')
    if (areaToken) config.headers.AreaAuthorization = 'Bearer ' + areaToken
  }
  // 增加第二token，用于跨学校认证
  if (sessionStorage.EOSTmpToken) config.headers.Authorization2 = 'Bearer ' + sessionStorage.EOSTmpToken
  // 第三方 认证 token
  if (DoUrl.getSearchByName('thirdToken') && !config.headers.Authorization) {
    config.headers.Authorization = 'Bearer ' + DoUrl.getSearchByName('thirdToken')
  }
  // 统一带上schoolId, 文件上传不需要带oss-img, // TODO运营平台 图片上传 没有schoolId 默认0
  let schoolId = ''
  if (Cookies.get('EOSSchoolId')) schoolId = Cookies.get('EOSSchoolId')
  else schoolId = '0'
  let seperate = config.url.indexOf('?') > -1 ? '&' : '?'
  if (config.method.toLocaleLowerCase() === 'post') {
    if (/oss-img/.test(config.url) === false && schoolId && !config?.data?.toString()?.includes('noSchoolId=true')) config.url += seperate + 'schoolId=' + schoolId
    // 添加强制修改标识
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    if (DoUrl.getSearchByName('forceUpdate')) config.url += seperate + 'forceUpdate=' + DoUrl.getSearchByName('forceUpdate')
  } else if (config.method.toLocaleLowerCase() === 'get') {
    if (schoolId && !config.url.includes('noSchoolId=true')) config.url += seperate + 'schoolId=' + schoolId
    // 添加强制修改标识
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    if (DoUrl.getSearchByName('forceUpdate')) config.url += seperate + 'forceUpdate=' + DoUrl.getSearchByName('forceUpdate')
  }
  // 微信上传， 文件上传导致loading叠加问题处理 loading=false
  if (!(/loading=false/.test(window.location.href)) && !(/loading=false/.test(config.url)) && !(/loading=false/.test(config.data)) || (config.params && config.params.loading !== false) || (config.data && config.data.loading !== false)) {
    if (!/token=/.test(location.href) && config.data?.loading !== false) showFullScreenLoading()
  }

  // TODO 学校发展平台课程池页面携带source ='kcc'
  const excludePaths = ['/v3/export/', 'eos/form/admin/byid']
  if (['kcss', 'kcc'].includes(DoUrl.getSearchByName('sourcePage')) && /SDP/.test(location.href) && !config.url.includes('source=') && !(excludePaths.find(path => config.url.includes(path)))) {
    if (config.method == 'get') {
      config.url += '&source=' + DoUrl.getSearchByName('sourcePage')
    } else { // post请求 formData和requestPayload
      Object.prototype.toString.call(config.data) === '[object Object]' ? config.data.source = DoUrl.getSearchByName('sourcePage') : config.url += '&source=' + DoUrl.getSearchByName('sourcePage')
    }
  }
  // 应用工厂统一增加应用id facAppId
  if (/no-code/.test(location.href) && DoUrl.getSearchByNameHash('appId')) {
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    config.url += seperate + 'facAppId=' + DoUrl.getSearchByNameHash('appId')
  }
  // 历史数据管理，传递termId
  const isCurrentTerm = VueInstance.$store.getters.isCurrentTerm === undefined ? 1 : VueInstance.$store.getters.isCurrentTerm
  if (DoUrl.getSearchByName('historyTermId') || !isCurrentTerm) {
    const filterList = ['/history/term/list']// 不需要传递termId
    const flag = filterList.find(v => config.url.includes(v))
    const historyTermId = DoUrl.getSearchByName('historyTermId') || DoUrl.getSearchByName('schoolTermId') || DoUrl.getSearchByName('termId') || VueInstance.$store.schoolTermId
    if (!flag) config.url += seperate + `historyTermId=${historyTermId}`
  }
  // 毕业生历史数据管理
  if (DoUrl.getSearchByName('graduateYear')) {
    config.url += seperate + `bysFlg=true`
  }
  if ((/no-code/.test(location.href) || /preview-info/.test(location.href)) && !/source/.test(config.url)) {
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    // source  1：应用工厂正式 7：预览或设置页面
    if (/app-preview/.test(location.href) || /\/app-layout/.test(location.href) || /\/permission-setting/.test(location.href) || /\/app-data-exchange/.test(location.href)) {
      config.url += `${seperate}source=7`
    } else {
      config.url += `${seperate}source=1`
    }
  }
  // 缓存预处理
  config = CacheAxios.preCache(config)
  return config
}, (error) => {
  return Promise.reject(error)
})

// respone拦截器
axiosInstance.interceptors.response.use(
  response => {
    // console.log('response:', response)
    // 微信上传， 文件上传导致loading叠加问题处理 loading=false
    if (!(/loading=false/.test(window.location.href)) && !(/loading=false/.test(response.config.url)) && !(/loading=false/.test(response.config.data))) {
      tryHideFullScreenLoading()
    }
    // mock数据返回处理
    if (/mock=true/.test(response.config.url)) {
      return response.data
    }
    // TODO 不知道为什么要放这个，页面渲染不对？ 目前flex布局，图片有可能有压扁情况
    setTimeout(() => {
      // 通过滚动强制浏览器进行页面重绘
      document.body.scrollTop += 1
    }, 50)
    if (response.data.success) {
      // 学校发展中判断学期接口中当前学期是否在对应学段时间返回内，不在则提示
      if (response.config.url.indexOf('/region/commom/listTermBeanByAreaId') > -1 && location.href.includes('SDP')) {
        let currentTerm = response?.data?.data?.find(row => { return row.isNow == 'Y' })
        if (!(new Date(currentTerm.stime).getTime() <= new Date().getTime() && new Date().getTime() < new Date(currentTerm.etime).getTime())) {
          try {
            Vue.prototype.$elNotify({
              type: 'warning',
              title: '警告',
              message: '当前时间与当前学期对应的时间范围不匹配，请联系学校管理员修改',
              position: 'bottom-right',
              duration: 5000
            })
          } catch (err) {
            console.log(err)
          }
        }
      }
      // success是true，errorCode不为空，这种就是业务异常，整个接口返回去
      if (response.data.errorCode !== '0' && response.data.errorCode !== null && response.data.errorCode !== '') {
        return response.data
      } else {
        // 缓存数据
        CacheAxios.setCache(response.config, response.data.data)
        // 只返回用到的data
        return response.data.data
      }
    }
    // -9 授权码失效 后台微信配置有误（目前是code重复校验有问题，微信错误码 40029） mistoken走认证
    if (response.data && (response.data.errorCode && (response.data.errorCode == 'MISTOKEN' || response.data.errorCode == '-9' || response.data.errorDesc.indexOf('40029') > -1)) || response.data.errorCode == '-107' || response.data.errorCode == '-109') {
      // 保存当前url
      let httpUrl = window.location.href
      const routerArr = ['/user/select', '/router']
      let isCludes = false
      for (let i = 0; i < routerArr.length; i++) {
        if (httpUrl.indexOf(routerArr[i]) != -1) {
          isCludes = true
        }
      }
      if (!isCludes) localStorage.setItem('lastUrl', httpUrl)
      // 访客token存在，优先处理
      if (sessionStorage.vistorToken) {
        return LoginToken.doVistorLink(localStorage.lastUrl)
      }
      // trustCode 存在链接中优先处理，用trustCode 登录
      let trustCode = DoUrl.getSearchByName('trustCode')
      // if (trustCode && response.data.errorCode != '-9') {
      if (trustCode) {
        return LoginToken.loginByTrustCode(localStorage.lastUrl, trustCode)
      }
      if (response.data.errorCode == '-109' || response.data.errorDesc.indexOf('40029') > -1) localStorage.setItem('lastUrl', '')
      // 素养移动端重新走认证，web端跳出登录页
      if (location.pathname == '/m/' || location.pathname == '/m/index.html' || /localhost:9999/.test(location.href)) {
        // 微信清空走认证, 其他如h5不处理
        if (DeviceUtil.isWechat()) {
          Cookies.set('EOSToken', '')
          window.location.href = location.pathname
        }
      } else if (/micro-container/.test(location.pathname) || /no-code/.test(location.pathname) || /AreaAdmin/.test(location.pathname) || /admin/.test(location.pathname) || /SDP/.test(location.pathname)) { // 来自微应用的，直接跳转跟路径(或者eos-web登录页)防止 重复重定向
        Cookies.set('EOSToken', '')
        window.location.replace('/eos-web/#/login')
      } else {
        Cookies.set('EOSToken', '')
        window.location.href = location.pathname
      }
      return Promise.reject(response)
    }
    if (response.data.errorCode == '-1011' || response.data.errorCode == 1420) {
      return Promise.reject(response)
    }
    if (response.data.errorCode == '-5033') { // 登录黑名单跳转链接操作
      location.href = response.data.errorDesc
      return Promise.reject(response)
    }
    if (response.data.errorCode == '-1012') { // 登录黑名单跳转链接操作
      Cookies.set('EOSToken', '')
      VueInstance.$confirm('您的帐号在其他设备登录，请重新登录后操作！', '消息', { showCancelButton: false, confirmButtonText: '确定', type: 'warning' }).then(() => {
        window.location.replace('/eos-web/#/login')
      })
      return Promise.reject(response)
    }
    // 教学管理系统-选课-冲突数组信息显示
    if (['-100910'].includes(response.data.errorCode)) {
      let _clash = []
      try { _clash = JSON.parse(response.data.errorDesc) } catch (error) { console.log(error) }
      let _msg = '<div>'
      for (const iterator of _clash) { _msg += '<div style="padding:3px 0; line-height: 20px;"><strong>' + iterator.name + '</strong>：' + iterator.msg + '</div>' }
      _msg += '</div>'
      VueInstance.$confirm(_msg, '消息', { dangerouslyUseHTMLString: true, showCancelButton: false, confirmButtonText: '确定', type: 'warning' })
      return false
    }
    // 没有关注的情况，跳转 用户名密码 登录界面
    // Error_ClassId_1001("用户不存在", -1001),
    // Error_ClassId_1002("授权失败,您尚未被添加入企业号", -1002),
    // Error_ClassId_1003("授权失败,你无权登陆", -1003),
    // Error_ClassId_1004("授权失败,您无权进入当前应用", -1004),
    // Error_ClassId_1005("当前用户尚未关注企业号，请先关注", -1005),
    // Error_ClassId_1006("后台微信配置有误", -1006),
    // Error_ClassId_1007("Openid转换失败", -1007),
    if (['-1001', '-1002', '-1003', '-1004', '-1005', '-1007'].includes(response.data.errorCode)) {
      // TODO no-code应用工厂有访客，暂不提示, noAuthToast为不需提示标识
      if (/no-code/.test(localStorage.lastUrl) == false && !sessionStorage.getItem('noAuthToast')) window.CommonService.Alert({ message: response.data.errorDesc })
      setTimeout(() => {
        // 访客token存在，优先处理
        if (sessionStorage.vistorToken) {
          return LoginToken.doVistorLink(localStorage.lastUrl)
        }
        let path = '/login-pwd/'
        let lastUrl = localStorage.getItem('lastUrl')
        if (lastUrl.split('#')?.[1]?.startsWith('/aggregation')) {
          path = '/courseLogin'
        } else {
          path += Cookies.get('EOSSchoolId')
        }
        // TODO 微信认证处理重定向，非微信暂不处理
        if (DeviceUtil.isWechat()) VueInstance && VueInstance.$router.replace({ path })
      }, 1000)
      return false
    }
    // token超时，退出到登录页面
    if (response.data.code === 401) {
      console.log('logout: 401')
      return Promise.reject()
    }

    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    if (window.CommonService) {
      // TODO 登录页面，不用message提示，用弹出框提示，auth/eos/login/pwd, 移动端给出提示
      // response.config.url.indexOf('auth/eos/login/pwd') == -1
      if (response.config && response.config.url) {
        let _api = response.config.url.split('?')[0]
        if (_api.indexOf('://') != -1) {
          _api = _api.split('://')[1]
          _api = _api.substr(_api.indexOf('/'))
        }
        let _excludeApis = ['/auth/eos/login/pwd', '/auth/eos/area/login', '/oss2/eos/oss/get/snapshot']
        if (_excludeApis.indexOf(_api) == -1 || /\/m\//.test(location.href)) {
          // 错误码不提示，未知错误不提示
          if (response.data.errorDesc.includes('错误码') == false && response.data.errorDesc.includes('未知错误') == false && response.data.errorDesc.includes('参数为空') == false) {
            if (!(/noToast=true/.test(response.config.url)) && !(/noToast=true/.test(response.config.data))) {
              // TODO 紧急问题，临时处理
              if (response.data.errorDesc && response.data.errorDesc != '失败') window.CommonService.Alert({ message: response.data.errorDesc })
            }
          }
          if (response.data.errorDesc.indexOf('未知错误') > -1) {
            window.CommonService.Alert({ message: '系统错误，请截图并联系管理员(' + response.data.data + ')' })
          }
        }
      }
    }
    // alert(response.data.errorDesc)
    //  window.vant.Toast()
    return Promise.reject(response)
  },
  (error) => {
    tryHideFullScreenLoading()
    // 请求拦截器中的source.cancel会将内容发送到error中
    // 通过axios.isCancel(error)来判断是否返回有数据 有的话直接返回给用户
    if (axios.isCancel(error)) {
      return Promise.resolve(error.message)
    }
    // 这里是返回状态码不为200时候的错误处理
    return Promise.reject(error)
  }
)

export default axiosInstance
