/*
家校中心移pc接口
*/
import { url, post, get } from '../../service/ApiDecorator'
class HomeSchCenter {
  // pc端-Excel活动详情导出
  @url('/homeschoolcenter/activity/exportActivityDetails')
  @get
  exportActivityDetails() {}

  // pc端-Excel活动列表导出
  @url('/homeschoolcenter/activity/exportActivityInfo')
  @get
  exportActivityInfo() {}

  // pc端-活动详情
  @url('/homeschoolcenter/activity/pageHscActivityDetails')
  @post
  pageHscActivityDetails() {}

  // pc端-活动列表
  @url('/homeschoolcenter/activity/pageHscActivityPc')
  @post
  pageHscActivityPc() {}

  // pc端-Excel活动评分导入
  @url('/homeschoolcenter/activity/importScore')
  @post
  importScore() {}

  // pc端-Excel活动评分导入模板
  @url('/homeschoolcenter/activity/templateActivityScore')
  @post
  templateActivityScore() {}

  // PC端-导出积分管理学生列表(总分)
  @url('/homeschoolcenter/thoughtevaluate/exportStuScoreSummary')
  @post
  exportStuScoreSummary() {}

  // PC端-积分管理学生列表(总分)
  @url('/homeschoolcenter/thoughtevaluate/pageStuScoreSummary')
  @post
  pageStuScoreSummary() {}

  // PC端-学生参与活动分页
  @url('/homeschoolcenter/thoughtevaluate/pageStuHandActivity')
  @post
  pageStuHandActivity() {}

  // PC端-行政班集合
  @url('/homeschoolcenter/thoughtevaluate/listClassByType')
  @post
  listClassByType() {}

  // PC端-Excel补分导入
  @url('/homeschoolcenter/activity/importSupplementScore')
  @post
  importSupplementScore() {}

  // PC端-Excel补分导入模板
  @url('/homeschoolcenter/activity/templateSupplementScore')
  @post
  templateSupplementScore() {}
}
export default new HomeSchCenter()
