import { post, url } from '../../service/ApiDecorator'
class DataVisualizationTianyi {
  // 获取教师基础信息
  @url('/v3/teaVisualized/getTeacherInfo')
  @post
  getTeacherInfo() {}

  // 师德高品位和科研高水平 和能力多方位
  @url('/v3/teaVisualized/getTaskTitleAndPublishTime')
  @post
  getTaskTitleAndPublishTime() {}

  // 教师标签
  @url('/v3/teaVisualized/getTeaLables')
  @post
  getTeaLables() {}

  // 专业高知识
  @url('/v3/teaVisualized/getBookNamesAndUrls')
  @post
  getBookNamesAndUrls() {}

  // 雷达图
  @url('/v3/teaVisualized/getSyScore')
  @post
  getSyScore() {}
}
export default new DataVisualizationTianyi()
