import { postJson, url, } from '../../service/ApiWebDecorator'

// 数据应用

class ExportPdf {
  // 一个pdf发送邮件
  @url('/v3/pdfConvert/joinBatchPdfConvertSendEmail')
  @postJson
  sendEmail() {}
  // 素养护照单个一个pdf发送邮件
  @url('/v3/pdfConvert/sendEmail')
  @postJson
  sendOnlyEmail() {}
  // 多个pdf发送邮件
  @url('/v3/pdfConvert/batchSendEmail')
  @postJson
  batchSendEmail() {}
}
export default new ExportPdf()
