import { get, post, postJson, url } from '../../service/ApiWebDecorator'

// 导入接口
class ImportBatch {
  // 获取导入百分比列表
  @url('/v3/getImportPercentList')
  @post
  getImportPercentList() {}

  // 删除指定列表数据
  @url('/v3/delImportPercent')
  @post
  delImportPercent() {}

  // 上传导入
  @url('/v3/uploadFormValue')
  @post
  uploadFormValue() {}

  // 导出错误数据
  @url('/v3/exportErrorValue')
  @post
  exportErrorValue() {}

  // 同步数据
  @url('/v3/tongbuExcelData')
  @post
  syncExcelDta() {}

  // 批量导入选择提交人
  @url('/v3/choiceUser/getTaskJoinUserPage')
  @postJson
  getTaskJoinUserPage() {}

  // 选择归属人
  @url('/v3/choiceUser/pageTaskBelongUser')
  @postJson
  pageTaskBelongUser() {}

  // 重新上传错误数据
  @url('/v3/updateExcelData')
  @post
  updateExcelData() {}

  // 查询进度条列表总条数
  @url('/v3/getCommonPercentCount')
  @post
  getCommonPercentCount() {}

  // 更新当前状态
  @url('/v3/updatePercentState')
  @post
  updatePercentState() {}

  // 查看任务状态
  @url('/v3/getTaskDetail')
  @post
  getTaskDetail() {}

  // 查看当前任务是否正在导入
  @url('/v3/getCommonPercentByFormId')
  @post
  getCommonPercentByFormId() {}

  // 富文本导入--选人插件列表数据
  @url('/v3/choiceUser/getFormItemChoicePage')
  @postJson
  getFormItemChoicePage() {}

  // 富文本导入--选人插件组织列表
  @url('/v3/choiceUser/getOrgListRecursiveByChoice')
  @get
  getOrgListRecursiveByChoice() {}

  // 导出获取表单
  @url('/v3/getForm')
  @post
  getForm() {}
  @url('/v3/admin/common/getFormItemInfoListByTaskId')
  @post
  getFormItemInfoListByTaskId() {}

  // 导出获取匹配字段
  @url('/v3/getRoleAndDutyList')
  @post
  getRoleAndDutyList() {}

  // 导出进度条
  @url('/v3/getCommonPercentInfo')
  @post
  getCommonPercentInfo() {}

  // 导入进度条
  @url('/v3/getUploadCommonPercentInfo')
  @post
  getUploadCommonPercentInfo() {}

  // 下载模板
  @url('/v3/exportForm')
  @post
  exportForm() {}
  // 查询导入
  @url('/v3/getExcelData')
  @post
  getExcelData() {}
}

export default new ImportBatch()
