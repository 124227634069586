import { get, post, url, postJson } from '../../service/ApiWebDecorator'

class ScoreList {
    // 获取得分场景数据
    @url('/v3/getSceneTree')
    @post
    getSceneTree() {}
    // 获取得分场景数据
    @url('/v3/getSceneComputeTree')
    @post
    getSceneComputeTree() {}
    // 素养分查询
    @url('/v3/target/filterStuList')
    @post
    getScoreList() {}
    // 获取培养模型修改状态
    @url('/v3/culitivate/getCultivateUpStatus')
    @post
    getCultivateUpStatus() {}
    // 查询培养模型修改情况判断是否需要更新数据
    @url('/v3/culitivate/judgeCultivateDataUp')
    @post
    judgeCultivateDataUp() {}
    // 更新培养模型关联得分数据
    @url('/v3/culitivate/updateCultivateScoreData')
    @post
    updateCultivateScoreData() {}
    // 查询指标修改情况接口
    @url('/v3/target/getCultivateDataUpProcess')
    @post
    getCultivateDataUpProcess() {}
    // 素养分列表导出
    @url('/v3/target/exportExcel')
    @get
    exportExcel() {}
    // 素养分详情导出
    @url('/v3/stu/exportStuScoreDetail')
    @get
    exportStuScoreDetail() {}
    // 素养得分首页
    @url('/v3/stu/searchStudent')
    @post
    searchStudent() {}
    // 组织得分列表
    @url('/v3/org/searchOrg')
    @post
    searchOrg() {}
    // 组织得分详情页
    @url('/v3/org/getOrgScoreDetail')
    @post
    getOrgScoreDetail() {}

    // 导出学生列表
    @url('/v3/stu/searchStudentExport')
    @get
    searchStudentExport() {}
    // 导出教师列表
    @url('/v3/target/searchTeaExport')
    @postJson
    searchTeaExport() {}
    // 导出学生详情
    @url('/v3/stu/getStuScoreDetailExport')
    @postJson
    getStuScoreDetailExport() {}
    // 导出老师详情
    @url('/v3/stu/teaScoreDetailExport')
    @get
    getTeaScoreDetailExport() {}
    // 学生详情是否能导出
    @url('/v3/stu/canExportStuScoreDetail')
    @get
    canExportStuScoreDetail() {}
    // 学生列表是否能导出
    @url('/v3/stu/canExportStuScore')
    @get
    canExportStuScore() {}

    // 导出组织列表
    @url('/v3/org/searchOrgExport')
    @get
    searchOrgExport() {}
    // 导出组织得分详情
    @url('/v3/org/getOrgScoreDetailExport')
    @get
    getOrgScoreDetailExport() {}
    // 组织详情是否能导出
    @url('/v3/org/canExportOrgScoreDetail')
    @get
    canExportOrgScoreDetail() {}
    // 组织列表是否能导出
    @url('/v3/org/canExportOrgScore')
    @get
    canExportOrgScore() {}
    // 获得基础分列表
    @url('/v3/stu/getStuBaseScoreDetail')
    @get
    getStuBaseScoreDetail() {}
    // 确定是否发送邮件
    @url('/v3/stu/searchStudentExportCount')
    @get
    searchStudentExportCount() {}
    // 组织得分确定是否发送邮件
    @url('/v3/org/searchOrgExportCount')
    @get
    searchOrgExportCount() {}
    // 导出下载列表
    @url('/v3/stu/searchFileDownLoadList')
    @get
    searchFileDownLoadList() {}
    // 素养得分现行导出文件数量
    @url('/v3/stu/getStuScoreDetailExportCount')
    @postJson
    getStuScoreDetailExportCount() {}
    // 获取维度
    @url('/sys/api/contactSet/getDetail')
    @get
    getContactSetDetail() {}
    // 获取得分详细
    @url('/app/task/getTaskDataDetail')
    @post
    getTaskDataDetail() {}
    // 获取得分详细(场景未发布不做限制)
    @url('/v3/task/getTaskDataDetailByPc')
    @post
    getTaskDataDetailByPc() {}
    // 获取异常信息
    @url('/v3/ruleStuErrorLog/getRuleStuErrorLog')
    @postJson
    getRuleStuErrorLog() {}
}

export default new ScoreList()
