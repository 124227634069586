import axios from './AxiosWebInterceptors'
import DoApi from '../utils/DoApi'

// api装饰器
function send(target, name, descriptor, callback) {
  descriptor.value = function() {
    return callback(arguments[0])
  }
  return descriptor
}

// 添加 路径
function url(url) {
  return function(target, name, descriptor) {
    descriptor.url = url
  }
}

function get(target, name, descriptor) {
  return send(target, name, descriptor, function(args, url) {
    let params = DoApi.jsonUrlFormat(args) || ''
    if (params) params = '?' + params
    return axios.get(DoApi.doPathParam(descriptor.url, args) + params)
  })
}

function post(target, name, descriptor) {
  return send(target, name, descriptor, function(args) {
    return axios.post(DoApi.doPathParam(descriptor.url, args), DoApi.doJson(args))
  })
}

function postJson(target, name, descriptor) {
  return send(target, name, descriptor, function(args) {
    return axios.post(DoApi.doPathParam(descriptor.url, args), DoApi.doPostJson(args))
  })
}
export {
  get,
  url,
  post,
  postJson,
}
