import { post, url } from '../../service/ApiWebDecorator'

// 导入接口
class GeneralRules {
  // 添加通用规则
  @url('/v3/rule/engine/add')
  @post
  ruleEngineAdd() {}
  // 修改通用规则
  @url('/v3/rule/engine/update')
  @post
  ruleEngineUpdate() {}
  // 删除通用规则
  @url('/v3/rule/engine/remove')
  @post
  ruleEngineRemove() {}
  // 规则引擎分页查询
  @url('/v3/rule/engine/page')
  @post
  ruleEnginePage() {}
  // 规则引擎详情查询
  @url('/v3/rule/engine/detail')
  @post
  ruleEnginedetail() {}
  // 规则引擎列表查询
  @url('/v3/rule/engine/list')
  @post
  ruleEngineList() {}
  // 业务规则分页查询
  @url('/v3/rule/page')
  @post
  EngineRulePage() {}
  // 业务规则详情查询
  @url('/v3/rule/detail')
  @post
  EngineRuleDetail() {}
  // 业务规则删除
  @url('/v3/rule/remove')
  @post
  EngineRuleRemove() {}
  // 业务规则修改执行状态
  @url('/v3/rule/changeExecuteStatus')
  @post
  ChangeExecuteStatus() {}
  // 查询微信小程序模板
  @url('/v3/eos/listAreaMinappTemplate')
  @post
  listAreaMinappTemplate() {}
  // 查询短信模板
  @url('/v3/eos/listMessageTemplate')
  @post
  listMessageTemplate() {}
  // 查询企业号微信应用
  @url('/v3/eos/listSendNoticeEosWxApp')
  @post
  listSendNoticeEosWxApp() {}
  // 查询钉钉应用
  @url('/v3/eos/listSendNoticeEosDingDingApp')
  @post
  listSendNoticeEosDingDingApp() {}
  // 业务规则保存
  @url('/v3/rule/add')
  @post
  ruleAdd() {}
  // 业务规则修改
  @url('/v3/rule/update')
  @post
  ruleUpdate() {}
  // 查询区域角色
  @url('/v3/eos/listAreaRole')
  @post
  listAreaRole() {}
  // 查询任务
  @url('/v3/listSceneTreeAll')
  @post
  listSceneTreeAll() {}
}

export default new GeneralRules()
