import { get, post, url } from '../../service/ApiWebDecorator'

// 素养可视化---以后接口统一
class ViewGroupCharts {
  // 获取可视化logo
  @url('/v3/eos/getSchoolLogo')
  @get
  getSchoolLogo() {}

  // 获取eos学校id
  @url('/v3/chart/getSchoolEosId')
  @post
  getSchoolEosId() {}
}

export default new ViewGroupCharts()
