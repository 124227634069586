import { get, post, postJson, url } from '../../service/ApiWebDecorator'

class AppPage {
  // 页面分组列表
  @url('/v3/fac/apppagegroup/listFacPageGroup')
  @get
  listFacPageGroup() {}
  // 查询应用页面分组区分终端
  @url('/v3/fac/apppagegroup/listFacPageGroupByTerminal')
  @get
  listFacPageGroupByTerminal() {}
  // 查询起始页面
  @url('/v3/fac/apppagegroup/getStartPage')
  @get
  getStartPage() {}
  // 查询页面详情
  @url('/v3/fac/apppage/getByPageId')
  @get
  getByPageId() {}
  // 添加页面分组
  @url('/v3/fac/apppagegroup/add')
  @get
  addPageGroup() {}
  // 删除页面分组
  @url('/v3/fac/apppagegroup/delete')
  @get
  deletePageGroup() {}
  // 修改页面分组
  @url('/v3/fac/apppagegroup/update')
  @post
  updatePage() {}
  // 修改应用页面排序
  @url('/v3/fac/apppagegroup/updateSort')
  @get
  updateSort() {}
  // 克隆应用工厂页面
  @url('/v3/fac/apppagegroup/copyPage')
  @get
  copyPage() {}
  // 添加菜单
  @url('/v3/fac/menu/add')
  @get
  addMenu() {}
  // 删除菜单
  @url('/v3/fac/menu/delete')
  @get
  deleteMenu() {}
  // 修改应用菜单
  @url('/v3/fac/menu/update')
  @post
  updateMenu() {}
  // 修改分組排序
  @url('/v3/fac/menu/updateSort')
  @get
  updateMenuSort() {}
  // 查询应用菜单区分终端
  @url('/v3/fac/menu/listFacMenuByTerminal')
  @get
  listFacMenuByTerminal() {}
  // 应用工厂复制事件
  @url('/v3/fac/clone/cloneActive')
  @postJson
  cloneActive() {}
  // 应用工厂复制条件
  @url('/v3/fac/clone/cloneCondition')
  @postJson
  cloneCondition() {}
  // 应用工厂复制动作
  @url('/v3/fac/clone/cloneAction')
  @postJson
  cloneAction() {}
  // 查询导入配置
  @url('/v3/fac/import/getImportConfigByBtnPageId')
  @get
  getImportConfigByBtnPageId() {}
  // 添加导入配置
  @url('/v3/fac/import/addImportConfig')
  @postJson
  addImportConfig() {}
  // 应用工厂下载导入模板
  @url('/v3/fac/import/downloadTemplate')
  @post
  downloadTemplate() {}
  // 应用工厂导入excel
  @url('/v3/fac/import/importExcelData')
  @post
  importExcelData() {}
  // 应用工厂导入已上传数据
  @url('/v3/fac/import/importUploadData')
  @post
  importUploadData() {}
  // 应用工厂导入进度条
  @url('/v3/fac/import/getFacAppImportPercent')
  @get
  getFacAppImportPercent() {}
  // 应用工厂导入进度条
  @url('/v3/fac/import/getPercentByBtnPageIdAndTerminal')
  @get
  getPercentByBtnPageIdAndTerminal() {}
  // 应用工厂导出错误信息
  @url('/v3/fac/import/exportErrorValue')
  @get
  exportErrorValue() {}
}

export default new AppPage()
