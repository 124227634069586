import Vue from 'vue'
/**
 * 富文本处理，添加点击事件
 */
function doHtml(text) {
  // 图片标签匹配
  const imgReg = /<(img|IMG)(\s|[^>]+)([^>]+\>|\>)/gi // 匹配 img

  text = text.replace(imgReg, function(str) {
    // // Todo 暂时以src分割，添加click事件
    const strArray = str.split('src=')
    // 测试参数
    str = strArray[0] + `preview="100" src=` + strArray[1]
     return str
  })
  return text
}

// 富文本处理，添加点击事件
Vue.directive('file-html', {
	bind: function(el, value, node) {
		if (!value.value) {
			el.innerHTML = ''
		} else {
			el.innerHTML = doHtml(value.value)
		}
	},
	update: function(el, value) {
		if (!value.value) {
			el.innerHTML = ''
		} else {
			el.innerHTML = doHtml(value.value)
		}
	},
	unbind: function() {
		// 清理工作
		// 例如，删除 bind() 添加的事件监听器
	}
})

