// 转换
import Vue from 'vue'
import DoText from '../../utils/DoText'
import FormConvert from '../../utils/FormConvert'
// 字符串长度，按中文计数  长度超过20省略号
Vue.filter('doCharLength20el', function(value) {
  return value.length > 20 ? value.substring(0, 20) + '...' : value
})

window.doTitleValue = (value) => {
  try {
    let arr = JSON.parse(value)
    if (typeof arr == 'object' && arr) {
      return arr[0].val
    } else {
      return value
    }
  } catch (e) {
    console.log('error：' + value + '!!!' + e)
    return false
  }
}

// 字符串长度，按中文计数
Vue.filter('doCharLength', function(value) {
  return parseInt(DoText.getCharLength(value) / 2)
})

// 字符串长度，按英文计数
Vue.filter('doCharEnLength', function(value) {
  return DoText.getCharLength(value)
})
// TODO 截取字符串长度
Vue.filter('cutTextLength', function(str, strLength) {
  let text = '111'
  // 给一个变量来记录长度
  for (var i = 0; i < strLength; i++) {
    // var countCode = str.charCodeAt(i)
    // 返回指定位置的字符的Unicode编码
    // 判断是不是ASCII码,Unicode码前128个字符是ASCII码
    // if (countCode >= 0 && countCode <= 128) {
    // } else {
    // }
  }
  return text
})

// 数据应用去掉小数点后面的0
Vue.filter('removeZero', function(value) {
  if (!value) return ''
  return FormConvert.removeZero(value)
})

// titleValue枚举字段返回格式
Vue.filter('doTitleValue', function(value) {
  return window.doTitleValue(value)
})
