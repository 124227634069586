import { post, postJson, url } from '../../service/ApiWebDecorator'

// 通讯录
class Modification {
  // 获取学生列表
  @url('/v3/choiceUser/getStuPage')
  @postJson
  getStuPage() {}

   // 获取老师列表
   @url('/v3/choiceUser/getTeaPage')
   @postJson
   getTeaPage() {}

  // 获取家长列表
  @url('/v3/choiceUser/getParPage')
  @postJson
  getParPage() {}

  // 获取所有学生列表
  @url('/v3/choiceUser/getAllStuList')
   @postJson
   getAllStuList() {}

   // 获取所有老师列表
  @url('/v3/choiceUser/getAllTeaList')
  @postJson
  getAllTeaList() {}

   // 获取所有老师列表
   @url('/v3/getTeaPageBySchoolId')
   @postJson
   getTeaPageBySchoolId() {}

  // 获取所有社团列表
  @url('/v3/choiceUser/getAssociationPage')
  @postJson
  getAssociationPage() {}

  // 过程数据-获得场景列表(web端)
  @url('/v3/itemData/getSceneByTea')
  @postJson
  getSceneByTea() {}

  // 过程数据-场景获取数据量
  @url('/v3/itemData/getSceneDataList')
  @post
  getSceneDataList() {}

  // 过程数据-过程获取数据量
  @url('/v3/itemData/getSceneItemDataList')
  @post
  getSceneItemDataList() {}

  // 过程数据-任务获取数据量
  @url('/v3/itemData/getFormDataList')
  @post
  getFormDataList() {}
  // 教师发展选人
  @url('/v3/top/listOrgsFilterTeaListByRange')
  @post
  listOrgsFilterTeaListByRange() {}
}

export default new Modification()
