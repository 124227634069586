import { get, post, url } from '../../service/ApiWebDecorator'

class AppList {
  // 添加应用页面
  @url('/v3/fac/apppage/add')
  @get
  apppageAdd() {}
  // 删除应用页面
  @url('/v3/fac/apppage/delete')
  @post
  apppageDelete() {}
  // 查询页面详情
  @url('/v3/fac/apppage/getById')
  @get
  apppageGetById() {}
  // 查询应用页面
  @url('/v3/fac/apppage/listFacPage')
  @get
  apppageListFacPage() {}
  // 修改应用页面
  @url('/v3/fac/apppage/update')
  @post
  apppageUpdate() {}
  // 应用工厂克隆应用
  @url('/v3/fac/clone/cloneFacApp')
  @get
  cloneFacApp() {}
}

export default new AppList()
