/**
 * 正则表达式
 */
class RegRule {
    // 构造
    constructor() {
        // http
        this.regHttp = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/gi
        // 公式运算符切分
        this.regFormulaOperate = /[\s\\+\-\*\/\(\)]+/g
    }
}

export default new RegRule()
