import { postJson, post, url } from '../../service/ApiWebDecorator'
import { post as postJsonEos, url as urlEos } from '../../service/ApiDecorator'

// 任务自定自己流程
class SystemUseStatistics {
  // 任务使用统计
  @url('/v3/usedCount/taskUsedCount')
  @post
  taskUsedCount() {}
  // 任务使用统计导出
  @url('/v3/usedCount/taskUsedCountExcel')
  @post
  taskUsedCountExcel() {}
  // 场景使用数据量
  @url('/v3/usedCount/sceneUsedCount')
  @postJson
  sceneUsedCount() {}
  // 场景使用数据量详情
  @url('/v3/usedCount/sceneDetailUsedCount')
  @post
  sceneDetailUsedCount() {}
  // 场景使用数据量导出
  @url('/v3/usedCount/sceneUsedCountExcel')
  @postJson
  sceneUsedCountExcel() {}
  // 场景使用数据量详情导出
  @url('/v3/usedCount/sceneDetailUsedCountExcel')
  @postJson
  sceneDetailUsedCountExcel() {}
  // 指标统计
  @url('/v3/usedCount/targetLevelCount')
  @postJson
  targetLevelCount() {}
  // 指标统计导出
  @url('/v3/usedCount/targetLevelCountExcel')
  @postJson
  targetLevelCountExcel() {}
  // 应用统计
  @urlEos('/appCount/api/sy/record/app/list')
  @postJsonEos
  getAPPList() {}
  // 用户统计
  @urlEos('/appCount/api/sy/record/user/list')
  @postJsonEos
  getUserList() {}
  // 用户统计
  @urlEos('/appCount/api/sy/record/list')
  @postJsonEos
  getRecordList() {}

  // 维度类型
  @url('/v3/exposed/taskMonitor/orgBeanType')
  @post
  orgBeanType() {}

  // 维度list
  @url('/v3/choiceUser/getOrgListByTypeAndSchool')
  @post
  getOrgListByTypeAndSchool() {}

  // 任务使用人数-未使用学生数
  @url('/v3/usedCount/getNotUsedDetails')
  @post
  getNotUsedDetails() {}

  // 任务使用人数-使用学生数
  @url('/v3/usedCount/getUserDetails')
  @post
  getUserDetails() {}

  // 任务使用人数-使用学生数导出
  @url('/v3/usedCount/getUserDetailsExcel')
  @post
  getUserDetailsExcel() {}

  // 任务使用人数-未使用学生数导出
  @url('/v3/usedCount/getNotUsedDetailsExcel')
  @post
  getNotUsedDetailsExcel() {}

  // 任务统计-系统使用情况
  @url('/v3/usedCount/getUsedDetailsByUserType')
  @post
  getUsedDetailsByUserType() {}

  // 任务统计-系统使用情况导出
  @url('/v3/usedCount/getUsedDetailsByUserTypeExcel')
  @post
  getUsedDetailsByUserTypeExcel() {}

  // 任务使用任务-详情页组织获取
  @url('/v3/usedCount/getOrgList')
  @post
  getOrgList() {}
}

export default new SystemUseStatistics()
