// 转换
import Vue from 'vue'
import DoText from '../../utils/DoText'
import FormConvert from '../../utils/FormConvert'

// 字符串长度，按中文计数
Vue.filter('doCharLength', function(value) {
  return parseInt(DoText.getCharLength(value) / 2)
})

// 字符串长度，按英文计数
Vue.filter('doCharEnLength', function(value) {
  return DoText.getCharLength(value)
})

// 省市区 地址转换
// import { ChinaAddressV4Data, Value2nameFilter as value2name } from 'vux-lvya'
// Vue.filter('valueToAddress', function(value) {
//   console.log('valueToAddress:', value)
//   if (!value) return ''
//   return value2name(value.split(','), ChinaAddressV4Data)
// })

// 数据应用去掉小数点后面的0
Vue.filter('removeZero', function(value) {
  if (!value) return ''
  return FormConvert.removeZero(value)
})

// 通讯录中 性别转换 1：男，2：女
Vue.filter('sexConvert', function(value) {
  return value == 1 ? '男' : value == 2 ? '女' : '--'
})

// 通讯录中 角色转换转换 0：老师，1：学生，2：家长
Vue.filter('roleConvert', function(value) {
  return value == 0 ? '老师' : value == 1 ? '学生' : '家长'
})

// 字符串长度，按中文计数  长度超过20省略号
Vue.filter('doCharLengthCN', function(value) {
  return value.length > 40 ? value.substring(0, 40) + '...' : value
})

// 字符串长度，按中文计数  长度超过20省略号
Vue.filter('removeSecond', function(value) {
  return value ? value.substring(0, 16) : ''
})
// 时间截取年月日
Vue.filter('timeLenght', function(value) {
  // if (!value) return ''
  return value.substring(0, 10)
})

