import { post, postJson, get, url } from '../../service/ApiWebDecorator'
// 亦庄-行为规范
class Behavior {
  // 基础分新建
  @url('/v3/custom/yizhuang/addConductScore')
  @post
  addConductScore() { }

  // 详情
  @url('/v3/custom/yizhuang/getConductScore')
  @post
  getConductScore() { }

  // 基础分列表
  @url('/v3/custom/yizhuang/pageConductScore')
  @post
  pageConductScore() { }

  // 初始化新建
  @url('/v3/custom/yizhuang/addConductTarget')
  @post
  addConductTarget() { }

  // 初始化-指标-详情
  @url('/v3/custom/yizhuang/getConductTarget')
  @post
  getConductTarget() { }

  // 得分汇总
  @url('/v3/custom/yizhuang/listFormItemScore')
  @post
  listFormItemScore() { }

  // 老师查看学生
  @url('/v3/custom/yizhuang/pageUserScore')
  @post
  pageUserScore() { }

  // 老师查看班级
  @url('/v3/custom/yizhuang/pageAdminClazzScore')
  @post
  pageAdminClazzScore() { }

  // 老师查看年级
  @url('/v3/custom/yizhuang/pageGradeScore')
  @post
  pageGradeScore() { }

  // 明细
  @url('/v3/custom/yizhuang/pageUserDetail')
  @post
  pageUserDetai() { }

  // 导出
  @url('/v3/export/exportData')
  @post
  exportData() { }

  // 学生查看自己
  @url('/v3/custom/yizhuang/getUserScoreDetail')
  @post
  getUserScoreDetail() { }

  // 保存任务
  @url('/v3/custom/yizhuang/addConductTask')
  @post
  addConductTask() { }

  // 获取任务
  @url('/v3/custom/yizhuang/getConductTask')
  @post
  getConductTask() { }

  // 行为规范新版接口--------------------------------------
  // 新增-编辑行为规范分类
  @url('/v3/custom/category/addCustomConductCategory')
  @post
  addCustomConductCategory() { }

  // 单个查询行为规范分类信息
  @url('/v3/custom/category/getCustomConductCategory')
  @post
  getCustomConductCategory() { }

  // 分页查询行为规范分类信息
  @url('/v3/custom/category/pageCustomConductCategory')
  @post
  pageCustomConductCategory() { }
  // 分页查询行为规范分类信息 (只能看属于自己分类) 学生成绩认定分类查询
  @url('/v3/custom/category/pageCustomConductCategory4StuByAuth')
  @post
  pageCustomConductCategory4StuByAuth() { }

  // 分页查询行为规范分类信息 (只能看属于自己分类) 班级成绩认定分类查询
  @url('/v3/custom/category/pageCustomConductCategoryByAuth')
  @post
  pageCustomConductCategoryByAuth() { }

  // 删除查询行为规范分类信息
  @url('/v3/custom/category/removeCustomConductCategory')
  @post
  removeCustomConductCategory() { }

  // 新建行为规范项目
  @url('/v3/custom/Entry/addCustomConductEntry')
  @post
  addCustomConductEntry() { }

  // 查询行为规范项目
  @url('/v3/custom/Entry/pageCustomConductEntry')
  @post
  pageCustomConductEntry() { }

  // 查询行为规范项目
  @url('/v3/custom/Entry/removeCustomConductEntry')
  @post
  removeCustomConductEntry() { }

  // 成绩认定分页-旧接口
  @url('/v3/custom/evaluate/pageCustomConductEvaluate')
  @post
  pageCustomConductEvaluate() { }
  // 学生成绩认定分页-新接口
  @url('/v3/custom/evaluate/pageCustomConductEvaluate/cjrd')
  @post
  pageCustomConductEvaluatecjrd() { }
  // 学生成绩得分查询
  @url('/v3/custom/evaluate/pageCustomConductEvaluate/xwgfdf')
  @post
  pageCustomConductEvaluatexwgfdf() { }

  // 成绩认定
  @url('/v3/custom/evaluate/saveCustomConductEvaluate')
  @postJson
  saveCustomConductEvaluate() { }

  // 成绩认定-学生批量认定
  @url('/v3/custom/evaluate/saveBatch4Stu')
  @postJson
  saveBatch4Stu() { }

  // 成绩认定编辑
  @url('/v3/custom/evaluate/updateCustomConductEvaluate')
  @post
  updateCustomConductEvaluate() { }

  // 成绩认定导出
  @url('/v3/custom/evaluate/getCustomConductEvaluateExcel')
  @post
  getCustomConductEvaluateExcel() { }

  // 成绩认定得分明细
  @url('/v3/custom/evaluate/pageEvaluateDetails')
  @postJson
  pageEvaluateDetails() { }

  // 删除成绩认定评分
  @url('/v3/custom/evaluate/removeCustomConductEvaluate')
  @post
  removeCustomConductEvaluate() { }

  // 得分汇总分页
  @url('/v3/custom/evaluate/pageSummaryEvaluate')
  @post
  pageSummaryEvaluate() { }

  // 得分汇总导出
  @url('/v3/custom/evaluate/summaryEvaluateExcel')
  @post
  summaryEvaluateExcel() { }

  // 得分汇总明细分页
  @url('/v3/custom/evaluate/pageSummaryEvaluateDetails')
  @post
  pageSummaryEvaluateDetails() { }

  // 得分汇总明细导出
  @url('/v3/custom/evaluate/summaryEvaluateDetailsExcel')
  @post
  summaryEvaluateDetailsExcel() { }

  // 老师查看学生
  @url('/v3/custom/evaluate/pageTeaToViewStuConduct')
  @post
  pageTeaToViewStuConduct() { }

  // 老师查看学生导出
  @url('/v3/custom/evaluate/teaToViewStuConductExcel')
  @post
  teaToViewStuConductExcel() { }

  // 老师查看学生明细导出
  @url('/v3/custom/evaluate/teaToViewStuConductDetailsExcel')
  @post
  teaToViewStuConductDetailsExcel() { }

  // 行为规范-老师查看班级
  @url('/v3/custom/evaluate/pageTeaToViewClassConduct')
  @post
  pageTeaToViewClassConduct() { }
  // 行为规范-班级成绩认定查询
  @url('/v3/custom/evaluate/pageTeaToViewClassConduct/cjrd')
  @post
  pageTeaToViewClassConductcjrd() { }
  // 行为规范-班级、年级得分查询
  @url('/v3/custom/evaluate/pageTeaToViewClassConduct/xwgfdf')
  @post
  pageTeaToViewClassConductxwgfdf() { }

  // 行为规范-老师查看班级导出
  @url('/v3/custom/evaluate/teaToViewClassConductExcel')
  @post
  teaToViewClassConductExcel() { }

  // 行为规范-老师查看班级明细导出
  @url('/v3/custom/evaluate/teaToViewClassDetailsExcel')
  @post
  teaToViewClassDetailsExcel() { }

  // 行为规范-老师查看年级
  @url('/v3/custom/evaluate/pageTeaToViewGradeConduct')
  @post
  pageTeaToViewGradeConduct() { }

  // 行为规范-老师查看年级导出
  @url('/v3/custom/evaluate/teaToViewOrgConductExcel')
  @post
  teaToViewOrgConductExcel() { }

  // 行为规范-老师查看年级明细导出
  @url('/v3/custom/evaluate/teaToViewGradeDetailsExcel')
  @post
  teaToViewGradeDetailsExcel() { }

  // 行为规范-学生查看自己
  @url('/v3/custom/evaluate/pageStuToViewSelf')
  @post
  pageStuToViewSelf() { }

  // 行为规范-学生查看自己展示总分
  @url('/v3/custom/evaluate/stuToViewSelfDetails')
  @post
  stuToViewSelfDetails() { }

  // 行为规范-选择年级列表
  @url('/v3/custom/evaluate/listGrade')
  @post
  listGrade() { }

  // 行为规范-选择班级列表
  @url('/v3/custom/evaluate/listClass')
  @post
  listClass() { }

  // 行为规范-下载模板
  @url('/v3/custom/Entry/templateEntryExcel')
  @post
  templateEntryExcel() { }

  // 行为规范-下载模板
  @url('/auth/eos/parents/getStus')
  @post
  getStus() { }

  // 行为规范-成绩认定通知设置
  @url('/v3/custom/yizhuang/addNoticeSet')
  @postJson
  addNoticeSet() { }
  // 行为规范-成绩认定通知设置
  @url('/v3/custom/yizhuang/getNoticeSet')
  @get
  getNoticeSet() { }

  // 导出用户列表分数 模板
  @url('/v3/custom/yizhuang/exportUserScoreDetail')
  @post
  exportUserScoreDetail() { }
  // 导出班级列表分数模板
  @url('/v3/custom/yizhuang/exportOrgTemplate')
  @post
  exportOrgTemplate() { }

  // 获取学校设置
  @url('/v3/custom/yizhuang/getGlobalInfo')
  @get
  getGlobalInfo() { }
  // 修改、编辑学校设置
  @url('/v3/custom/yizhuang/addGlobalInfo')
  @postJson
  addGlobalInfo() { }

  // 班级成绩认定（修改）
  @url('/v3/custom/evaluate/updateCustomConductEvaluate4Class')
  @post
  updateCustomConductEvaluate4Class() { }
  // 班级成绩认定（可批量）
  @url('/v3/custom/evaluate/saveCustomConductEvaluate4Class')
  @postJson
  saveCustomConductEvaluate4Class() { }
  // 班级成绩认定-明细
  @url('/v3/custom/evaluate/pageSummaryEvaluateDetails4Class')
  @postJson
  pageSummaryEvaluateDetails4Class() { }
  // 班级成绩认定-明细删除
  @url('/v3/custom/evaluate/removeCustomConductEvaluate4Class')
  @post
  removeCustomConductEvaluate4Class() { }
  // 班级成绩认定-导入
  @url('/v3/custom/yizhuang/importOrgScoreDetail')
  @post
  importOrgScoreDetail() { }

  // 行为规范-得分统计（班级-项目维度）(移动端使用)
  @url('/v3/custom/evaluate/pageTeaToViewClassEntryConduct')
  @post
  pageTeaToViewClassEntryConduct() { }
  // 行为规范-班级得分分页-关联学生与班级
  @url('/v3/custom/evaluate/pageSummaryEvaluateDetailsCombine')
  @postJson
  pageSummaryEvaluateDetailsCombine() { }
}

export default new Behavior()
