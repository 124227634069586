import { get, post, postJson, url } from '../../service/ApiWebDecorator'

class PageComs {
  // 添加组件标识
  @url('/v3/component/addCompMark')
  @post
  addCompMark() { }

  // 校验组件标识是否存在
  @url('/v3/component/checkCompMarkName')
  @post
  checkCompMarkName() { }

  // 首页获取组件标识列表
  @url('/v3/component/getCompMarkListFromIndex')
  @post
  getCompMarkListFromIndex() { }

  // 新建获取组件标识列表
  @url('/v3/component/getCompMarkListFromAddComponent')
  @post
  getCompMarkListFromAddComponent() { }

  // 编辑获取组件标识列表
  @url('/v3/component/getCompMarkListFromEditComponent')
  @post
  getCompMarkListFromEditComponent() { }

  // 获取组件标识列表
  @url('/v3/component/getCompMarkList')
  @post
  getCompMarkList() { }

  // 获取组件标识详情
  @url('/v3/component/getCompMarkDetail')
  @post
  getCompMarkDetail() { }

  // 修改组件标识
  @url('/v3/component/upCompMark')
  @post
  upCompMark() { }

  // 删除组件标识
  @url('/v3/component/delCompMark')
  @post
  delCompMark() { }

  // 添加页面组件
  @url('/v3/component/addCompPage')
  @post
  addCompPage() { }

  // 删除单条页面组件
  @url('/v3/component/delCompPage')
  @post
  delCompPage() { }

  // 获取页面组件列表
  @url('/v3/component/getCompPageList')
  @post
  getCompPageList() { }

  // 获取页面组件详情
  @url('/v3/component/getCompPageDetail')
  @post
  getCompPageDetail() { }

  // 获取内置页面组件，有分页
  @url('/v3/component/getCompPageBuiltinPage')
  @post
  getCompPageBuiltinPage() { }

  // 获取页面组件内部来源列表
  @url('/v3/component/getCompPageInnerList')
  @post
  getCompPageInnerList() { }

  // 修改页面组件
  @url('/v3/component/upCompPage')
  @post
  upCompPage() { }

  // 编辑的页面源码-预览或保存源码
  @url('/v3/component/saveCompEdit')
  @post
  saveCompEdit() { }

  // 获取页面组件需要定制的数据结构
  @url('/v3/component/getFormValueResp')
  @post
  getFormValueResp() { }

  // 获取归属人聚合结果
  @url('/v3/aggreation/getAggreationDataUserResult')
  @postJson
  getAggreationDataUserResult() { }

  // 新获取归属人聚合结果
  @url('/v3/aggreationNew/getAggreationDataUserResultNew')
  @postJson
  getAggreationDataUserResultNew() { }

  // 获取数据应用列表
  @url('/v3/aggreation/getBaseDetailResult')
  @postJson
  getBaseDetailResult() { }

  // 新获取数据应用列表
  @url('/v3/aggreationNew/getBaseDetailResultNew')
  @postJson
  getBaseDetailResultNew() { }

  // 获取明细结果
  @url('/v3/aggreation/getDetailResult')
  @postJson
  getDetailResult() { }

  // 获取明细结果
  @url('/v3/aggreationNew/getDetailResultNew')
  @postJson
  getDetailResultNew() { }

  // 总成绩
  @url('/v3/aggreation/getAllMaxAvgScore')
  @postJson
  getAllMaxAvgScore() { }

  // 新总成绩
  @url('/v3/aggreationNew/getAllMaxAvgScoreNew')
  @postJson
  getAllMaxAvgScoreNew() { }

  // 详情数据
  @url('/v3/aggreation/getAggreationResultByOtherFormItemValue')
  @postJson
  getAggreationResultByOtherFormItemValue() { }

  // 详情数据
  @url('/v3/aggreationNew/getAggreationResultByOtherFormItemValueNew')
  @postJson
  getAggreationResultByOtherFormItemValueNew() { }

  @url('/v3/aggreation/getDetailMatchResult')
  @postJson
  getDetailMatchResult() { }

  @url('/v3/aggreationNew/getDetailMatchResultNew')
  @postJson
  getDetailMatchResultNew() { }

  @url('/v3/aggreation/getAggreationDataResult')
  @postJson
  getAggreationDataResult() { }

  @url('/v3/aggreationNew/getAggreationDataResultNew')
  @postJson
  getAggreationDataResultNew() { }

  // 考试名称级联数据
  @url('/v3/aggreation/getEsAggreationDataTree')
  @get
  getEsAggreationDataTree() { }

  // 生成 考试名称级联数据
  @url('/v3/aggreation/getAggreationDataTree')
  @get
  getAggreationDataTree() { }

  // 素养护照，获取页面组件的绑定的内置组件
  @url('/v3/component/getChildComponent')
  @post
  getChildComponent() { }
}

export default new PageComs()
