/**
 * Created by lgzhang08@gmail.com 20180706
 * text 处理类
 */
import DeviceUtil from './DeviceUtil'
import WxAuth from '../service/auth/WxAuth'

class DoText {
  /**
   * 获取字符串长度
   */
  getCharLength(str, strLength) {
    var inputLength = 0
    strLength = strLength || str.length
    // 给一个变量来记录长度
    for (var i = 0; i < strLength; i++) {
      var countCode = str.charCodeAt(i)
      // 返回指定位置的字符的Unicode编码
      // 判断是不是ASCII码,Unicode码前128个字符是ASCII码
      if (countCode >= 0 && countCode <= 128) {
        inputLength++
      } else {
        inputLength += 2
        // 如果是扩展码，则一次+2
      }
    }
    return inputLength
  }

  /**
   * 获取字符串的字节长度
   * @param {*} val
   * @returns
   */
  getLength(val) {
    let str = val.toString()
    let bytesCount = 0
    for (let i = 0, n = str.length; i < n; i++) {
        let c = str.charCodeAt(i)
        if (c < 255) {
            bytesCount++
        } else {
            bytesCount += 2
        }
    }
    return bytesCount
  }

  // 处理富文本
  doHtml(text) {
    if (!text) return text
    let thumbType = '!/fw/800/format/webp'
    // ios webp打不开，需要兼容一下
    if (DeviceUtil.getDeviceType().isiOS) {
      thumbType = '!/fw/800'
    }
    // 图片标签匹配
    const imgReg = /<(img|IMG)(\s|[^>]+)([^>]+\>|\>)/gi // 匹配 img
    // debugger
    text = text.replace(imgReg, function(str) {
      // // Todo 暂时以src分割，添加click事件
      if (str.match('src=')) {
        const strArray = str.split('src=')
        // 提取src
        const src = strArray[1].split('"')[0]
        // onclick="globalPreviewImg(event, ${src})"
        str = strArray[0] + ` src="` + src + strArray[1].split('"')[1] + thumbType + '" />'
        // console.log('str:', str)
      }
      return str
    })

    return text
  }
  // html 代码显示处理
  escape2Html(str) {
      const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) { return arrEntities[t] })
  }
  // 选择文本。createTextRange(setSelectionRange)是input方法
  selectText(textbox, startIndex, stopIndex) {
    if (textbox.createTextRange) {
      // ie
      const range = textbox.createTextRange()
      range.collapse(true)
      range.moveStart('character', startIndex) // 起始光标
      range.moveEnd('character', stopIndex - startIndex) // 结束光标
      range.select() // 不兼容苹果
    } else {
      // firefox/chrome
      textbox.setSelectionRange(startIndex, stopIndex)
      textbox.focus()
    }
  }

  /**
   *
   * @param obj text pathname hashPath, query
   * @param wxSchema 微信认证链接
   * @return {Promise<void>}
   */
  async initCopyText(obj) {
    // 分享url处理
    let copyText = obj.text + await WxAuth.getWxShareUrl(obj.pathname || '/m/', obj.hashPath, { ...obj.query })
    setTimeout(() => {
      new window.ClipboardJS('.copyBtn').on('success', function(e) {
        console.log('复制成功！！！')
      }).on('error', function(e) {
        console.log('复制失败！！！')
      })
    }, 600)
    return copyText
  }

  /**
   * 只提取富文本中 文字 类型且并去掉样式
   * @param str
   */
  getPlainText(richCont) {
    let value = richCont
    if (richCont) {
      // 方法一：
      value = value.replace(/\s*/g, '') // 去掉空格
      value = value.replace(/<[^>]+>/g, '') // 去掉所有的html标记
      value = value.replace(/↵/g, '') // 去掉所有的↵符号
      value = value.replace(/[\r\n]/g, '') // 去掉回车换行
      value = value.replace(/&nbsp;/g, '') // 去掉空格
      value = this.convertIdeogramToNormalCharacter(value)
      return value
    } else {
      return null
    }
  }
  convertIdeogramToNormalCharacter(val) {
    const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' }
    return val.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) { return arrEntities[t] })
  }
  /**
   * hex转rgba #ff0000颜色转换rgba
   * @param str
   */
  hex2Rgba(bgColor, alpha = 1) {
    let color = bgColor.slice(1) // 去掉'#'号
    let rgba = [
      parseInt('0x' + color.slice(0, 2)),
      parseInt('0x' + color.slice(2, 4)),
      parseInt('0x' + color.slice(4, 6)),
      alpha
    ]
    return 'rgba(' + rgba.toString() + ')'
  }
  /**
   * 复制指定内容到剪切板
   * @param {*} txt 指定要复制内容
   */
  copyText(txt) {
    let input = document.createElement('input')
    input.value = txt
    document.body.appendChild(input)
    input.select()
    document.execCommand('Copy')
    window.CommonService.Alert({ message: '复制成功', type: 'success' })
    document.body.removeChild(input)
  }
}
// 导出单例，方便直接使用
export default new DoText()
