import axios from 'axios'
import Storage from './Storage'
// import RefreshToken from './RefreshToken'
// 利用axios的cancelToken来取消请求
const CancelToken = axios.CancelToken
class CacheAxios {
  constructor() {
  }
  // 缓存预处理
  preCache(config) {
    // token刷新处理，先放在这里
    // RefreshToken.getToken(config)
    // 接口数据缓存
    if (config && this.canCacheCtrl(config)) {
      let source = CancelToken.source()
      config.cancelToken = source.token
      // 去缓存池获取缓存数据
      let data = Storage.getItem('fac_' + config.url)
      if (Storage.hasKey('fac_' + config.url)) {
        source.cancel(data)
      }
    }
    return config
  }
  // 缓存控制
  canCacheCtrl(config) {
    // 目前只缓存 get请求
    if (config.method.toLocaleLowerCase() === 'get') {
      // 管理后台 SDP不缓存，本地和dev不缓存，方便调试
      if (/\/SDP/.test(location.pathname) || /\/admin/.test(location.pathname) || /localhost:9528/.test(location.href) || /dev.lvya.org/.test(location.href)) {
        // 缓存部分接口
        return false
      }
      // debugCache 调试 开启缓存
      if (/debugCache/.test(location.href)) return true
      // 数据应用 页面组件 相关接口
      if (/getCompEditByIdAndType|getCommonMsgByDataAppId|getOrgListByType|listOrgByType/gi.test(config.url)) {
        return true
      }
      // 应用工厂预览页面不缓存
      if (/\/no-code\/app-preview/.test(location.href)) return false
      // 本地不缓存
      if (/localhost/.test(location.hostname)) return false
      // fac为应用工厂标识
      if (/\/fac\//gi.test(config.url) || /\/facapp\//gi.test(config.url)) return true
      // region 区域化
      if (/\/region\//gi.test(config.url)) return true
      // sycommon sdk接口
      // if (/\/sycommon\//gi.test(config.url)) return true
      // nodejs sdk接口
      if (/\/node-proxy\//gi.test(config.url)) return true
      // 获取学校信息
      if (/\/getschool\//gi.test(config.url)) return true
    }
    // post请求谨慎处理
    if (config.method.toLocaleLowerCase() === 'post') {
      // 获取学校信息
      if (/auth\/eos\/my\/getschool/gi.test(config.url) || /auth\/eos\/getschool\/byDomain/gi.test(config.url)) {
        return true
      }
    }
    return false
  }
  // 缓存设置处理
  setCache(config, data) {
    if (config && this.canCacheCtrl(config)) {
      // 取所有参数值作为变量名
      Storage.setItem({
        name: 'fac_' + config.url,
        value: data
      })
    }
    return config
  }
}
//  导出单例，方便直接使用
export default new CacheAxios()
