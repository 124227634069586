import { post, url, get, postJson } from '../../service/ApiWebDecorator'

// 任务自定自己流程
class TaskDataMonitor {
  // 组件主查询--数据列表
  @url('/v3/exposed/taskMonitor/pageTaskMonitorResult')
  @postJson
  pageTaskMonitorResult() { }
  // 数据应用--数据监控主查询
  @url('/v3/dataapp/monitor/pageMonitorResultV2')
  @postJson
  pageMonitorResult() { }
  // @url('/v3/dataapp/monitor/pageMonitorResult')
  // @postJson
  // pageMonitorResult() { }
  // 数据应用--数据监控主查询
  @url('/v3/dataapp/monitor/pageAreaMonitorResultV2')
  @postJson
  pageAreaMonitorResult() { }
  // @url('/v3/dataapp/monitor/pageAreaMonitorResult')
  // @postJson
  // pageAreaMonitorResult() { }
  // 数据应用--数据监控设置项
  @url('/v3/dataapp/monitor/getMonitorCondition')
  @post
  getMonitorCondition() { }

  // 维度类型查询
  @url('/v3/exposed/taskMonitor/orgBeanType')
  @post
  orgBeanType() { }
  // 根据学段查询学校
  @url('/v3/eos/sys/school/type/listSchoolBySysSchoolType')
  @post
  listSchoolBySysSchoolType() { }
  // 查询组织下的组织
  @url('/v3/org/listOrgTreeByPid')
  @post
  listOrgTreeByPid() { }

  // 页面组件-监控详情
  @url('/v3/exposed/taskMonitor/pageTaskMonitorDetails')
  @postJson
  pageTaskMonitorDetails() { }
  // 数据应用-数据监控详情
  @url('/v3/dataapp/monitor/pageMonitorDetailsV2')
  @postJson
  pageMonitorDetails() { }
  // @url('/v3/dataapp/monitor/pageMonitorDetails')
  // @postJson
  // pageMonitorDetails() { }

  // 主查询导出
  @url('/v3/exposed/taskMonitor/exportTaskMonitorResult')
  @postJson
  exportTaskMonitorResult() { }

  // 详情询导出
  @url('/v3/exposed/taskMonitor/exportTaskMonitorDetails')
  @postJson
  exportTaskMonitorDetails() { }

  // 根据任务获取数据监控默认选项
  @url('/v3/dataapp/getDataAppConditionMonitorSet')
  @get
  getDataAppConditionMonitorSet() { }
}

export default new TaskDataMonitor()
