import { get, url, post } from '../../service/ApiDecorator'

// 教师晋级档案
class Report {
  // 等级信息
  @url('/develop/jobTitle/tea/getTeaJobLevelInfo')
  @post
  getTeaJobLevelInfo() {}

  // 等级对应任务
  @url('/develop/jobTitle/tea/listJobLevelTask')
  @post
  listJobLevelTask() {}

  // 转换base64
  @url('/:prdfix/base/getBase64Logo')
  @get
  getBase64Logo() {}
}

export default new Report()
