import { get, postJson, url } from '../../service/ApiWebDecorator'

// 数据转换
class AppSqlRule {
  // 新增数据转换规则
  @url('/v3/fac/appsqlrule/addSqlRule')
  @postJson
  addSqlRule() {}
  // 修改数据转换规则
  @url('/v3/fac/appsqlrule/updateSqlRule')
  @postJson
  updateSqlRule() {}
  // 通过交互ID，获取所有交互规则
  @url('/v3/fac/appsqlrule/listSqlRuleComposeVoByActiveId')
  @get
  listSqlRuleComposeVoByActiveId() {}
  // 生成变量更新子查询需要的sql片段集合
  @url('/v3/fac/blockly/getUpdateVarChildSqlReq')
  @postJson
  getUpdateVarChildSqlReq() {}
  // 执行自定义sql
  @url('/v3/customsql/executeCustomSql')
  @postJson
  executeCustomSql() {}
  // 自定义sql列表
  @url('/v3/customsql/page')
  @get
  customSqlList() {}
  // 自定义sql保存配置
  @url('/v3/customsql/add')
  @postJson
  addcustomSql() {}
  // 自定义sql保存配置 根据id修改
  @url('/v3/customsql/updateById')
  @postJson
  updateById() {}
  // 自定义sql获取配置
  @url('/v3/customsql/getById')
  @get
  getById() {}
  //  自定义sql根据id删除
  @url('/v3/customsql/deleteByIds')
  @get
  deleteByIds() {}
}

export default new AppSqlRule()
