// 转换
import Vue from 'vue'

// 参与情况
Vue.filter('joinText', function(teaDoType, task) {
  // 老师负责人任务
  console.log('task:', task)
  if (task.teaDoType == 0 || task.teaDoType == 1) {
    if (task.cycle == 2) return '当周统计:'
    else if (task.cycle == 3) return '当月统计:'
    else return '参与情况:'
  }
  return '参与情况:'
})

// 参与时间
Vue.filter('timeText', function(teaDoType) {
  // 老师负责人任务
  if (teaDoType == 0 || teaDoType == 1) return '时间限制:'
  return '参与时间:'
})

