/**
 * Created by lgzhang08@gmail.com
 * 选人插件
 */
import ApiAdmin from '../api/admin/index'
import DoArray from '../utils/DoArray'
class DoSelect {
  constructor() {
    this.orgAllList = []
    this.orgAllNameList = []
  }

  async getOrgList() {
    let orgList = []
    if (!VueInstance.$route.query.openSchool) {
      orgList = await ApiAdmin.OrgAdmin.getOrgListRecursive()
    } else { // 开通素养学校单独处理
      let params = {
        querySchoolId: VueInstance.$route.query.schoolId
      }
      orgList = await ApiAdmin.openSchool.getOrgListRecursiveBySchoolId(params)
    }
    this.orgAllList = orgList
    // 修改顶级id为-100，前后端约定全部为-100
    if (this.orgAllList && this.orgAllList[0] && this.orgAllList[0].id == 0) this.orgAllList[0].id = -100
  }

  /**
   * 选人插件 选中父级  父级全选 去除子级id
   */
  async doTreeData(data, orgTmpList) {
    if (!this.orgAllList || this.orgAllList.length == 0) await this.getOrgList()
    let orgAllList = _.cloneDeep(this.orgAllList)
    if (orgTmpList) orgAllList = orgTmpList
    let orgShowList = JSON.parse(JSON.stringify(data))
    data.forEach(org => {
      // 非组织不用处理
      let inAll = false
      let node = DoArray.getRecursiveNode(orgAllList, 'id', org.id)
      // 计数，如果node的children个数都被选择，则子级在显示列表中移除
      let count = 0
      node && node.children && node.children.forEach(child => {
        data.forEach(orgCopy => {
          if (child.id == orgCopy.id) count++
        })
      })
      if (count > 0 && node.children.length == count) inAll = true
      if (inAll) {
        node.children.forEach(child => {
          DoArray.deleteByPropsId(orgShowList, 'id', child.id)
        })
      }
    })
    return orgShowList
  }
  /**
   * 添加name
   */
  addNameProps(list, treeName) {
    for (let i = 0; i < list.length; i++) {
      if (!treeName) list[i].treeName = list[i].label
      else list[i].treeName = treeName + '/' + list[i].label
      if (list[i].children && list[i].children.length > 0) {
        this.addNameProps(list[i].children, list[i].treeName)
      }
    }
  }
  /**
   * 组织递归名字处理
   */
  async doTreeNameData(orgId) {
    if (!this.orgAllNameList || this.orgAllNameList.length == 0) {
      this.orgAllNameList = await ApiAdmin.OrgAdmin.getOrgListRecursive()
      // 不需要顶级
      this.orgAllNameList = this.orgAllNameList && this.orgAllNameList[0] && this.orgAllNameList[0].children
      this.addNameProps(this.orgAllNameList)
    }
    return DoArray.getRecursiveNode(this.orgAllNameList, 'id', orgId)
  }

  /**
   * 组织id筛选 选中父级  父级全选 去除子级id
   */
  async doTreeDataById(data) {
    if (!this.orgAllList || this.orgAllList.length == 0) await this.getOrgList()
    let orgShowList = JSON.parse(JSON.stringify(data))
    data.forEach(orgId => {
      let inAll = false
      let node = DoArray.getRecursiveNode(this.orgAllList, 'id', orgId)
      // 计数，如果node的children个数都被选择，则子级在显示列表中移除
      let count = 0
      node.children && node.children.forEach(child => {
        data.forEach(orgCopyId => {
          if (child.id == orgCopyId) count++
        })
      })
      if (count > 0 && node.children.length == count) inAll = true
      if (inAll) {
        node.children.forEach(child => {
          DoArray.deleteByValue(orgShowList, child.id)
        })
      }
    })
    orgShowList.forEach((orgId, index) => {
      let node = DoArray.getRecursiveNode(this.orgAllList, 'id', orgId)
      orgShowList[index] = node
    })
    return orgShowList
  }

  // 删除组织
  chooseDelOrgFun(item, index, orgShowList, orgList) {
    if (!orgList) orgList = this.orgAllList
    if (!orgList || orgList?.length == 0) return false
    orgShowList.splice(index, 1)
    // 删除组织 还需要递归删除 当前组织下的所有子级
    let node = {}
    node = DoArray.getRecursiveNode(orgList, 'id', item.id, 'children', node)
    let keys = DoArray.getRecursiveKeys([node], 'id', [])
    if (node) {
      let tmpOrg = []
      // 保留没有删除的
      orgList.forEach((org) => {
        let findIndex = -1
        keys.forEach((key) => {
          if (key == org.id) findIndex = 0
        })
        if (findIndex == -1) tmpOrg.push(org)
      })
      orgList = tmpOrg
    }
    return orgList
  }
}

// 导出单例，方便直接使用
export default new DoSelect()
window.DoSelect = new DoSelect()
