/**
 * Created by lgzhang08@gmail.com 20180706
 * url相关的url处理类
 */

import AdminApi from '../api/eos/admin'
import BusTMS from '../coms-web/layout/TMS-frame/eventBus.js'
import DemoAppLogin from '../const/DemoAppLogin'
class DoUrl {
  /**
   * 解析window.location.serch
   */
  getSearchByName(name = '', url = '') {
    let reg = new RegExp('[?|&]' + name + '=([^&]*)(&|$)', 'i')
    let curUrl = url || window.location.href
    let r = curUrl.match(reg)
    if (r != null) return (r[1].split('#')[0])
    // SDP带上appCode
    // TODO SDP带上appCode
    if (/SDP/.test(location.href) && name == 'appCode' && sessionStorage.appCode) {
      return sessionStorage.appCode
    }
    return null
  }

  /**
   * 解析window.location.serch，从#hash前截取
   */
  getSearchByNameNoHash(name = '', url = '') {
    let reg = new RegExp('[?|&]' + name + '=([^&]*)(&|$)', 'i')
    let curUrl = url || window.location.href
    curUrl = curUrl.split('#')[0]
    let r = curUrl.match(reg)
    if (r != null) return (r[1].split('#')[0])
    return null
  }

  /**
   * 解析window.location.serch，从#hash后截取
   */
  getSearchByNameHash(name = '', url = '') {
    let reg = new RegExp('[?|&]' + name + '=([^&]*)(&|$)', 'i')
    let curUrl = url || window.location.href
    curUrl = curUrl.split('#')[1]
    if (!curUrl) return null
    let r = curUrl.match(reg)
    if (r != null) return (r[1].split('#')[0])
    return null
  }

  /*
   * json转成url格式
   * 中文encode
   */
  jsonUrlFormat(data = {}) {
    const param = function(obj) {
      let query = ''
      let name, value, fullSubName, subName, subValue, innerObj, i
      for (name in obj) {
        value = obj[name]
        if (value instanceof Array) {
          for (i = 0; i < value.length; ++i) {
            subValue = value[i]
            fullSubName = name + '[]'
            innerObj = {}
            innerObj[fullSubName] = subValue
            query += param(innerObj) + '&'
          }
        } else if (value instanceof Object) {
          for (subName in value) {
            subValue = value[subName]
            fullSubName = name + '[' + subName + ']'
            innerObj = {}
            innerObj[fullSubName] = subValue
            query += param(innerObj) + '&'
          }
        } else if (value !== undefined && value !== null) {
          query += name + '=' + value + '&'
          // query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&'
        }
      }
      return query.length ? query.substr(0, query.length - 1) : query
    }
    // 递归将json转成url
    return data.toString() === '[object Object]' ? param(data) : data
  }
  /**
   * 页面参数处理
   */
  doUrlPath(path, query) {
    query = this.jsonUrlFormat(query)
    if (query) path += '?' + query
    return location.origin + location.pathname + '#' + path
  }

  /**
   * 新页面打开
   * @param path
   * @param query
   * @param isSelf 是否当前页面
   * @param pathname 路径名，默认当前路径
   * @param origin 域名+端口
   */
  openNew(path, query = {}, isSelf, pathname = location.pathname, origin = location.origin) {
    query.noCache = (new Date()).getTime()
    query = this.jsonUrlFormat(query)
    if (query) path += '?' + query
    path = origin + pathname + '#' + path
    console.log('openNew:', path)
    // TODO 应用工厂 iframe暂时在父级打开
    if (/no-code/.test(location.href) && window.self != window.top && isSelf) {
      window.parent.location.href = path
      window.parent.location.reload()
    }
    if (!isSelf) window.open(path, '_blank')
    if (isSelf) {
      window.location.href = path
    }
  }

  /**
   * 新页面打开带路径参数
   * @param path
   * @param query
   * @param isSelf 是否当前页面
   * @param pathname 路径名，默认当前路径
   * @param origin 域名+端口
   */
  openNewWithSearch(path, query, isSelf, pathname = location.pathname, origin = location.origin) {
    query.noCache = (new Date()).getTime()
    query = this.jsonUrlFormat(query)
    if (query) path += '?' + query
    path = origin + pathname + location.search + '#' + path
    console.log('openNew:', path)
    if (!isSelf) window.open(path, '_blank')
    // 替换当前页面
    if (isSelf == 'replace') {
      window.location.replace(path)
    } else if (isSelf) {
      // const oldPath = window.location.href
      window.location.href = path
      // // 应用工厂内部页面，跳转到相同路由，强制刷新
      // if (this.getSearchByName('pageId', oldPath) && this.getSearchByName('pageId', oldPath) === this.getSearchByName('pageId', path)) {
      //   location.reload()
      // }
    }
  }
  /**
   * 信任码处理
   */
  async doEosUrl(url) {
    if (/http/.test(url) == false) url = location.origin + url
    const resData = await AdminApi.getTrustCode()
    let seperate = '?'
    if (url.indexOf(('?')) > -1) seperate = '&'
    return url + seperate + 'trustCode=' + resData + '&domain=' + location.hostname
  }

  /**
   * 打开页面  window.open,方式打开导致 部分浏览器拦截问题处理
  */
  openUrl(url, appid = '', type = '_blank') {
    let hostUrl = url?.split('/eos-web/')[0]?.split('//')[1]
    let configDemoUrls = DemoAppLogin.fnGetDemoUrl()
    let uSearch = this.getSearchByName('dau', url)
    // 如果是当前域名且是 SDP admin项目，不需要重新认证直接跳转 || url.startsWith('/admin/'),跨校demo应用，不需要认证，直接跳转
    // dau:1674960351 2023-01-29 10:45:50时间戳值
    if (url.startsWith('/SDP/') || url.includes('/SDP/') || (configDemoUrls.includes(hostUrl) && uSearch == '1674960351')) {
      window.open(url, type)
      return
    }
    const token = Cookies.get('EOSToken')
    const newUrl = encodeURIComponent(url)
    let iframeUrl = `/center/redirectUrl?token=${token}&url=${newUrl}`
    if (appid) iframeUrl = `${iframeUrl}&appid=${appid}`
    window.open(iframeUrl, type)
  }
  /**
     * 打开页面  window.open,方式打开导致 部分浏览器拦截问题处理
    */
  getTrustCodeUrl(url) {
    const token = Cookies.get('EOSToken')
    const newUrl = encodeURIComponent(url)
    const iframeUrl = `/center/redirectUrl?token=${token}&url=${newUrl}`
    return iframeUrl
  }
  /**
   * url replace
   */
  replaceUrl(self, newQuery, path) {
    // 增加时间戳
    let timeStr = { noCache: (new Date()).getTime() }
    // TODO 数据应用dataSee先不加随机时间，页面会重复跳转，需要优化enter事件
    if (/dataSee/.test(location.href)) timeStr = {}
    const query = { ...self.$route.query, ...newQuery, ...timeStr }
    // todo trustCode会导致页面刷新
    if (/dataSee/.test(location.href)) delete query['trustCode']
    self.$router.replace({ path: path || self.$route.path, query })
  }
  /**
   * url push
   */
  pushUrl(self, newQuery, path) {
    // noCache添加随机时间戳
    const query = {
      ...self.$route.query,
      ...newQuery,
      ...{
        noCache: (new Date()).getTime()
      }
    }
    self.$router.push({ path: path || self.$route.path, query })
  }
  /**
   * 拦截不能操作的接口
   */
  forbiddenApi(apiUrl) {
    let ctrl = false
    // 非预览页 不处理
    if (/phonePreview/.test(location.href) == false) return false
    let url = ['app/stu/v1/addStuTaskFormList', 'app/stu/v1/addStuTeaTaskFormList']
    url.forEach((item) => {
      if (apiUrl.indexOf(item) > -1) ctrl = true
    })
    if (ctrl) VueInstance && window.vant.Toast.clear()
    return ctrl
  }
  /**
   * qiankun微应用加载
   * url 需要跳转的子应用url
   * microApp，主应用，默认micro-container
   */
  pushMicroUrl(self, url, isReplace, microApp = '/micro-container') {
    // TODO 发展平台微前端处理
    if (/SDP/.test(location.href)) microApp = '/SDP'
    // 主项目用微应用加载，项目调试用iframe
    if (window.location.href.includes(microApp)) {
      url = microApp + url
      console.log('url:', url)
      if (isReplace) window.history.replaceState(null, url, url)
      else window.history.pushState(null, '', url)
    } else {
      // 本地调试，用本地端口，素养端口为9528
      if (window.location.href.includes('localhost')) {
        url = url.replace('/admin', '//localhost:9528')
        BusTMS.$emit('iframeShowUrl', url)
      } else {
        BusTMS.$emit('iframeShowUrl', url)
      }
    }
  }
  /**
   * eos跳转素养应用统一处理
   * @param {*} self
   * @param {*} app 应用信息
   */
  getSYappUrl(self, app) {
    let dealUrl = app.url
    // if (dealUrl.includes('/admin/index.html') && dealUrl.includes('?') && app.type == 1) dealUrl = dealUrl.replace('?', '&')
    // if (app.type == '1') dealUrl = dealUrl.replace('/admin/index.html', `/admin/index.html?appId=${app.id}`)
    if (app.commonapptype == 2) {
      let u = app.url.split('#')[0]
      if (u.includes('?')) {
        dealUrl = dealUrl.replace('#/', `&appId=${app.id}#/`)
      } else {
        if (dealUrl.includes('/admin/index.html')) dealUrl = dealUrl.replace('/admin/index.html', `/admin/index.html?appId=${app.id}`)
        else if (dealUrl.includes('/admin/')) dealUrl = dealUrl.replace('/admin/', `/admin/?appId=${app.id}`)
      }
    }
    if (app.opentype == 1 || app.opentype == 3) {
      // window.open(dealUrl)
      let type = app.opentype == 3 ? '_self' : '_blank'
      const token = Cookies.get('EOSToken')
      const newUrl = encodeURIComponent(dealUrl)
      const iframeUrl = `/center/redirectUrl?token=${token}&url=${newUrl}`
      window.open(iframeUrl, type)
    } else {
      // 在iframe中打开
      self.$router.push({ path: `/app/${app.id}` })
    }
  }
  /**
   * 应用跨校认证跳转链接转换
   * @param {*} url
   */
  getToOtherSchoolUrl(url) {
    // 精准教学 101.200.213.227 jzjx.lvya.org这两个域名，跳转到当前域名认证
    var hostnameList = [window.TeachingOrigin, 'jzjx.lvya.org']
    var hostName = url.split('/')[0] + '//' + url.split('/')[1] + url.split('/')[2]
    return (hostnameList.indexOf(url.split('/')[2]) > -1 ? location.origin : hostName) + '/admin/#/jump-school?' + 'token=' + Cookies.get('EOSToken') + '&domain=' + location.hostname + '&url=' + encodeURIComponent(url)
  }
  /**
   * 打开精准教学页面，统一添加trustCode
   * @param isReplace 是否替换当前页
   */
  async openJZJXPage(path, query = {}, isReplace = false) {
    // 区分测试和正式域名
    const hostList = [window.TeachingHost, 'jzjx.lvya.org']
    let hostName = hostList[1]
    if (location.origin.includes('localhost') || location.origin.includes('-test')) hostName = hostList[0]
    // 统一添加trustCode
    // 性能优化，加载认证过一次不用再认证，teachAuthCtrl
    if (!sessionStorage.teachAuthCtrl) {
      query.trustCode = await AdminApi.getTrustCode()
      sessionStorage.teachAuthCtrl = 1
    }
    // 来源标识
    query.source = 'SDP'
    query.otherBtn = 0 // 跳转学生码页面判断是否有同步学生码的按钮权限
    if (sessionStorage.SDPRoleTree?.length) {
      let OtherSetObj = JSON.parse(sessionStorage.SDPRoleTree)?.find(row => { return row.code == 'OtherSet' })
      if (OtherSetObj?.children?.filter(item => { return item.code == 'OtherSetStuCodeSync' })?.length) query.otherBtn = 1
    }
    const seperate = path.indexOf('?') > -1 ? '&' : '?'
    let url = location.protocol + '//' + hostName + path + seperate + this.jsonUrlFormat(query)
    console.log('open page:', url)
    if (isReplace) {
      window.location.href = url
    } else {
      // window.open(url, '_blank') safari 浏览器会阻止js打开新页面（跨域） 详情： https://blog.csdn.net/qq_34574204/article/details/121821538
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
  /**
   * 应用跨学校认证跳转
   * @param {*} self
   * @param {*} app
   */
  toOtherSchool(self, app) {
    let newUrl = this.getToOtherSchoolUrl(app.url)
    if (app.opentype == 1 || app.opentype == 3) {
      let type = app.opentype == 3 ? '_self' : '_blank'
      window.open(newUrl, type)
    } else {
      // 在iframe中打开
      self.$router.push({ path: `/app/${app.id}` })
    }
  }
  /**
   * 微前端micro-app 新页面打开方式
   * @param path
   * @param query
   * @param isSelf 是否当前页面
   * @param pathname 路径名，默认当前路径
   * @param origin 域名+端口
   */
  openMicroPage(params = { microName: '', path: '', query: '' }) {
    params.query.schoolId = Cookies.get('EOSSchoolId')
    let query = params.query ? this.jsonUrlFormat(params.query) : {}
    let path = params.path + '?' + query
    path = origin + '/' + params.microName + '/' + location.search + '#' + path
    console.log('openNew:', path)
    if (!params.isSelf) window.open(path, '_blank')
    else {
      window.location.href = path
    }
  }
  /**
   * 打开社团页面
   * @param urlObj
   */
  openClubPage(urlObj, row) {
    // 模拟数据，根据domain获取
    let domain = /localhost/.test(location.href) ? window.GateWayServer.replace('http://', '').replace(/\//gi, '') : location.hostname
    if (urlObj?.[domain]) {
      // window.CommonService.Alert('应用工厂关联页面未配置，请配置后再打开')
      let url = urlObj?.[domain]?.clubUrl + `&clubId=${row.eosAssociationId}&clubManagerId=${row.userid}`
      // clubManagerId 社长用户id
      if (location.href.indexOf('micro-container') > -1) {
        // 微应用打开没有路径/
        url = url.replace('admin/', 'admin')
        this.pushMicroUrl(this, url)
        // TODO 应用工厂页面加载有问题，先用新页面打开
        // window.open(url, '_blank')
      } else {
        url = url.split('admin/')
        url = url[0] + 'admin/?microPath=micro-container' + url[1]
        VueInstance.$router.push({
          path: '/TMS/iframeView',
          query: {
            url: url
          }
        })
      }
      return
    }
    // TODO micro-container 微应用与独立封装系统跳转素养路由不一致,临时解决
    if (location.href.indexOf('micro-container') > -1) {
      this.pushMicroUrl(this, `/admin#/TMS/course-router?clubId=${row.eosAssociationId}`)
    } else {
      VueInstance.$router.push({
        path: '/TMS/iframeView',
        query: {
          url: `/admin/?microPath=micro-container'}#/TMS/course-router?clubId=${row.eosAssociationId}`
        }
      })
    }
  }
  /**
   * 获取当前域名
   * @param {*} type // origin   host
   */
  getLocationUrl(type = 'origin') {
    let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
    return type == 'host' ? origin.split('//')[1] : origin
  }
}

// 导出单例，方便直接使用
export default new DoUrl()
window.DoUrl = new DoUrl()
