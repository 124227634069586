import { postJson, post, url, get } from '../../service/ApiDecorator'
/**
 * 多元化课程所有接口
 * creater:sunchonggao
 * time:20210607
 */
class ApiTMSCourse {
  // 课程管理课程列表--查询
  @url('/v3/course/pageCourseMain')
  @get
  pageCourseMain() {}
  // 课程管理添加
  @url('/v3/course/addCourseMain')
  @postJson
  addCourseMain() {}
  // 课程管理详情
  @url('/v3/course/detailCourseMain')
  @get
  detailCourseMain() {}
  // 课程管理页删除
  @url('/v3/course/removeCourseMain')
  @get
  removeCourseMain() {}
  // 本学期课程列表--查询
  @url('/v3/course/page')
  @get
  getCourseList() {}
  // 学科管理--查询学科（列表）
  @url('/v3/subject/list')
  @post
  getSubjectList() {}
  // 学科管理--查询学科（分页）
  @url('/v3/subject/page')
  @post
  getSubjectPage() {}
  // 学科管理--添加学科
  @url('/v3/subject/add')
  @post
  addSubject() {}
  // 学科管理--删除学科
  @url('/v3/subject/remove')
  @get
  deleteSubject() {}
  // 课程类型--查询课程类型（列表）
  @url('/v3/courseType/list')
  @get
  getCourseTypeList() {}
  // 课程类型--查询课程类型（分页）
  @url('/v3/courseType/page')
  @get
  getCourseTypePage() {}
  // 课程类型--添加课程类型
  @url('/v3/courseType/add')
  @get
  addCourseType() {}
  // 课程类型--删除课程
  @url('/v3/courseType/remove')
  @get
  deleteCourseType() {}
  // 查询可与课程关联的场景
  @url('/v3/course/listScene')
  @get
  getListScene() {}
  // 保存课程
  @url('/v3/course/add')
  @postJson
  saveCourse() {}
  // 删除课程
  @url('/v3/course/remove')
  @post
  deleteCourse() {}
  // 获取课程详情
  @url('/v3/course/detail')
  @get
  getCourseDetail() {}
  // 普通实践课-教师-课程查询
  @url('/v3/course/tea/pageTeacherCourse')
  @get
  getPageTeacherCourse() {}
  // 普通实践课-教师-活动列表查询
  @url('/v3/course/act/practice/teacher/pageActivity')
  @get
  getPageActivity() {}
  // 普通实践课-教师-所有活动列表查询
  @url('/v3/course/act/practice/teacher/pageAllActivity')
  @get
  getPageAllActivity() {}
  // 普通实践课-教师-活动删除
  @url('/v3/course/act/practice/teacher/removeActivity')
  @post
  removeActivity() {}
  // 普通实践课-教师-活动详情
  @url('/v3/course/act/practice/teacher/detailActivity')
  @get
  getDetailActivity() {}
  // 普通实践课-教师-活动新增
  @url('/v3/course/act/practice/teacher/addActivity')
  @post
  addActivity() {}
  // 普通实践课--教师--获取活动课程
  @url('/v3/course/act/practice/teacher/pageTask')
  @post
  getPageTask() {}
  // 普通实践课--教师--获取对应数据应用ID
  @url('/v3/course/act/practice/teacher/getProcessDataAppId')
  @post
  getProcessDataAppId() {}
  // 普通实践课--学生--查询参与的活动
  @url('/v3/course/act/practice/student/pageJoinActivity')
  @get
  getPageJoinActivity() {}
  // 普通实践课--学生--查询可报名的活动
  @url('/v3/course/act/practice/student/pageCanJoinActivity')
  @get
  getPageCanJoinActivity() {}
  // 普通实践课--学生--报名活动
  @url('/v3/course/act/practice/student/signUp')
  @post
  signUp() {}
  // 普通实践课--学生--退出活动
  @url('/v3/course/act/practice/student/quitActivity')
  @get
  quitActivity() {}
  // 普通实践课--学生--认定结果
  @url('/v3/course/act/practice/student/getActivityScoreDetail')
  @get
  getActivityScoreDetail() {}
  // 普通实践课--教师--认定列表
  @url('/v3/course/act/practice/teacher/pageUserScore')
  @get
  getPageUserScore() {}
  // 普通实践课--教师--添加学生选人插件
  @url('/v3/course/act/practice/teacher/pageCanJoinUser')
  @post
  getPageCanJoinUser() {}
  // 普通实践课--教师--添加学生选人插件--组织列表
  @url('/v3/course/act/practice/teacher/listOrgTreeBean')
  @post
  getListOrgTreeBean() {}
  // 普通实践课--教师--评分详情
  // @url('/v3/course/act/practice/student/getActivityScoreDetail')
  // @post
  // getActivityScoreDetail() {}
  // 普通实践课--教师--添加评分
  @url('/v3/course/act/practice/teacher/addUserScore')
  @post
  addUserScore() {}
  // 普通实践课--教师--批量评分
  @url('/v3/course/act/practice/teacher/batchAddUserScore')
  @postJson
  batchAddUserScore() {}
  // 获取所有学期
  @url('/region/commom/listTermBeanByAreaId')
  @post
  listTermBeanByAreaId() {}
  // 普通实践课--教师--素养分分析
  @url('/v3/course/act/practice/teacher/getActivityChart')
  @post
  getActivityChart() {}

  // 学生管理学院新增
  @url('/v3/leader/add')
  @postJson
  leaderAdd() {}
  // 学生管理学院列表
  @url('/v3/leader/list')
  @get
  leaderList() {}
  // 学生管理学院删除
  @url('/v3/leader/del')
  @get
  leaderDel() {}
  // 学生管理学院更新
  @url('/v3/leader/update')
  @get
  leaderUpdate() {}
  // 学生管理学院，根据老师id获取所在年级
  @url('/v3/leader/getByTeaId')
  @get
  getByTeaId() {}
  // 查询可设置为本学期课程的主课程
  @url('/v3/course/pageCanSetCurrentMainCourse')
  @post
  pageCanSetCurrentMainCourse() {}
  // 添加主课程
  @url('/v3/course/addCurrentByCourseMain')
  @post
  addCurrentByCourseMain() {}
  // 选人插件所对应的组织
  @url('/v3/course/listCourseOrgTreeByType')
  @post
  listCourseOrgTreeByType() {}
  // 选人插件所对应的教师
  @url('/v3/course/pageTeacher')
  @postJson
  pageTeacher() {}
  // 移动端已参与的活动
  @url('/app/course/act/practice/pageJoinActivity')
  @post
  appPageJoinActivity() {}
  // 移动端可报名活动
  @url('/app/course/act/practice/pageCanJoinActivity')
  @post
  appPageCanJoinActivity() {}
}

export default new ApiTMSCourse()
