// 转换
import Vue from 'vue'
import DoTime from '../../utils/Time/DoTime'

window.showDate = function(value) {
	// 值更新时的工作
	// 也会以初始值为参数调用一次
	// 判断获取到的新值与原来的值是否一致，避免冗余加载
	if (!value) return
	function toDateTime(dateStr) {
		if (dateStr > 1) {
			return dateStr
		}
        return Date.parse(dateStr.replace(/-/gi, '/'))
    }
	let createDate = new Date(toDateTime(value))
	if (createDate == 'Invalid Date') return
	const currentDate = new Date()
	const createDateTimestamp = createDate.getTime()
	var nextDayDate = new Date(toDateTime(value)) // 更新时间第二天的日期
	nextDayDate.setDate(nextDayDate.getDate() + 1)
	var difftime1 = parseInt((new Date(toDateTime(DoTime.formatTime(nextDayDate, 'yyyy-MM-dd 00:00:00'))) - createDate) / 1000) // 更新时间跟第二天零点的时间差，单位：秒

	var difftime2 = parseInt((currentDate.getTime() - createDateTimestamp) / 1000) // 获得两个时间的时间差（秒），当前时间-最新更新时间

	value = toDateTime(value)

	if (difftime2 < difftime1) {
		createDate = '今天 ' + DoTime.formatTime(value, 'HH:mm')
	} else if (difftime2 < (difftime1 + 3600 * 24)) {
		createDate = '昨天 ' + DoTime.formatTime(value, 'HH:mm')
	} else if (difftime2 < (difftime1 + 3600 * 24 * 2)) {
		createDate = '前天' + DoTime.formatTime(value, 'HH:mm')
	} else if (createDate.getFullYear() == currentDate.getFullYear()) {
		createDate = DoTime.formatTime(value, 'MM月dd日 HH:mm')
	} else {
		createDate = DoTime.formatTime(value, 'yyyy年MM月dd日 HH:mm')
	}

	return createDate
}

window.showDateYear = function(value) {
  // 值更新时的工作
  // 也会以初始值为参数调用一次
  // 判断获取到的新值与原来的值是否一致，避免冗余加载
  if (!value) return
  function toDateTime(dateStr) {
    if (dateStr > 1) {
      return dateStr
    }
    return Date.parse(dateStr.replace(/-/gi, '/'))
  }
  let createDate = new Date(toDateTime(value))
  if (createDate == 'Invalid Date') return
  const currentDate = new Date()
  // const createDateTimestamp = createDate.getTime()
  var nextDayDate = new Date(toDateTime(value)) // 更新时间第二天的日期
  nextDayDate.setDate(nextDayDate.getDate() + 1)
  // var difftime1 = parseInt((new Date(toDateTime(DoTime.formatTime(nextDayDate, "yyyy-MM-dd 00:00:00"))) - createDate) / 1000); //更新时间跟第二天零点的时间差，单位：秒
  //
  // var difftime2 = parseInt((currentDate.getTime() - createDateTimestamp) / 1000); //获得两个时间的时间差（秒），当前时间-最新更新时间

  value = toDateTime(value)

  if (createDate.getFullYear() == currentDate.getFullYear()) {
    createDate = DoTime.formatTime(value, 'MM月dd日 HH:mm')
  } else {
    createDate = DoTime.formatTime(value, 'yyyy年MM月dd日 HH:mm')
  }

  return createDate
}

window.showMeetDate = function(value) {
	// 值更新时的工作
	// 也会以初始值为参数调用一次
	// 判断获取到的新值与原来的值是否一致，避免冗余加载
	if (!value) return
	function toDateTime(dateStr) {
		if (dateStr > 1) {
			return dateStr
		}
        return Date.parse(dateStr.replace(/-/gi, '/'))
    }
	let createDate = new Date(toDateTime(value))
	if (createDate == 'Invalid Date') return
	const currentDate = new Date()
	const createDateTimestamp = createDate.getTime()
	var nextDayDate = new Date(toDateTime(value)) // 更新时间第二天的日期
	nextDayDate.setDate(nextDayDate.getDate() + 1)

	var difftime3 = parseInt((createDateTimestamp - currentDate.getTime()) / 1000) // 获得两个时间的时间差（秒），当前时间-最新更新时间
	value = toDateTime(value)
	if (difftime3 > 0) { // 未来时间
		if (difftime3 > 86400) { // 大于一天
			createDate = parseInt((createDateTimestamp - currentDate.getTime()) / 1000 / 60 / 60 / 24) + '天后开始'
		} else if (difftime3 < 86400 && difftime3 > 3600) {
			createDate = parseInt((createDateTimestamp - currentDate.getTime()) / 1000 / 60 / 60) + '小时后开始'
		} else if (difftime3 < 3600 && difftime3 > 900) {
			createDate = parseInt((createDateTimestamp - currentDate.getTime()) / 1000 / 60) + '分钟后开始'
		} else if (difftime3 > 60 && difftime3 < 900) {
			createDate = '即将开始'
		} else if (difftime3 <= 60) {
			createDate = '马上开始'
		}
	} else {
		createDate = '马上开始'
	}

	return createDate
}

window.showDateMoment = function(value) {
	// 值更新时的工作
	// 也会以初始值为参数调用一次
	// 判断获取到的新值与原来的值是否一致，避免冗余加载
	if (!value) return
	function toDateTime(dateStr) {
        return Date.parse(dateStr.replace(/-/gi, '/'))
    }
	let createDate = new Date(toDateTime(value))
	if (createDate == 'Invalid Date') return
	const currentDate = new Date()
	const createDateTimestamp = createDate.getTime()
	var nextDayDate = new Date(toDateTime(value)) // 更新时间第二天的日期
	nextDayDate.setDate(nextDayDate.getDate() + 1)
	var difftime1 = parseInt((new Date(toDateTime(DoTime.formatTime(nextDayDate, 'yyyy-MM-dd 00:00:00'))) - createDate) / 1000) // 更新时间跟第二天零点的时间差，单位：秒
//	console.log("difftime1:" , difftime1)
	var difftime2 = parseInt((currentDate.getTime() - createDateTimestamp) / 1000) // 获得两个时间的时间差（秒），当前时间-最新更新时间

	value = toDateTime(value)
  // else if (difftime2 / 60 > 59 && difftime2 / 60 <= 60 * 12) {
  // 	createDate = parseInt(difftime2 / 60 / 60) + '小时前'
  // }
	if (difftime2 / 60 < 1) {
		createDate = '刚刚'
	} else if (difftime2 / 60 <= 9 && difftime2 / 60 >= 1) {
		createDate = parseInt(difftime2 / 60) + '分钟前'
	} else if (difftime2 < difftime1) {
    // '今天 ' +
    createDate = DoTime.formatTime(value, 'HH:mm')
	} else if (difftime2 < (difftime1 + 3600 * 24)) {
		createDate = '昨天 ' + DoTime.formatTime(value, 'HH:mm')
	} else if (difftime2 < (difftime1 + 3600 * 24 * 2)) {
		createDate = '前天' + DoTime.formatTime(value, 'HH:mm')
	} else if (createDate.getFullYear() == currentDate.getFullYear()) {
		createDate = DoTime.formatTime(value, 'MM月dd日')
	} else {
		createDate = DoTime.formatTime(value, 'yyyy年MM月dd日')
	}
	return createDate
}

/**
 * 返回传入日期和今天相隔天数
 * @param {String} dateStr
 */
let isWhichDay = dateStr => {
  if (dateStr.length > 10) {
    dateStr = dateStr.substring(0, 10)
  }
  dateStr = dateStr + ' 10:00:00'
  let historyDay = new Date(dateStr)
  let today = new Date()
  today.setMilliseconds(0)
  today.setSeconds(0)
  today.setMinutes(0)
  today.setHours(10)
  return today.getTime() - historyDay.getTime()
}
/**
 * 根据传入日期  返回对应显示效果
 * @param {*} time
 * 规则如下：
 * 1 分钟以内的， 刚刚
 * 1 小时以内， ** 分钟前
 * 超过1小时， 仍在当天： hh: mm（ 时： 分） 显示具体的提交时的时分
 * 跨天， 但少于24小时： 昨天
 * 跨天， 但少于48小时： 前天
 * 跨天， 超过48小时： yyyy - MM - dd（ 年： 月： 日）
 */
window.compTime = time => {
  const
    s = 1000
  const m = 60 * s
  const h = 60 * m
  const d = 24 * h
  const diffTime = Date.now() - new Date(time)
  let comM = diffTime / m
  let comH = diffTime / h
  let result = comM < 1 ? '刚刚'
    : comM > 1 && comM < 60 ? Math.floor(comM) + '分钟前'
      : isWhichDay(time) / d == 0 && comH > 1 ? time.substring(10)
        : isWhichDay(time) / d == 1 ? '昨天'
          : isWhichDay(time) / d == 2 ? '前天' : time.substring(0, 10)
  return result
}

/**
 * 根据传入日期  倒计时显示
 * @param {*} time
 * 规则如下：
 * 未开启 超过一天的显示 2019-10-02，其余显示 14:05
 *
 * 进行中的如下：
 * 超过一天，显示剩余：##天，向上取整，（待统一：显示具体日期：2019-10-02）
 * 不足一天，显示剩余：##小时
 * 不足一小时，显示剩余：##分钟
 * 不足一分钟，显示剩余：##秒
 */
window.doEndTime = (task) => {
  if (task.jointimetype == '0') {
    return '不限制'
  }
  if (task.jointimetype == '1') {
    return '手动'
  }
  if (task.jointimetype == '2') {
    return task.joinstarttime + '~' + task.joinendtime
  }
  const s = 1000
  const m = 60 * s
  const h = 60 * m
  const d = 24 * h
  let result = ''
  const diffTime = new Date() - Date.now()
  let comM = diffTime / m
  // let comH = diffTime / h
  let comD = diffTime / d
  if (comM <= 1) {
    result = '剩余：' + Math.ceil(comM * 60) + '秒'
  } else if (comM >= 1 && comM <= 60) {
    result = '剩余：' + Math.ceil(comM) + '分钟'
  } else if (comD <= 1) {
    result = '剩余：' + Math.ceil(comD * 24) + '小时'
  } else if (comD > 1) {
    result = '剩余：' + Math.ceil(comD) + '天'
  } else { return result }
}
// 过去多少天
window.showPastDays = function(value) {
  // 值更新时的工作
  // 也会以初始值为参数调用一次
  // 判断获取到的新值与原来的值是否一致，避免冗余加载
  if (!value) return
  function toDateTime(dateStr) {
    return Date.parse(dateStr.replace(/-/gi, '/'))
  }
  const createDate = new Date(toDateTime(DoTime.formatTime(value, 'yyyy-MM-dd 23:59:59')))
  if (createDate == 'Invalid Date') return
  const currentDate = new Date()
  const createDateTimestamp = createDate.getTime()
  var difftime2 = parseInt((currentDate.getTime() - createDateTimestamp) / 1000) // 获得两个时间的时间差（秒），当前时间-最新更新时间
  var count = Math.ceil(difftime2 / 3600 / 24)
  return count
}

// 时间转换
Vue.filter('showDate', function(value) {
	return window.showDate(value)
})

// 时间转换，几分钟前，xx小时前
Vue.filter('showDateMoment', function(value) {
	return window.showDateMoment(value)
})
Vue.filter('DateFormatMoment', function(value) {
	return DoTime.dateFormat('星期w hh:mm:ss', value)
})
// 时间转换,未来时间
Vue.filter('showFutureDate', function(value) {
	return window.showMeetDate(value)
})

// 时间转换，几分钟前，xx小时前  当前规则
Vue.filter('showComDateMoment', function(value) {
  return window.compTime(value)
})

// 显示 完整年
Vue.filter('showDateYear', function(value) {
  return window.showDateYear(value)
})

// 过去多少天
Vue.filter('showPastDays', function(value) {
  return window.showPastDays(value)
})

// 时间去除秒
Vue.filter('filterTime', function(value) {
  return value ? value.substring(0, 16) : ''
})
