import { get, post, url, postJson } from '../../service/ApiWebDecorator'

// 场景 管理后台
class SceneAdmin {
  // 编辑保存 场景
  @url('/v3/saveScene')
  @post
  saveScene() { }
  // 克隆 场景
  @url('/v3/copyTask')
  @post
  copyTask() { }
  // 获取过程
  @url('/v3/getItem')
  @post
  getSceneItem() { }
  // 编辑过程
  @url('/v3/saveSceneItem')
  @post
  saveSceneItem() { }
  // 删除过程
  @url('/v3/deleteSceneItem')
  @post
  deleteSceneItem() { }
  // 发布场景
  @url('/v3/scene/shelve')
  @post
  sceneShelve() { }
  // 显示 场景
  @url('/v3/showScene')
  @post
  showScene() { }
  // 隐藏 场景
  @url('/v3/hideScene')
  @post
  hideScene() { }

  // 获取所有场景类型
  @url('/v3/getSceneTypeList')
  @get
  getSceneTypeList() { }

  // 获取验证码
  @url('/v3/sendCode')
  @post
  sendCode() { }
  // 验证验证码
  @url('/v3/checkCode')
  @post
  checkCode() { }

  // 导入导出 获取过程任务列表
  @url('/v3/exportSceneInfoHx')
  @post
  getExportList() { }

  // 导入导出保存功能
  @url('/v3/exportSceneInfoByRequire')
  @post
  saveExporting() { }

  // 场景大厅共享
  @url('/v3/sceneHall/saveSceneRefType')
  @post
  saveSceneRefType() { }

  // 获取共享状态
  @url('/v3/sceneHall/getSceneHallStatus')
  @post
  getSceneHallStatus() { }

  // 获取共享场景列表
  @url('/v3/sceneHall/getSceneHall')
  @post
  getSceneHall() { }

  // 场景详情说明
  @url('/v3/sceneHall/getSceneHallExplain')
  @post
  getSceneHallExplain() { }

  // 立即使用
  @url('/v3/sceneHall/immediateUse')
  @post
  immediateUse() { }
  // 克隆场景
  @url('/v3/copyScene')
  @post
  copyScene() { }

  // 查询数据应用列表
  @url('/v3/dataapp/getReportDataAppList')
  @post
  getReportDataAppList() { }

  // 获取报表数据来源
  @url('/v3/dataapp/getReportList')
  @post
  getReportList() { }

  // 删除应用数据
  @url('/v3/dataapp/delReportDataApp')
  @post
  delReportDataApp() { }

  // 新建场景
  @url('/v3/getSceneEditWhenBuild')
  @post
  getSceneEditWhenBuild() { }
  // 场景编辑--获取场景信息  /v3/getSceneEdit
  @url('/v3/getSceneEdit')
  @post
  getSceneEdit() { }

  // 获取字典数据
  @url('/v3/getFilterConditions')
  @post
  getFilterConditions() { }

  // 获取报表字段
  @url('/v3/dataapp/getFormItemInfoListByReportId')
  @post
  getFormItemInfoListByReportId() { }

  // 修改数据应用状态
  @url('/v3/dataapp/updateReportDataAppState')
  @post
  updateReportDataAppState() { }
  // 修改数据应用状态(开)
  @url('/v3/dataapp/updateReportDataAppStateOn')
  @post
  updateReportDataAppStateOn() { }
  // 修改数据应用状态(关)
  @url('/v3/dataapp/updateReportDataAppStateOff')
  @post
  updateReportDataAppStateOff() { }

  // 查询应用详细信息
  @url('/v3/dataapp/getReportDataAppDetail')
  @post
  getReportDataAppDetail() { }

  // 新建数据应用
  @url('/v3/dataapp/addReportDataApp')
  @postJson
  addReportDataApp() { }

  // 编辑保存
  @url('/v3/dataapp/updateReportDataApp')
  @postJson
  updateReportDataApp() { }

  // 克隆
  @url('/v3/dataapp/cloneDataAppInfo')
  @post
  cloneDataAppInfo() { }

  // 分享至EOS 获取套件信息/v3/eos/saveEosData
  @url('/v3/eos/getAssembly')
  @post
  getAssembly() { }

  // 分享至EOS
  @url('/v3/eos/saveEosData')
  @post
  saveEosData() { }
  // 场景
  @url('/v3/itemData/saveEosDataScene')
  @post
  saveEosDataScene() { }
  // 过程
  @url('/v3/itemData/saveEosDataSceneItem')
  @post
  saveEosDataSceneItem() { }
  // 任务
  @url('/v3/itemData/saveEosDataTask')
  @post
  saveEosDataTask() { }
  // 应用
  @url('/v3/dataapp/saveEosData')
  @post
  saveEosDataDataapp() { }
  // 场景建模
  @url('/v3/scene/saveEosData')
  @post
  saveEosDataSceneModel() { }
  // 字段引用搜索
  @url('/v3/searchByName')
  @post
  cascaderSearchByName() { }

  // 导出得分
  @url('/v3/stu/exportStuScoreDetail')
  @post
  exportStuScoreDetail() { }

  // 获取报表字段
  @url('/v3/dataapp/getFormItemListWithTypeByReportId')
  @post
  getFormItemListWithTypeByReportId() { }

  // 修改数据应用状态(开)
  @url('/region/app/pushDataAPP')
  @post
  pushDataAPP() { }

  // 场景列表分类
  @url('/v3/sceneClassify/list')
  @post
  list() { }

  // 场景分类删除
  @url('/v3/sceneClassify/delete')
  @post
  delete() { }

  // 场景分类删除
  @url('/v3/sceneClassify/add')
  @post
  add() { }

  // 场景分类删除
  @url('/v3/sceneClassify/update')
  @post
  update() { }

  // 场景类型列表
  @url('/v3/getSceneListByOrgOrClassify')
  @post
  getSceneListByOrgOrClassify() { }

  // eos职位列表
  @url('/v3/duty/listDuty')
  @post
  listDuty() { }

  // eos角色列表
  @url('/v3/sysRole/listRole')
  @post
  listRole() { }

  // 更新任务属性
  @url('/v3/updateById')
  @post
  updateById() { }

  // 场景任务获取高扫图片
  @url('/v3/getTaskProcessScanTemplateUrl')
  @post
  getTaskProcessScanTemplateUrl() { }

  // 保存印制版式
  @url('/v3/updateTaskScanModel')
  @post
  updateTaskScanModel() { }

  // 获取过程数据表头顺序（同步任务时调用）
  @url('/v3/itemData/getDataAppDefaultTaskField')
  @post
  getDataAppDefaultTaskField() { }
  // 获取过程数据表头顺序（同步任务时调用）
  @url('/v3/itemData/getDataAppDefaultTaskFieldByDataAppId')
  @post
  getDataAppDefaultTaskFieldByDataAppId() { }
}

export default new SceneAdmin()
