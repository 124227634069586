import { get, post, postJson, url } from '../../service/ApiWebDecorator'

class TeahcerGrowth {
  // 批量添加/更新推荐老师，id列表，逗号分割
  @url('/v3/topTea/saveAll')
  @post
  saveTeacher() {}
  // 批量添加/更新轮播图，对象(url表示图像链接，link_url表示点击图像以后会跳转到的链接)数组转为string传过来，有id则表示更新（listBanner会取到已经添加的轮播图，里面必有id），没有则表示添加
  @url('/v3/image/saveAllBanner')
  @post
  saveBanner() {}
  // 同时保存推荐教师和banner
  @url('/v3/top/saveAllRecommendTeaAndBanner')
  @post
  saveOption() {}
  // 返回所有推荐教师id
  @url('/v3/top/listRecommendTea')
  @post
  getTeacherList() {}
  // 根据推荐教师id获取教师信息
  @url('/app/tea/common/select/getTeaListByIds')
  @post
  getStuListByIds() {}
  // 返回所有播图
  @url('/v3/top/listBanner')
  @post
  getBannerList() {}
  // 获取组织和组织下老师人数
  @url('/v3/org/getOrgTeaCount')
  @post
  getOrgTeaCount() {}
  // 设置页初始化
  @url('/v3/top/listRecommendTeaAndBanner')
  @post
  initOption() {}
  // 查询教师列表
  @url('/v3/choiceUser/getTeaPageInTeacherGrowth')
  @postJson
  getTeaPageInTeacherGrowth() {}
  // 查询教师列表2
  @url('/v3/passport/getTeaPageInTeacherGrowthAdmin')
  @post
  getTeaPageInTeacherGrowthAdmin() {}
  // 自定义查询教师
  @url('/v3/top/getAllQueryTeacherFilter')
  @post
  getAllQueryTeacherFilter() {}
  // 保存版面设计
  @url('/v3/passport/savePassport')
  @post
  savePassport() {}
  // 获取版面设计老师
  @url('/v3/teaDevelop/getPassport')
  @post
  getPassport() {}
  // 获取版面设计学生
  @url('/v3/passport/getPassport')
  @post
  getPassportPAS() {}
  // 获取版面设计学生
  @url('/v3/passport/getPassportByTermAndUser')
  @post
  getPassportByTermAndUser() {}
  // 获取展示单元的数据
  @url('/v3/passport/getPassportRevealUnitEnhanc')
  @post
  getPassportRevealUnitEnhanc() {}
  // 获取展示单元列表的数据
  @url('/v3/passport/getDataByCondition')
  @post
  getDataByCondition() {}
  // 获取教师发展筛选条件数据
  @url('/v3/tea/getAvailableTeacherProperty')
  @post
  getAvailableTeacherProperty() {}
  //  获取eos教师动态字段
  @url('/sys/api/common/fileid/queryUserFieldAll')
  @get
  getEosFieldInfo() {}
  // 获取eos教师基础字段
  @url('/sys/api/common/fileid/queryBaseUserField')
  @get
  getEosBaseInfo() {}
  // 素养获取eos用户的筛选字段
  @url('/sys/api/user/dataApp/page/sy/byId')
  @get
  getEosAllInfoById() {}
  // 获取展示单元图表
  @url('/v3/passport/getPassportRevealUnitEnhance')
  @postJson
  getPassportRevealUnitEnhance() {}
  // 根据id获取用户详情
  @url('/v3/choiceUser/getById')
  @post
  getById() {}
  // 检测展示单元是否被占用
  @url('/v3/passport/canRevealUnitDelete')
  @post
  canRevealUnitDelete() {}
  // 获取首页设置教师列表
  @url('/v3/top/getRangeInfo')
  @post
  getRangeInfo() {}
  // 获取教师列表组织
  @url('/v3/top/listOrgsFilterTeaListByTop')
  @post
  listOrgsFilterTeaListByTop() {}
  // 教师发展数据统计单校基础信息
  @url('/common/eos/getReport')
  @post
  getReport() {}
  // 教师发展数据统计区域基础信息
  @url('/common/eos/area/getReport')
  @post
  getAreaReport() {}
  // 教师发展列表页获取所有院校
  @url('/region/commom/listAreaSchoolList')
  @post
  listAreaSchoolList() {}
  // 教师发展数据统计发展统计
  @url('/v3/top/professionStatisticsList')
  @post
  professionStatisticsList() {}
  // 导出excel
  @url('/v3/passport/searchStudentExport')
  @postJson
  searchStudentExport() {}
  // 教师发展组织查询
  @url('/v3/org/teacher/orgList')
  @postJson
  getOrgList() {}
  // 教师发展专业发展导出
  @url('/v3/top/professionStatisticsExport')
  @post
  professionStatisticsExport() {}
  // 教师发展基础信息导出
  @url('/v3/top/professionBasicExport')
  @postJson
  professionBasicExport() {}
  // 获取所有教师id
  @url('/v3/choiceUser/listTeaTeacherDevelopmentSelectAll')
  @postJson
  getAllTeaIds() {}
  // eos获取字典列表(code查询)
  @url('/sys/api/user/getDictionaryListByCode')
  @get
  getDictionaryListByCode() {}
  // eos获取字典列表(id查询)
  @url('/sys/api/user/getDictionaryList')
  @get
  getDictionaryListById() {}
  // 获取用户的班级 type =5行政班
  @url('/v3/choiceUser/getUserOrgByUserId')
  @post
  getUserOrgByUserId() {}
  // 学期小结-获取课程任务字段级联
  @url('/develop/passport/listCourseTaskItems')
  @get
  listCourseTaskItems() {}

  // 学期小结-获取场景任务字段级联
  @url('/v3/getSceneInfos')
  @get
  getSceneInfos() {}

  // 获取学期小结数据
  @url('/develop/passport/listStudentIntent')
  @postJson
  listStudentIntent() {}
  // 获取学期小结证书数据
  @url('/develop/passport/listCertificates')
  @postJson
  listCertificates() {}
}

export default new TeahcerGrowth()
