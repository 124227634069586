/**
 * Created by lgzhang08@gmail.com 20200302
 * 认证获取token
 */

import LoginApi from '../../api/eos/Login'
import AdminApi from '../../api/eos/admin'
import DoUrl from '../../utils/DoUrl'
import DeviceUtil from '../../utils/DeviceUtil'

class LoginToken {
  // 免登录
  async loginPwd(name, type, pwd) {
    let domain = location.hostname
    // 本地domain用服务器nginx地址
    if (/localhost/.test(location.href) || /192.168.4.56/.test(location.href)) domain = window.GateWayServer.split('//')[1].replace('/', '')
    // domain临时修改 DomainServer
    if (DoUrl.getSearchByName('DomainServer')) domain = DoUrl.getSearchByName('DomainServer')
    // 预发环境域名-pre去掉当正式处理
    domain = domain.replace('-pre', '')
    const schoolInfo = await LoginApi.getSchool({ domain: domain })
    Cookies.set('EOSSchoolId', schoolInfo.id)
    Cookies.set('eosDomain', schoolInfo.domain)
    localStorage.setItem('areaSchoolInfo', JSON.stringify(schoolInfo))
    // 缓存学生或者老师
    Cookies.set('roleType', type)
    if (type == 'tea') type = 0
    if (type == 'stu') type = 1
    if (type == 'p') type = 2
    const params = {
      name: name,
      pwd: window.hex_md5(pwd || 'lvya8888'),
      type: type || 0
    }
    const code = await LoginApi.loginPwd(params)
    await this.getToken(code)
  }
  async getToken(code) {
    const params = {
      authorizationCode: code
    }
    const loginInfo = await LoginApi.getToken(params)
    Cookies.set('EOSToken', loginInfo.token)
    // 缓存用户
    if (loginInfo.user) {
      localStorage.userInfo = JSON.stringify(loginInfo.user)
      VueInstance && VueInstance.$store.commit('userInfo', loginInfo.user)
    }
    return true
  }

  // 验证码登录
  async loginPhone(name, type, code) {
    let domain = location.hostname
    if (/localhost/.test(location.href)) domain = window.GateWayServer.split('//')[1].replace('/', '')
    const schoolInfo = await LoginApi.getSchool({ domain: domain })
    Cookies.set('EOSSchoolId', schoolInfo.id)
    // 缓存学生或者老师
    Cookies.set('roleType', type)
    if (type == 'tea') type = 0
    if (type == 'stu') type = 1
    if (type == 'p') type = 2
    const params = {
      phone: name,
      code: code,
      type: type
    }
    const userInfoList = await LoginApi.loginPhone(params)
    // let userInfoList = []
    // 只登录当前角色
    let authCode = ''
    userInfoList && userInfoList.forEach((user) => {
      if (user.type == type) authCode = user.authorizationCode
    })
    if (authCode) return await this.getToken(authCode)
    else alert('当前角色用户信息用空')
  }
  // lvya等认证链接
  async doTrustUrl(url) {
    const trustCode = await AdminApi.getTrustCode()
    const seperate = url.indexOf('?') > -1 ? '&' : '?'
    url += seperate + 'trustCode=' + trustCode + '&domain=' + location.hostname
    console.log('jump url:', url)
    return url
  }

  /**
   * 处理三方用户
   * @param user
   * @return {Promise<void>}
   */
  async getAuthThirdUser(user) {
    const params = {
      name: user.name,
      pwd: window.hex_md5('lvya8888'),
      type: user.type
    }
    const code = await LoginApi.loginPwd(params)
    return (await LoginApi.getToken({ authorizationCode: code })).token
  }
  /**
   * 访客用户链接处理
   * @param link 重定向链接
   * @param forceVisitor 强制访客访问
   * @return {Promise<void>}
   */
  doVistorLink(link, forceVisitor) {
    // 路径处理
    let url = location.origin + location.pathname
    // 如果是微信等第三方回调用户信息，需要尝试获取用户，获取不到再以访客身份访问, isFirstAuth为认证过的标识，只认证一次，防止重复认证
    if (DeviceUtil.isAuthCallBack() && !Cookies.get('EOSToken') && !sessionStorage.isFirstAuth && !forceVisitor) {
      // window.location.href = location.pathname
      location.replace(location.pathname)
      return true
    }
    // hash url
    let hashRouter = link.split('#')[1]
    // 增加三方用户 thirdToken
    let userInfo = localStorage.vistorUserInfo ? JSON.parse(localStorage.vistorUserInfo) : {}
    url += '?thirdToken=' + sessionStorage.vistorToken + '&thirdUserId=' + userInfo.id + '#' + hashRouter
    localStorage.userInfo = localStorage.vistorUserInfo
    // 用完删除
    // delete sessionStorage.vistorToken
    // delete localStorage.vistorUserInfo
    // 重定向
    location.replace(url)
    return true
  }
  async loginByTrustCode(link, code) {
    // 路径处理
    let url = location.origin + location.pathname
    let hashRouter = link.split('#')[1]
    url += '#' + hashRouter
    await this.getToken(code)
    location.replace(url)
  }
}

// 导出单例，方便直接使用
export default new LoginToken()
