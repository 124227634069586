export default [
    {
        path: '/header',
        component: () => import('@/views/jr/components/header.vue')
    },
    {
        path: '/footer',
        component: () => import('@/views/jr/components/footer.vue')
    },
    {
        path: '/index-home',
        component: () => import('@/views/jr/index/index-home.vue')
    },
    {
        path: '/home',
        component: () => import('@/views/jr/index/all.vue')
    },
    // {
    //     path: '/subpageHeader',
    //     component: () => import('@/views/jr/subpage/components/header.vue')
    // },
    // {
    //     path: '/subpageFooter',
    //     component: () => import('@/views/jr/subpage/components/footer.vue')
    // },
    {
        path: '/subpageContent',
        component: () => import('@/views/jr/subpage/content.vue')
    },
    {
        path: '/subpage',
        component: () => import('@/views/jr/subpage/subpageAll.vue')
    },
    {
        path: '/projectCreation',
        component: () => import('@/views/jr/projectCreation.vue')
    },
    {
        path: '/map',
        component: () => import('@/views/jr/map.vue')
    },
    {
        path: '/details',
        component: () => import('@/views/jr/details.vue')
    },
    {
        path: '/list',
        component: () => import('@/views/jr/list.vue')
    },
    {
        path: '/indexList',
        component: () => import('@/views/jr/indexList.vue')
    },
    // {
    //     path: '/indexDetails',
    //     component: () => import('@/views/jr/indexDetails/indexDetails.vue')
    // },
    {
        path: '/indexDetails2',
        component: () => import('@/views/jr/indexDetails.vue')
    },
    {
        path: '/search',
        component: () => import('@/views/jr/search.vue')
    },
    {
        path: '/searchDetails',
        component: () => import('@/views/jr/searchDetails.vue')
    }
]
