import { post, url } from '../../service/ApiWebDecorator'

// 新版可视化
class NewVisualization {
  // 获取图表分组
  @url('/v3/chart/getChartGroupList')
  @post
  getChartGroupList() {}

  // 获得图表集合
  @url('/v3/visual/page/getVisualMaterialPage')
  @post
  getChartInfoPage() {}

  // 保存页面
  @url('/v3/visual/page/addVisualPageInfo')
  @post
  addVisualPageInfo() {}
  // 编辑保存页面
  @url('/v3/visual/page/updateVisualPageInfo')
  @post
  updateVisualPageInfo() {}

  // 根据id获取页面信息
  @url('/v3/visual/page/getVisualPageInfo')
  @post
  getVisualPageInfo() {}

  // 获取页面列表
  @url('/v3/visual/page/getVisualPageInfoPage')
  @post
  getVisualPageInfoPage() {}

  // 另存为模板
  @url('/v3/visual/page/template/addVisualPageTemplate')
  @post
  addVisualPageTemplate() {}

  // 编辑模板
  @url('/v3/visual/page/template/getVisualPageTemplate')
  @post
  getVisualPageTemplate() {}

  // 获取模板列表
  @url('/v3/visual/page/template/getVisualPageTemplatePage')
  @post
  getVisualPageTemplatePage() {}

  // 推送驾驶舱
  @url('/v3/visual/eos/addEosCockpitInfo')
  @post
  addEosCockpitInfo() {}

  // 删除页面
  @url('/v3/visual/page/deleteVisualPageInfo')
  @post
  deleteVisualPageInfo() {}

  // 删除模板
  @url('/v3/visual/page/template/deleteVisualPageTemplate')
  @post
  deleteVisualPageTemplate() {}

  // 获取预览页图表数据
  @url('/v3/chart/summaryChart')
  @post
  summaryChart() {}

  // 点击图标获取内容
  @url('/v3/chart/summaryChartXAxis')
  @post
  summaryChartXAxis() {}
  // 点击图标获取内容 --无权限接口
  @url('/v3/chart/summaryChartXAxisNoAuth')
  @post
  summaryChartXAxisNoAuth() {}

  // 新建图表 ---获取报表和分组
  @url('/v3/chart/getChart')
  @post
  getChart() {}

  // 根据选择的报表获取字段
  @url('/v3/chart/getListReportFormItem')
  @post
  getListReportFormItem() {}

  // 根据字段获取选项值  枚举字段
  @url('/v3/getNewFormItem')
  @post
  getNewFormItem() {}

  // 保存图表分组
  @url('/v3/chart/saveChartGroup')
  @post
  saveChartGroup() {}

  // 保存图表
  @url('/v3/chart/updateChartV1')
  @post
  saveChartV1() {}

  // 获取页面组件详情
  @url('/v3/component/getCompPageDetailOnlyQuery')
  @post
  getCompPageDetailOnlyQuery() {}

  // 页面组件获取数据应用数据
  @url('/v3/dataapp/getDataAppResultForConditionNoAuth')
  @post
  getDataAppResultForConditionNoAuth() {}

  // 三河职教(页面组件晨午检)
  @url('/v3/api/query/getTempAnomalousCount')
  @post
  getTempAnomalousCount() {}

  // 同步数据
  @url('/v3/chart/syncChartResult')
  @post
  syncChartResult() {}

  // 结果集
  @url('/v3/chart/addWebResult')
  @post
  addWebResult() {}
  // 分组分享权限
  @url('/v3/chart/canShowGroupShareLink')
  @post
  canShowGroupShareLink() {}
  // 图表分享权限
  @url('/v3/chart/canShowChartShareLink')
  @post
  canShowChartShareLink() {}
  // listChart
  @url('/v3/chart/summaryGroupInfo')
  @post
  summaryGroupInfo() {}
  // viewGroup
  @url('/v3/chart/summaryGroup')
  @post
  summaryGroup() {}
  // 根据指标id和学生id获取指标(湖南汽车)
  @url('/v3/api/getStuTargetScpreInfoDetail')
  @post
  getTargetList() {}
  // 根据图表id获取图表信息
  @url('/v3/chart/listChartByIds')
  @post
  listChartByIds() {}

  // 可视化看板无权限
  @url('/v3/visual/page/noPermission/getVisualPageInfoPage')
  @post
  getVisualPageInfoPageNoPermission() {}
}

export default new NewVisualization()
