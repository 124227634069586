class DemoAppData {
  constructor() {
    // 不考虑区域学校。 红岭,珠海十六中,上海上汇,天津财经,北京十一学校,郑州金融,海淀二小,济源职业技术学院,松山湖未来学校
    this.demoAppUrl = ['szhl.lvya.org', 'zhsslz.lvya.org', 'shsyxx.lvya.org', 'tjufe.lvya.org', 'cloud.bjeaedu.com', 'zzsjrxx.lvya.org', 'ex01.lvya.org', 'jyzyjsxy.lvya.org', 'dgsshwlxx.lvya.org']
  }
  fnGetDemoUrl() {
    return this.demoAppUrl
  }
}

export default new DemoAppData()
