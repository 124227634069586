/**
 * Created by lgzhang08@gmail.com 20200109
 * 微信认证
 * 通过域名换取appid-->拼接微信appId回调地址-->获取微信code-->换取授权码-->获取token
 */

import Login from '../../api/eos/Login'
import DoUrl from '../../utils/DoUrl'
import LoginToken from './LoginToken'
class WxAuth {
    /**
     * 通过域名换取appid
     */
  async doWxAuth(url, authType) {
    if (/eos.tyzx.com.cn/.test(location.href) && /trustCode/.test(location.href)) {
      const code = DoUrl.getSearchByName('trustCode')
      let url = decodeURIComponent(DoUrl.getSearchByName('url'))
      localStorage.setItem('lastUrl', url)
      await this.getSchoolInfo()
      let res = await this.authCode(authType, code)
      if (res) window.location.replace(url)
    } else if (/code=/.test(location.href)) {
      let signleUser = await this.authCode(authType)
      if (signleUser) location.replace(localStorage.getItem('lastUrl'))
      return
    } else {
      let schoolInfo = await this.getSchoolInfo(true)
      // 账号密码登录
      if (schoolInfo.loginType == 2) {
        // TODO 素养链接直接打开, 应用工厂访客链接
        if (location.pathname.indexOf('/m') > -1) {
          // 访客token存在，优先处理
          if (localStorage.lastUrl && sessionStorage.vistorToken) {
            LoginToken.doVistorLink(localStorage.lastUrl, true)
            // localStorage.lastUrl = ''
            return
          }
        }
        // 区域学校
        if (schoolInfo.type == 1) {
          window.location.replace('/eos-app/#/areaLogin')
          // VueInstance.$router.replace({ path: '/areaLogin' })
          return
        }
        // 单校
        window.location.replace('/eos-app/#/singleLogin')
        // VueInstance.$router.replace({ path: '/singleLogin' })
        return
      }
      // 账号密码登录不用获取appId
      const appId = schoolInfo.type == 1 ? await Login.getAreaWxAppid({ areaId: schoolInfo.areaid }) : await Login.getWxAppid()
      localStorage.appId = appId
      await this.openWxRedirectUrl(appId, url)
    }
  }
  async getSchoolInfo(returnBack = false) {
    // TODO 取微信域名
    let domain = window.location.hostname
    if (/localhost/.test(location.href)) domain = window.GateWayServer.split('//')[1].replace('/', '')
    // 预发环境域名-pre去掉当正式处理
    domain = domain.replace('-pre', '')
    const schoolInfo = await Login.getSchool({ domain: domain })
    if (!schoolInfo) {
      alert('学校信息获取失败')
      return false
    }
    // pc 移动统一使用areaSchoolInfo缓存
    localStorage.areaSchoolInfo = JSON.stringify(schoolInfo)
    Cookies.set('EOSSchoolId', schoolInfo.id)
    if (returnBack) return schoolInfo
  }
  async authCode(authType, code = '') {
    await this.getUserInfo(code)
    // 认证过的标识，用于访客等权限判断
    sessionStorage.isFirstAuth = 1
    let userInfoList = localStorage.userInfoList && JSON.parse(localStorage.userInfoList)
    // 家长暂不显示
    // userInfoList = userInfoList.filter(item => item.type != 2)
    if (userInfoList && userInfoList.length > 1) { // 多用户到选择用户页面
      let schoolInfo = localStorage.areaSchoolInfo ? JSON.parse(localStorage.areaSchoolInfo) : ''
      let path = schoolInfo.type == 1 ? '/chooseRole' : '/user/select'
      let routerObj = {
        path: path,
      }
      if (schoolInfo.type != 1) {
        routerObj.query = {
          authType: authType || ''
        }
      }
        VueInstance.$router.replace(routerObj)
    } else if (userInfoList && userInfoList.length == 1) { // 第一个用户登录
        const tokenCtrl = await this.getToken(userInfoList[0])
        let type = userInfoList[0].type
        if (type == 0) type = 'tea'
        if (type == 1) type = 'stu'
        if (type == 2) type = 'p'
        Cookies.set('roleType', type)
        if (tokenCtrl) return true
    } else {
        // alert('用户获取失败')
    }
  }
    /**
     * 拼接微信appId回调地址
     */
    async openWxRedirectUrl(appId, url) {
        const wxUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='
        const wxUrl2 = '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        url = wxUrl + appId + '&redirect_uri=' + encodeURIComponent(url) + wxUrl2
        // console.log('url:', url)
        // window.location.href = url
        location.replace(url)
    }
    /**
     * 第三方平台授权公众号，获取授权code
     */
    async openWxRedirectUrlThird(appId, url, component_appid) {
      let _url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId +
                  '&redirect_uri=' + encodeURIComponent(url) +
                  '&response_type=code&scope=snsapi_base&state=STATE&component_appid=' + component_appid + '#wechat_redirect'
      // console.log('_url:', _url)
      location.replace(_url)
    }

    /**
     * 换取授权码
     */
    async getUserInfo(code) {
      delete localStorage.userInfoList
      const params = {
        code: code || DoUrl.getSearchByName('code'),
        appid: localStorage.appId || ''
      }
      let userInfoList
      let schoolInfo = localStorage.areaSchoolInfo ? JSON.parse(localStorage.areaSchoolInfo) : ''
      if (schoolInfo != '') {
        // 区域
        // areaLogin Y 代表是区域登陆 N 为单校登陆
        // loginType 1 企业号登陆 2 密码登陆
        if (schoolInfo.type == 1) {
          params.areaId = schoolInfo.areaid
          // 微信认证可能失败
          try {
            userInfoList = await Login.getAreaUserInfo(params)
          } catch (error) {
            console.log('areaUser error:', error)
          }
          if (typeof userInfoList == 'string' || !userInfoList) {
            localStorage.areaOpenid = userInfoList
            // TODO 素养链接直接打开, 应用工厂访客链接
            if (location.pathname.indexOf('/m') > -1) {
              // 访客token存在，优先处理
              if (localStorage.lastUrl && sessionStorage.vistorToken) {
                LoginToken.doVistorLink(localStorage.lastUrl, true)
                localStorage.lastUrl = ''
              }
            } else if (userInfoList) {
              window.location.href = '/eos-app/#/areaLogin'
              // VueInstance.$router.replace({ path: '/areaLogin' })
            }
          } else if (Array.isArray(userInfoList)) {
            localStorage.rolesLen = userInfoList.length
            localStorage.userInfoList = JSON.stringify(userInfoList)
            VueInstance.$store.commit('userSchoolList', userInfoList)
            return true
          }
        } else {
          userInfoList = await Login.getUserInfo(params)
          localStorage.rolesLen = userInfoList?.length
          // 老师 学生 家长 排序
          let userType = [0, 1, 2]
          let userList = []
          userType.forEach(type => {
            userInfoList && userInfoList.forEach(user => {
              if (user.type == type) userList.push(user)
            })
          })
          localStorage.userInfoList = JSON.stringify(userList)
        }
      }
    }

    /**
     * 获取token
     */
    async getToken(user) {
        const params = {
            authorizationCode: user.authorizationCode
        }
        const EOSToken = await Login.getToken(params)
        Cookies.set('EOSToken', EOSToken.token)
        localStorage.setItem('userInfo', JSON.stringify(EOSToken.user))
        // await Login.testjwt(params)
        if (EOSToken) return true
    }

    /**
     * 小程序打开链接生成
     */
    async generateScheme(path = '', query = {}) {
      // 本地调试 和测试环境用体验版,正式用正式版
      let envVersion = 'release'
      if (/localhost/.test(location.href) || /-test/.test(location.href)) envVersion = 'trial'
      const params = {
        expireType: 0,
        path: path,
        envVersion: envVersion,
        query: query,
      }
      return await Login.generatescheme(params)
    }

  /**
   * 小程序分享链接
   */
  async getWxShareUrl(pathname, hashPath, query = {}) {
    // 添加学校id
    if (!query.schoolId) query.schoolId = Cookies.get('EOSSchoolId')
    // 本地调试兼容
    let url = /localhost/.test(location.href) ? window.GateWayServer + pathname : location.origin + pathname
    url += hashPath + '?' + DoUrl.jsonUrlFormat(query)
    return await this.generateScheme('pages/webView/webView', 'shareUrl=' + encodeURIComponent(url))
  }
}

// 导出单例，方便直接使用
export default new WxAuth()
