import { post, url } from '../../service/ApiWebDecorator'

class AreaApp {
  // 区域化综合素质平台接口文档
  @url('/region/app/getIdByType')
  @post
  getIdByType() {}
}

export default new AreaApp()
