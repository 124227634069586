import { post, get, url, postJson } from '../../service/ApiDecorator'
class ApiEvaluateLesson {
  // 模板列表
  @url('/v3/lesson/evaluate/template/pageTemplate')
  @post
  pageTemplate() { }

  // 新建模板
  @url('/v3/lesson/evaluate/template/addTemplate')
  @postJson
  addTemplate() { }

  // 复制模板
  @url('/v3/lesson/evaluate/template/copyTemplate')
  @post
  copyTemplate() { }

  // 删除模板
  @url('/v3/lesson/evaluate/template/removeTemplate')
  @post
  removeTemplate() { }

  // 修改状态
  @url('/v3/lesson/evaluate/template/updateState')
  @post
  updateState() { }

  // 模板详情
  @url('/v3/lesson/evaluate/template/detailTemplate')
  @post
  detailTemplate() { }

  // 课程类型列表
  @url('/v3/lesson/evaluate/type/pageType')
  @post
  pageType() { }

  // 添加课程类型
  @url('/v3/lesson/evaluate/type/addType')
  @post
  addType() { }

  // 删除课程类型
  @url('/v3/lesson/evaluate/type/removeType')
  @post
  removeType() { }

  // 课程类型详情
  @url('/v3/lesson/evaluate/type/detailType')
  @post
  detailType() { }

  // 分析概览-听课量
  @url('/v3/lesson/evaluate/countAttendLesson')
  @post
  countAttendLesson() { }

  // 分析概览-今日新增
  @url('/v3/lesson/evaluate/countTodayLessonDetails')
  @post
  countTodayLessonDetails() { }

  // 分析概览-学科分布
  @url('/v3/lesson/evaluate/groupSubjectLesson')
  @post
  groupSubjectLesson() { }

  // 分析概览-教研组分布
  @url('/v3/lesson/evaluate/groupTeachingLesson')
  @post
  groupTeachingLesson() { }

  // 分析概览-教学能力分析
  @url('/v3/lesson/evaluate/groupAnalysisOfTeachingAbility')
  @post
  groupAnalysisOfTeachingAbility() { }

  // 听课活动-分析概览详情页
  @url('/v3/lesson/evaluate/pageAnalysisDetails')
  @post
  pageAnalysisDetails() { }

  // 听课活动-导出excel
  @url('/v3/lesson/evaluate/pageAnalysisDetails/exportExcel')
  @post
  pageAnalysisDetailsExportExcel() { }

  // 听课记录图片导出
  @url('/v3/lesson/evaluate/pageAnalysisDetails/exportPic')
  @post
  exportPic() { }

  // 听课活动-获取全部教研组
  @url('/v3/lesson/evaluate/listTeachingGroup')
  @post
  listTeachingGroup() { }

  // 教师分析-听课教师-主讲教师排名
  @url('/v3/lesson/evaluate/pageSortTeaAttendance')
  @post
  pageSortTeaAttendance() { }

  // 教师统计-查询页
  @url('/v3/lesson/evaluate/pageTeaDetails')
  @post
  pageTeaDetails() { }

  // 教师统计-导出
  @url('/v3/lesson/evaluate/exportTeaDetails')
  @post
  exportTeaDetails() { }

  // 教学能力分析-老师对比列表
  @url('/v3/lesson/evaluate/pageTeaTemplateLessonEvaluate')
  @post
  pageTeaTemplateLessonEvaluate() { }

  // 教学能力分析-课程对比列表
  @url('/v3/lesson/evaluate/pageTemplateLessonAnalysis')
  @post
  pageTemplateLessonAnalysis() { }

  // 教学能力分析--应用量
  @url('/v3/lesson/evaluate/countApplyTemplate')
  @post
  countApplyTemplate() { }

  // 教学能力分析-分页查询有数据的模板
  @url('/v3/lesson/evaluate/template/pageEvaluateTemplate')
  @post
  pageEvaluateTemplate() { }

  // 教学能力分析-课程或老师对比图形
  @url('/v3/lesson/evaluate/lessonOrTeaTemplateChart')
  @post
  lessonOrTeaTemplateChart() { }

  // 学校发展平台-教师发展-能力分析-老师听课记录
  @url('/v3/lesson/evaluate/pageTeaAnalysisDetails')
  @post
  pageTeaAnalysisDetails() { }

  // 听课活动-分析概览详情页-导出
  @url('/v3/lesson/evaluate/exportAnalysisDetails')
  @postJson
  exportAnalysisDetails() { }

  // 区域可视化-听课动态
  @url('/v3/lesson/evaluate/listAreaDataCount')
  @get
  listAreaDataCount() { }

  // 区域可视化-听课动态详情
  @url('/v3/lesson/evaluate/listAreaDataCountDetail')
  @get
  listAreaDataCountDetail() { }

  // 区域可视化-听课动态详情
  @url('/v3/lesson/evaluate/listAreaSchoolCount')
  @get
  listAreaSchoolCount() { }

  // 区域可视化-授课质量
  @url('/v3/lesson/evaluate/listAreaSchoolAvgScore')
  @get
  listAreaSchoolAvgScore() { }

  // 区域可视化-授课质量
  @url('/region/commom/listTermBeanBySchoolId')
  @post
  listTermBeanBySchoolId() { }
}
export default new ApiEvaluateLesson()
