/**
 * Created by lgzhang08@gmail.com 20180706
 * 小数转换
 */
class DecimalConvert {
  constructor() {

  }

  /**
   * 保留n位小数
   * 功能：将浮点数四舍五入，取小数点后n位
   */
  toDecimal(x, position = 1) {
    if (!x) return x
    let point = '10'
    for (let i = 1; i < position; i++) {
      point += '0'
    }
    point = parseInt(point)
    let f = parseFloat(x)
    if (isNaN(f)) {
      return
    }
    f = Math.round(x * point) / point
    return f
  }

  /**
   * 制保留2位小数，如：2，会在2后面补上00.即2.00
   */
  toDecimal2(x) {
    var f = parseFloat(x)
    if (isNaN(f)) {
      return false
    }
    f = Math.round(x * 100) / 100
    var s = f.toString()
    var rs = s.indexOf('.')
    if (rs < 0) {
      rs = s.length
      s += '.'
    }
    while (s.length <= rs + 2) {
      s += '0'
    }
    return s
  }
}

// 导出单例，方便直接使用
export default new DecimalConvert()
