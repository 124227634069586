import { post, url } from '../../service/ApiWebDecorator'

class Aggregation {
  // 新增数据聚合分组
  @url('/v3/newReport/addReportFormGroup')
  @post
  addReportFormGroup() {}
  // 编辑分组
  @url('/v3/newReport/updateReportFormGroup')
  @post
  updateReportFormGroup() {}
  // 分组列表
  @url('/v3/newReport/listReportFormGroup')
  @post
  listReportFormGroup() {}
  // 分组列表
  @url('/v3/newReport/getReportFormGroup')
  @post
  getReportFormGroup() {}
  // 分组分页
  @url('/v3/newReport/pageReportFormGroup')
  @post
  pageReportFormGroup() {}
  // 分组删除
  @url('/v3/newReport/deleteReportFormGroup')
  @post
  deleteReportFormGroup() {}
  // 数据聚合列表
  @url('/v3/newReport/pageReportForm')
  @post
  pageReportForm() {}
  // 获取场景列表
  @url('/v3/listAllScene')
  @post
  listAllScene() {}
  // 获取过程列表
  @url('/v3/newReport/ajaxItem')
  @post
  ajaxItem() {}
  // 获取任务列表
  @url('/v3/newReport/ajaxTask')
  @post
  ajaxTask() {}
  // 根据任务id获取字段值
  @url('/v3/newReport/listFormItem')
  @post
  listFormItem() {}
  // 新增
  @url('/v3/newReport/add')
  @post
  add() {}
  // 编辑详情
  @url('/v3/newReport/getReportFormDetail')
  @post
  getReportFormDetail() {}
  // 编辑保存
  @url('/v3/newReport/edit')
  @post
  edit() {}
  // 删除数据聚合前判断
  @url('/v3/newReport/checkUse')
  @post
  checkUse() {}
  // 删除数据聚合
  @url('/v3/newReport/del')
  @post
  del() {}
  // 发布数据聚合
  @url('/v3/newReport/publishReportForm')
  @post
  publishReportForm() {}
  // 审核通过数据聚合
  @url('/v3/newReport/passReportForm')
  @post
  passReportForm() {}
  // 审核驳回数据聚合
  @url('/v3/newReport/rejectReportForm')
  @post
  rejectReportForm() {}
  // 生成数据聚合预览数据
  @url('/v3/newReport/generatePreviewReportForm')
  @post
  generatePreviewReportForm() {}
  // 预览数据聚合
  @url('/v3/newReport/preview')
  @post
  preview() {}
  @url('/v3/newReport/pageReportEsData')
  @post
  pageReportEsData() {}
  // 表头设置聚合项列表
  @url('/v3/newReport/listReportFormItem')
  @post
  listReportFormItem() {}
  // 表头设置保存
  @url('/v3/newReport/updateReportFormItemList')
  @post
  updateReportFormItemList() {}
  // 进度条
  @url('/v3/newReport/getReportFormPercent')
  @post
  getReportFormPercent() {}
  // 标准化-获取数据列表
  @url('/region/regionStandard/listStandard')
  @post
  listStandard() {}
  // 标准化-获取表单项
  @url('/v3/newReport/listFormItemByFormIds')
  @post
  listFormItemByFormIds() {}
  // 导出
  @url('/v3/newReport/exportReportForm')
  @post
  exportReportForm() {}
}

export default new Aggregation()
