import { post, postJson, url } from '../../service/ApiWebDecorator'

// 数据中心
class ScoreDataCenter {
  // 个人数据中心  任务得分列表
  @url('/v3/scoreCount/userTaskCount')
  @postJson
  userTaskCount() { }
  // 个人数据中心  任务得分明细
  @url('/v3/scoreCount/baseScoreLog')
  @post
  baseScoreLog() { }
  // 组织数据中心 查询组织人员
  @url('/v3/scoreCount/orgsDataCount')
  @postJson
  orgsDataCount() { }
}

export default new ScoreDataCenter()
