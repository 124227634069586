// 图片
import Vue from 'vue'
 import ApiFile from '../../api/eos/File'
const CdnServer = '/'
// 普通模块图片路径处理
Vue.directive('src-relative', function(el, value) {
  const defaultImg = 'static/img/layout/default/common.png'

  if (!value.value) {
    el.src = defaultImg
    return false
  }

  el.src = value.value

  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    el.src = defaultImg
  }
})

// 普通模块图片路径处理
Vue.directive('src-logo', function(el, value) {
  let errorCtrl = false

  const defaultImg = 'static/img/logo/logo.jpg'

  if (!value.value) {
    el.src = defaultImg
    return false
  }

  el.src = value.value

  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    if (!errorCtrl) el.src = defaultImg
    errorCtrl = true
  }
})

// 头像图片路径处理
Vue.directive('src-avatar', function(el, value) {
  let errorCtrl = false
  const defaultImg = CdnServer + 'static/img/user/avatar-default.png'

  if (!value.value) {
    el.src = defaultImg
    return false
  }

  el.src = value.value

  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    if (!errorCtrl) el.src = defaultImg
    errorCtrl = true
  }
})

// 场景默认图片
Vue.directive('src-scene', function(el, value) {
  let errorCtrl = false
  const defaultImg = 'http://sy-oss.lvya.org/2019/09/16/upload_ycwpwuu886zifl9cci0cmbgquet4wlf0.png'
  if (!value.value) {
    el.src = defaultImg
    return false
  }
  el.src = value.value
  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    if (!errorCtrl) el.src = defaultImg
    errorCtrl = true
  }
})

// 头像图片路径处理-教师发展档案
Vue.directive('src-avatar-sex', function(el, binding) {
  let errorCtrl = false
  const sex = binding.value.sex
  const val = binding.value.url
  const defaultImg = sex == '1' ? 'static/icon/teaArchives/avatar-m.png' : 'static/icon/teaArchives/avatar-w.png'
  if (!val) {
    el.src = defaultImg
    return false
  }

  el.src = val

  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    if (!errorCtrl) el.src = defaultImg
    errorCtrl = true
  }
})

// 微信图片显示，不存在时回滚本地localId
Vue.directive('src-wx', function(el, value) {
  console.log('src-wx:', value.value)

  const defaultImg = 'static/img/layout/no-img.png'

  if (!value.value) {
    el.src = defaultImg
    return false
  }

  el.src = value.value

  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    el.src = sessionStorage.getItem(value.value) || defaultImg
  }
})

// 视频缩略图处理
Vue.directive('src-poster', {
  inserted: async function(el, value) {
    let defaultImg = '/static/img/form/video-default.jpg'
    if (!value.value) {
      return false
    }
    let videoSrc = value.value
    videoSrc = videoSrc.replace('http://', 'https://')
    let poster = videoSrc.substring(0, videoSrc.length - 4) + '.jpg'
    let isExist = await ApiFile.isExist({ url: poster })
    try {
      if (isExist) {
        el.src = poster
      } else {
        let src = await ApiFile.videoSnapShot({ url: videoSrc, noToast: true })
        if (src) el.src = src
      }
    } catch (error) {
      el.src = defaultImg
    }
    // 在图片加载失败的时候执行onerror方法 给src赋值
    el.onerror = async function() {
      el.src = defaultImg
    }
  }
})

// eos应用默认图标处理
Vue.directive('src-app-img', function(el, value) {
  let errorCtrl = false
  const defaultImg = 'static/icon/eos/default_noData.png'

  if (!value.value) {
    el.src = defaultImg
    return false
  }

  el.src = value.value

  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    if (!errorCtrl) el.src = defaultImg
    errorCtrl = true
  }
})

// cdn图片
Vue.directive('src-cdn', function(el, value) {
  if (!value.value) {
    return ''
  }
  // http链接不做处理
  if (/http/.test(value.value)) {
    el.src = value.value
    return
  }
  // 去掉cdn路径开头的斜杠
  if (value.value && value.value[0] == '/') value.value = value.value.substr(1)
  el.src = CdnServer + value.value
})

// 文件后缀图标获取
Vue.directive('src-file-suffix', function(el, value) {
  const url = CdnServer + 'static/icon/form/'
  let _type = 'other'
  let _icons = {
    'excel': ['xlsx', 'xls'],
    'word': ['docx', 'doc'],
    'ppt': ['pptx', 'ppt'],
    'pdf': ['pdf'],
    'rar': ['zip', 'rar', 'tar', '7z'],
    'img': ['png', 'gif', 'jpg', 'jpeg', 'bmp'],
    'other': [],
  }
  let _suffix = value.value.match(/\.\w+$/)
  if (!_suffix) {
    el.src = url + 'ico_t_' + 'other' + '.png'
    return false
  }
  _suffix = _suffix[0].substr(1)
  for (const key in _icons) {
    if (_icons[key].indexOf(_suffix) != -1) {
      _type = key
    }
  }
  el.src = url + 'ico_t_' + _type + '.png'
})
// 菜单图片
Vue.directive('menu-icon', function(el, value) {
  let errorCtrl = false
  if (!value.value) {
    el.src = ''
    return false
  }
  el.src = '/static/icon/library/' + value.value + '.png'
  // 在图片加载失败的时候执行onerror方法 给src赋值
  el.onerror = function() {
    if (!errorCtrl) el.src = ''
    errorCtrl = true
  }
})
