import { get, post, url } from '../../service/ApiWebDecorator'

class ApiManage {
  // 获取api接口分页
  @url('/v3/api/getApiInfoPage')
  @post
  getApiInfoPage() {}

  // 删除api接口
  @url('/v3/api/removeApiInfoById')
  @post
  removeApiInfoById() {}

  // 获取api接口详情
  @url('/v3/api/getApiInfoById')
  @post
  getApiInfoById() {}

  // 添加或更新api接口
  @url('/v3/api/addOrUpdateApiInfo')
  @post
  addOrUpdateApiInfo() {}
  // add by sth @2020/05/13
  // 添加api接口
  @url('/v3/api/addApiInfo')
  @post
  addApiInfo() {}
  // 更新api接口
  @url('/v3/api/updateApiInfo')
  @post
  updateApiInfo() {}

  // 获取api接口详情
  @url('/app/fixedapi/listFixedApi')
  @get
  listFixedApi() {}
}

export default new ApiManage()
