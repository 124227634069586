import { post, url } from '../../service/ApiWebDecorator'

class CustomApi {
  // - 过程数据接口数据返回
  @url('/v3/dataapp/getDataAppResultForCondition')
  @post
  getDataAppResult() {}
  // -- 获取区域(学校)所有学期
  @url('/region/commom/listTermBeanByAreaId')
  @post
  listTermBean() {}
  // -- 区域化-获取区域学年列表
  @url('/region/commom/listAreaTermYearList')
  @post
  getlistAreaTermYearList() {}
  // -- 获取人员信息
  @url('/region/commom/listUserBeansList')
  @post
  getListUserBeansList() {}
  // -- 执行按钮调用的接口
  @url('/v3/newReport/reportEsData')
  @post
  getReportEsData() {}
  // -- 学期评价生成结果调用的接口
  @url('/v3/termStandard/oneTermStandard')
  @post
  oneTermStandard() {}
  // -- 毕业评价生成结果调用的接口
  @url('/v3/termStandard/leaveSchoolStandard')
  @post
  leaveSchoolStandard() {}
  // -- 根据学年学期归属人查询学生是否存在
  @url('/v3/termStandard/stuForYearTerm')
  @post
  stuForYearTerm() {}
}
export default new CustomApi()
