import { get, post, url, postJson } from '../../service/ApiWebDecorator'

// 通讯录
class AddressBook {
  // 获取老师列表
  @url('/v3/tea/getTeaList')
  @post
  getTeaList() {}
  // 同步老师
  @url('/v3/syncEosTeaData')
  @post
  syncEosTeaData() {}
  // 新增老师
  @url('/v3/addTeacherToEos')
  @post
  addTea() {}
  // 获取eos角色列表
  @url('/v3/getEosRoleList')
  @post
  getEosRoleList() {}
  // 老师详情页 - 基础信息
  @url('/v3/tea/getTeaInfo')
  @post
  getTeaInfo() {}
  // 老师详情页 - 行政机构
  @url('/v3/tea/getAdministartionOrgPathList')
  @post
  getAdminOrgList() {}
  // 老师详情页 - 行政角色 和 管理员角色
  @url('/v3/sysRole/getSysRolesAndTeaRoles')
  @post
  getRoleList() {}
  // 获取老师行政班
  @url('/v3/tea/getTeaAdministrationClassPath')
  @post
  getAdminClassList() {}
  // 获取教学班
  @url('/v3/tea/getTeaClassPath')
  @post
  getTeachClassList() {}
  // 获取教师班级角色
  @url('/v3/tea/getTeaClassRole')
  @post
  getClassRoleList() {}
  // 批量导入老师
  @url('/v3/tea/uploadTeaExcel')
  @post
  uploadTeaExcel() {}
  // 修改老师管理员角色接口
  @url('/v3/tea/updateTeaSysRole')
  @post
  updateTeaSysRole() {}
  // 保存老师详情
  @url('/v3/tea/saveTeaDetail')
  @post
  saveTeaDetails() {}
  // 素养得分页教师列表接口
  @url('/v3/target/filterTeaList')
  @postJson
  getTeaScoreList() {}
  // 素养教师得分明细
  @url('/v3/stu/getTeaScoreDetail')
  @post
  getTeaScoreDetail() {}

  // 获取学生列表
  @url('/v3/target/filterStuList')
  @postJson
  getStuList() {}
  // 同步学生
  @url('/v3/syncEosStuData')
  @post
  syncEosStuData() {}
  // 新增学生
  @url('/v3/addStuToEos')
  @post
  addStu() {}
  // 获取学生详情
  @url('/v3/stu/getStuInfo')
  @post
  getStuInfo() {}
  // 获取学生详情
  @url('/v3/stu/stuDetail')
  @post
  getStuDetails() {}
  // 得分明细
  @url('/v3/stu/getStuScoreDetail')
  @post
  getStuScoreDetail() {}
  // 获取学生素养得分和全校排名
  @url('/v3/target/getScoreAndRankByStuId')
  @post
  getRankInfo() {}
  // 获取某个学生的所在社团
  @url('/v3/association/listStuAssociation')
  @post
  getOneStuClubList() {}
  // 添加社团成员（学生）
  @url('/v3/association/addAssociationMember')
  @post
  addClubMember() {}
  // 修改学生信息
  @url('/v3/modifyStuInEos')
  @post
  modifyStuInfo() {}

  // 修改用户信息
  @url('/v3/modifyUserInEos')
  @post
  modifyUserInfo() {}
  // 获取eos用户角色
  @url('/v3/getEosUserDetail')
  @post
  getUserEosInfo() {}
  // 获取任务 提交人
  @url('/app/task/getTaskParticipant')
  @get
  getTaskParticipant() {}
}

export default new AddressBook()
