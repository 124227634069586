import { post, postJson, url } from '../../service/ApiWebDecorator'

// 变量中心
class VariableCenter {
  // 分页查询变量中心变量
  @url('/v3/fac/appvariable/pageByParam')
  @postJson
  pageByParam() {}
  // 分页查询变量中心变量
  @url('/v3/fac/appvariable/add')
  @post
  add() {}
  // 删除变量中心变量
  @url('/v3/fac/appvariable/delete')
  @post
  delete() {}
  // 变量中心修改变量信息
  @url('/v3/fac/appvariable/update')
  @post
  update() {}
  // 根据id获取变量信息
  @url('/v3/fac/appvariable/get')
  @post
  get() {}
  // 根据id获取变量信息
  @url('/v3/fac/appvariable/pageAvailableByAppId')
  @post
  pageAvailableByAppId() {}
}

export default new VariableCenter()
