import { url, get, postJson, post } from '../../service/ApiDecorator'
class TeachClassMag {
  // 判断是否开通教学班
  @url('/develop/course/getClazzType')
  @post
  getClazzType() {}
  // 查询本学期课程设置的年级(教学班)
  @url('/develop/course/current/listCurrentCourseGradeTeaClazz')
  @get
  listCurrentCourseGradeTeaClazz() {}

  // eos教学班查询
  @url('/v3/choiceUser/getTeaClazzTree')
  @get
  getTeaClazzTree() {}

  // 获取教学班下的人员
  @url('/v3/choiceUser/getTeaingStuPage')
  @postJson
  getTeaingStuPage() {}

  // 获取教学班集合
  @url('/develop/course/task/tea/listTeaClassByCourseTaskId')
  @get
  listTeaClassByCourseTaskId() {}
}
export default new TeachClassMag()
