import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 按模块导入路由
// import DemoRouters from './demo-router'
import Jr from './jr-router'
import Portal from './portal'

export default new Router({
    // mode: 'history', //后端支持可开
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        // ...DemoRouters,
        ...Jr,
        ...Portal,
    ]
})
