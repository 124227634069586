import { post, url, get, postJson } from '../../service/ApiDecorator'
class AttendanceM {
  // 个人考勤列表
  @url('/signinStu/classify/getListByTea')
  @post
  getListByTea() {}

  // 获取登录人的行政班列表
  @url('/auth/eos/user/getXzb')
  @post
  getXzb() {}

  // 获取组织下的学生
  @url('/sys/api/user/query/orderByOrgSort')
  @get
  getOrgStuList() {}

  // 互动班级下学生考勤情况
  @url('/signinStu/classifyRecord/getDetailByOrgId')
  @get
  getDetailByOrgId() {}

  // 考勤记录管理-保存明细
  @url('/signinStu/classifyRecord/saveInfo')
  @postJson
  saveInfo() {}

  // 考勤记录管理-列表
  @url('/signinStu/classifyRecord/getList')
  @post
  classifyRecordGetList() {}

  // 考勤记录管理-删除明细
  @url('/signinStu/classifyRecord/del')
  @post
  classifyRecordDel() {}

  // 考勤学生统计-按学期
  @url('/signinStu/classifyCount/getStuCountListByTerm')
  @post
  getStuCountListByTerm() {}

  // 考勤学生统计-按学期-发布数据
  @url('/signinStu/classifyCount/getStuCountListByTerm/upsert')
  @post
  getStuCountListByTermUpsert() {}
}
export default new AttendanceM()
