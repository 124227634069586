import { get, post, url } from '../../service/ApiWebDecorator'

// 数据应用

class DataApp {
  // 查询数据应用详细信息
  @url('/v3/dataapp/getReportDataAppDetail')
  @get
  getReportDataAppDetail() { }

  // 获取需要修改的字段
  @url('/v3/dataapp/getTaskDataDetailByAppId')
  @get
  getTaskDataDetailByAppId() { }

  // 修改提交数据
  @url('/v3/dataapp/updateDataAppFormVal')
  @post
  updateDataAppFormVal() { }

  // 判断来源
  @url('/v3/dataapp/getSource')
  @post
  getSource() { }
  // 获取用户查询结果（数据应用）
  @url('/v3/dataapp/getDataAppResultForCondition')
  @post
  getDataAppResultForConditionNew() { }
  // 获取用户查询结果（数据应用-非登录状态）
  @url('/v3/dataapp/getHzDataAppResultForCondition')
  @post
  getHzDataAppResultForCondition() { }
  // 获取用户查询结果（数据应用）
  @url('/v3/dataapp/getDataAppResultForConditionOld')
  @post
  getDataAppResultForCondition() { }
  // 获取用户查询结果（数据应用--素养护照页面用）
  @url('/v3/dataapp/getDataAppResultForConditionNoAuth')
  @post
  getDataAppResultForConditionNoAuth() { }
  // 获取用户查询结果（过程数据）
  @url('/v3/dataapp/getProcessDataResultForCondition')
  @post
  getProcessDataResultForCondition() { }

  // 导出报表
  @url('/v3/aggreation/exportData')
  @get
  exportData() { }
  // 导出报表
  @url('/v3/aggreation/exportTeacherJudgementPage')
  @get
  exportTeacherJudgementPage() { }
  // 获取用户查询条件
  @url('/v3/dataapp/getDataAppCondition')
  @get
  getDataAppConditionNew() { }
  // 获取用户查询条件(非登录模式)
  @url('/v3/dataapp/getHzDataAppCondition')
  @get
  getHzDataAppCondition() { }
  // 获取用户查询条件
  @url('/v3/dataapp/getDataAppConditionOld')
  @get
  getDataAppCondition() { }

  // 获取数据追踪-数据处理字段数据
  @url('/v3/dataapp/viewDetails')
  @post
  viewDetails() { }
  // 获取数据追踪-详情
  @url('/v3/dataapp/viewMoreDetails')
  @post
  viewMoreDetails() { }
  // 过程数据-分享场景看过程和任务接口(web端)
  @url('/v3/itemData/getSceneItemAndTaskList')
  @post
  getSceneItemAndTaskList() { }

  // 过程数据-获得任务列表(web端)
  @url('/v3/itemData/getTaskDataAppId')
  @post
  getTaskDataAppId() { }

  // 获取管理部门
  @url('/v3/getOrgListByOrgType')
  @post
  getOrgListByOrgType() { }

  // 根据年级获取年级和班级
  @url('/v3/org/v3/getOrgListBySchoolId')
  @post
  getOrgListBySchoolId() { }

  // 数据应用修改通知
  @url('/v3/eos/sendEosNotice')
  @post
  sendEosNotice() { }

  // 小程序发通知
  @url('/v3/eos/sendMiniProgram')
  @post
  sendMiniProgram() { }

  // 根据数据应用id获取自定义页面组件的类型  params：{dapId:null,type:null},//type为页面组件中可选内部页面类型
  @url('/v3/component/getCompEditByIdAndType')
  @get
  getCompEditByIdAndType() { }

  // 数据应用formId
  @url('/v3/dataapp/getCommonMsgByDataAppId')
  @get
  getCommonMsgByDataAppId() { }

  // 任务排序
  @url('/v3/aggreation/getOrderResult')
  @post
  getOrderResult() { }

  // 批量删除数据应用数据
  @url('/app/dataapp/deleteFormValuesForApply')
  @post
  deleteFormValuesForApply() { }

  // 删除数据应用数据
  @url('/app/dataapp/deleteOneFormValueForApply')
  @post
  deleteOneFormValueForApply() { }

  // 判断任务是否是审核任务和是否需要审核赋分
  @url('/v3/compute/getTaskCheckInfoAndScoreType')
  @post
  getTaskCheckInfoAndScoreType() { }

  // 判断应用数据数据状态
  @url('/v3/dataapp/getDataStateByReportId')
  @post
  getDataStateByReportId() { }

  // 判断应用数据数据状态
  @url('/v3/dataapp/listBelongIdByFormValueIdList')
  @post
  listBelongIdByFormValueIdList() { }
  // 数据应用归属人列表
  @url('/app/task/listBelongUser')
  @post
  listBelongUser() { }
  // 数据应用归属人搜索
  @url('/app/itemChoice/selectByNameForApply')
  @post
  selectByNameForApply() { }
  // 安亭周考核定制
  @url('/v3/custom/anting/getAntingSemesterInfo')
  @post
  getAntingSemesterInfo() { }
  // 安亭生成结果
  @url('/v3/custom/anting/generateAntingAgg')
  @post
  generateAntingAgg() { }
  // 获取组织下数据提交人允许提交的人
  @url('/v3/getTaskLimitUserIdList')
  @post
  getTaskLimitUserIdList() { }
  // 获取系统时间
  @url('/all/base/getSysTimestamp')
  @post
  getSysTimestamp() { }
  // 获取系统时间
  @url('/v3/dataapp/listUserFormItemInfoVo')
  @post
  listUserFormItemInfoVo() { }
  // 新建判断是否有权限
  @url('/v3/dataapp/checkUserDataAppPermission')
  @post
  checkUserDataAppPermission() { }
  // 新建时归属人是否已经提交
  @url('/v3/dataapp/checkDataAppPermission')
  @post
  checkDataAppPermission() { }
  // 查询任务分组表单分页数据
  @url('/v3/dataapp/getChildItemInfoByFormValueId')
  @get
  getChildItemInfoByFormValueId() { }

  // 通过任务id获取数据应用appId
  @url('/v3/itemData/getTaskDataAppId4ModelRule')
  @get
  getTaskDataAppId4ModelRule() { }
}

export default new DataApp()
