import { get, post, url, postJson } from '../../service/ApiWebDecorator'

// 规则引擎
class RuleEngine {
  // 获取基本信息
  @url('/v3/compute/getComputeInfo')
  @get
  getComputeInfo() { }

  @url('/develop/course/task/getComputeInfo')
  @post
  getSdpComputeInfo() { }

  // 获取任务群或任务下的字段集合和任务集合
  @url('/develop/customtemplate/task/getCourseCustomTaskItem')
  @get
  getCourseCustomTaskItem() { }

  // 规则引擎搜索场景列表
  @url('/region/computeRule/getSceneList')
  @get
  getSceneList() { }

  // 规则引擎搜索场景列表
  @url('/region/computeRule/getSceneListNoAuthV2')
  @get
  getSceneListNoAuthV2() { }

  // 规则引擎搜索场景列表(无权限)(已废除)
  @url('/region/computeRule/getSceneListNoAuth')
  @get
  getSceneListNoAuth() { }

  // 根据场景获取规则引擎列表
  @url('/develop/computeRule/listRule')
  @get
  getRuleList() { }

  @url('/develop/course/task/listRule')
  @get
  getSdpListRule() { }

  // 学校发展平台-规则引擎列表
  @url('/develop/computeRule/pageRuleByCourseIdOrTemplateId')
  @get
  pageRuleByCourseIdOrTemplateId() { }

  // 获取规则指标
  @url('/v3/compute/getTargetList')
  @get
  getTargetList() { }

  // 获取全指标
  @url('/v3/target/getTargetTree')
  @get
  getTargetTree() { }

  // 批量执行规则（审核、赋分）
  @url('/v3/compute/executeBatchRule')
  @post
  executeBatchRule() { }

  // 新增/编辑规则
  @url('/region/computeRule/insert')
  @post
  insertOrUpdate() { }

  // 发展平台规则引擎单独保存
  @url('/develop/computeRule/addOrUpdateComputeRule')
  @postJson
  addOrUpdateComputeRule() { }

  // add by sth @2020/05/13
  // 新增规则
  @url('/v3/compute/insert')
  @post
  insert() { }
  // 编辑规则
  @url('/v3/compute/update')
  @post
  update() { }
  // 学校发展平台编辑回显
  @url('/develop/computeRule/getRuleDetailById')
  @get
  getRuleDetailById() { }
  // 复制规则
  @url('/v3/compute/clone')
  @post
  clone() { }

  // 批量删除规则 -根据场景id批量删除场景下的规则
  @url('/region/computeRule/delBySceneId')
  @post
  delBySceneId() { }

  // 删除单条规则
  @url('/region/computeRule/delRuleById')
  @post
  delRuleById() { }

  // 获取 字段
  @url('/v3/getNumFormItemList')
  @get
  getNumFormItemList() { }

  // 添加规则引擎 获取场景列表（所有）
  @url('/region/computeRule/getAllSceneList')
  @get
  getAllSceneList() { }

  // 检查批量操作的规则是否需要手动赋分
  @url('/v3/compute/checkBatchRuleNeedSetScore')
  @post
  checkBatchRuleNeedSetScore() { }

  // 修改规则名称
  @url('/v3/compute/updateRuleName')
  @post
  updateRuleName() { }

  // 规则执行人 选人插件列表
  @url('/v3/compute/getItemChoiceList')
  @get
  getItemChoiceList() { }
  // 规则执行人 人脸识别
  @url('/v3/compute/getFaceItemList')
  @get
  getFaceItemList() { }

  // 规则执行人 添加分数
  @url('/v3/compute/addRuleScoreBatchExcute')
  @postJson
  addRuleScoreBatchExcute() { }
  // 获取赋分规则列表
  @url('/v3/compute/getNeedAddRuleScoreManual')
  @get
  getNeedAddRuleScoreManual() { }
  // 获取赋分规则列表
  @url('/v3/compute/getAddRuleDetailForTask')
  @get
  getAddRuleDetailForTask() { }
  // 过程数据和数据应用是否需要赋分
  @url('/v3/compute/getTaskCheckInfoAndScoreType')
  @post
  getTaskCheckInfoAndScoreType() { }
  // 获取指标模板列表
  @url('/region/targetModel/listTargetModelByCompute')
  @post
  listTargetModelByCompute() { }
  // 修改执行状态
  @url('/region/computeRule/updateExecuteStatus')
  @postJson
  updateExecuteStatus() { }
  // 规则引擎推送
  @url('/region/computeRule/push')
  @post
  pushRule() { }
  // 获取档位等第关系
  @url('/develop/course/getLevelEquivalencyList')
  @get
  getLevelEquivalencyList() { }
  // 保存档位等第关系
  @url('/develop/course/saveLevelEquivalency')
  @post
  saveLevelEquivalency() { }
  // 暂存--规则引擎保存、修改
  @url('/develop/computeRule/addOrUpdateComputeRuleForStash')
  @postJson
  addOrUpdateComputeRuleForStash() { }
  // 暂无-规则列表
  @url('/develop/computeRule/pageByCourseIdForStash')
  @get
  pageByCourseIdForStash() { }
  // 暂存--删除规则
  @url('/develop/computeRule/delRuleByIdForStash')
  @post
  delRuleByIdForStash() { }
  // 暂存--规则引擎列表
  @url('/develop/computeRule/getRuleDetailByIdForStash')
  @get
  getRuleDetailByIdForStash() { }
  // 暂存---根据key保存所有暂存规则
  @url('/develop/computeRule/listByKeyForStash')
  @post
  listByKeyForStash() { }
  // 删除暂存
  @url('/develop/computeRule/delRuleStashByKey')
  @post
  delRuleStashByKey() { }
  // 总体保存任务和规则
  @url('/develop/customtemplate/task/addOrUpdateCustomTemplaBo')
  @postJson
  addOrUpdateCustomTemplaBo() { }
}

export default new RuleEngine()
