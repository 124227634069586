import { post, url, postJson } from '../../service/ApiDecorator'
class DataVisualizationKaizhou {
  // 当日人员流量+本周人员流量
  @url('/onecard/manage/consume/count')
  @postJson
  consumeCount() {}

  // 当日各专业部消费情况对比-消费占比
  @url('/onecard/manage/consume/dayCount')
  @postJson
  consumeDaycount() {}

  // 分页明细
  @url('/onecard/manage/consume/page')
  @postJson
  consumePage() {}

  // 当日人员流量+本周人员流量
  @url('/onecard/manage/signin/count')
  @post
  signinCount() {}

  // 当日各专业部出勤情况对比
  @url('/onecard/manage/signin/dayCount')
  @postJson
  signinDaycount() {}

  // 当日学生出勤情况
  @url('/onecard/manage/signin/dayOrgCount')
  @postJson
  signinDayorgcount() {}

  // 分页明细
  @url('/onecard/manage/signin/page')
  @postJson
  signinPage() {}

  // 统计汇总
  @url('/signin/count/countAll')
  @postJson
  signin2Countall() {}

  // 当日各专业部考勤情况对比-专业
  @url('/signin/count/dayCount')
  @postJson
  signin2Daycount() {}

  // 当日各年级考勤情况对比
  @url('/signin/count/dayGradeCount')
  @postJson
  signin2Daygradecount() {}

  // 近一周出勤情况
  @url('/signin/count/latestWeekCount')
  @post
  signin2Latestweekcount() {}

  // 当日各年级消费情况对比-消费占比（前三名）
  @url('/onecard/manage/consume/dayGradeCount')
  @postJson
  consumeDayGradeCount() {}

  // 当日各班级考勤情况对比
  @url('/signin/count/dayOrgCount')
  @postJson
  dayOrgCount() {}

  // 当日各班级考勤情况对比
  @url('/vocate/v2/vocateManage/jwinfo/countAll')
  @postJson
  countAll() {}
}
export default new DataVisualizationKaizhou()
