import { post, url, postJson, get } from '../../service/ApiDecorator'
class ApiEvaluateLessonM {
  // 邀请听课分页
  @url('/app/lesson/evaluate/pageLesson')
  @post
  pageLesson() {}

  // 发起听课
  @url('/app/lesson/evaluate/startLesson')
  @postJson
  startLesson() {}

  // 上传听课记录
  @url('/app/lesson/evaluate/uploadEvaluate')
  @postJson
  uploadEvaluate() {}

  // 听课详情
  @url('/app/lesson/evaluate/detailLesson')
  @post
  detailLesson() {}

  // 获取学科
  @url('/v3/eos/dict/listDict')
  @get
  listDict() {}

  // 获取课程详情
  @url('/app/lesson/evaluate/detailLessonScore')
  @post
  detailLessonScore() {}

  // 删除课程
  @url('/app/lesson/evaluate/removeLesson')
  @post
  removeLesson() {}

  // 获取课程评论
  @url('/app/lesson/evaluate/pageLessonRecord')
  @post
  pageLessonRecord() {}

  // 课程评论点赞
  @url('/app/lesson/evaluate/likeRecord')
  @post
  likeRecord() {}

  // 课程评论取消点赞
  @url('/app/lesson/evaluate/cancelLikeRecord')
  @post
  cancelLikeRecord() {}

  // 查看教学设计
  @url('/app/lesson/evaluate/getLessonDesignUrl')
  @post
  getLessonDesignUrl() {}

  // 我的听课本
  @url('/app/lesson/evaluate/pageUserEvaluateHistory')
  @post
  pageUserEvaluateHistory() {}

  // 我的听课本删除
  @url('/app/lesson/evaluate/removeEvaluate')
  @post
  removeEvaluate() {}

  // 个人听课进度
  @url('/app/lesson/evaluate/getEvaluateCount')
  @post
  getEvaluateCount() {}

  // 推门听课startLessonByJoin
  @url('/app/lesson/evaluate/startLessonByJoin')
  @postJson
  startLessonByJoin() {}

  // 个人统计详情
  @url('/app/lesson/evaluate/listLectureAvgScore')
  @post
  listLectureAvgScor() {}

  // 查询用户学科职位等信息
  @url('/app/lesson/evaluate/getUserBasicInfo')
  @post
  getUserBasicInfo() {}

  // 教研组下每个用户的评课次数
  @url('/app/lesson/evaluate/listUserEvaluateCount')
  @post
  listUserEvaluateCount() {}

  // 查询教研组下各用户各类型的评课次数
  @url('/app/lesson/evaluate/pageUserEvaluateCountByType')
  @post
  pageUserEvaluateCountByType() {}

  // 教研组听课信息详情
  @url('/app/lesson/evaluate/getCurrentTermCountInfo')
  @post
  getCurrentTermCountInfo() {}

  // 新增编辑用户听课计划/app/lesson/evaluate/saveUserPlan
  @url('/app/lesson/evaluate/saveUserPlan')
  @post
  saveUserPlan() {}

  // 听评课得分项分数
  @url('/app/lesson/evaluate/listLessonAvgScore')
  @post
  listLessonAvgScore() {}

  // 已经评课的用户
  @url('/app/lesson/evaluate/pageLessonEvaluateUser')
  @post
  pageLessonEvaluateUser() {}

  // 未评课的用户
  @url('/app/lesson/evaluate/listLessonNotUploadUser')
  @post
  listLessonNotUploadUser() {}

  // 未评课的用户
  @url('/v3/org/listUserOrgByTypes')
  @post
  listUserOrgByTypes() {}

  // 区域下听评课的数据统计
  @url('/app/lesson/evaluate/getAreaEvaluateCount')
  @post
  getAreaEvaluateCount() {}

  // 生成区域小程序二维码
  @url('/v3/eos/createwxaqrcode')
  @post
  createwxaqrcode() {}

  // 通过二维码报名课程
  @url('/app/lesson/evaluate/inviteJoinByQrCode')
  @post
  inviteJoinByQrCode() {}

  // 区域下查询所有教师的数据
  @url('/common/eos/select/area/users/query')
  @post
  getAreaUsers() {}

  // 查询已经提交评分的老师
  @url('/app/lesson/evaluate/countAreaSchoolHaveUpload')
  @post
  countAreaSchoolHaveUpload() {}

  // 导出成绩
  @url('/app/lesson/evaluate/exportLessonScore')
  @post
  exportLessonScore() {}

  // 教研组长导出
  @url('/app/lesson/evaluate/exportUserEvaluateCountByType')
  @post
  exportUserEvaluateCountByType() {}
}
export default new ApiEvaluateLessonM()
