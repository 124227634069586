import { post, url } from '../../service/ApiWebDecorator'

class TeaRole {
  // 获取老师所有角色
  @url('/v3/teaRole/listTeaRole')
  @post
  getRoleList() {}
  // 添加老师角色
  @url('/v3/teaRole/addTeaRole')
  @post
  addRole() {}
  // 删除老师角色
  @url('/v3/teaRole/removeTeaRole')
  @post
  removeRole() {}
  // 修改老师角色
  @url('/v3/teaRole/updateTeaRole')
  @post
  updateRole() {}
}

export default new TeaRole()
