import { post, url } from '../../service/ApiWebDecorator'

// 场景 管理后台
class SceneEdit {
  // 获取当前场景下所有数据处理可使用字段
  @url('/v3/getSceneDataDealList')
  @post
  getSceneFilterList() {}

  // 保存字段
  @url('/v3/saveNewFormItem')
  @post
  saveNewFormItem() {}

  // 字段详情
  @url('/v3/getNewFormItem')
  @post
  getNewFormItem() {}

  // 克隆字段
  @url('/v3/cloneFormItemInfo')
  @post
  cloneFormItemInfo() {}
  // 克隆字段
  @url('/v3/copySceneItem')
  @post
  copySceneItem() {}

  // 过程/任务显示隐藏
  @url('/v3/updateSceneItemTaskLock')
  @post
  updateSceneItemTaskLock() {}

  // 过程拖拽排序
  @url('/v3/updateSceneItemByIdAndSort')
  @post
  updateSceneItemSort() {}

  // 任务拖拽排序
  @url('/v3/updateTaskByIdAndSort')
  @post
  updateTaskSort() {}

  // 根据任务的数据处理范围查询选人插件
  @url('/v3/getChoiceListByTaskDataBelongType')
  @post
  getChoiceListByTaskDataBelongType() {}

  // 获取引用字段集合
  @url('/v3/formItem/getRefFormItemInfoList')
  @post
  getRefFormItemInfoList() { }

  // 获取任务集当前引用表单项Id
  @url('/v3/getTaskSetCurrentRefFormItemId')
  @post
  getTaskSetCurrentRefFormItemId() { }

  // 根据表单项ID获取任务信息
  @url('/v3/getTaskByFormItemId')
  @post
  getTaskByFormItemId() { }

  // 获取表单详情
  @url('/v3/getForm')
  @post
  getForm() { }

  // 删除字段
  @url('/v3/deleteNewFormItem')
  @post
  deleteNewFormItem() { }

  // 字段排序
  @url('/v3/saveFormItemSort')
  @post
  saveFormItemSort() { }

  // 任务保存
  @url('/v3/saveNewTask')
  @post
  saveNewTask() { }

  // 修改过程展示类型
  @url('/v3/task/updateShowTypeBySceneItemId')
  @post
  processUpdateShowType() {}

  // 分页查询指定展示类型的过程
  @url('/v3/sceneItem/pageByShowType')
  @post
  processPageByShowType() {}

  // 修改任务展示类型
  @url('/v3/task/updateShowType')
  @post
  taskUpdateShowType() {}

  // 分页查询指定展示类型的任务
  @url('/v3/task/pageByShowType')
  @post
  taskPageByShowType() {}

  // 获取最大过程sort
  @url('/v3/sceneItem/getLatestSortBySceneId')
  @post
  getLatestSortBySceneId() {}

  // 获取当前过程任务的sort
  @url('/v3/task/getLatestSortBySceneItemId')
  @post
  getLatestSortBySceneItemId() {}
}

export default new SceneEdit()
