/**
 * Created by lgzhang08@gmail.com 20161012
 * api相关的url处理类
 */
class DoApi {
    /**
     * 解析window.location.serch
     */
    getSearchByName(name) {
      var reg = new RegExp('[?|&]' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.href.match(reg)
      if (r != null) return (r[1].split('#')[0])
      return null
    }
    /*
     * json转成url格式
     * 中文encode
     */
    jsonUrlFormat(data = {}) {
        const param = function(obj) {
            let query = ''
            let name, value, fullSubName, subName, subValue, innerObj, i
            for (name in obj) {
                value = obj[name]
                if (value instanceof Array) {
                    for (i = 0; i < value.length; ++i) {
                        subValue = value[i]
                        fullSubName = name + '[]'
                        innerObj = {}
                        innerObj[fullSubName] = subValue
                        query += param(innerObj) + '&'
                    }
                } else if (value instanceof Object) {
                    for (subName in value) {
                        subValue = value[subName]
                        fullSubName = name + '[' + subName + ']'
                        innerObj = {}
                        innerObj[fullSubName] = subValue
                        query += param(innerObj) + '&'
                    }
                } else if (value !== undefined && value !== null) {
                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&'
                }
            }
            return query.length ? query.substr(0, query.length - 1) : query
        }
        // 增加 去缓存时间戳
        // data.noCache = new Date().getTime()
        // 资源平台appId增加
        // if(data.apiType == 'res') data.appId = window.NewResAppId
        // 递归将json转成url, 增加sign签名 和 去缓存时间戳noCache
        return data.toString() === '[object Object]' ? param(data) : data
    }

    /**
     * 处理post请求，简单encode封装
     * 暂未使用
     */
    doJson(params) {
        return this.jsonUrlFormat(params)
    }
    /**
     * 处理post请求，简单encode封装
     */
    doPostJson(params) {
      // 统一添加schoolId
      if (params && !params.schoolId && Cookies.get('EOSSchoolId')) params.schoolId = Cookies.get('EOSSchoolId')
      // TODO 应用工厂预览页面和提交页面接口分开标识，source字段，代表数据来源，7为预览数据 1正式
      if (/\/app-preview/.test(location.href)) {
        params.source = 7
      } else if (/\/app-submit/.test(location.href) || /preview-info/.test(location.href) || /previewtInfo/.test(location.href)) {
        params.source = 1
      }
      return params
    }
    /**
     * 处理路径参数
     * 将路径参数:path 统一params中替换
     */
    doPathParam(url, params) {
      let self = this
      url = url.replace(/:\w+/gi, function(str) {
        const props = str.split(':')[1]
        // 手动传入业务前缀，直接返回，不需判断是素养还是eos
        if (props == 'prdfix' && params && params[props]) return params[props]
        // TODO 本地调试 暂时通过url prdfix标识，线上通过项目路径标识: eos-web, eos-app，使用saascommon，素养admin m no-code项目使用 sycommon
        // 素养本地调试 9528 9999，两个端口
        if (self.getSearchByName('prdfix')) {
          if (props == 'prdfix') return self.getSearchByName('prdfix')
        } else if (/eos-web/.test(location.href) || /eos-app/.test(location.href)) {
          if (props == 'prdfix') return 'saascommon'
        } else if (/\/admin/.test(location.href) || /\/SDP/.test(location.href) || /\/m\//.test(location.href) || /\/no-code\//.test(location.href) || location.port == '9528' || location.port == '9999') {
          if (props == 'prdfix') return 'sycommon'
        }
        // 路径前缀默认 saascommon
        if (props == 'prdfix' && (!params || !params[props])) return 'saascommon'
        return params && params[props] || null
      })
      // 应用工厂接口更换 /sycommon/eos/form/admin/byid --> /app/fac/form/wx/byid  /sycommon/eos/form/wx/calculate --> /app/fac/form/wx/calculate
      if (/no-code/.test(location.href) && /\/SDP/.test(location.href) == false) {
        url = url.replace('/sycommon/eos/form/admin/byid', '/app/fac/form/wx/byid')
        url = url.replace('/sycommon/eos/form/wx/calculate', '/app/fac/form/wx/calculate')
      }
      // 学校发展平台学生成长功能权限与素养功能权限 接口映射
        url = this.getFuncSDPUrl(url)
      return url
    }

    /**
     * 学校发展平台学生成长功能权限与素养功能权限 接口映射
     * @param url
     */
    getFuncSDPUrl(url) {
        // 学校发展接口
        if (/SDP/.test(location.href) == false) return url
        // 组织规划直接替换
        let UrlObj = {
            // '/v3/org/searchOrg': '/develop/org/searchOrg',
            '/v3/target/filterStuList': '/develop/target/filterStuList',
            '/v3/getSceneEdit': '/develop/scene/getSceneEdit', // 场景建模接口
            '/v3/target/filterTeaList': '/develop/tea/targetModel/filterTeaList',
            // '/v3/passport/getDataByCondition': '/develop/passport/getDataByCondition',
            // '/v3/api/': '/develop/api/',
            '/v3/api/updateApiInfo': '/develop/api/updateApiInfo',
            '/v3/api/removeApiInfoById': '/develop/api/removeApiInfoById',
            '/v3/api/getApiInfoPage': '/develop/api/getApiInfoPage',
            '/v3/api/getApiInfoById': '/develop/api/getApiInfoById',
            '/v3/api/addApiInfo': '/develop/api/addApiInfo',
            '/v3/dataapp/updateReportDataApp': '/develop/dataapp/updateReportDataApp',
            '/v3/dataapp/getDataAppResultForCondition': '/develop/dataapp/getDataAppResultForCondition',
            // 场景相关接口
            '/v3/updateSceneItemTaskLock': '/develop/updateSceneItemTaskLock',
            '/v3/copyTask': '/develop/copyTask',
            '/v3/saveSceneItem': '/develop/saveSceneItem',
            '/v3/saveScene': '/develop/saveScene',
            '/v3/getItem': '/develop/getItem',
            '/v3/scene/shelve': '/develop/scene/shelve',
            '/v3/scene/unshelve': '/develop/scene/unshelve',
            '/v3/updateSceneItemByIdAndSort': '/develop/updateSceneItemByIdAndSort',
            '/v3/copySceneItem': '/develop/copySceneItem',
            '/v3/deleteSceneItem': '/develop/deleteSceneItem',
            '/v3/compute/checkBatchRuleNeedSetScore': '/develop/compute/checkBatchRuleNeedSetScore',
            '/v3/compute/executeBatchRule': '/develop/compute/executeBatchRule',
            '/v3/compute/getComputeInfo': '/develop/compute/getComputeInfo',
            // '/v3/component/': '/develop/component/',
            '/v3/component/getCompMarkList': '/develop/component/getCompMarkList',
            '/v3/component/delCompMark': '/develop/component/delCompMark',
            '/v3/component/upCompMark': '/develop/component/upCompMark',
            '/v3/component/addCompMark': '/develop/component/addCompMark',
            '/v3/component/getCompPageList': '/develop/component/getCompPageList',
            '/v3/component/addCompPage': '/develop/component/addCompPage',
            '/v3/component/upCompPage': '/develop/component/upCompPage',
            '/v3/component/delCompPage': '/develop/component/delCompPage',
            '/v3/component/getCompMarkListFromIndex': '/develop/component/getCompMarkListFromIndex',
            '/v3/component/getCompMarkListFromAddComponent': '/develop/component/getCompMarkListFromAddComponent',
            '/development/management/api/target/list': '/develop/development/management/api/target/list',
            // '/development/management/api/result/listByPage': '/develop/development/management/api/result/listByPage',
            '/development/management/api/index/qualityAnalysis': '/develop/development/management/api/index/qualityAnalysis', // 诊断分析
            '/development/management/api/report/listByPage': '/develop/development/management/api/report/listByPage', // 质量报告
            '/development/management/api/template/templateList': '/develop/development/management/api/template/templateList', // 考核管理
            '/development/management/api/assess/getSet': '/develop/development/management/api/assess/getSet', // 部门绩效
            '/development/management/api/assess/getOrg': '/develop/development/management/api/assess/getOrg', // 部门绩效
            '/development/management/api/assess/getSort': '/develop/development/management/api/assess/getSort', // 部门绩效
            '/development/management/api/index/monthTaskCompletionRate': '/develop/development/management/api/index/monthTaskCompletionRate', // 学校看板
        }
        let ExcludeUrl = []
        // 学生成长将region替换成 develop
        if (/SDP\/educationModel\//.test(location.pathname)) {
            // 将region替换成 develop
            UrlObj = {
                ...UrlObj,
                '/region/targetModel/': '/develop/targetModel/', // 培养目标
                '/region/cultivate/': '/develop/cultivate/', // 培养模型
                '/region/computeRule/': '/develop/computeRule/', // 过程评价
                '/region/educationscene/': '/develop/educationscene/', // 学校发展平台-育人场景-规则引擎
                '/v3/target/': '/develop/target/', // 学校发展平台-育人场景-规则引擎
                '/v3/passport/getStuList': '/develop/passport/getStuList', // 学校发展平台-成长报告
                '/v3/saveInitScene': '/develop/saveInitScene',
                '/v3/getSceneList': '/develop/getSceneList',
            }
            // 以下url不处理
            ExcludeUrl = [
                '/region/targetModel/listTargetModelByCompute',
                '/region/targetModel/listTargetModelAll',
                '/region/cultivate/ajaxCultivate',
                '/region/cultivate/getCulitivateInfo',
                '/region/targetModel/getTargetModelDetail',
                '/region/computeRule/getSceneListNoAuthV2',
            ]
        } else if (/SDP\/teaGrowth\//.test(location.pathname)) { // 教师发展
            // 将region替换成 develop
            UrlObj = {
                ...UrlObj,
                '/region/targetModel/listTargetModel': '/develop/tea/targetModel/listTargetModel', // 教师发展目录
                '/v3/choiceUser/getTeaPageInTeacherGrowth': '/develop/choiceUser/getTeaPageInTeacherGrowth', // 发展报告
                '/region/targetModel/getTargetModelDetail': '/develop/tea/targetModel/getTargetModelDetail', // 发展指标
                '/v3/teaablity/pageTeaNoConfirmation': '/develop/teaablity/pageTeaNoConfirmation', // 专家认定
                '/v3/rule/page': '/develop/rule/page',
                '/v3/scoreCount/orgsDataCount': '/develop/scoreCount/orgsDataCount',
                // '': '/develop/course/task/tea/pageAllTaskAuth',
                '/v3/top/getRangeInfo': '/develop/top/getRangeInfo',
            }
            // 以下url不处理
            ExcludeUrl = [
                '/region/targetModel/listTargetModelByCompute',
                '/region/targetModel/listTargetModelAll',
                '/region/cultivate/ajaxCultivate',
                '/region/cultivate/getCulitivateInfo',
            ]
        }
        if (ExcludeUrl.includes(url)) return url
        // 循环替换
        for (let key in UrlObj) {
            if (url.indexOf(key) > -1) {
                // url = url.replace('/region/', '/develop/').replace('/v3/', '/develop/')
                url = url.replace(key, UrlObj[key])
            }
        }
        return url
    }
    /**
     * 解析cookies并获取token
     */
    getValueFromCookies(cookies, name) {
        if (!cookies) return
        let value = cookies.split(';').find(c => c.trim().startsWith(name + '='))
        if (!value) return
        // 去掉22% 引号
        value = value.replace('%22', '').replace('%22', '')
        return value.split(name + '=')[1]
    }
}

// 导出单例，方便直接使用
export default new DoApi()
