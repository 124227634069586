import { get, post, postJson, url } from '../../service/ApiWebDecorator'

class AppInfo {
  // 添加基础信息
  @url('/v3/fac/appinfo/add')
  @post
  appinfoAdd() {}
  // 删除应用
  @url('/v3/fac/appinfo/delete')
  @post
  appinfoDelete() {}
  // 查询应用基础信息详情
  @url('/v3/fac/appinfo/getById')
  @get
  getById() {}
  // 初始化应用
  @url('/v3/fac/appinfo/initApp')
  @post
  initApp() {}
  // 查询应用基础信息
  @url('/v3/fac/appinfo/listFacAppInfo')
  @get
  listFacAppInfo() {}
  // 分页查询应用基础信息
  @url('/v3/fac/appinfo/pageFacAppInfo')
  @get
  pageFacAppInfo() {}
  // 修改基础信息
  @url('/v3/fac/appinfo/update')
  @post
  appInfoUpdate() {}
  // 获取枚举字段进度条设置
  @url('/v3/eos/form/wx/getOptionInfo')
  @get
  getOptionInfo() {}
  // 保存应用发布记录
  @url('/v3/fac/releaseRecord/addReleaseRecord')
  @postJson
  addReleaseRecord() {}
  // 获取应用发布记录
  @url('/v3/fac/releaseRecord/listReleaseRecordByAppId')
  @get
  listReleaseRecordByAppId() {}
}

export default new AppInfo()
