import Vue from 'vue'

/**
 * 纵向滚动加载
 * { callback: loadMore, class: '.el-table__body-wrapper', multiple: 2 }
 * @param callback 滚动回调方法
 * @param class 监听滚动元素的类名
 * @param multiple 读取元素的可见高度(倍数)
 * scrollHeight 获取元素内容高度(只读)
 * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0
 * clientHeight 读取元素的可见高度(只读)
 * 如果元素滚动到底, 下面等式返回true, 没有则返回false
 * ele.scrollHeight - ele.scrollTop === ele.clientHeight
 */
Vue.directive('scroll-load', function(el, binding) {
  const selectWrap = el.querySelector(binding.value.class)
  selectWrap.addEventListener('scroll', function() {
    const getToBottom = this.scrollHeight - this.scrollTop <= (this.clientHeight * binding.value.multiple)
    if (getToBottom) {
      binding.value.callback()
    }
  })
})
