import { post, url, postJson, get } from '../../service/ApiDecorator'
class HistoryMag {
  // 历史学生列表
  @url('/develop/history/stu/list')
  @post
  stuList() {}

  // 某个学生-某个学期-历史课程列表
  @url('/develop/history/stu/course/page')
  @post
  courseList() {}

  // 某个学生-某个学期-历史课程-任务列表(废除)
  @url('/develop/history/stu/course/listTask')
  @post
  courseListTask() {}

  // 历史学期列表
  @url('/develop/history/term/list')
  @post
  termList() {}

  // 历史学期数据归档
  @url('/develop/history/term/syncHistory')
  @postJson
  syncHistory() {}

  // 获取归档步骤的状态
  @url('/develop/history/term/listStepExecution')
  @post
  listStepExecution() {}

  // 某个用户-查询场景数据量
  @url('/develop/history/scene/itemData/getSceneDataList')
  @post
  getSceneDataList() {}

  // 获取场景下的任务信息
  @url('/develop/history/scene/task/list')
  @post
  sceneListTask() {}

  // 获取场景下的过程与任务信息
  @url('/develop/history/scene/itemData/getSceneItemAndTaskList')
  @post
  getSceneItemAndTaskList() {}

  // 某个用户-查询子过程数据量
  @url('/develop/history/scene/itemData/getSceneItemDataList')
  @post
  getSceneItemDataList() {}

  // 用户的-过程数据列表
  @url('/develop/history/scene/listByUserId')
  @post
  listByUserId() {}

  // 查询表单数据量
  @url('/develop/history/scene/itemData/getFormDataList')
  @post
  getFormDataList() {}

  // 证书列表
  @url('/develop/history/cert/getMyAcquireCertificateList')
  @postJson
  getMyAcquireCertificateList() {}

  // 历史年级、班级
  @url('/develop/history/org/list')
  @post
  getOrgList() {}

  // 历史学生明细
  @url('/develop/history/stu/detail')
  @post
  stuDetail() {}

  // 运营平台-学校列表
  @url('/develop/oper/school/list')
  @get
  schoolList() {}

  // 运营平台-所有学期列表（学期带是否归档的标识）
  @url('/develop/history/term/listTermWithHistory')
  @postJson
  listTermWithHistory() {}

  // 毕业生历史学生列表
  @url('/develop/history/stu/list/graduate')
  @post
  graduate() {}

  // 某个学生-所有学期-历史课程列表(毕业生课程)
  @url('/develop/history/stu/course/allList')
  @post
  courseAllList() {}

  // 毕业年级列表
  @url('/develop/graduate/org/nj/list2')
  @post
  getNjList() {}

  // 毕业班级列表
  @url('/develop/graduate/org/bj/list')
  @post
  getBjList() {}

  // 某个学生-所有学期-历史证书列表(毕业生课程)
  @url('/develop/history/cert/pageHistoryCerts')
  @post
  pageHistoryCerts() {}

  // 学校下已经归档的学期
  @url('/develop/history/term/listHistoryWithTerm')
  @postJson
  listHistoryWithTerm() {}

  // 历史学期数据归档-手工删除原始库的数据
  @url('/develop/history/term/syncHistory/deleteSyData')
  @postJson
  deleteSyData() {}
}
export default new HistoryMag()
