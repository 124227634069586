/*
* 表单字段数据转换
*/

import FormType from '../const/FormType'
import DoArray from './DoArray'
import DoUrl from './DoUrl'
import qs from 'querystring'
import axios from '../service/AxiosInterceptors'
// import { ItemPrivateJson } from '../coms-web/form-making/js/FieldConst'
class FormConvert {
  // 默认值增加
  doDefaultValue(data, isQueryShow) {
    if (!data || !data.itemInfos) return data
    let tmpData = JSON.parse(JSON.stringify(data))
    // 定制表单字段
    this.doCustomForm(tmpData.itemInfos)
    // 默认值等处理
    tmpData.itemInfos && tmpData.itemInfos.forEach((item) => {
      // 增加val
      if (!item.val && item.val !== 0) {
        // 增加文本默认值
        if (item.bz && (item.type == 8 || item.formItemType == 8)) {
          item.val = item.formItemValue = item.bz
        } else item.val = ''
      }
      // 增加强制修改字段状态fieldStatus，方便定制
      if (!item.fieldStatus) item.fieldStatus = ''
      // TODO 增加文本默认值
      if (item.bz && (item.type == 8 || item.formItemType == 8)) {
        item.val = item.formItemValue = item.bz
      }
      // 处理枚举进度条信息
      if (FormType.EnumProgress.find(type => type == item.type) && item.typeJson) this.doEnumProgress(item)
      // 枚举默认值处理
      this.doEnumDefault(item)
      // TODO 回转 item.val
      if (item.val && typeof (item.val) === 'object') {
        item.val = JSON.stringify(item.val)
      }
      let typeJson = item.typeJson
      if (typeJson && typeof (typeJson) === 'string') {
          item.typeJson = JSON.parse(typeJson)
      }
      // 默认值
      if (!item.val && item.val !== 0 && (item.defaultVal || ((item.typeJson && item.typeJson.defaultVal) || (item.typeJson && item.typeJson.constantValue)))) {
          item.val = item.defaultVal || (item.typeJson && item.typeJson.defaultVal) || (item.typeJson && item.constantValue)
      }
      // 审核自定义字段加上citeDataType(是否引用数据 0否 1是)
      if (!item.citeDataType) item.citeDataType = 0
      // eos 系统字段
      if (item.eosType && item.eosType != 1004 && item.operateType) {
        item.operateType = item.operateType.toString()
      }
    })
    // 增加表单属性 selected 表单项 选中
    tmpData.itemInfos = this.addProps(tmpData.itemInfos)
    // 处理引用数据
    tmpData.itemInfos = this.doCiteData(tmpData.itemInfos)
    // 模板标题显示控制
    if (tmpData.setting) {
      tmpData.setting.isTemplateShow = tmpData.setting.isTemplate == 'Y'
    }
    // 表单显隐 控制
    this.doFormShowCtrl(tmpData.itemInfos)
    // 解析exJson
    tmpData = this.parseExJson(tmpData)
    // 默认值 状态 以及 分栏字段 递归处理
    tmpData.itemInfos = this.doRecursiveData(tmpData.itemInfos, isQueryShow)
    return tmpData
  }

  /**
   * 处理枚举进度条信息
   * @param {*} item 字段信息
   */
  doEnumProgress(item) {
    let typeJson = item.typeJson
    try {
      if (typeJson && typeof (typeJson) === 'string') typeJson = JSON.parse(typeJson)
      let hasMaxCount = item.optionInfos?.find(option => option.maxCount && option.maxCount > 0)
      item.showProgress = false
      // 是否满足展示滚动条
      if ((hasMaxCount && typeJson?.progressNum == '2') || typeJson?.progressRatio == '2') {
        item.showProgress = true
      }
      if (hasMaxCount) {
        item.optionInfos.map(option => option.hasMaxCount = true)
      }
    } catch (err) {
      console.log(err, item.typeJson)
    }
  }

  /**
   * 默认值 状态 以及 分栏字段 递归处理
   * @param tmpData
   * @param isQueryShow 查询数据，表格展示不进行默认值处理
   */
  doRecursiveData(formList, isQueryShow) {
    if (!formList || !formList.length) return formList
     let addChild = (dataList) => {
      dataList.forEach(ele => {
        // TODO 增加功能权限和数据权限
        ele.funcCheck = [false, false, false, false]
        ele.dataCheck = [false, false, false, false]
        // 默认状态处理，showState 3,只读，为query状态
        if (ele.showState == 3 && !ele.fieldStatus) ele.fieldStatus = 'Query'
        // showState为1,状态为Query，清空，变成默认状态
        if (ele.showState == 1 && ele.fieldStatus === 'Query')ele.fieldStatus = ''
        // 默认值
        let typeJson = ele.typeJson
        if (typeJson && typeof (typeJson) === 'string') {
          try {
            ele.typeJson = JSON.parse(typeJson)
          } catch (e) {
            console.log('ele', ele)
          }
        }
        // 默认值
        if (!isQueryShow && !ele.val && ele.val !== 0 && (ele.defaultVal || ele.typeJson && (ele.typeJson.defaultVal || ele.typeJson.constantValue))) {
          ele.val = ele.defaultVal || (ele.typeJson && ele.typeJson.defaultVal) || (ele.typeJson && ele.typeJson.constantValue)
        }
        // 增加文本默认值
        if (ele.bz && (ele.type == 8 || ele.formItemType == 8)) {
          ele.val = ele.formItemValue = ele.bz
        }
        // 应用工厂选人插件默认值提前处理，因为执行规则需要用到默认值
        let choiceJson = ele?.formItemChoiceInfo?.limitids
        if (!isQueryShow && !ele.optionInfos?.length && !ele.val && choiceJson?.defaultType) {
          // defaultType 1与设置的选择范围相同 2 自定义
          let selectList = []
          if (choiceJson?.defaultType == 1 && choiceJson?.limitList?.length) {
            selectList = choiceJson?.limitList
          }
          if (choiceJson?.defaultType == 2 && choiceJson?.defaultList?.length) {
            selectList = choiceJson?.defaultList
          }
          ele.val = DoArray.doArrayValue(selectList, 'id')?.toString() || ''
        }
        // 枚举默认值处理
       !isQueryShow && this.doEnumDefault(ele)
        // 38 分栏  // 递归添加 columnRate 分栏比例
        if (ele.type == 38 && ele.typeJson.columnRate) {
          ele.typeJson.columnRate = ele.typeJson.columnRate + ''
          let rates = ele.typeJson.columnRate.split(':')
          ele.childItem && ele.childItem.forEach((child, index) => {
            child.columnRate = rates[index] ? rates[index] : 1
          })
        }
        if (ele.childItem) {
          addChild(ele.childItem)
          // 分组字段处理
          this.doGroupData(ele)
        }
      })
    }
    addChild(formList)
    return formList
  }

  /**
   * 枚举默认值处理
   * @param item
   */
  doEnumDefault(item) {
    // 枚举 optionInfos选项增加check属性 isDefault =Y
    if (item.optionInfos && [4, 5, 16, 17].find(formType => formType == item.type)) {
      item.optionInfos.map((option) => {
        option.checked = false
        // 添加自定义内容值
        if (!option.customVal) option.customVal = null
        // 默认选项
        if (option.isDefault === 'Y') {
          // 新增时 默认选项需要赋值
          // TODO 暂时通过url里有没有 formValId 判断是新增还是查询
          if (!DoUrl.getSearchByName('formValId') && !DoUrl.getSearchByName('formValueId')) {
           option.checked = true
          }
        }
      })
      // TODO 暂时通过url里有没有 formValId 判断是新增还是查询
      if (!DoUrl.getSearchByName('formValId') && !DoUrl.getSearchByName('formValueId')) {
        if (!item.val) {
          let optionInfos = item.optionInfos.filter((option) => option.checked)
          if (optionInfos && optionInfos.length > 0) item.val = JSON.stringify(optionInfos)
        }
      }
      if (item.val) {
        // 这个try catch是为了防止分组字段里面枚举字段报错,导致dataSee分页失效逻辑不走(二师数字校园应用中心过程数据体温上报)
        try {
          const val = typeof (item.val) === 'object' ? item.val : JSON.parse(item.val)
          item.optionInfos && item.optionInfos.forEach((item) => {
            item.checked = false
              val.forEach((valItem) => {
                if (valItem && item.id == valItem.id) {
                  item.checked = true
                  item.customVal = valItem.customVal
                }
              })
          })
        } catch (e) {
          // console.log(e)}
        }
      }
    }
    return item
  }

  /**
   * 处理分组表单
   * @param ele
   */
  doGroupData(ele) {
    // 分组字段，需要增加 多个
    if (ele.type === '32') {
      // 增加分组表单的人数据集合
      let childItem = ele.childItem ? JSON.parse(JSON.stringify(ele.childItem)) : []
      // 增加 valOther，用户存放手机验证码等
      childItem.forEach((item) => {
        item.valOther = ''
      })
      let tableColumn = []
      childItem.forEach(element => {
        let column = {}
        if (element?.itemValue) element.recordNum = element.itemValue.recordNum
        if (element.recordNum) {
          if (tableColumn.length == 0) {
            column.recordNum = element.recordNum
            column.tableColumn = []
            column.tableColumn.push(element)
            tableColumn.push(column)
          } else {
            let has = false
            tableColumn.forEach(atem => {
              if (atem.recordNum == element.recordNum) {
                has = true
                atem.tableColumn.push(element)
              }
            })
            if (!has) {
              column.recordNum = element.recordNum
              column.tableColumn = []
              column.tableColumn.push(element)
              tableColumn.push(column)
            }
          }
        }
      })
      if (tableColumn.length == 0) {
        if (!ele.typeJson.groupShowType || ele.typeJson.groupShowType == 1) {
          ele.countList = [{
            pid: ele.id,
            isUpdate: false,
            recordNum: 0,
            childItem: childItem
          }]
        } else {
          ele.countList = []
        }
      } else {
        ele.countList = []
        tableColumn.forEach(ctem => {
          ele.countList.push({
            pid: ele.id,
            isUpdate: true,
            recordNum: ctem.recordNum,
            childItem: ctem.tableColumn
          })
        })
      }
    }
  }
  /**
   * 表单显隐 控制
   * @param formList
   */
  doFormShowCtrl(formList) {
    // terminal 1 两端可见 2：pc，3：移动
    // TODO 先通过 url判断终端
    formList.forEach((item, index) => {
      if (item.terminal == 3 && /app-preview\?/.test(location.href)) {
        formList.splice(index, 1)
      }
      if (item.terminal == 2 && /app-preview-m\?/.test(location.href)) {
        formList.splice(index, 1)
      }
    })
  }
  /**
   * 定制表单字段
   * "dbItemName": "val1",
   "formItemType": "16",
   "fieldStatus": "Query" 字段状态
   * @param data
   */
  doCustomForm(data) {
    let customForm = VueInstance.$route.query.customForm ? JSON.parse(decodeURIComponent(VueInstance.$route.query.customForm)) : ''
    if (customForm) {
      data && data.forEach((item) => {
        let findObj = DoArray.searchArrayObject(customForm, item.formItemDbName, 'dbItemName')
        // 字段赋值
        if (findObj) {
          if (findObj.fieldStatus) item.fieldStatus = findObj.fieldStatus
          if (!item.val) item.val = findObj.val || ''
        }
      })
    }
    return data
  }
  // 列表数据处理
  doListDefaultValue(list, props = 'formItemInfoVoList') {
    if (!list) return
    list.forEach((item) => {
      item[props] = this.doDefaultValue({ itemInfos: item[props] }).itemInfos
    })
    return list
  }
  // 处理引用数据
  doCiteData(data) {
    data && data.forEach((item) => {
      // 空数据去除
      if (!item.listCiteData) item.listCiteData = []
      if (!item.citeDataVoList) item.citeDataVoList = []
      item.listCiteData = item.listCiteData && item.listCiteData.filter((item) => item.val || item.formItemVal)
      if (item.citeDataVoList) {
        item.listCiteData = item.citeDataVoList && item.citeDataVoList.filter((item) => {
          item.val = item.val || item.formItemVal
          return item.val
        })
      }
      // 测试数据 假数据
      // item.listCiteData = item.listCiteData.concat(item.listCiteData).concat(item.listCiteData).concat(item.listCiteData).concat(item.listCiteData).concat(item.listCiteData).concat(item.listCiteData)
      // item.citeDataVoList = item.citeDataVoList.concat(item.citeDataVoList).concat(item.listCiteData).concat(item.listCiteData).concat(item.listCiteData).concat(item.listCiteData)
      // item.showImgUrl = false
    })
    return data
  }

  // typeJson 需要存成字符串
  saveItemPrivateJson(data) {
    const tmpField = JSON.parse(JSON.stringify(data))
    this.recursiceChildItem(tmpField)
    return tmpField
  }
  // 多层级childItem 递归处理
  recursiceChildItem(data) {
    if (data.typeJson) {
      if (typeof (data.typeJson) === 'object') data.typeJson = JSON.stringify(data.typeJson)
    } else {
      // const typeName = FormType.list[tmpField.type].typeJson
      // if (ItemPrivateJson[typeName]) tmpField.typeJson = JSON.stringify(ItemPrivateJson[typeName])
      data.typeJson = [29, 31].includes(data?.type) ? JSON.stringify({ maxSize: 130 }) : [6].includes(data?.type) ? JSON.stringify({ imgFormatValList: [], isCompress: 1 }) : '{}'
    }
    data.childItem && data.childItem.map((item) => {
      this.recursiceChildItem(item)
    })
  }
  // 增加表单属性 selected 表单项 选中
  addProps(data) {
      data && data.forEach((item) => {
          item.selected = false
          // 分组表单
          item.childItem && item.childItem.map((child) => {
            // TODO 目前只对分组表单有效
            if (child.type == '32') {
              item.childItem.forEach(child => {
                child.showState = child.showState || item.showState
              })
            }
            child.selected = false
            // 子级 分组表单标识
            child.isChild = true
            // 增加val
            if (!child.val && child.val !== 0) {
              // 增加文本默认值
              if (child.bz && child.type == 8) child.val = child.bz
              else child.val = ''
            }
            // TODO 增加文本默认值
            if (child.bz && child.type == 8) child.val = child.bz
            if (child.typeJson && typeof (child.typeJson) === 'string') {
              // TODO 容错处理
              try {
                child.typeJson = JSON.parse(child.typeJson)
              } catch (e) {
                if (child.typeJson == '{hideTitle=false}') child.typeJson = {}
              }
              // 默认值
              if (!child.val && child.val !== 0 && child.typeJson.defaultVal) {
                child.val = child.typeJson.defaultVal
              }
            }
          })
      })
      return data
  }
  // 扩展的字段从ExJson读取出来，放入字段中
  parseExJson(data) {
    // exJson数据处理
    function doExJsonProps(item, exJson) {
      // 素养引用数据
      item.apiMethodRefId = exJson.apiMethodRefId || ''
      // 过滤筛选
      item.filterInfo = exJson.filterInfo || ''
      item.rangeObjList = exJson.rangeObjList || []
      // 选人
      item.formItemChoiceInfo = exJson.formItemChoiceInfo || {}
      // 递归
      item.childItem && item.childItem.map((child) => {
        if (child.exJson) doExJsonProps(child, JSON.parse(child.exJson))
      })
    }
    // typeJson数据处理  https://www.tapd.cn/36839446/markdown_wikis/?#1136839446001003834
    function doTypeJsonProps(item, typeJson) {
      typeJson = typeof typeJson === 'string' ? JSON.parse(typeJson) : typeJson
      // 数字字段 扩展项
      if (FormType.Digital.indexOf(parseInt(item.type)) > -1) {
        item.inputInterval = typeJson.inputInterval || '0'
        item.displayType = typeJson.displayType || 0
        item.rangeMax = typeJson.rangeMax || typeJson.rangeMax == 0 ? Number(typeJson.rangeMax) : null
        item.rangeMin = typeJson.rangeMin || typeJson.rangeMin == 0 ? Number(typeJson.rangeMin) : null
        item.inputLimit = typeJson.inputLimit || 1
        // 默认手动设置常量
        item.fieldValue = typeJson.fieldValue ? parseInt(typeJson.fieldValue) : 2
        item.constantValue = typeJson.constantValue || ''
      }
      // 选人插件
      if (item.type == 10 && typeJson.formItemChoiceInfo) {
        item.formItemChoiceInfo = typeJson.formItemChoiceInfo
        // TODO 应用工厂转成对象处理
        if (/no-code/.test(location.href) && item.formItemChoiceInfo) {
          if (!item.formItemChoiceInfo.limitids) item.formItemChoiceInfo.limitids = {}
          else if (typeof item.formItemChoiceInfo.limitids !== 'object') item.formItemChoiceInfo.limitids = JSON.parse(item.formItemChoiceInfo.limitids)
        }
      }
      return item
    }

    function bianLiChildItem(item) {
      item.childItem && item.childItem.map((child) => {
        // 是否重复 取反
        child.isRepeat = !child.isRepeat
        if (child.eosType == 10002 || child.eosType == 10004) {
          child.isRepeat = false
        }
        if (child.exJson) {
          // 素养引用数据
          const exJson = JSON.parse(child.exJson)
          doExJsonProps(child, exJson)
        }
        if (child.typeJson) {
          // typeJson 数据处理
          child = doTypeJsonProps(child, child.typeJson)
        }
        // 分组表单&&遍历&&容器 doTypeJsonProps处理
        if (['32', '45', '46', '39'].includes(child.type)) {
          bianLiChildItem(child)
        }
      })
    }
    data.itemInfos && data.itemInfos.forEach((item) => {
      // 是否重复 取反
      item.isRepeat = !item.isRepeat
      if (item.eosType == 10002 || item.eosType == 10004) {
        item.isRepeat = false
      }
      if (item.exJson) {
        // 素养引用数据
        const exJson = JSON.parse(item.exJson)
        doExJsonProps(item, exJson)
      }
      if (item.typeJson) {
        // typeJson数据处理
        item = doTypeJsonProps(item, item.typeJson)
      }
      // 分组表单&&遍历&&容器 doTypeJsonProps处理
      if (['32', '45', '46', '39'].includes(item.type)) {
        bianLiChildItem(item)
      }
    })
    return data
  }
  // 单个 扩展的字段从存入ExJson
  saveExJson(data) {
    let item = {}
    if (data) item = JSON.parse(JSON.stringify(data))
    this.recursiceChildItem(item)
      // 是否重复 取反
      item.isRepeat = !item.isRepeat
      // 选项自定义处理
      item.optionInfos && item.optionInfos.forEach(item => {
        item.custom = item.custom === true || item.custom == '1' ? '1' : '0'
        // 选项值处理
        if (item.name) item.val = item.name
      })
      // 扩展数据处理
      item = this.doExJsonData(item)
      // 分组表单
      if (item.type === '32') {
        item.childItem && (item.childItem = item.childItem.map((child) => {
          // 是否重复 取反
          child.isRepeat = !child.isRepeat
          // 扩展数据处理
          child = this.doExJsonData(child)
          if (child.typeJson && (typeof (child.typeJson) === 'object')) {
            child.typeJson = JSON.stringify(child.typeJson)
          }
          return child
        }))
      }
      // 保存 选人插件处理
      if (item.typeJson && item.type == 10) {
        if (typeof (item.typeJson) !== 'object') {
          item.typeJson = JSON.parse(item.typeJson)
        }
        // 系统字段选择部门单独处理
        if (item.eosType == '10003') {
          item.typeJson.formItemChoiceInfo.type = 3
        }
        // TODO 应用工厂 初始化选人插件为学生, 99为应用工厂选人插件标识
        if (/no-code/.test(location.href)) {
          if (!item.typeJson.formItemChoiceInfo || !item.typeJson.formItemChoiceInfo.type) {
            item.typeJson.formItemChoiceInfo = {}
            item.typeJson.formItemChoiceInfo.type = 99
            // 当前选择的类型，默认学生
            item.typeJson.formItemChoiceInfo.currentType = 1
            // 当前范围限定，默认学生
            item.typeJson.formItemChoiceInfo.limitType = 1
            item.typeJson.formItemChoiceInfo.limitids = {
              type: '1'
            }
            // 限定范围列表
            item.typeJson.formItemChoiceInfo.limitList = []
          }
          item.exJson = JSON.stringify(item.typeJson)
        }
      }
      if (item.typeJson && typeof (item.typeJson) === 'object') item.typeJson = JSON.stringify(item.typeJson)
      return item
  }
  // 扩展数据处理
  doExJsonData(data) {
    let item = JSON.parse(JSON.stringify(data))
    let exJson = {}
    let typeJson = {}
    if (item.exJson) exJson = JSON.parse(item.exJson)
    // TODO json转换可能出错，先刷新处理
    try {
      if (item.typeJson && typeof item.typeJson == 'object') typeJson = JSON.parse(JSON.stringify(item.typeJson))
      else if (item.typeJson) typeJson = JSON.parse(item.typeJson)
    } catch (error) {
      window.location.reload()
    }
    // 素养引用数据
    if (item.filterInfo) exJson.filterInfo = item.filterInfo
    if (item.formItemChoiceInfo) exJson.formItemChoiceInfo = item.formItemChoiceInfo
    // 素养 api对接数据
    if (item.apiMethodRefId) exJson.apiMethodRefId = item.apiMethodRefId
    exJson.dataRangeType = item.dataRangeType
    // if (item.listApiUrl) exJson.listApiUrl = item.listApiUrl
    // 数据范围
    if (item.rangeObjList) {
      exJson.rangeObjList = item.rangeObjList
      let ids = []
      item.rangeObjList.forEach(item => {
        ids.push(item.id)
      })
      item.dataRangeIds = ids.join(',')
    }
    // 系统字段选择部门单独处理
    if (item.eosType == '10003') {
      exJson.formItemChoiceInfo.type = 3
    }
    // 系統字段拓展表单字段  存储关联类型、用户或角色类型、所属表单id及code
    if (item.eosType === '10005' && item.fieldTypeId && item.exFieldCode) {
      exJson.exUserType = item.exUserType
      exJson.userTypeId = item.userTypeId
      exJson.fieldTypeId = item.fieldTypeId
      exJson.exFieldCode = item.exFieldCode
      exJson.exFieldName = item.exFieldName
      exJson.ismultiselect = item.ismultiselect
      exJson.types = item.types
    }
    item.exJson = JSON.stringify(exJson)
    // 数字字段 扩展项
    if (FormType.Digital.indexOf(parseInt(item.type)) > -1) {
      typeJson.inputInterval = item.inputInterval
      typeJson.displayType = item.displayType
      typeJson.rangeMax = parseFloat(item.rangeMax)
      typeJson.rangeMin = parseFloat(item.rangeMin)
      typeJson.inputLimit = item.inputLimit
      typeJson.constantValue = item.constantValue
    }
    // 选人插件 扩展项
    if (item.type == 10) {
      typeJson.formItemChoiceInfo = item.formItemChoiceInfo
    }
    // 样式等自定义
    if (['1', '9', '15', '16'].includes(item.type)) {
      // typeJson.isBlock = item.isBlock
      if (item.type == '1') item.val = typeJson.defaultVal
      item.typeJson = typeJson
    }
    item.typeJson = typeJson
    return item
  }
  // 数据应用去掉小数点后面的0
  removeZero(val, type) {
      if (/(?:\.0*|(\.\d+?)0+)$/.test(val)) {
          return val.split('.')[0]
      } else {
          return val
      }
  }
  /*
     * 状态控制
     * 自由审批表单权限 是怎么处理，提交人填，其他人只能查看
     * 固定审批走权限控制
     * @param formList,表单List
     * @param formStateList,固定审批, 权限List
     * @info.type 1 自由审批，2固定审批, info.formState，字段状态：Add Query
     * @operator true false, 是否可操作， 不可操作状态下，只判断 字段权限1(只读),3(隐藏)，不考虑编辑状态
     * @filterHide 是否过滤
     */
  doFormState(formList, formStateList, info, operator, filterHide = true) {
      let resData = this.doDefaultValue({ itemInfos: formList }, true)
      let tmpFormList = []
      if (info.type == 1) { // 1 自由审批
        if (info.formState) {
          resData.itemInfos.forEach((formItem) => {
            formItem.state = info.formState
          })
        }
        tmpFormList = resData.itemInfos
      } else if (info.type == 2) { // 2固定审批
        // 按字段显示，然后根据权限进行控制
        resData.itemInfos.forEach((formItem, index) => {
          // 查找字段权限列表，找不到直接赋值Query
          let auth = formStateList.filter(stateItem => stateItem.formItemId == formItem.id)
          if (!auth.length || auth[0].type == 1) {
            formItem.state = 'Query'
            tmpFormList.push(formItem)
          } else {
            if (auth[0].type == 2) {
              formItem.state = operator ? 'Add' : 'Query'
              tmpFormList.push(formItem)
            } else if (auth[0].type == 3 && !filterHide) {
              // 审批时，隐藏字段也需要提交（仅供审批提交取值用）
              formItem.state = 'Query'
              tmpFormList.push(formItem)
            }
          }
        })
      }
      return tmpFormList
    }
  doFormStateQuery(formList, info) {
    let resData = this.doDefaultValue({ itemInfos: formList }, true)
    if (info.formState) {
      resData.itemInfos.forEach((formItem) => {
        formItem.state = info.formState
      })
    }
    return resData.itemInfos
  }
  // 根据字段type 查找字段类型
  getCategoryByFormType(type) {
    return FormType.formCategory[FormType.list[type].catagory] || ''
  }
  // 用户信息显示控制
  showUserInfo(formItem) {
    //  showRefUserFlag 0 自动 1 显示  2不显示
    let showInfo = formItem.showImgUrl
    // if(formItem.citeDataType == 1 && formItem.listCiteData) {
    //   let citeData = formItem.listCiteData
    //   for(let i=0; i<citeData.length; i++) {
    //     if(citeData[i].userId) {
    //       showInfo = true
    //       break
    //     }
    //   }
    // }
    let typeJson = formItem.typeJson ? JSON.parse(formItem.typeJson) : {}
    if (typeJson.showRefUserFlag == 1) showInfo = true
    if (typeJson.showRefUserFlag == 2) showInfo = false
    return showInfo
  }
  //  去掉特殊表情等 ET异类
  removeET(value) {
    if (!value) return value
    return value.replace(/[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/g, '')
  }
  // 处理提交数据
  doSubmitData(formInfo) {
    const submitData = []
    // 把全字段中可操作性字段替换，方便取值
    let itemInfosAll = []
    formInfo?.itemInfosAll?.forEach(v => {
      let field = formInfo.itemInfos.filter(m => m.id == v.id)
      itemInfosAll.push(field.length > 0 ? field[0] : v)
    })
    let itemInfos = itemInfosAll.length ? itemInfosAll : formInfo.itemInfos
    itemInfos && itemInfos.forEach((item) => {
      // // 枚举字段选项自定义回传
      // item.optionInfos.map((item) => {
      //     // 默认选项
      //     if (item.isDefault === 'Y') item.checked = true
      // })
      // 分组表单 增加 recordNum
      if (item.type === '32' && item.eosType !== '10005') {
        // countList 为分组表单个数
        item.countList && item.countList.map((count, countIndex) => {
          count.childItem && count.childItem.map((child, childIndex) => {
            submitData.push({
              // id: item.id,
              itemId: child.id,
              val: child.val || '',
              itemType: child.type,
              valOther: child.secret || child.valOther || '',
              recordNum: countIndex
            })
          })
        })
      } else if (item.type === '32' && item.eosType === '10005') {
        // countList 为分组表单个数
        item.countList && item.countList.map((count, countIndex) => {
          count.childItem && count.childItem.map((child, childIndex) => {
            submitData.push({
              // id: item.id,
              itemId: child.id,
              val: child.val || '',
              itemType: child.type,
              valOther: child.secret || child.valOther || '',
              recordNum: child.recordNum || child.recordNum == '0' ? child.recordNum : countIndex
            })
          })
        })
      } else if (item.type === '45') {
        item.childItem && item.childItem.map((child, childIndex) => {
          submitData.push({
            id: child.itemValue?.id || '',
            itemId: child.id,
            val: child.val || '',
            itemType: child.type,
            valOther: child.secret || child.valOther || '',
            recordNum: 0
          })
        })
      } else {
        let submitIds = DoArray.doArrayValue(submitData, 'itemId')
        if (!submitIds.includes(item.id)) {
          submitData.push({
            id: item.itemValue?.id,
            itemId: item.id,
            val: item.val || '',
            itemType: item.type,
            valOther: item.secret || item.valOther || '',
            recordNum: 0,
            apiUrlInfos: item.apiUrlInfos,
          })
        }
      }
    })
    return submitData
  }
  // 应用工厂处理提交数据
  doSubmitDataNoCode(formInfo) {
    let submitData = []
    // 分组字段id集合
    let groupIds = []
    formInfo.itemInfos && formInfo.itemInfos.forEach((item) => {
      // // 枚举字段选项自定义回传
      // item.optionInfos.map((item) => {
      //     // 默认选项
      //     if (item.isDefault === 'Y') item.checked = true
      // })
      // 分组表单 增加 recordNum
      if (item.type === '32') {
        groupIds.push(item.id)
        // countList 为分组表单个数
        item.countList && item.countList.map((count, countIndex) => {
          count.childItem && count.childItem.map((child, childIndex) => {
            const newitem = {
              refId: child.id,
              val: child.val || '',
              name: child.name,
              valOther: item.valOther || '',
              recordNum: countIndex,
              tableValId: child.tableValId || '',
              itemType: child.type,
            }
            if (item.showState == 4) newitem.isHide = true // 不显示-后端必填不校验
            // else if ($(`#module_${child['id']}`).length === 0) newitem.isHide = true // 不显示-后端必填不校验
            submitData.push(newitem)
          })
        })
      } else {
        const newitem = {
          refId: item.id,
          val: item.val || '',
          name: item.name,
          valOther: item.valOther || '',
          recordNum: 0,
          pid: item.pid,
          tableValId: item.tableValId || '',
          optionInfos: item.optionInfos ? item.optionInfos : null,
          itemType: item.type,
        }
        if (item.showState == 4) newitem.isHide = true
        // else if ($(`#module_${item['id']}`).length === 0) newitem.isHide = true // 不显示-后端必填不校验
        submitData.push(newitem)
      }
    })
    // 分组字段放重了，只放countList里的数据，扁平化的数据需要去掉 groupId == item.pid
    submitData = submitData.filter(item => !groupIds?.find(groupId => groupId == item.pid))
    return submitData
  }

  /**
   * 处理提交数据
   * 根据遍历数据条数组数据
   * {
      submitId: submitId,
      tableValId: tableValId,
      userId: userId,
      wxCDataFieldParamList: [],
    }
   * @return {Promise<void>}
   */
  getWxCDataTableParamList(formList, formFlatList, variableList, paramsObj, comsItem, ruleType) {
    const isMultiOpera = comsItem?.typeJson?.buttonType === 'multiOpera' // 是否批量操作
    // 批量按钮-批量变量处理
    const items = paramsObj.wxCDataFieldParamList
    const multiAriableList = {}
    if (isMultiOpera) {
      const tableValIds = comsItem.tableValId
      items.forEach(v => {
        if (tableValIds.includes(v.tableValId)) {
          if (!(v.refId in multiAriableList)) multiAriableList[v.refId] = { valArray: [], batchVariable: 1 }
          multiAriableList[v.refId].valArray.push(v.val)
        }
      })
    }
    // 查找遍历，如果没有遍历组一条数，有遍历根据遍历数据组
    // TODO 先查找第一个，多个遍历先不支持
    let findObj = DoArray.getRecursiveNode(formList, 'type', 46, 'childItem')
    // 批量变量处理
    paramsObj.wxCDataFieldParamList = paramsObj.wxCDataFieldParamList.map(field => {
      if (field.refId in multiAriableList) {
        field = {
          ...field,
          ...multiAriableList[field.refId]
        }
      }
      return field
    })
    if (!findObj) return [paramsObj]
    // 有遍历根据遍历数据组
    let paramList = []
    // 非遍历字段
    let flatOtherList = formList && DoArray.flattenTreeData(formList, 'childItem', '46')
    findObj?.childItem.forEach((child, index) => {
      if (!paramList[index]) paramList[index] = _.cloneDeep(paramsObj)
      // 取遍历的数据id tableValId
      // todo 判断按钮是否在遍历里面，有-优先取遍历的tableValId，没有-取没paramsObj的tableValId
      if (comsItem?.id && JSON.stringify(findObj).includes(comsItem.id) && child.tableValId) paramList[index].tableValId = child.tableValId
      else paramList[index].tableValId = paramsObj.tableValId
      // 批量操作并且是删除 - 赋值为null
      if (isMultiOpera && ruleType == 4) paramList[index].tableValId = null
      // 获取遍历下的字段
      let fieldList = DoArray.flattenTreeData(child?.childItem, 'childItem', '46')
      paramList[index].wxCDataFieldParamList = []
      let allFieldList = flatOtherList.concat(fieldList)
      allFieldList.forEach(field => {
        // TODO 分组表单暂未处理
        let newItem = {
          refId: field.id,
          val: field.val || '',
          name: field.name,
          valOther: field.valOther || '',
          recordNum: 0,
          tableValId: child.tableValId || '',
          itemType: field.type,
          valArray: field.valArray || null,
          batchVariable: field.batchVariable || 0,

        }
        paramList[index].wxCDataFieldParamList.push(newItem)
      })
      // 变量
      variableList?.forEach(variable => {
        paramList[index].wxCDataFieldParamList.push({
          refId: variable.id,
          val: (variable.value instanceof Object ? JSON.stringify(variable.value) : variable.value) ?? variable.initValue,
          valOther: null,
          name: variable.name,
          refType: 'variable', // 仅前端查看添加，方便与字段区分
          valArray: variable.valArray || null,
          batchVariable: variable.batchVariable || 0, // 是否批量变量
        })
      })
    })
    return paramList
  }
  // 批量提交处理
  doBatchData(formList, taskInfo, exParams) {
    // 代人填写详情，需要从选人插件中取出归属人id
    let dataUserIds = []
    // 归属人类型：老师1 学生0
    let queryTypeList = []
    function getDataUserIds() {
      if (taskInfo.choiceItemInfo && taskInfo.choiceItemInfo.formItemChoiceInfo) {
        let formItemChoiceInfo = taskInfo.choiceItemInfo.formItemChoiceInfo
        if (!formItemChoiceInfo) return false
        formList.forEach((item) => {
          if (formItemChoiceInfo.id == item.id && item.val) {
            // 代老师和学生填写是json
            if (formItemChoiceInfo.type == 4) {
              let userList = JSON.parse(item.val)
              userList.forEach(user => {
                dataUserIds.push(user.userId || user.id)
                queryTypeList.push(user.userRole == 'tea' ? 1 : 0)
              })
            } else {
              // TODO 数组处理
              if (item.val.indexOf('[') > -1) {
                let userList = JSON.parse(item.val)
                userList.forEach(user => {
                  dataUserIds.push(user.userId || user.id)
                })
              } else {
                dataUserIds = item.val.split(',')
              }
              dataUserIds.forEach(user => {
                queryTypeList.push(formItemChoiceInfo.type == 2 ? 1 : 0)
              })
            }
          }
        })
      }
    }
    // 处理归属人
    getDataUserIds()
    // 表单项
    let submitParams = {
      formId: taskInfo.formId
    }
    submitParams.items = this.doSubmitData({ itemInfos: formList })
    let batchParams = []
    // 处理引用数据id
    let refFormValueId = ''
    let hasQuery = true
    formList.forEach(ele => {
      if (ele.queryFieldFlag && hasQuery) {
        hasQuery = false
        refFormValueId = ele.citeDataVoList && ele.citeDataVoList[0] && ele.citeDataVoList[0].formValueId
      }
    })
    if (dataUserIds && dataUserIds.length > 0) { // 代人填详情
      let belongInfos = []
      dataUserIds.forEach((item, index) => {
        belongInfos.push({ stuId: item, queryType: queryTypeList[index] })
      })
      submitParams.refFormValueId = refFormValueId || ''
      submitParams.exJson = exParams
      submitParams.exJson.belongInfos = belongInfos
      submitParams.exJson = JSON.stringify(submitParams.exJson)
      batchParams.push(submitParams)
    } else { // 自己填和代人填列表
      exParams.refFormValueId = refFormValueId || ''
      exParams.stuId = DoUrl.getSearchByNameHash('stuId') || ''
      submitParams.exJson = JSON.stringify(exParams)
      batchParams = [submitParams]
    }
    if (taskInfo.dataBelongType == 5) {
      // 归属人提交对象为人脸识别提交接口数据处理
      let newExJson = JSON.parse(batchParams[0].items[0].val, batchParams[0].exJson)
      let newExJsons = JSON.parse(batchParams[0].exJson)
      newExJsons.belongInfos = this.getFaceVal(newExJson)
      newExJsons.faceResult = batchParams[0].items[0].val
      batchParams[0].exJson = JSON.stringify(newExJsons)
    }
    return batchParams
  }
  // 归属人为人脸识别数据提取
  getFaceVal(list) {
    let nameArr = []
    list && list.forEach(item => {
      item.persons.forEach(ele => {
        nameArr.push({ stuId: ele.id, queryType: ele.type ^ 1 })
      })
      return nameArr
    })
    return nameArr
  }
  // 数字字段 placeholder提示
  doDigitalRangeText(item) {
      let textStart = '请输入'
    let textMiddle = ''
    let textEnd = item.inputLimit == 1 ? '正数' : item.inputLimit == 2 ? '负数' : '正负数'
    if (item.typeJson && (item.typeJson.rangeMin || item.typeJson.rangeMax)) {
      let minText = '-∞'
      let maxText = '∞'
      if (item.inputLimit == 1) minText = 0
      if (item.inputLimit == 2) maxText = 0
      textMiddle = ((item.typeJson.rangeMin || item.typeJson.rangeMin == 0) ? item.typeJson.rangeMin : minText) + '-' + ((item.typeJson.rangeMax || item.typeJson.rangeMax == 0) ? item.typeJson.rangeMax : maxText) + '的'
    }
    return textStart + textMiddle + textEnd
  }
  // 枚举字段值处理, 数据字典（级联单选，级联多选）
  getEnumFieldVal(data) {
    if (!data.length) return
    let list = []
    // 先扁平化处理
    data.forEach(item => {
      if (!['32', '45'].includes(item.type)) {
        list.push(item)
      } else {
        item.childItem.forEach(child => {
          list.push(child)
        })
      }
    })
    list && list.forEach(item => {
      if (['4', '5', '16', '17'].includes(item.type)) {
        if (item?.val?.includes('{')) {
          let nameArr = []
          let arr = JSON.parse(item.val).filter(ele => ele.checked)
          arr.forEach(ele => {
            // 自定义选项处理
            if (ele.custom == '1' && ele.customVal && ele.customVal != '') {
              ele.name = `${ele.name}:${ele.customVal}`
            }
            nameArr.push(ele.name)
          })
          item.val = nameArr.toString()
        }
      }
      if (['34', '35'].includes(item.type)) {
        if (item.val != '') {
          item.val = DoArray.doArrayValue(JSON.parse(item.val), 'label').toString()
        }
      }
    })
    return list
  }
  // 枚举字段数据表格兼容老数据显示 转换成json串
  compatibility(value, list) {
    let result = []
    let valArr = value.split(',')
    if (list instanceof Array) {
      // TODO 匹配一个数字为sort值
      if (/^\d{1}$/.test(value)) {
        list.forEach(item => {
          if (valArr.includes(item.sort.toString())) {
            result.push(item.name)
          }
        })
        return result.toString()
      } else {
        return value
      }
    } else {
      return value
    }
  }
  // 枚举填写值转换兼容
  convertEnumValue(item) {
      let val = item.val
      if (!val) return ''
      let list = []
      let lists = []
      if (val.includes('{')) {
        let valLIst = JSON.parse(val)
        if (!Array.isArray(valLIst)) valLIst = [valLIst]
        valLIst.forEach((valItem) => {
          item.optionInfos && item.optionInfos.forEach((optionItem) => {
            if (valItem.id == optionItem.id) {
              valItem.name = optionItem.name
            }
          })
          if (valItem.custom == 1 && valItem.customVal) {
            list.push(valItem.name + ':' + valItem.customVal)
          } else {
            list.push(valItem.name)
          }
        })
        // TODO, 枚举字段val值处理，强制增加 formId, itemId
        item.optionInfos && item.optionInfos.forEach((optionItem) => {
          valLIst.forEach((valItem) => {
            if (valItem.id == optionItem.id) {
              if (!valItem.formId || !valItem.itemId) {
                valItem.itemId = optionItem.itemId
                valItem.formId = optionItem.formId
                item.val = JSON.stringify(valLIst)
              }
              valItem.name = optionItem.name
              if (valItem.custom == 1) {
                valItem.name = optionItem.name + ':' + valItem.customVal
              }
              lists.push(optionItem)
            }
          })
        })
        // 枚举选项进度条信息赋值
        list = list.map((name, i) => ({
          name,
          cover: lists[i] ? lists[i].cover : '',
          count: lists[i] ? lists[i].count : '',
          currentRatio: lists[i] ? lists[i].currentRatio : '',
          maxCount: lists[i] ? lists[i].maxCount : '',
          hasMaxCount: lists[i]?.hasMaxCount || false
        }))
      } else {
          list = val.split(',')
      }
      return list
  }
  /**
   * 枚举字段值回显处理
   * @param {Array} opts 选项
   * @param {string} vals 选中值 json字符串
   */
  enumShowDeal(opts, vals) {
    let checkedArr = []
    let valsArr = []
    if (vals?.includes('{')) {
      checkedArr = JSON.parse(vals).filter(item => item.checked)
      valsArr = JSON.parse(vals)
    }
    let idArr = DoArray.doArrayValue(checkedArr, 'id')
    opts && opts.forEach(item => {
      let curVal = valsArr.find(val => val.id == item.id)
      if (idArr.includes(item.id)) {
        item.checked = true
      } else {
        item.checked = false
      }
      if (curVal && curVal.customVal) {
        item.customVal = curVal.customVal
      }
    })
    // return opts
  }

  /**
   * 恢复已缓存数据，引用的数据不需要处理
   */
  getCachData(formList, id) {
    let cachData = localStorage.getItem('taskFormList-' + id)
    if (!cachData) return formList
    cachData = JSON.parse(cachData)
    let tmpFormList = JSON.parse(JSON.stringify(formList))
    cachData.forEach((item) => {
      tmpFormList.forEach((formItem, index) => {
        // 支付明细等不用回显
        const notShowList = ['37']
        if (item.id == formItem.id && item.citeDataType == 0 && !formItem.val && formItem.val !== 0 && !notShowList.includes(item.type)) {
          tmpFormList[index] = { ...item }
        }
      })
    })
    return tmpFormList
  }

  /**
   * 根据归属人id获取分组字段API数据（业务逻辑比较明确，有一定局限）主要针对梅州需求
   * 注：直接改变数组，无返回值
   * @param {string} stuId 归属人id
   * @param {string} schoolId 学校id
   * @param {Array} list 表单列表
   */
  async getGroupFormApiByStuId(stuId, list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].type == '32' && list[i].isApi && list[i].apiUrlInfos && list[i].apiUrlInfos[0] && list[i].apiUrlInfos[0].url) {
        let info
        if (list[i].apiUrlInfos[0].paramJson.constructor === Object) {
          info = list[i].apiUrlInfos[0].paramJson
        } else {
          info = JSON.parse(list[i].apiUrlInfos[0].paramJson)
        }
        let APIParams = {
          sourceTaskCode: info.sourceTaskCode,
          targetTaskCode: info.targetTaskCode,
          sourceItemCodeStr: info.sourceItemCodeStr,
          targetItemCode: info.targetItemCode,
          userIds: stuId,
          schoolId: Cookies.get('schoolId'),
        }
        if (info.childItem == 1) {
          await axios.post(list[i].apiUrlInfos[0].url, qs.stringify(APIParams)).then(res => {
            if (res && res.length) {
              if (res[0].childItem && !res[0].countList) {
                res[0].countList = this.setGroupFormChildItem(res[0].childItem, res[0].id)
              }
              list.splice(i, 1, res[0])
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }
    }
  }
  /**
   * 分组表单字段根据recordNum分组
   * @param {Array} childItem 分组表单列表
   * @param {string} pid 父字段id
   */
  setGroupFormChildItem(childItem, pid) {
    let list = JSON.parse(JSON.stringify(childItem))
    if (list.length > 0) { // 根据recordNum处理提交批次
			let childDefaultValue = this.doDefaultValue({ itemInfos: list }).itemInfos
			let tableColumn = []
			childDefaultValue.forEach(element => {
				if (element.type == '10' && element.optionInfos) { // 选人插件表单中会返回id,但在表格中展示需要中文
					let personList = []
					element.optionInfos.forEach(person => {
						personList.push(person.name)
					})
					element.val = personList.toString()
				}
				let column = {}
				if (tableColumn.length == 0) {
					column.recordNum = element.recordNum
					column.childItem = []
          column.childItem.push(element)
          column.pid = pid
					tableColumn.push(column)
				} else {
					let has = false
					tableColumn.forEach(item => {
						if (item.recordNum == element.recordNum) {
							has = true
							item.childItem.push(element)
						}
					})
					if (!has) {
						column.recordNum = element.recordNum
						column.childItem = []
						column.childItem.push(element)
						tableColumn.push(column)
					}
				}
			})
			return tableColumn
		}
  }
  /**
   * 批量处理独立数据字段
   * @param {Array} formList // 表单数据
   * @param {Array} batchParams // 多选状态下已选归属人提交的表单
   * @param {string} personIds // 多选的归属人id
   * @param {Array} formListCopy // 原始表单数据
   * @param {string} schoolId // 当前学校id
   */
  async findSingleDataField(formList, batchParams, personIds, formListCopy, schoolId) {
    for (let i = 0; i < formList.length; i++) { // 找出需要处理独立数据的字段
      if (formList[i].typeJson.singleData) {
        // 处理引用，当前为外部处理，如遇到外部无法处理的情况可解开注释
        let refFormValueId = ''
        if (formList[i].queryFieldFlag) refFormValueId = formList[i].citeDataVoList && formList[i].citeDataVoList[0] && formList[i].citeDataVoList[0].formValueId
        batchParams.forEach(person => {
          if (typeof (person.exJson) !== 'object') person.exJson = JSON.parse(person.exJson)
          person.exJson.refFormValueId = refFormValueId || ''
          let exJson = JSON.stringify(person.exJson)
          person.exJson = exJson
        })
        // api对接
        if (formList[i].type == '32' && formList[i].isApi && formList[i].apiUrlInfos && formList[i].apiUrlInfos[0] && formList[i].apiUrlInfos[0].url) {
          let info = formList[i].apiUrlInfos[0].paramJson
          let APIParams = {
            sourceTaskCode: info.sourceTaskCode,
            targetTaskCode: info.targetTaskCode,
            sourceItemCodeStr: info.sourceItemCodeStr,
            targetItemCode: info.targetItemCode,
            userIds: '',
            schoolId: '',
          }
          if (info.userId == 1) APIParams.userIds = personIds.toString()
          if (info.schoolId == 1) APIParams.schoolId = schoolId
          if (info.childItem == 1) {
            // 获取api独立数据
            await axios.post(formList[i].apiUrlInfos[0].url, qs.stringify(APIParams)).then(res => {
              let resDatas = res
              if (resDatas && resDatas.length) {
                batchParams.forEach(perData => {
                  let hasField = false // 匹配成功标识
                  resDatas.forEach(field => {
                    if (field.dataBelongId == perData.stuId) { // 根据独立数据中的dataBelongId标识匹配
                      hasField = true
                      perData.items.splice(i, 1, field)
                    }
                  })
                  if (!hasField) { // 无匹配数据处理逻辑
                    perData.items.splice(i, 1, formListCopy[i])
                  }
                })
              }
              // batchParams.forEach(perData => { // 处理数据结构
              //   let fieldIndex = null
              //   for (let j = 0; j < perData.items.length; j++) {
              //     if (perData.items[j].id || !perData.items[j].itemId) {
              //       let liteField = {
              //         apiUrlInfos: perData.items[j].apiUrlInfos,
              //         itemId: perData.items[j].id,
              //         recordNum: perData.items[j].recordNum || '0',
              //         val: perData.items[j].val,
              //         valOther: perData.items[j].valOther,
              //       }
              //       if (perData.items[j].type == '32' && perData.items[j].childItem && perData.items[j].childItem.length) {
              //         fieldIndex = j
              //         liteField.childItem = perData.items[j].childItem
              //       }
              //       perData.items.splice(j, 1, liteField)
              //     }
              //   }
              //   if (fieldIndex !== null) {
              //     let childArr = []
              //     perData.items[fieldIndex].childItem.forEach(child => {
              //       childArr.push({
              //         apiUrlInfos: child.apiUrlInfos,
              //         itemId: child.id,
              //         recordNum: child.recordNum || '0',
              //         val: child.val,
              //         valOther: child.valOther,
              //       })
              //     })
              //     perData.items.splice(fieldIndex, 1, ...childArr)
              //   }
              // })
            }).catch(err => {
              console.log(err)
            })
          }
          // =======================
        }
      }
      batchParams.forEach(perData => { // 处理数据结构
        let fieldIndex = null
        for (let j = 0; j < perData.items.length; j++) {
          if (perData.items[j].id || !perData.items[j].itemId) {
            let liteField = {
              apiUrlInfos: perData.items[j].apiUrlInfos,
              itemId: perData.items[j].id,
              recordNum: perData.items[j].recordNum || '0',
              val: perData.items[j].val,
              valOther: perData.items[j].valOther,
            }
            if (perData.items[j].type == '32' && perData.items[j].childItem && perData.items[j].childItem.length) {
              fieldIndex = j
              liteField.childItem = perData.items[j].childItem
            }
            perData.items.splice(j, 1, liteField)
          }
        }
        if (fieldIndex !== null) {
          let childArr = []
          perData.items[fieldIndex]?.childItem?.forEach(child => {
            childArr.push({
              apiUrlInfos: child.apiUrlInfos,
              itemId: child.id,
              recordNum: child.recordNum || '0',
              val: child.val,
              valOther: child.valOther,
            })
          })
          perData.items.splice(fieldIndex, 1, ...childArr)
        }
      })
    }
  }
}
export default new FormConvert()
window.FormConvert = new FormConvert()
