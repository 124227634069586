import { post, url, postJson } from '../../service/ApiDecorator'
class DataVisualization {
  // 二师视力健康填报-视力异常排名
  @url('/v3/visual/ershi/getErshiEyesightForSchoolRankingCount')
  @post
  getErshiEyesightForSchoolRankingCount() {}

  // 二师视力健康填报-各学段视力统计
  @url('/v3/visual/ershi/getErshiEyesightCount')
  @post
  getErshiEyesightCount() {}

  // 二师视力健康填报-全区域学生视力情况分布-近视非近视情况
  @url('/v3/visual/ershi/getErshiEyesightAllAreaCount')
  @post
  getErshiEyesightAllAreaCount() {}

  // 二师视力健康填报-男女生近视情况对比
  @url('/v3/visual/ershi/getErshiEyesightAllAreaBySexCount')
  @post
  getErshiEyesightAllAreaBySexCount() {}

  // 二师视力健康填报-各批次近视情况
  @url('/v3/visual/ershi/getErshiEyesightAllAreaStuYear')
  @post
  getErshiEyesightAllAreaStuYear() {}

  // 二师视力健康填报-各批次近视情况
  @url('/v3/visual/ershi/getErshiEyesightMyopiaByBatch')
  @post
  getErshiEyesightMyopiaByBatch() {}

  // 二师视力健康填报-视力分布情况-分布
  @url('/v3/visual/ershi/getErshiEyesightMyopiaDistribution')
  @post
  getErshiEyesightMyopiaDistribution() {}

  // 获取某日的应用使用统计
  @url('/sys/api/log/v2/getSchoolAppTj')
  @postJson
  getSchoolAppTj() {}

  // 获取某日的用户使用统计
  @url('/sys/api/log/v2/getSchoolTj')
  @postJson
  getSchoolTj() {}

  // 获取取某日某学校或者区域用户使用总统计
  @url('/sys/api/log/v2/getSchoolTjByid')
  @postJson
  getSchoolTjByid() {}

  // 获取区域用户近30天的使用总统计
  @url('/sys/api/log/v2/getSchoolTjByidLatest')
  @postJson
  getSchoolTjByidLatest() {}

  // 获取户近30天的用户使用统计
  @url('/sys/api/log/v2/getSchoolTjLatest')
  @postJson
  getSchoolTjLatest() {}

  // 不同学校类型的学校数量
  @url('/sys/area/visual/typeToSchoolCount')
  @postJson
  typeToSchoolCount() {}

  // 不同学校类型的学生数量
  @url('/sys/area/visual/typeToStuCount')
  @post
  typeToStuCount() {}

  // 不同学段的学生数量
  @url('/sys/area/visual/learnPeriodToStuCount')
  @post
  learnPeriodToStuCount() {}

  // 不同学校类型的老师数量
  @url('/sys/area/visual/typeToTeaCount')
  @post
  typeToTeaCount() {}

  // 不同职称的老师数量
  @url('/sys/area/visual/jobToTeaCount')
  @post
  jobToTeaCount() {}

  // 不同学段的老师数量
  @url('/sys/area/visual/learnPeriodToTeaCount')
  @post
  learnPeriodToTeaCount() {}

  // 不同性别的老师数量
  @url('/sys/area/visual/sexToTeaCount')
  @post
  sexToTeaCount() {}

  // 不同学历的老师数量
  @url('/sys/area/visual/educationToTeaCount')
  @post
  educationToTeaCount() {}

  // 老师列表（职位）
  @url('/sys/area/visual/jobTeaList')
  @post
  jobTeaList() {}

  // 学校列表
  @url('/sys/area/visual/schoolList')
  @post
  schoolList() {}

  // 学生列表
  @url('/sys/area/visual/stuList')
  @post
  stuList() {}

  // 老师列表（学校类型）
  @url('/sys/area/visual/typeTeaList')
  @post
  typeTeaList() {}

  // 老师列表
  @url('/sys/area/visual/teaList')
  @post
  teaList() {}
}
export default new DataVisualization()
