import { post, url } from '../../service/ApiWebDecorator'

class AppCategory {
  // 添加分类信息
  @url('/v3/fac/category/add')
  @post
  categoryAdd() {}
  // 删除分类
  @url('/v3/fac/category/delete')
  @post
  categoryDelete() {}
  // 查询分类详情
  @url('/v3/fac/category/getById')
  @post
  categoryGetById() {}
  // 查询应用页面
  @url('/v3/fac/category/listFacCategory')
  @post
  categoryListFacCategory() {}
  // 分页查询应用基础信息
  @url('/v3/fac/category/pageFacCategory')
  @post
  categoryPageFacCategory() {}
  // 修改分类信息
  @url('/v3/fac/category/update')
  @post
  categoryUpdate() {}
}

export default new AppCategory()
