/**
 * api 拦截器
 * author: lgzhang@gmail.com
 * date: 2019-4-20
 *
 * 功能说明：
 *  1 统一增加header， token
 *  2 统一加载转圈处理，移动端 window.vant.Toast({type:loading,message:''}),web端 element loading
 *  3 统一异常处理，移动端mistoken重新认证，web端跳转登录页
 */

import axiosOrigin from 'axios'

import DoUrl from '../utils/DoUrl'
import LoginToken from './auth/LoginToken'
import CacheAxios from '../utils/storage/CacheAxios'

var axios = axiosOrigin.create({
  baseURL: window.GateWayServer
})

axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
// 声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
// 调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0
let endLoadingCtrl = true
// let loadingInstance = ''

function startLoading() {
  VueInstance.$store.commit('ajaxLoadingFinished', false)
  // 延迟100秒，如果100毫秒数据回来就不用loading了
  needLoadingRequestCount++
  // 应用工厂自定义loading开启了就不用默认loading
  if (sessionStorage.globalLoadImage) return
  setTimeout(() => {
    if (needLoadingRequestCount > 0) {
      if (window.CommonService) location.href.includes('SDP') ? VueInstance.$sdpLoading?.show() : window.CommonService?.Loading()
    }
  }, 100)
}

function endLoading() {
  VueInstance.$store.commit('ajaxLoadingFinished', true)
  if (window.CommonService) location.href.includes('SDP') ? VueInstance.$sdpLoading?.hide() : window.CommonService.Loading?.close()
}

function tryHideFullScreenLoading() {
  needLoadingRequestCount--
  if (needLoadingRequestCount < 0) needLoadingRequestCount = 0
  setTimeout(() => {
    if (needLoadingRequestCount <= 0) {
      endLoading()
    }
  }, parseInt(Math.random() * 500))
  // 50秒超时也隐藏
  if (endLoadingCtrl) {
    endLoadingCtrl = false
    setTimeout(() => {
      endLoadingCtrl = true
      endLoading()
      needLoadingRequestCount = 0
    }, 120000)
  }
}

axios.interceptors.request.use(function(config) {
  // 临时配置，后端服务
  if (/http/.test(config.url) == false && DoUrl.getSearchByName('TomcatServer')) {
    config.url = location.protocol + '//' + DoUrl.getSearchByName('TomcatServer') + config.url
  }
  // mock数据处理，mock=true
  if (/mock=true/.test(window.location.href) || /mock=true/.test(config.url) || /mock=true/.test(config.data)) {
    config.method = 'get'
    config.url = window.MockServer + '/mock-data' + config.url.split('?')[0] + '.json?mock=true&noCache=' + (new Date()).getTime()
  }
  // 统一增加后端服务地址
  if (/(http|https):\/\//.test(config.url) == false) {
    config.url = window.GateWayServer + config.url
  }

  // 统一带上schoolId, 文件上传不需要带oss-img
  let schoolId = ''
  if (Cookies.get('EOSSchoolId')) schoolId = Cookies.get('EOSSchoolId')
  let seperate = config.url.indexOf('?') > -1 ? '&' : '?'
  if (config.method.toLocaleLowerCase() === 'post') {
    if (/oss-img/.test(config.url) === false && schoolId) config.url += seperate + 'schoolId=' + schoolId
    // 添加强制修改标识
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    if (DoUrl.getSearchByName('forceUpdate')) config.url += seperate + 'forceUpdate=' + DoUrl.getSearchByName('forceUpdate')
  } else if (config.method.toLocaleLowerCase() === 'get') {
    if (schoolId) config.url += seperate + 'schoolId=' + schoolId
    // 添加强制修改标识
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    if (DoUrl.getSearchByName('forceUpdate')) config.url += seperate + 'forceUpdate=' + DoUrl.getSearchByName('forceUpdate')
  }
  // 封装应用 appSourceType 1
  // TODO 数据权限需要区分 封装应用和独立应用，暂时通过 url路径表示 "/admin/index.html?id=".indexOf('index.html?id') > -1标识
  // TODO 新增判断如果路由包含micro-container/admin，则代表不是封装应用，后期这块有可能还要改，先这样判断兼容下
  if (location.href.indexOf('?id') == -1 && location.href.indexOf('?appCode') == -1 && location.href.indexOf('micro-container/admin') == -1) {
    let seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    if (/oss-img/.test(config.url) === false) config.url += seperate + 'appSourceType=1'
  }
  // 判断区域单校混合使用
  if (DoUrl.getSearchByName('isArea')) {
    let isArea = DoUrl.getSearchByName('isArea')
    if (isArea == 0) {
      isArea = 'N'
    } else if (isArea == 1) {
      isArea = 'Y'
    }
    if (config.method.toLocaleLowerCase() === 'post') {
      config.url += seperate + 'isArea=' + isArea
    } else if (config.method.toLocaleLowerCase() === 'get') {
      config.url += seperate + 'isArea=' + isArea
    }
  }
  // TODO 应用工厂预览页面和提交页面接口分开标识，source字段，代表数据来源，7为预览数据
  seperate = config.url.indexOf('?') > -1 ? '&' : '?'
  if (/\/app-preview/.test(location.href) && !/source=7/.test(config.url)) {
    config.url += seperate + 'source=7'
  }
  // TODO 学校发展平台课程池页面携带source ='kcc'
  if (['kcss', 'kcc'].includes(DoUrl.getSearchByName('sourcePage')) && /SDP/.test(location.href) && !config.url.includes('source=') && !config.url.includes('/v3/export/')) {
    if (config.method == 'get') {
      config.url += '&source=' + DoUrl.getSearchByName('sourcePage')
    } else { // post请求 formData和requestPayload
      Object.prototype.toString.call(config.data) === '[object Object]' ? config.data.source = DoUrl.getSearchByName('sourcePage') : config.url += '&source=' + DoUrl.getSearchByName('sourcePage')
    }
  }
  // 应用工厂统一增加应用id facAppId
  if (/no-code/.test(location.href) && DoUrl.getSearchByNameHash('appId')) {
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    config.url += seperate + 'facAppId=' + DoUrl.getSearchByNameHash('appId')
  }
  // 素养护照 中使用应用工厂时，赋值facAppId
  if (config.method?.toLocaleLowerCase() === 'post' && config?.data?.facAppId && !/facAppId/.test(config.url)) {
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    config.url += seperate + 'facAppId=' + config?.data?.facAppId
  }
  // 素养管理端统一放弃缓存dynmicCacheFlag 2
  if (DoUrl.getSearchByNameNoHash('appCode') || DoUrl.getSearchByNameNoHash('appId')) {
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    config.url += seperate + 'dynmicCacheFlag=2'
  }
  // 是否当前学期
  const isCurrentTerm = VueInstance.$store.getters.isCurrentTerm === undefined ? 1 : VueInstance.$store.getters.isCurrentTerm
  // 历史数据管理，传递termId
  if (DoUrl.getSearchByName('historyTermId') || !isCurrentTerm) {
    const historyTermId = DoUrl.getSearchByName('historyTermId') || DoUrl.getSearchByName('schoolTermId') || DoUrl.getSearchByName('termId') || VueInstance.$store.schoolTermId
    config.url += seperate + `historyTermId=${historyTermId}`
  }
  // 毕业生历史数据管理
  if (DoUrl.getSearchByName('graduateYear')) {
    config.url += seperate + `bysFlg=true`
  }
  // 文件上传不需要设置header
  if (/oss-img/.test(config.url)) return config
  // 添加 应用标识
  if (DoUrl.getSearchByName('appCode')) {
    config.headers.eosAppCode = DoUrl.getSearchByName('appCode')
  } else if (location.href.indexOf('?id') > -1) {
    config.headers.eosAppId = DoUrl.getSearchByName('id')
  } else if (DoUrl.getSearchByName('appId')) {
    config.headers.eosAppId = DoUrl.getSearchByName('appId')
  }

  // 增加 header token
  let token = Cookies.get('EOSToken') || ''
  if (Cookies.get('SDPOperateToken')) token = Cookies.get('SDPOperateToken') || ''
  if (token) config.headers.Authorization = 'Bearer ' + token
  // post !(/loading=false/.test(config.data)) postJson (config.data && config.data.loading !== false) get !(/loading=false/.test(config.url))
  if (!(/loading=false/.test(window.location.href)) && (!((/loading=false/.test(config.data)) || (config.data && config.data.loading == false) || (/loading=false/.test(config.url))))) {
    // 带token的为分享页面或者pdf导出，不需要loading
    if (!/token=/.test(location.href)) {
      startLoading()
    } else {
      needLoadingRequestCount++
    }
  }
  // 增加第二token，用于跨学校认证
  if (sessionStorage.EOSTmpToken) config.headers.Authorization2 = 'Bearer ' + sessionStorage.EOSTmpToken
  // 第三方 认证 token
  if (DoUrl.getSearchByName('thirdToken') && !config.headers.Authorization) {
    config.headers.Authorization = 'Bearer ' + DoUrl.getSearchByName('thirdToken')
  }
  // 如果是下载地址，直接打开url
  if (/isDownloadUrl/.test(config.url)) {
    window.location.href = config.url
    return Promise.reject({})
  }
  if ((/no-code/.test(location.href) || /preview-info/.test(location.href) || /previewtInfo/.test(location.href)) && !/source/.test(config.url)) {
    seperate = config.url.indexOf('?') > -1 ? '&' : '?'
    // source  100：应用工厂正式 7：预览或设置页面, 预览页面permission-setting也用预览
    if (/app-preview/.test(location.href) || /\/app-layout/.test(location.href) || /\/permission-setting/.test(location.href) || /\/app-data-exchange/.test(location.href)) {
      config.url += `${seperate}source=7`
    } else {
      config.url += `${seperate}source=1`
    }
  }
  // 缓存预处理
  config = CacheAxios.preCache(config)
  return config
}, function(error) {
  return Promise.reject(error)
})

// respone拦截器
axios.interceptors.response.use(
  response => {
    tryHideFullScreenLoading()
    // TODO 拦截器临时处理区域可视化接口
    if (response && response.config && response.config.url && response.config.url.indexOf('regionalDemo') > -1) {
      return response.data
    }
    // 学校发展中判断学期接口中当前学期是否在对应学段时间返回内，不在则提示
    if (response.config.url.indexOf('/region/commom/listTermBeanByAreaId') > -1 && location.href.includes('SDP')) {
      let currentTerm = response?.data?.data?.find(row => { return row.isNow == 'Y' })
      if (!(new Date(currentTerm.stime).getTime() <= new Date().getTime() && new Date().getTime() < new Date(currentTerm.etime).getTime())) {
        try {
          Vue.prototype.$elNotify({
            type: 'warning',
            title: '警告',
            message: '当前时间与当前学期对应的时间范围不匹配，请联系学校管理员修改',
            position: 'bottom-right',
            duration: 5000
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
    if (response.data.success && (response.data.errorCode == null || response.data.errorCode == 0)) {
      // 缓存数据
      CacheAxios.setCache(response.config, response.data.data)
      // 只返回用到的data
      return response.data.data
    } else {
      /**
       * code为非200是抛错 可结合自己业务进行修改
       */
      if (response.data.errorCode == 'MISTOKEN' || response.data.errorCode == '-107' || response.data.errorCode == '-109') { // mistoken走认证
        // 保存当前url
        let httpUrl = window.location.href
        const routerArr = ['/user/select', '/router']
        let isCludes = false
        for (let i = 0; i < routerArr.length; i++) {
          if (httpUrl.indexOf(routerArr[i]) != -1) {
            isCludes = true
          }
        }
        if (!isCludes) localStorage.setItem('lastUrl', httpUrl)
        // 访客token存在，优先处理
        if (sessionStorage.vistorToken) {
          return LoginToken.doVistorLink(localStorage.lastUrl)
        }
        // 移动端重新走认证，web端跳出登录页
        if (location.pathname == '/m/') window.location.href = location.pathname
        else {
          // 跳转到登录页
          Cookies.remove('EOSToken')
          window.location.replace('/eos-web/#/login')
        }
      } else {
        // 未知错误不显示 参数为空不提示
        // 错误码不提示，未知错误不提示
        if (response.data && response.data.errorDesc.indexOf('未知错误') == -1 && response.data.errorDesc != '参数为空' && response.data.errorDesc.includes('错误码') == false) {
          // 若不是正确的返回code，且已经登录，就抛出错误
          try {
            // TODO 发展平台教师视图，增加判断,checkUserDataAppPermission接口不需要提示
            if (location.href.indexOf('teaView') != -1 && response.config.url.indexOf('checkUserDataAppPermission') != -1) {
              return Promise.reject(response)
            }
            // 临时增加判断，素养护照和教师发展版面设计获取eos字段是报错问题
            if (response.data.errorCode != '1420') {
              console.log('response', response)
              window.CommonService.Alert({ message: response.data.errorDesc })
            }
            return Promise.reject(response)
          } catch (error) {
            console.log('error')
          }
        }
        if (response.data.errorDesc.indexOf('未知错误') > -1) {
          window.CommonService.Alert({ message: '系统错误，请截图并联系管理员(' + response.data.data + ')' })
        }
        if (response.data.errorCode == '-901') {
          window.CommonService.Alert({ message: response.data.errorDesc || '系统繁忙！请稍后' })
        }
        return Promise.reject(response)
      }
    }
    // TODO 不知道为什么要放这个，页面渲染不对?
    setTimeout(() => {
      // 通过滚动强制浏览器进行页面重绘
      document.body.scrollTop += 1
    }, 50)
  },
  err => {
    tryHideFullScreenLoading()
    // 请求拦截器中的source.cancel会将内容发送到error中
    // 通过axios.isCancel(error)来判断是否返回有数据 有的话直接返回给用户
    if (axiosOrigin.isCancel(err)) {
      return Promise.resolve(err.message)
    }
    // 这里是返回状态码不为200时候的错误处理
    switch (err && err.response && err.response.status) {
      case 400:
        err.message = '请求错误'
        break

      case 401:
        err.message = '未授权，请登录'
        break

      case 403:
        err.message = '拒绝访问'
        break

      case 404:
        err.message = '请求地址出错404'
        break

      case 408:
        err.message = '请求超时'
        break

      case 500:
        err.message = '服务器内部错误'
        break

      case 501:
        err.message = '服务未实现'
        break

      case 502:
        err.message = '网关错误'
        break

      case 503:
        err.message = '服务不可用'
        break

      case 504:
        err.message = '网关超时'
        break

      case 505:
        err.message = 'HTTP版本不受支持'
        break

      default:
    }
    return Promise.reject(err)
  }
)

export default axios
// 导出成全局，方便使用
window.globalAxios = axios
