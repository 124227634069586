/**
 * 表单类型 type
 1 单行input 2 多行textarea 3 日期时间 4 单项选择 5 多项选择 6 图片上传 7 平分 8 描述文本 9 地理位置 10 选人插件,
 11 显示项 12 学生信息 13 系统信息 14 邮箱 15 电话 16选择列表 17 多选列表 18自动编号
 19 整数 20 小数 21 百分数 22 日期 23 公式 24 引用 25 省市区 26 邮编 27 身份证 28 音频 29 视频 34 级联单选 35 级联多选 52评分
 56 学期 57 学年
 */
// 数值型 运算
const DigitalOperate = [0, 1, 2, 3, 4, 5, 6, 7]
//  字符运算
const CharOperate = [8, 9, 10, 11, 12, 13]
// 物料分类
class FormType {
    constructor() {
      // 数值型
      this.Digital = [19, 20, 21, 52]
      // 枚举型字段
      this.Enum = [4, 5, 16, 17, 34, 35]
      // 可以设置进度条的枚举型字段
      this.EnumProgress = [4, 5, 16, 17]
      // 时间型字段
      this.Datetime = [3, 22]
      // 输入型
      this.Input = [1, 2, 6, 9, 10, 14, 15, 25, 26, 28, 29, 31]
      // 基础字段排序,前32个为基础字段 加入文字、图标字段、图片
      this.baseSortList = [...Array.from(new Array(36).keys()), 47, 48, 49, 52, 55, 57, 56, 58]
      // 高级字段
      this.advanceSortList = [32, 36, 37]
      // 布局字段
      this.layoutSortList = [38, 39, 40, 42, 43, 46, 51]
      // 布局嵌套字段 32-分组表单, 38-分栏, 39-容器, 46-遍历(遍历不能嵌套表格或遍历),45-tab字段
      this.layoutTreeList = [32, 38, 39, 45, 46]
      // 表格type
      this.tableType = 43
      // 表格单元格type
      this.tableCellType = 44
      // 遍历type
      this.loopType = 46
      // 遍历组件 - 表格和遍历
      this.layoutLoopType = [43, 46]
      // 素养 表单，用前38
      this.sySortList = Array.from(new Array(38).keys()).concat(52)
      // 其他系统使用字段，
      this.sySystemFieldsList = ['10000', '10001', '10002', '10003', '10004']
      // eos 表单，用前38
      this.eosSortList = [1, 2, 3, 4, 5, 6, 8, 9, 14, 15, 16, 17, 19, 20, 21, 22, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 45, 50, 54]
      // 不需要逐条编辑的字段，用于数据应用新建后逐条编辑
      this.noEditFields = [30, 32]
      // 自动编号支持选择字段暂时只支持4,16
      this.autoCodeSelect = [4, 16]
      this.autoCodeModel = ['yyyy-MM-dd HH:mm:ss', 'yyyy/MM/dd HH:mm:ss', 'yyyy-MM-dd', 'yyyy-MM', 'yyyy/MM', 'yyyy', 'MM', 'dd', 'HH', 'yyyy/MM/dd', 'yyyy年MM月dd日', 'MM月dd日', 'yyyyMMdd', 'MMdd', 'yyyyMMddHHmmss', 'yy', 'RANDOM_NO', 'RANDOM_a', 'RANDOM_A', 'RANDOM_aNO', 'RANDOM_aNO', 'RANDOM_aANO', 'SORT_ALL', 'SORT_YEAR', 'SORT_MONTH', 'SORT_DAY']
      // 字段分类
      this.formCategory = {
          int: { // 数字型字段
            operateType: [9],
            operateRule: {
              9: DigitalOperate
            },
            list: [18, 19, 20, 21, 30, 52],
          },
          char: { // 字符型字段
            operateType: [8, 9],
            operateRule: {
              8: DigitalOperate,
              9: CharOperate
            },
            list: [1, 2],
          },
          enum: { // 枚举型字段
            operateType: [9],
            operateRule: {
              9: [...CharOperate, ...[14, 15]]
            },
            list: [4, 5, 16, 17],
          },
          date: { // 时间型字段
            operateType: [9],
            operateRule: {
              9: [...DigitalOperate, 12, 13]
            },
            list: [3, 22],
          },
          input: { // 输入型字段
            operateType: [9],
            operateRule: {
              9: [12, 13]
            },
            list: [6, 9, 10, 14, 15, 25, 26, 28, 29, 31],
          },
          cascade: { // 级联单选
            operateType: [9],
            operateRule: {
              9: [...CharOperate, ...[14, 15]]
            },
            list: [34, 35],
          },
        }
        // eos字段与素养字段向对应
      this.eosControlType = {
        0: 1,
        1: 2,
        2: 8,
        3: 4,
        4: 5,
        5: 4,
        6: 3,
        7: 6,
        8: 6,
        10: 33,
        12: 8,
        13: 1
      }
        // 字段列表
        const list = {
            1: {
                name: '单行',
                type: 'input',
                catagory: 'char',
                fieldType: 0
            },
            2: {
                name: '多行',
                type: 'textarea',
                catagory: 'char',
                fieldType: 0
            },
            3: {
                name: '日期时间',
                type: 'datetime',
                icon: true,
                catagory: 'date',
                fieldType: 1
            },
            4: {
                name: '单选框',
                type: 'radio',
                catagory: 'enum',
                fieldType: 3
            },
            5: {
                name: '多选框',
                type: 'checkbox',
                catagory: 'enum',
                fieldType: 3
            },
            6: {
                name: '图片上传',
                type: 'imgUpload',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            8: {
                name: '描述文本',
                type: 'desText',
                catagory: 'input',
                fieldType: 0
            },
            9: {
                name: '地理位置',
                type: 'position',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            10: {
                name: '选人插件',
                type: 'selectMember',
                icon: true,
                catagory: 'input'
            },
            // 11: {
            //     name: '显示项',
            //     type: 'show'
            // },
            // 12: {
            //     name: '学生信息',
            //     type: 'stuInfo'
            // },
            // 13: {
            //     name: '系统信息',
            //     type: 'sysInfo'
            // },
            14: {
                name: '邮箱',
                type: 'email',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            15: {
                name: '电话',
                type: 'phone',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            16: {
                name: '选择列表',
                type: 'selectList',
                catagory: 'enum',
                fieldType: 3
            },
            17: {
                name: '多选列表',
                type: 'multiList',
                catagory: 'enum',
                fieldType: 3
            },
            // 18: {
            //     name: '自动编号',
            //     type: 'autoList'
            // },
            19: {
                name: '整数',
                type: 'int',
                catagory: 'int'
            },
            20: {
                name: '小数',
                type: 'decimal',
                catagory: 'int',
                fieldType: 2
            },
            21: {
                name: '百分数',
                type: 'percent',
                catagory: 'int',
                fieldType: 2
            },
            22: {
                name: '日期',
                type: 'date',
                icon: true,
                catagory: 'date',
                fieldType: 1
            },
            // 23: {
            //     name: '公式',
            //     type: 'formula'
            // },
            // 24: {
            //     name: '引用',
            //     type: 'quote'
            // },
            25: {
                name: '省市区',
                // province city district
                type: 'PCD',
                catagory: 'input',
                fieldType: 0
            },
            26: {
                name: '邮编',
                type: 'postCode',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            27: {
                name: '身份证',
                // identification card
                type: 'ID',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            28: {
                name: '音频',
                type: 'audio',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            29: {
                name: '视频',
                type: 'video',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            30: {
              name: '数据处理',
              type: 'formData',
              catagory: 'int',
              fieldType: 2
            },
            31: {
                name: '附件',
                type: 'accessory',
                icon: true,
                catagory: 'input',
                fieldType: 0
            },
            32: {
                name: '分组表单',
                type: 'groupForm',
                catagory: 'input'
            },
            34: {
                name: '级联单选',
                type: 'casRadio',
                catagory: 'cascade'
            },
            35: {
                name: '级联多选',
                type: 'casCheckbox',
                catagory: 'cascade'
            },
            36: {
                name: '人脸识别',
                type: 'imgOCR',
                catagory: 'input',
                comsType: ''
            },
            37: {
              name: '支付明细',
              type: 'payComs',
            },
            // 33: {
            //   name: '投票',
            //   type: 'radio',
            //   catagory: 'enum',
            //   fieldType: 3
            // },
          38: {
            name: '分栏',
            type: 'columns',
          },
          39: {
            name: '容器',
            type: 'container',
          },
          40: {
            name: '按钮',
            type: 'button',
            buttonType: 'button'
          },
          42: {
            name: '分页',
            type: 'pagination',
            // pagination
          },
          43: {
            name: '表格',
            type: 'table'
          },
          44: {
            name: '单元格',
            type: 'Cell'
          },
          45: {
            name: 'tab',
            type: 'tab'
          },
          46: {
            name: '遍历',
            type: 'traversal'
          },
          47: {
            name: '文字',
            type: 'nocodeWord'
          },
          48: {
            name: '图标',
            type: 'nocodeIcon'
          },
          49: {
            name: '图片',
            type: 'nocodeImg'
          },
          50: {
            name: '通用选人',
            type: 'comSelectMember'
          },
          51: {
            name: 'iframe',
            type: 'iframe'
          },
          52: {
            name: '评分',
            type: 'score',
            catagory: 'int',
            fieldType: 2,
            typeJson: { shapeStyle: 'star', shapeCount: 5, shapeUseType: 1, shapeValue: 1 }
          },
          // 前端用，非系统字段类型
          53: {
            name: '多选操作',
            type: 'multiOpera',
          },
          54: {
            name: '自动编号',
            type: 'autoCode',
          },
          55: {
            name: '扫码',
            type: 'scanCode',
          },
          57: {
            name: '学年',
            type: 'schoolYear',
          },
          56: {
            name: '学期',
            type: 'schoolTerm',
          },
          58: {
            name: '二维码',
            type: 'qrCode',
          },
      }
      // 系统字段
      const systemList = {
        '10000': {
          eosType: '10000',
          type: 6,
          name: '证件照',
          catagory: 'input',
          iconName: 'ID'
        },
        '10001': {
          eosType: '10001',
          type: 1,
          name: '姓名',
          catagory: 'input',
          iconName: 'name'
        },
        '10002': {
          eosType: '10002',
          type: 1,
          name: '账号',
          catagory: 'input',
          iconName: 'ID'
        },
        '10003': {
          eosType: '10003',
          type: 10,
          name: '所属部门',
          catagory: 'input',
          iconName: 'department'
        },
        '10004': {
          eosType: '10004',
          type: 1,
          name: '手机号',
          catagory: 'input',
          iconName: 'department'
        },
        '10005': {
          eosType: '10005',
          type: 32,
          name: '拓展表单',
          catagory: 'input',
          iconName: 'groupForm'
        },
        '10006': {
          eosType: '10006',
          type: 27,
          name: '身份证号',
          catagory: 'input',
          iconName: 'idCard'
        },
        '10007': {
          eosType: '10007',
          type: 5,
          name: '角色',
          catagory: 'input',
          iconName: 'roles'
        },
        '10008': {
          eosType: '10008',
          type: 4,
          name: '性别',
          catagory: 'input',
          iconName: 'sex'
        },
        '10009': {
          eosType: '10009',
          type: 1,
          name: '学籍号',
          catagory: 'input',
          iconName: 'stuCode'
        },
        '10010': {
          eosType: '10010',
          type: 1,
          name: '学号',
          catagory: 'input',
          iconName: 'schoolstuCode'
        },
        '10011': {
          eosType: '10011',
          type: 3,
          name: '入学时间',
          catagory: 'input',
          iconName: 'admissionTime'
        },
        '10012': {
          eosType: '10012',
          type: 1,
          name: '家长手机号',
          catagory: 'input',
          iconName: 'parentPhones'
        },
        '10013': {
          eosType: '10013',
          type: 16,
          name: '科目',
          catagory: 'input',
          iconName: 'subjectId'
        },
        '10014': {
          eosType: '10014',
          type: 16,
          name: '学历',
          catagory: 'input',
          iconName: 'education'
        },
        '10015': {
          eosType: '10015',
          type: 16,
          name: '职位',
          catagory: 'input',
          iconName: 'jobTitle'
        },
        '10016': {
          eosType: '10016',
          type: 3,
          name: '入职时间',
          catagory: 'input',
          iconName: 'joinTime'
        },
        '10017': {
          eosType: '10017',
          type: 1,
          name: '工资账户',
          catagory: 'input',
          iconName: 'salaryAccount'
        },
        '10018': {
          eosType: '10018',
          type: 1,
          name: '考勤卡号',
          catagory: 'input',
          iconName: 'timeCardNum'
        },
        '10019': {
          eosType: '10019',
          type: 6,
          name: '头像',
          catagory: 'input',
          iconName: 'idPhoto'
        },
        '10020': {
          eosType: '10020',
          type: 14,
          name: '邮箱',
          catagory: 'input',
          iconName: 'email'
        },
       /*
        '10021': {
          eosType: '10021',
          type: 57,
          name: '学年',
          catagory: 'schoolYear',
          iconName: 'schoolYear'
        },
        '10022': {
          eosType: '10022',
          type: 56,
          name: '学期',
          catagory: 'schoolTerm',
          iconName: 'schoolTerm'
        }*/
      }
      this.list = list
      this.systemList = systemList
      /**
       * selectOption
       * 自调用方法
       * @return {Array} [{name: name(字段名), value: type（字段类型）}]
       * 可用于下拉框单选多选的option
       */
      this.selectOption = (() => {
        let selectOption = []
        for (let i in this.list) {
          selectOption.push({
            name: this.list[i].name,
            value: i
          })
        }
        return selectOption
      })()
      // 按钮扩展字段， typeJson.buttonType: export为导出
      this.buttonTyeList = [
        {
          name: '导出',
          type: 'button',
          typeId: '40',
          buttonType: 'export'
        },
        {
          name: '导入',
          type: 'button',
          typeId: '40',
          buttonType: 'import'
        },
        {
          name: '退出',
          type: 'button',
          typeId: '40',
          buttonType: 'logout'
        },
      ]
      // 不能设置自定义图标的组件
      /* page 图标  文字  图片 分栏  容器  按钮  分页  表格  单元格  遍历  iframe  导出*/
      this.excludeSetFontIcon = [47, 48, 49, 38, 39, 40, 42, 43, 44, 46, 51, 55]
    }
}

export default new FormType()
// 暴露成全局，方便使用
window.FormType = new FormType()
