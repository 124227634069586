import { get, post, url, postJson } from '../../service/ApiWebDecorator'

// 组织机构
class Passport {
  // 获取展示单元(素养护照)
  @url('/v3/passport/getPassportRevealUnitByConditions')
  @post
  getPassportRevealUnitByConditionsPAS() { }
  // 获取展示单元
  @url('/v3/teaDevelop/getPassportRevealUnitByConditions')
  @post
  getPassportRevealUnitByConditions() {}

  // 删除展示单元(旧)
  @url('/v3/passport/deletePassportRevealUnit')
  @post
  deletePassportRevealUnitPAS() {}

  // 删除展示单元(教师发展)
  @url('/v3/teaDevelop/deletePassportRevealUnit')
  @post
  deletePassportRevealUnit() {}

  // 新建报表内容切换
  @url('/v3/passport/getDataByCondition')
  @post
  getDataByCondition() {}

  // 素养护照数据源获取过程
  @url('/v3/passport/getCourseDataAppList')
  @get
  getCourseDataAppList() {}

  // 保存展示单元(素养护照)
  @url('/v3/passport/savaPassportRevealUnit')
  @post
  savaPassportRevealUnitPAS() {}
  // 保存展示单元
  @url('/v3/teaDevelop/savaPassportRevealUnit')
  @post
  savaPassportRevealUnit() {}
  // 编辑保存单元(素养护照)
  @url('/v3/passport/updatePassportRevealUnit')
  @post
  updatePassportRevealUnitPAS() {}
  // 编辑保存单元
  @url('/v3/teaDevelop/updatePassportRevealUnit')
  @post
  updatePassportRevealUnit() {}

  // 编辑查询
  @url('/v3/passport/selectPassportRevealUnit')
  @post
  selectPassportRevealUnit() {}

  /* 素养护照设计接口*/

  // 用户注册接口
  @url('/v3/passport/getPassportData')
  @post
  getPassportData() {}

  // 拖拽信息实时保存
  @url('/v3/passport/updatePassportStuInfo')
  @post
  updatePassportStuInfo() {}

  // 点击预览接口
  @url('/v3/passport/getPreviewAllStuInfo')
  @post
  getPreviewAllStuInfo() {}

  // 保存
  @url('/v3/passport/savePassportInfo')
  @post
  savePassportInfo() {}

  // 重置
  @url('/v3/passport/resetPassportInfo')
  @post
  resetPassportInfo() {}

  // 点击学生预览
  @url('/v3/passport/getPreviewStuInfo')
  @post
  getPreviewStuInfo() {}

  // 预览查询--布局内容
  @url('/v3/passport/getPreviewPassportInfo')
  @post
  getPreviewPassportInfo() {}

  // 保存后预览
  @url('/v3/passport/getPreviewStuDetailPassportInfo')
  @post
  getPreviewStuDetailPassportInfo() {}

  // 学生分析进入素养护照预览
  @url('/v3/passport/getStuDetailPassportInfo')
  @post
  getStuDetailPassportInfo() {}

  // 个人全面发展
  @url('/v3/passport/getTargetScoreByCondition')
  @post
  getTargetScoreByCondition() {}
  // 从学生分析进入，判断是否有权限
  @url('/v3/passport/getSuYangPower')
  @post
  getSuYangPower() {}

  // 学生分析--三个图替换
  @url('/v3/passport/getDataPic')
  @post
  getDataPic() {}

  // 亦庄定制---过程性数据评价
  @url('/v3/passport/custom/getYIZHUANGPassportData')
  @post
  getYIZHUANGPassportData() {}

  // 亦庄定制---学习成绩
  @url('/v3/passport/custom/getYIZHUANGAchievementData')
  @post
  getYIZHUANGAchievementData() {}

  // 素养护照 得分明细
  @url('/v3/passport/getTargeRuleSumScore')
  @post
  getTargeRuleSumScore() {}

  // 素养护照  获得指标明细
  @url('/v3/stu/getStuScoreDetail')
  @post
  getStuScoreDetail() {}
  @url('/v3/target/getStuSorceDetailNotContainBasicScore')
  @post
  getStuSorceDetailNotContainBasicScore() {}

  // 获取得分最后一层详情
  @url('/v3/passport/getDetailDataByComputeId')
  @post
  getDetailDataByComputeId() {}
  // 获取得分最后一层详情 最新
  @url('/v3/passport/getDetailDataByRuleFlag')
  @post
  getDetailDataByRuleFlag() {}
   // 获取得分最后一层详情 最新
  @url('/v3/passport/listPassportModel')
  @post
  getlistPassportModel() {}
  // 删除素养护照模板列表页
  @url('/v3/passport/removePassportModel')
  @post
  removePassportModel() {}
  // 获取学生的指标模板
  @url('/region/targetModel/getTargetModelByUserId')
  @post
  getTargetModelByUserId() {}
  // 获取学生的学期
  @url('/v3/passport/listAllTermByUser')
  @post
  listAllTermByUser() {}
  // 综合素质呈现学生列表
  @url('/region/passport/listUser')
  @postJson
  listUser() {}

  @url('/v3/passport/getStuList')
  @post
  getStuList() {}
  // 获取学年
  @url('/v3/api/query/listAreaTermYearList')
  @post
  listAreaTermYearList() {}
  // 获取学期
  @url('/v3/api/query/listTermBeanByYear')
  @post
  listTermBeanByYear() {}
  // 获取素养护照模板类型
  @url('/v3/passport/getRecentTempleModelList')
  @post
  getRecentTempleModelList() {}
  // 获取素养护照模板类型
  @url('/v3/passport/listAllTermStartEndTime')
  @post
  listAllTermStartEndTime() {}
  // 获取学年列表
  @url('/center/api/schoolYear/getList')
  @get
  getSchoolYearList() {}
  // 获取模型
  @url('/v3/passport/getOrgModel')
  @post
  getOrgModel() {}

  // 模板选择列表
  @url('/v3/passport/listPassportModelByStuSchoolYear')
  @post
  listPassportModelByStuSchoolYear() {}

  // 素养护照数据应用（区域下发）字段提换
  @url('/v3/passport/replaceItems')
  @postJson
  replaceItems() {}
}

export default new Passport()
