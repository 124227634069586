/*
家校中心移动端接口
*/
import { url, post, postJson } from '../../service/ApiDecorator'
class HomeSchCenterMobile {
  // 老师端-删除活动
  @url('/homeschoolcenter/activity/deleteHscActivityById')
  @post
  deleteHscActivityById() {}

  // 家长端-活动详情
  @url('/homeschoolcenter/activity/getActivityById')
  @post
  getActivityById() {}

  // 家长端-获取活动列表
  @url('/homeschoolcenter/activity/pageFamilyHscActivity')
  @post
  pageFamilyHscActivity() {}

  // 老师端-获取活动列表
  @url('/homeschoolcenter/activity/pageHscActivity')
  @post
  pageHscActivity() {}

  // 老师端-发布活动
  @url('/homeschoolcenter/activity/saveBatchHscActivityInfo')
  @postJson
  saveBatchHscActivityInfo() {}

  // 家长端-提交签到
  @url('/homeschoolcenter/sign/saveActivitySignRelate')
  @post
  saveActivitySignRelate() {}

  // 教师端-获取活动感想列表
  @url('/homeschoolcenter/thought/pageThoughtByActivityId')
  @post
  pageThoughtByActivityId() {}

  // 家长端-提交感想
  @url('/homeschoolcenter/thought/saveThoughtRelateByActivity')
  @post
  saveThoughtRelateByActivity() {}

  // 家长端-获取家庭得分
  @url('/homeschoolcenter/thoughtevaluate/getFamilyScoreByStuId')
  @post
  getFamilyScoreByStuId() {}

  // 教师端-批量评价
  @url('/homeschoolcenter/thoughtevaluate/saveBatchThoughtEvaluate')
  @postJson
  saveBatchThoughtEvaluate() {}

  // 教师端-全量全选批量评价
  @url('/homeschoolcenter/thoughtevaluate/saveAllThoughtRelate')
  @post
  saveAllThoughtRelate() {}

  // 教师端-获取该登录人必须是班主任所在的组织集合
  @url('/homeschoolcenter/activity/getOrgListByUserId')
  @post
  getOrgListByUserId() {}

  // 家长端-获取家长下的学生集合
  @url('/homeschoolcenter/activity/getStuBeanByParentId')
  @post
  getStuBeanByParentId() {}

  // 家长端和教师端-点击家庭积分进入详情页
  @url('/homeschoolcenter/activity/pageFamilyScoreDetailsHscActivity')
  @post
  pageFamilyScoreDetailsHscActivity() {}

  // 教师端-点击活动积分进入学生分页列表并展示得分
  @url('/homeschoolcenter/thoughtevaluate/pageStuAndFamilyScore')
  @post
  pageStuAndFamilyScore() {}

  // 教师端-感想详情
  @url('/homeschoolcenter/thought/getThoughtById')
  @post
  getThoughtById() {}

  // 教师端-批量评语
  @url('/homeschoolcenter/thoughtevaluate/saveBatchEvaluateDes')
  @post
  saveBatchEvaluateDes() {}

  // 教师端-没有评语但有评分感想列表
  @url('/homeschoolcenter/thought/pageNoEvalThoughtByActivityId')
  @post
  pageNoEvalThoughtByActivityId() {}

  // 教师端-查看提交统计
  @url('/homeschoolcenter/thought/getActivityStatistics')
  @post
  getActivityStatistics() {}

  // 教师端-家庭活动家长已经查看
  @url('/homeschoolcenter/wiew/pageViewPer')
  @post
  pageViewPer() {}

  // 教师端-家庭活动家长没有查看
  @url('/homeschoolcenter/wiew/pageNoViewPer')
  @post
  pageNoViewPer() {}

  // 教师端-查看签到家长
  @url('/homeschoolcenter/sign/pageSignPer')
  @post
  pageSignPer() {}

  // 教师端-查看没有签到
  @url('/homeschoolcenter/sign/pageNoSignPer')
  @post
  pageNoSignPer() {}

  // 教师端-查看提交感想家长
  @url('/homeschoolcenter/thought/pageThoughtPer')
  @post
  pageThoughtPer() {}

  // 教师端-查看没有提交感想
  @url('/homeschoolcenter/thought/pageNoThoughtPer')
  @post
  pageNoThoughtPer() {}

  // 家长端-保存查看家庭课程
  @url('/homeschoolcenter/wiew/saveHscActivityViewRelate')
  @post
  saveHscActivityViewRelate() {}

  // 教师端-获取学期列表
  @url('/homeschoolcenter/activity/getTermList')
  @post
  getTermList() {}

  // 教师端-获取课程列表
  @url('/homeschoolcenter/activity/pageCourse')
  @post
  pageCourse() {}
}
export default new HomeSchCenterMobile()
