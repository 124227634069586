import { post, url, } from '../../service/ApiWebDecorator'

// 数据应用

class ExamineCustom {
  // 数据Api
  @url('/v3/api/getApiInfoPage')
  @post
  getApiInfoPage() {}
  // 数据Api
  @url('/v3/api/getApiInfoMethodPage')
  @post
  getApiInfoMethodPage() {}
  // 审核字段删除
  @url('/v3/deleteTaskItemExtend')
  @post
  deleteTaskItemExtend() {}
  // 判断任务是否是审核任务和是否需要审核赋分
  @url('/v3/compute/getTaskCheckInfoAndScoreType')
  @post
  getTaskCheckInfoAndScoreType() {}

  // 判断自主赋分、审核是否需要赋分(审核任务判断是否有审核权限、不是审核判断是否有修改权限)
  @url('/v3/compute/getNeedSetScoreByAppId')
  @post
  getNeedSetScoreByAppId() {}

  // 获取驳回原因
  @url('/v3/getDefaultCheckInfo')
  @post
  getDefaultCheckInfo() {}
}
export default new ExamineCustom()
