import { post, url } from '../../service/ApiWebDecorator'

// 教师发展档案
class Archives {
  // 获取老师列表信息---表格
  @url('/v3/admin/common/getTeacherDetailList')
  @post
  getTeacherDetailList() {}

  // 获取竞赛类别 -表格筛选条件
  @url('/v3/admin/common/getRaceCategories')
  @post
  getRaceCategories() {}

  // 获取级别-表格筛选条件
  @url('/v3/admin/common/getLevel')
  @post
  getLevel() {}

  // 获取奖次-表格筛选条件
  @url('/v3/admin/common/getAwardLevel')
  @post
  getAwardLevel() {}

  // 获取自定义筛选条件信息
  @url('/v3/admin/common/getConditions')
  @post
  getConditions() {}

  // 导出教师档案
  @url('/v3/admin/common/exportTeaRecord')
  @post
  exportTeaRecord() {}

  // 导出奖项图片
  @url('/v3/admin/common/exportTeaImg')
  @post
  exportTeaImg() {}

  // 获取老师详情信息 --title信息
  @url('/v3/admin/common/getEOSTeacherInfo')
  @post
  getEOSTeacherInfo() {}

  // 场景任务内容
  @url('/v3/admin/common/listFormValueResp')
  @post
  listFormValueResp() {}

  // 场景任务内容
  @url('/v3/admin/common/getTaskField')
  @post
  getTaskField() {}
}

export default new Archives()
